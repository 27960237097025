import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import enGb from 'date-fns/locale/en-GB';
registerLocale('en-gb', enGb);

const DateBox = props => (
  <>
    {props.attributes.placeholder || props.attributes.info ? (
      <label>
        {props.attributes.placeholder === 'Cover Reveal Date '
          ? 'Cover Reveal Date'
          : props.attributes.placeholder === 'Ticket sales start date*'
          ? 'Ticket/Book box sale start date*:'
          : props.attributes.placeholder}
        {props.attributes.placeholder === 'Ticket sales start date*' ? '' : ':'}
        {props.attributes.info ? (
          <span style={{ marginLeft: '5px' }}>
            <i
              className="fa fa-question-circle"
              data-tip
              data-for={props.attributes.display_name}
              style={{ fontSize: '1.2em' }}
            />
            <ReactTooltip
              className="toolTip"
              id={props.attributes.display_name}
              aria-haspopup="true"
              effect="solid"
              place="right"
            >
              <>
                <p style={{ margin: 0, padding: 0 }}>
                  {props.attributes.info ===
                  'Ticket sales start date to be featured on the OTR calendar.'
                    ? 'Ticket/book box sale start date to be featured on the OTR calendar in lilac.'
                    : props.attributes.info ===
                      'Book signing event to be featured on the OTR calendar for its duration.'
                    ? 'Book signing to be featured in the OTR calendar for its duration (in purple).'
                    : props.attributes.info}
                </p>
                <p style={{ margin: 0, padding: 0 }}>
                  {props.attributes.info ===
                  'Book signing event to be featured on the OTR calendar for its duration.'
                    ? 'Book box start and end date is the open sale date(s), and end date can be updated when sold out.'
                    : ' '}
                </p>
              </>
              {/* {paras.map(el => {
            return [<p style={{ margin: 0, padding: 0 }}>{el}</p>]
          })} */}
            </ReactTooltip>
          </span>
        ) : (
          ''
        )}
      </label>
    ) : (
      ''
    )}
    <DatePicker
      minDate={props.minDate}
      selected={
        props.attributes.value && props.attributes.value !== 'null'
          ? moment(props.attributes.value).toDate()
          : ''
      }
      className={
        props.submitted && props.attributes.value === ''
          ? 'form-control invalid'
          : 'form-control'
      }
      placeholderText="Click to select a date"
      onChange={date =>
        props.IsUnderCustom
          ? props.onCustomHandler(
              date,
              props.index,
              props.parentIndex,
              'date',
              props.attributes.display_name
            )
          : props.onChangeDateHandler(
              date,
              props.index,
              props.parentIndex,
              'date'
            )
      }
      maxDate={props.maxDate ? new Date(props.maxDate) : ''}
      required={!!props.attributes.validation}
      locale="en-gb"
      calendarStartDay={1}
    />
  </>
);
export default DateBox;
