import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import Logo from '../../Components/left-logo';
import '../Landing/landing.css';
import Footer from './footer';
import { getstatic } from '../../Actions/index';
import axios from 'axios';
import { BASEURL } from '../../constant';
import Layout from '../layout/layout';

class Condition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      version: '',
      id: ''
    };

    this.getTermsConditions = this.getTermsConditions.bind(this);
  }

  async getTermsConditions() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getTermsConditions'
      );
      console.log({ response });
      if (response.data.length)
        this.setState({
          content: response.data[0].consent_body,
          version: response.data[0].version,
          id: response.data[0].id
        });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount = async () => {
    this.getTermsConditions();
    // let route = this.props.history.location.pathname;
    // route = route.split("/").pop();
    // // let data = getPrivacy.data.result;

    // if (route) {
    //   const headers = {
    //     "x-authorization": localStorage.getItem("token"),
    //   };
    //   const getPrivacy = await axios.get(BASEURL + `/api/v1/condition`, {
    //     headers: headers,
    //   });
    //   if (getPrivacy.status === 200) {
    //     const { consent_body } = getPrivacy.data.result;
    //     console.log(
    //       "Body =>",
    //       getPrivacy.data.result.Condition[0].consent_body
    //     );
    //     this.setState({
    //       consent_body: getPrivacy.data.result.Condition[0].consent_body,
    //     });
    //   }
    // }
  };

  render() {
    window.scrollTo(0, 0);
    console.log('this.state.consent_body', this.state);
    return (
      <Layout>
        <div
          className="terms-condition terms-condition-second"
          style={
            localStorage.getItem('betaagreement') ? {} : { paddingBottom: 50 }
          }
        >
          <div className="container">
            <div className="mb-4">
              <h2 className="text-center heading">TERMS &amp; CONDITIONS</h2>
            </div>

            {this.state.content && (
              <div
                contentEditable="false"
                className="msg-text"
                dangerouslySetInnerHTML={{ __html: this.state.content }}
              ></div>
            )}
            {localStorage.getItem('betaagreement') && (
              <div className="edit-profile-btn">
                <a href="/" class="">
                  <button
                    type="submit"
                    className="btn new_btn_save_bt m-0"
                    style={{
                      marginRight: '0% !important'
                    }}
                  >
                    Back to Dashboard
                  </button>
                </a>
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }

  componentDidMount() {
    // this.props.resetSignupPage();
    // console.log('componentDidMount');

    this.getTermsConditions();
  }
}
// {======= VideoFrames Component ========}

const mapStateToProps = state => ({
  pages: state.userListData
});

const mapDispatchToProps = dispatch => ({
  getstatic: url => dispatch(getstatic(url))
});
export default connect(mapStateToProps, mapDispatchToProps)(Condition);
