import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { notify } from 'react-notify-toast';
// import Logo from '../../Components/left-logo';
// import { Link, Route, Switch } from 'react-router-dom';
// import ReactHtmlParser from 'react-html-parser';
// import { css } from '@emotion/core';
// import $ from 'jquery';
import { getUpgradablePlans, upgragePlan } from '../../Actions/index';
import ConditionsModel from '../Static/conditionsModel';
// import PaymentModal from './paymentModal';
// import Thankyou from './thankyou';
// import paymentModal from './paymentModal';
import UpgradeModel from '../Static/upgradeModel';

class UpgradePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipType: null,
      title: null,
      showPrivacyModal: false,
      showPaymentModal: false,
      showUpgradeModal: false,
      planCost: 0,
      plansCost: {}
    };
    this.confirmPayment = this.confirmPayment.bind(this);
    this.addClientToken = this.addClientToken.bind(this);
    // this.handleRadioChange= this.handleRadioChange.bind(this);
  }

  // clearData(){
  //   localStorage.clear();
  // }

  openModal = () => {
    this.setState({ showPrivacyModal: true });
  };

  hideModal = () => {
    this.setState({ showPrivacyModal: false });
  };
  confirmPayment(e) {
    e.preventDefault();
    // this.togglePaymentModal();
    this.toggleUpgradeModal();
  }

  togglePaymentModal() {
    this.setState({
      showPaymentModal: !this.state.showPaymentModal
      // profileData: {}
    });
  }
  toggleUpgradeModal() {
    this.setState({
      showUpgradeModal: !this.state.showUpgradeModal
      // profileData: {}
    });
  }

  addClientToken(token) {
    console.log('token in ', token);
    this.togglePaymentModal();

    this.props.upgradePlan(
      '/api/v1/users/upgragePlan',
      {
        membershipId: this.state.membershipType,
        clientToken: token
      },
      { headers: { 'x-authorization': localStorage.getItem('token') } }
    );
  }

  handleRadioChange = event => {
    this.setState({
      membershipType: event.target.value,
      planCost: this.state.plansCost[event.target.value]
    });
  };

  // complete_signup(membership) {

  // }

  render() {
    const membershipDetails = this.props.membershipDetails.length
      ? this.props.membershipDetails
      : [];
    let isTypeExists = membershipDetails.length > 1 ? true : false;
    let membershipId = null;
    // let plan = null;

    if (membershipDetails.length) {
      return (
        <div style={{ backgroundColor: '#1f1f1f' }}>
          {/* <PaymentModal
            isOpen={this.state.showPaymentModal}
            addClientToken={this.addClientToken}
            planCost={this.state.planCost}
            toggle={this.togglePaymentModal.bind(this)}
          /> */}
          <UpgradeModel
            isOpen={this.state.showUpgradeModal}
            addClientToken={this.addClientToken}
            toggle={this.toggleUpgradeModal.bind(this)}
            membershipType={this.state.membershipType}
          />

          {/* <div className="container-fluid"> */}
          <div className="main_right upgrade-plan-page">
            <form onSubmit={e => this.confirmPayment(e)}>
              <div className="author_main_s">
                <div className="modal-body_s">
                  <h1>{this.state.title ? this.state.title : 'Membership'}</h1>
                  {/* <ul className="model_text"> */}
                  <span></span>

                  {membershipDetails &&
                    membershipDetails.map(result => {
                      if (result.length) {
                        return result.map(membershipDetails => {
                          let key = Object.keys(membershipDetails)[0];
                          let value = Object.values(membershipDetails)[0];

                          if (key == 'membershipId' && !isTypeExists) {
                            this.state.membershipType = value;
                          }

                          if (key == 'membershipId' && isTypeExists) {
                            membershipId = value;
                          }

                          if (key == 'planCost' && !isTypeExists) {
                            this.state.planCost = +value;
                          }

                          if (key == 'planCost' && isTypeExists) {
                            this.state.plansCost[membershipId] = value;
                          }

                          if (key == 'header' && isTypeExists) {
                            return (
                              <div>
                                <input
                                  type="radio"
                                  value={membershipId}
                                  name="plan"
                                  id={membershipId}
                                  checked={
                                    this.state.membershipType === membershipId
                                  }
                                  onChange={this.handleRadioChange}
                                  required
                                />{' '}
                                <label for={membershipId}>
                                  <b>{value} </b>
                                </label>
                              </div>
                            );
                          }

                          if (Object.keys(membershipDetails).length !== 0) {
                            if (key == 'header') {
                              this.state.title = value;
                            }
                            if (
                              key &&
                              (key.toLowerCase() === 'undefined' ||
                                key.toLowerCase() === 'header' ||
                                key === 'membershipId' ||
                                key ==
                                  'Publicly display email on profile page?' ||
                                key == 'emailDisplay' ||
                                key == 'planCost')
                            ) {
                              return;
                            }

                            if (key == 'subheader') {
                              return (
                                <span>
                                  <b>{value}</b>
                                </span>
                              );
                            } else {
                              return (
                                <li>
                                  <span>{value}</span>
                                </li>
                                // {/* <span> <b>{value[0]}</b></span></li> */}
                              );
                            }
                          }
                        });
                      }
                    })}

                  {/* </ul> */}
                </div>
              </div>

              <ConditionsModel
                isOpen={this.state.showPrivacyModal}
                toggle={this.hideModal.bind(this)}
              />
              <div></div>
              <div className="thanktwo_main">
                {/* <form className="form_custom"> */}
                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-filled pull-right"
                    value="PAY NOW"
                  />
                </div>
                {/* </form> */}
              </div>
            </form>
          </div>
        </div>
        // </div>
      );
    }
    // else if (this.props.success) {
    //   return (
    //     <div style={{ backgroundColor: "#1f1f1f" }}>
    //       <div className="container-fluid">
    //         <div className="row signupmain">
    //           <Logo />
    //           <div className="main_right">
    //             <div className="thankyou_cover">
    //               <div className="inner_thankyou">
    //                 <h1>Thank You</h1>
    //                 <p>For Becoming An </p>
    //                 <p>ON target reader Member</p>
    //                 <div className="button_btft" style={{ marginTop: "10%" }}>
    //                   <div className="col-md-12 text-center"  >
    //                     <Link to="/log-in"
    //                       onClick={() => localStorage.clear()}
    //                       className="btn new_btn_save_bt float-md-right">
    //                       Login
    //               </Link>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //   )
    // }
    else {
      return <div></div>;
    }
  }

  componentDidMount() {
    if (this.props.props.location && this.props.props.location.state) {
      const { user_type } = this.props.props.location.state;
      if (user_type) {
        this.props.getMembershipDetails(
          `/api/v1/upgradePlanDetails/${user_type}`,
          { headers: { 'x-authorization': localStorage.getItem('token') } }
        );
      } else {
        this.props.props.history.push('/dashboard');
      }
    } else {
      this.props.props.history.push('/dashboard');
    }
  }
}

const mapStateToProps = state => {
  return {
    loading: state.signuploading,
    membershipDetails: state.getMembershipDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMembershipDetails: (url, header) =>
      dispatch(getUpgradablePlans(url, header)),
    upgradePlan: (url, body, headers) =>
      dispatch(upgragePlan(url, body, headers))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlan);
