import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import './style.css';
import '../Landing/landing.css';
import '../Footer/footer.css';
import { Table, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { getstatic } from '../../Actions/index';
import { css } from '@emotion/core';
import Logo from '../../Components/left-logo';
import Footer from './footer';
import $ from 'jquery';
import { browserName, CustomView } from 'react-device-detect';
import axios from 'axios';
import { BASEURL } from '../../constant';
import Layout from '../layout/layout';
import createHistory from 'history/createHashHistory';

const override = css`
  position: relative;
`;
const globalHistory = new createHistory();

class membershipTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
      modal7: false,
      modal7: false
    };
  }
  handleSubmit = async e => {
    e.preventDefault();

    const { title, content } = this.state;
    await axios.post(BASEURL + 'api/v1/plans', {
      title,
      content
    });

    await this.setState({ message: '' });

    await this.notifyHandle();
  };

  toggle = () => this.setState({ modal: !this.state.modal });
  toggle1 = () => this.setState({ modal1: !this.state.modal1 });
  toggle2 = () => this.setState({ modal2: !this.state.modal2 });
  toggle3 = () => this.setState({ modal3: !this.state.modal3 });
  toggle4 = () => this.setState({ modal4: !this.state.modal4 });
  toggle5 = () => this.setState({ modal5: !this.state.modal5 });
  toggle6 = () => this.setState({ modal6: !this.state.modal6 });
  toggle7 = () => this.setState({ modal7: !this.state.modal7 });
  toggle8 = () => this.setState({ modal8: !this.state.modal8 });

  handleRefresh = () => {
    globalHistory.push('/join');
  };

  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Layout>
          {/* <div className="Addbackcolour"> */}
          {/* <div className="main_right author_profile"> */}
          <div className="membership-plan-page">
            <div className="container-fluid pt-3">
              <div className="mb-4">
                <h2 className="text-center heading">Membership plans</h2>
              </div>

              <p className="sub_heading" style={{ marginLeft: '14%' }}>
                <b>Target the plan that’s right for you:</b>
              </p>
              <div className="content">
                <Col md="12" sm="12" xs="12" className="mx-auto" id="scroll">
                  <Table bordered className="mt-2 equal-width" id="table1">
                    <thead>
                      <tr id="tr" className="firsttr">
                        <th id="border" className="stickhead"></th>
                        <th id="border" className="stickhead"></th>
                        <th id="border" className="stickhead"></th>
                        <th
                          className="btn-yellow border_round stickhead1"
                          id="border"
                        >
                          <span className="header-tag best_value">
                            BEST VALUE
                          </span>
                        </th>
                        <th id="border" className="stickhead"></th>
                        <th id="border" className="stickhead"></th>
                        <th
                          className="btn-purple border_round stickhead1"
                          id="border"
                        >
                          <span className="header-tag best_value">
                            BEST VALUE
                          </span>
                        </th>
                        <th id="border" className="stickhead"></th>
                      </tr>

                      <tr className="text-center sticky-row secondtr">
                        <th id="border1" className="stickhead">
                          <h6 className="text-left title">Membership type</h6>
                        </th>
                        <th className="head stickhead headtitle aligncenter">
                          <h3 className="red main_head">READER</h3>
                        </th>
                        <th className="head stickhead headtitle">
                          <h3 className="orange main_head">BLOGGER</h3>
                        </th>
                        <th className="head stickhead headtitle">
                          <h3 className="yellow main_head">BLOGGER PREMIUM</h3>
                        </th>
                        <th className="head stickhead headtitle">
                          <h3 className="green main_head">VENDOR</h3>
                        </th>
                        <th className="head stickhead headtitle">
                          <h3 className="blue main_head">AUTHOR</h3>
                        </th>
                        <th className="head stickhead headtitle">
                          <h3 className="purple main_head">AUTHOR PREMIUM</h3>
                        </th>
                        <th className="head stickhead headtitle">
                          <h3 className="pink main_head"> PR FIRM BOOKSTORE</h3>
                        </th>
                      </tr>

                      <tr className="text-center sticky-row thirdtr">
                        <th id="border1" className="stickhead"></th>
                        <th className="head stickhead headDescription aligncenter">
                          <p className="sub_head">
                            Exclusive Reader membership.
                          </p>
                        </th>
                        <th className="head stickhead headDescription">
                          <p className="sub_head">
                            Reader membership plus access to PR features.
                          </p>
                        </th>
                        <th className="head stickhead headDescription">
                          <p className="sub_head">
                            Blogger membership with public profile & PR
                            features.
                          </p>
                        </th>
                        <th className="head stickhead headDescription">
                          <p className="sub_head">
                            Network with authors & vendors plus have full use of
                            reader calendar.
                          </p>
                        </th>
                        <th className="head stickhead headDescription">
                          <p className="sub_head">
                            A multi-purpose membership exclusive to Authors.
                          </p>
                        </th>
                        <th className="head stickhead headDescription">
                          <p className="sub_head author-description1">
                            All the Author plan perks plus{' '}
                            <i className="text-italic">unlimited</i> book
                            releases.
                          </p>
                        </th>
                        <th className="head stickhead headDescription">
                          <p className="sub_head">
                            Membership tailored to PR professionals &
                            Bookstores.
                          </p>
                        </th>
                      </tr>

                      <tr className="text-center sticky-row fourthtr">
                        <th id="border1" className="stickhead"></th>
                        <th className="head stickhead headele aligncenter color1">
                          <div>
                            <p className="font-weight-bold">
                              <div className="item1 ">
                                <strike className="red">
                                  <span className="black price_1 ">$39 </span>
                                </strike>

                                <span className="launch">LAUNCH: </span>
                                <span className="freeclass">FREE*</span>
                              </div>
                              <div class="item2 free-tag">
                                <span className="usd">USD/year</span>
                                <span className="launch ">
                                  FIRST YEAR <br />
                                  <i>(*limited time)</i>
                                </span>
                              </div>
                            </p>
                            {/* <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-red"
                            >
                              Sign up
                            </Link> */}
                            <button
                              onClick={this.handleRefresh}
                              target="_blank"
                              className="button btn-red"
                            >
                              Sign up
                            </button>
                          </div>
                        </th>
                        <th className="head stickhead headele color2">
                          <div>
                            <p className="font-weight-bold">
                              <div className="item1">
                                <strike className="orange">
                                  <span className="black price_1">$59 </span>
                                </strike>
                                <span className="launch">LAUNCH: </span>
                                <span className="price_1">$49</span>
                              </div>

                              <div className="item2">
                                <span className="usd">USD/year</span>
                              </div>
                            </p>
                            {/* <Link
                              to={'/sign-up'}
                              className="button btn-orange"
                              target="_blank"
                            >
                              Sign up
                            </Link> */}
                            <button
                              onClick={this.handleRefresh}
                              target="_blank"
                              className="button btn-orange"
                            >
                              Sign up
                            </button>
                          </div>
                        </th>
                        <th className="head stickhead headele color3">
                          <div>
                            <p className="font-weight-bold txt">
                              <div className="item1">
                                <strike className="yellow">
                                  <span className="black price_1">$89 </span>
                                </strike>
                                <span className="launch">LAUNCH: </span>
                                <span className="price_1">$59</span>
                              </div>

                              <div className="item2">
                                <span className="usd">USD/year</span>
                              </div>
                            </p>

                            {/* <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-yellow"
                            >
                              Sign up
                            </Link> */}
                            <button
                              onClick={this.handleRefresh}
                              target="_blank"
                              className="button btn-yellow"
                            >
                              Sign up
                            </button>
                          </div>
                        </th>
                        <th className="head stickhead headele color4">
                          <div>
                            <p className="font-weight-bold mt-2">
                              <div className="item1">
                                <strike className="green">
                                  <span className="black price_1">$149 </span>
                                </strike>
                                <span className="launch">LAUNCH: </span>
                                <span className="price_1">$99</span>
                              </div>
                              <div className="item2">
                                <span className="usd">USD/year</span>
                              </div>
                            </p>
                            {/* <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-green"
                            >
                              Sign up
                            </Link> */}
                            <button
                              onClick={this.handleRefresh}
                              target="_blank"
                              className="button btn-green"
                            >
                              Sign up
                            </button>
                          </div>
                        </th>
                        <th className="head stickhead headele color5">
                          <div>
                            <p className="font-weight-bold txt">
                              <div className="item1">
                                <strike className="blue">
                                  <span className="black price_1">$390 </span>
                                </strike>
                                <span className="launch">LAUNCH: </span>
                                <span className="price_1">$260</span>
                              </div>
                              <div className="item2">
                                <span className="usd">USD/year</span>
                              </div>
                            </p>
                            {/* <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-blue"
                            >
                              Sign up
                            </Link> */}
                            <button
                              onClick={this.handleRefresh}
                              target="_blank"
                              className="button btn-blue"
                            >
                              Sign up
                            </button>
                          </div>
                        </th>
                        <th className="head stickhead headele color6">
                          <div>
                            <p className="font-weight-bold txt">
                              <div className="item1">
                                <strike className="purple">
                                  <span className="black price_1">$580 </span>
                                </strike>
                                <span className="launch">LAUNCH: </span>
                                <span className="price_1">$390</span>
                              </div>

                              <div className="item2">
                                <span className="usd">USD/year</span>
                              </div>
                            </p>
                            {/* <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-purple"
                            >
                              Sign up
                            </Link> */}
                            <button
                              onClick={this.handleRefresh}
                              target="_blank"
                              className="button btn-purple"
                            >
                              Sign up
                            </button>
                          </div>
                        </th>
                        <th className="head stickhead headele color7">
                          <div>
                            <p className="font-weight-bold txt">
                              <div className="item1">
                                <strike className="pink">
                                  <span className="black price_1">$720 </span>
                                </strike>
                                <span className="launch">LAUNCH: </span>
                                <span className="price_1">$540</span>
                              </div>
                              <div className="item2">
                                <span className="usd">USD/year</span>
                              </div>
                            </p>
                            {/* <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-pink"
                            >
                              Sign up
                            </Link> */}
                            <button
                              onClick={this.handleRefresh}
                              target="_blank"
                              className="button btn-pink"
                            >
                              Sign up
                            </button>
                          </div>
                        </th>
                      </tr>

                      <tr
                        className="text-center hiderow"
                        style={{ height: '50%' }}
                      >
                        <th id="border1" className="stickhead">
                          Membership type
                        </th>
                        <th id="heading" className="head ">
                          <div className="headerSection">
                            <h5 className="red main_head">READER</h5>
                          </div>

                          <div className="headerSection">
                            <p className="sub_head">
                              Exclusive Reader membership.
                            </p>
                          </div>

                          <div>
                            <p className="font-weight-bold">
                              <div>
                                <strike className="red">
                                  <span className="black price_1">$25 </span>
                                </strike>

                                <span className="launch">LAUNCH: </span>
                                <span>FREE*</span>
                              </div>
                              <div class="d-flex justify-content-center">
                                <span className="usd mr-4">USD/year</span>
                                <span className="launch ">
                                  FIRST YEAR <br />
                                  (*limited time)
                                </span>
                              </div>
                            </p>
                            <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-red"
                            >
                              Sign up
                            </Link>
                          </div>
                        </th>
                        <th id="heading" className="head ">
                          <div className="margin1">
                            <h5 className="orange mb-5 main_head">BLOGGER</h5>
                            <p className="sub_head">
                              Reader membership plus access to PR features.
                            </p>
                          </div>

                          <div className="btn1">
                            <p className="font-weight-bold">
                              <strike className="orange">
                                <span className="black price_1">$39 </span>
                              </strike>
                              <span className="launch">LAUNCH: </span>
                              <span className="price_1">$29</span>
                              <br />
                              <span className="usd">USD/year</span>
                            </p>
                            <Link
                              to={'/sign-up'}
                              className="button btn-orange"
                              target="_blank"
                            >
                              Sign up
                            </Link>
                          </div>
                        </th>
                        <th id="heading" className="head ">
                          <div className="margin1">
                            <h5 className="yellow mb-3 main_head">
                              BLOGGER PREMIUM
                            </h5>
                            <p className="sub_head">
                              Blogger membership with public profile & PR
                              features.
                            </p>
                          </div>

                          <div className="btn1">
                            <p className="font-weight-bold txt">
                              <strike className="yellow">
                                <span className="black price_1">$59 </span>
                              </strike>
                              <span className="launch">LAUNCH: </span>
                              <span className="price_1">$49</span>
                              <br />
                              <span className="usd">USD/year</span>
                            </p>

                            <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-yellow"
                            >
                              Sign up
                            </Link>
                          </div>
                        </th>
                        <th id="heading" className="head ">
                          <div className="margin1">
                            <h5 className="green mb-5 main_head">VENDOR</h5>
                            <p className="sub_head">
                              Full use of the OTR reader calendar plus ability
                              to connect & network with authors.
                            </p>
                          </div>
                          <div className="btn1">
                            <p className="font-weight-bold mt-2">
                              <strike className="green">
                                <span className="black price_1">$120 </span>
                              </strike>
                              <span className="launch">LAUNCH: </span>
                              <span className="price_1">$90</span>
                              <br />
                              <span className="usd">USD/year</span>
                            </p>
                            <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-green"
                            >
                              Sign up
                            </Link>
                          </div>
                        </th>
                        <th id="heading" className="head ">
                          <div className="margin1">
                            <h5 className="blue mb-5 main_head">AUTHOR</h5>
                            <p className="sub_head">
                              A multi-purpose membership exclusive to Authors.
                            </p>
                          </div>
                          <div className="btn1">
                            <p className="font-weight-bold txt">
                              <strike className="blue">
                                <span className="black price_1">$360 </span>
                              </strike>
                              <span className="launch">LAUNCH: </span>
                              <span className="price_1">$240</span>
                              <br />
                              <span className="usd">USD/year</span>
                            </p>
                            <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-blue"
                            >
                              Sign up
                            </Link>
                          </div>
                        </th>
                        <th id="heading" className="head ">
                          <div className="margin1">
                            <h5 className="purple mb-4 main_head">
                              AUTHOR PREMIUM
                            </h5>
                            <p className="sub_head">
                              All the Author plan perks plus <i>unlimited</i>{' '}
                              book releases.
                            </p>
                          </div>

                          <div className="btn1">
                            <p className="font-weight-bold txt">
                              <div className="item1">
                                <strike className="purple">
                                  <span className="black price_1">$540 </span>
                                </strike>
                                <span className="launch">LAUNCH: </span>
                                <span className="price_1">$360</span>
                              </div>

                              <div className="item2">
                                <span className="usd">USD/year</span>
                              </div>
                            </p>
                            <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-purple"
                            >
                              Sign up
                            </Link>
                          </div>
                        </th>
                        <th id="heading" className="head ">
                          <div className="margin1">
                            <h5 className="pink mb-5 main_head">PR FIRM</h5>
                            <p className="sub_head">
                              Membership tailored specifically to PR
                              professionals.
                            </p>
                          </div>
                          <div className="btn1">
                            <p className="font-weight-bold txt">
                              <div className="item1">
                                <strike className="pink">
                                  <span className="black price_1">$720 </span>
                                </strike>
                                <span className="launch">LAUNCH: </span>
                                <span className="price_1">TBD</span>
                              </div>
                              <div className="item2">
                                <span className="usd">USD/year</span>
                              </div>
                            </p>
                            <Link
                              to={'/sign-up'}
                              target="_blank"
                              className="button btn-pink"
                            >
                              Sign up
                            </Link>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="txt">
                        <th id="border1" style={{ verticalAlign: 'top' }}>
                          <h6 className="text-left title">Benefits</h6>
                        </th>
                        <td id="border2" className="details1 color1">
                          <ul className="text_data">
                            <li>
                              Full access to all OTR reader calendar events plus
                              easy filter/search functions.
                            </li>
                            <li>
                              Organize your reading calendar with options to set
                              daily/weekly reminders.
                            </li>
                            <li>
                              Customize your OTR reader calendar by adding &
                              saving events you wish to follow.
                            </li>
                            <li>
                              Quickly find links to pre-orders, book trailers,
                              teasers, playlists.
                            </li>
                            <li>
                              Bookmark links to release parties & giveaways.
                            </li>
                            <li>
                              Option to register as a beta reader on a list open
                              to authors.{''} &nbsp;
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle6}
                              ></i>
                            </li>
                            <li>
                              Access ARC sign-ups available to all readers.
                            </li>
                            <li>
                              Receive notifications when favorite authors share
                              releases & news.
                            </li>
                            <li>
                              Stay current by quickly viewing new, popular or
                              featured events.
                            </li>
                          </ul>
                        </td>
                        <td id="border3" className="details1 color2">
                          <ul className="text_data">
                            <li>
                              Exclusive blogger access to all Promo & ARC sign
                              ups.
                            </li>
                            <li>
                              Featured on the blogger list viewable by all
                              authors.
                            </li>
                            <li>
                              Quickly filter events specific to your blog.
                            </li>
                            <li>
                              Easy access to links for pre-orders, book
                              trailers, teasers, playlists.
                            </li>
                            <li>
                              Bookmark links to release parties & giveaways.
                            </li>
                            <li>
                              Option to register as a beta reader on a list open
                              to authors. &nbsp;
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle6}
                              ></i>
                            </li>
                            <li>
                              Full access to all calendar events with easy
                              filter/search functions.
                            </li>
                            <li>
                              Organize your reading calendar with options to set
                              daily/weekly reminders.
                            </li>
                            <li>
                              Receive notifications when favorite authors share
                              news & releases.
                            </li>
                            <li>
                              Stay current by quickly viewing new, popular or
                              featured events.
                            </li>
                          </ul>
                        </td>
                        <td id="border4" className="details1 color3">
                          <p className="text_data">
                            <i>
                              <b>Everything in Blogger plus:</b>
                            </i>
                          </p>
                          <ul className="text_data2 marging-10">
                            <li>
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>{' '}
                              Customize Blogger profile page with links to
                              social media &amp; blog events.
                            </li>
                            <li>
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>{' '}
                              <div>
                                Organize blog postings in Daily Task with the
                                option of reminders.{' '}
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                  onClick={this.toggle2}
                                ></i>
                              </div>
                            </li>
                            <li>
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>{' '}
                              Add giveaways & release parties to the OTR
                              calendar.
                            </li>
                            <li>
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>{' '}
                              <div>
                                Gain followers.{' '}
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                  onClick={this.toggle7}
                                ></i>
                              </div>
                            </li>
                            <li>
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>{' '}
                              <div>
                                Earn or purchase Blast to followers.{' '}
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                  onClick={this.toggle8}
                                ></i>
                              </div>
                            </li>
                          </ul>
                        </td>
                        <td id="border5" className="details1 color4">
                          <ul className="text_data">
                            <li>
                              Highlight your services, contact & social media
                              links on a customized Vendor page.
                            </li>
                            <li>
                              Offer any of the following services: Agent, Blurb
                              Writer, Book Boxes, Book Cover Designer, Book
                              Formatter, Book Signing Organizer, Cover Model,
                              Editor, Graphic Designer, Merchandising, Personal
                              Assistant (PA), Photographer, Proofreader,
                              Publisher, Social Media Consultant, Social Media
                              Manager, Swags, Web Design, Workshops
                            </li>
                            <li>
                              Network with authors & other vendors.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle4}
                              ></i>
                            </li>
                            <li>
                              Be featured on our vendor list viewable by all
                              authors.
                            </li>
                            <li>
                              Indicate on profile/vendor list if new clients are
                              being accepted.
                            </li>
                            <li>
                              To secure canceled bookings with new clients,
                              register Vendor Cancellation Openings (VCO), to be
                              featured on the Vendor tab.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle5}
                              ></i>
                            </li>

                            <li>
                              Organize your Daily Tasks with daily or weekly
                              reminder options.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle2}
                              ></i>
                            </li>
                            <li>
                              Promote book boxes & signings in the OTR calendar.
                            </li>
                            <li>
                              Option to register as a beta reader on a list open
                              to authors.{''} &nbsp;
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle6}
                              ></i>
                            </li>
                            <li>
                              Full access to all reader calendar events with
                              easy filter/search functions.
                            </li>
                            <li>
                              Organize your own reading calendar with options to
                              set daily/weekly reminders.
                            </li>
                          </ul>
                        </td>
                        <td id="border6" className="details1 color5">
                          <ul className="text_data">
                            <li>
                              Add three book releases per membership year with
                              add-on option at $160 USD per additional release.
                            </li>
                            <li>
                              Customize Author profile page with links to all
                              your calendar events, social media & contact
                              details.
                            </li>
                            <li>
                              3-in-1: Promote using Author/PR functions, network
                              &amp; organize your own reading calendar with
                              daily or weekly reminder options.
                            </li>
                            <li>
                              Add & manage book releases, release parties &
                              giveaways.
                            </li>
                            <li>
                              Bookmark links for pre-orders, book trailers,
                              playlists & teasers.
                            </li>
                            <li>
                              Free release day Blast to followers with option to
                              earn or purchase additional Blasts.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle}
                              ></i>
                            </li>
                            <li>
                              Provide bloggers easy access to sign-up forms for
                              PR opportunities such as cover reveals, ARCs,
                              release promo & blog tours.
                            </li>
                            <li>
                              Connect with beta readers & extend ARC signups to
                              all readers.
                            </li>
                            <li>
                              Access to list of all Blogger & Blogger Premium
                              members.
                            </li>
                            <li>
                              Increase exposure by gaining followers.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle1}
                              ></i>
                            </li>
                            <li>
                              Organize Daily Tasks with reminder options.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle2}
                              ></i>
                            </li>
                            <li>
                              Track your writing goals.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle3}
                              ></i>
                            </li>
                            <li>
                              Network with vendors (cover designers, editors
                              etc.), PR firms, bookstores & other authors.
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle4}
                              ></i>
                            </li>
                            <li>
                              Easily check for last-minute Vendor Cancellation
                              Openings to reserve services with previously
                              fully-booked vendors.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle5}
                              ></i>
                            </li>
                          </ul>
                        </td>
                        <td id="border7" className="details1 color6">
                          <p className="text_data">
                            <i>
                              <b>Everything in Author plus:</b>
                            </i>
                          </p>
                          <ul className="text_data2 marging-10">
                            <li>
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>{' '}
                              <div className="">
                                Add <i>unlimited</i> book releases to the OTR
                                calendar.
                              </div>
                            </li>
                          </ul>
                        </td>
                        <td id="border8" className="details1 color7">
                          <ul className="text_data">
                            <li>
                              Feature any of these services: PR Services,
                              Bookstore, Book boxes, Book signing organizer,
                              Merchandising, Publisher, Swags & Workshops.
                            </li>
                            <li>
                              {/* Add events to the OTR calendar, such as PR tours,
                              giveaways or release parties. */}
                              Add events to the calendar, such as PR tours for
                              clients, giveaways, release parties, book boxes &
                              signings.
                            </li>
                            <li>
                              Provide bloggers easy access to sign-up forms for
                              cover reveals, ARCs, release promo & blog tours.
                            </li>
                            <li>
                              {/* Customizable PR profile to highlight PR services,
                              hosted events, contacts & social media links. */}
                              Customizable PR profile to highlight services,
                              hosted events, contact & social media links.
                            </li>
                            <li>
                              Gain followers.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle7}
                              ></i>
                            </li>

                            <li>
                              Earn or purchase a Blast to followers.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle8}
                              ></i>
                            </li>
                            <li>
                              {/* Organize your postings in Daily Tasks.{' '} */}
                              Organize notes, to-do list & postings in Daily
                              Tasks with reminders
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle2}
                              ></i>
                            </li>

                            <li>
                              Network with authors & vendors (designers, social
                              media consultants etc.){' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle4}
                              ></i>
                            </li>

                            <li>
                              Get featured on the vendor list viewable by all
                              authors.
                            </li>
                            <li>
                              Indicate on profile/vendor list if new clients are
                              being accepted.
                            </li>
                            <li>
                              Secure canceled bookings with new clients,
                              register Vendor Cancellation Openings (VCO), to
                              get featured on the Vendor tab.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle5}
                              ></i>
                            </li>
                            <li>
                              Option to register as a beta reader on a list open
                              to authors.{' '}
                              <i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={this.toggle6}
                              ></i>
                            </li>
                            <li>
                              Full access to all OTR calendar events with easy
                              filter/search functions.
                            </li>
                            <li>
                              Organize your own reading calendar with options to
                              set daily/weekly reminders.
                            </li>
                          </ul>
                          {/* <p className="text_data">
                              *With our launch we offer 3-month barter
                              membership with PR firms, please contact SueBee:
                              xxx
                            </p> */}
                        </td>
                      </tr>
                      <tr>
                        <th id="border1">
                          <h6 className="text-left title">
                            Reader calendar access
                          </h6>
                        </th>

                        <td className="text-center alignMiddle aligncenter">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr>
                        <th id="border1">
                          <h6 className="text-left title">
                            Personal event calendar
                          </h6>
                        </th>

                        <td className="text-center alignMiddle aligncenter">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="txt">
                        <th id="border1">
                          <h6 className="text-left mt-5 title">
                            Viewable events
                          </h6>
                        </th>

                        <td className="details1 aligncenter">
                          <ul className="text_data3">
                            <li>Book releases</li>
                            <li>Cover reveals</li>
                            <li>Release parties</li>
                            <li>Giveaways</li>
                            <li>Book Boxes & Signings</li>
                            <li>OTR Public ARC sign-ups</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Book promos</li>
                            <li>All ARC sign-ups</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Book promos</li>
                            <li>All ARC sign-ups</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p className="text_data viewableTxt">
                            Same as Reader.
                          </p>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Book promos</li>
                            <li>All ARC sign-ups</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Book promos</li>
                            <li>All ARC sign-ups</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Book promos</li>
                            <li>All ARC sign-ups</li>
                          </ul>
                        </td>
                      </tr>
                      <tr className="txt">
                        <th id="border1">
                          <h6 className="text-left mt-3 title">
                            Set-up events
                          </h6>
                        </th>

                        <td className="text-center alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="text-center alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="details1">
                          <ul className="text_data3 ml-3">
                            <li>Release parties</li>
                            <li>Giveaways</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <ul className="text_data3 ml-3">
                            <li>Book Boxes & Signings</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <ul className="text_data3">
                            <li>Book releases *</li>
                            <li>Book promos</li>
                            <li>Cover reveals</li>
                            <li>ARC sign-ups</li>
                            <li>Release parties</li>
                            <li>Giveaways</li>
                            <li>Book Boxes & Signings</li>
                          </ul>
                          <p className="text_data4">
                            *Limit 3 book releases per year.
                          </p>
                        </td>
                        <td className="details1">
                          <ul className="text_data3">
                            <li>
                              <i>Unlimited</i> Book releases
                            </li>
                            <li>Book promos</li>
                            <li>Cover reveals</li>
                            <li>ARC sign-ups</li>
                            <li>Release parties</li>
                            <li>Giveaways</li>
                            <li>Book Boxes & Signings</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <ul className="text_data3">
                            <li>Book promos</li>
                            <li>Cover reveals</li>
                            <li>ARC sign-ups</li>
                            <li>Release parties</li>
                            <li>Giveaways</li>
                            <li>Book Boxes & Signings</li>
                          </ul>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">
                            Customizable profile page
                          </h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>

                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">
                            Profile visible to
                          </h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="details1">
                          <ul className="text_data3 ml-3">
                            <li>All OTR members</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <ul className="text_data3 ml-3">
                            <li>Authors</li>
                            <li>Author Premiums</li>
                            <li>Vendors</li>
                            <li>PR Firms/Bookstores</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <ul className="text_data3 ml-3">
                            <li>All OTR members</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <ul className="text_data3 ml-3">
                            <li>All OTR members</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <ul className="text_data3 ml-3">
                            <li>All OTR members</li>
                          </ul>
                        </td>
                      </tr>

                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">
                            Accessible tabs/pages
                          </h6>
                        </th>

                        <td className="details1">
                          <ul className="text_data3 ml-3">
                            <li>Home tab</li>
                            <li>Calendar tab</li>
                            <li>Event filter tab</li>
                            <li>List tab</li>
                            <li>Privacy policy</li>
                            <li>Terms & Conditions</li>
                            <li>Membership plans</li>
                            <li>About us</li>
                            <li>Help tab</li>
                            <li>Offers tab</li>
                            <li>Membership info page</li>
                            <li>Preference page</li>
                          </ul>
                        </td>
                        <td className="details1 aligncenter">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Edit Blogger contact</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>My Events tab</li>
                            <li>Daily Tasks tab</li>
                            <li>Profile page</li>
                            <li>Send Blast page</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Vendor tab</li>
                            <li>My Events tab</li>
                            <li>Daily Tasks tab</li>
                            <li>Messages tab</li>
                            <li>Profile page</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Vendor tab</li>
                            <li>My Events tab</li>
                            <li>Daily Tasks tab</li>
                            <li>Messages tab</li>
                            <li>Profile page</li>
                            <li>Send Blast page</li>
                            <li>Advertise tab</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Vendor tab</li>
                            <li>My Events tab</li>
                            <li>Daily Tasks tab</li>
                            <li>Messages tab</li>
                            <li>Profile page</li>
                            <li>Send Blast page</li>
                            <li>Advertise tab</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Vendor tab</li>
                            <li>My Events tab</li>
                            <li>Daily Tasks tab</li>
                            <li>Messages tab</li>
                            <li>Profile page</li>
                            <li>Send Blast page</li>
                            <li>Advertise tab</li>
                          </ul>
                        </td>
                      </tr>

                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">Accessible lists</h6>
                        </th>

                        <td className="details1">
                          <ul className="text_data3 ml-3">
                            <li>Add self as Beta Reader</li>
                            <li>Following list</li>
                            <li>All Profiles list</li>
                            <li>Most followed list</li>
                          </ul>
                        </td>
                        <td className="details1 aligncenter">
                          <p
                            className="text_data viewableTxt"
                            // style={{ fontStyle: 'italic' }}
                          >
                            Same as Reader.
                          </p>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Followers page</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            // style={{ fontStyle: 'italic' }}
                          >
                            Same as Reader.
                          </p>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Beta Reader list</li>
                            <li>Blogger list</li>
                            <li>Followers page</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Beta Reader list</li>
                            <li>Blogger list</li>
                            <li>Followers page</li>
                          </ul>
                        </td>
                        <td className="details1">
                          <p
                            className="text_data viewableTxt"
                            style={{ fontStyle: 'italic' }}
                          >
                            Everything in Reader plus:
                          </p>
                          <ul className="text_data3">
                            <li>Followers page</li>
                          </ul>
                        </td>
                      </tr>

                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">Private messaging</h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">Gain followers</h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">Follow favorites</h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">Send Blasts</h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">Receive Blasts</h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">
                            Sign-up for beta reader list
                          </h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">
                            Featured on Blogger list
                          </h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">
                            Featured on Vendor list
                          </h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">
                            Daily Tasks + reminders
                          </h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th id="border1">
                          <h6 className="text-left title">Access VCO list</h6>
                        </th>

                        <td className="alignMiddle aligncenter">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-times-circle cross"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                        <td className="alignMiddle">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <div className="edit-profile-btn">
                  <a href="/" class="">
                    <button
                      type="submit"
                      className="btn new_btn_save_bt m-0"
                      style={{
                        marginRight: '0% !important'
                      }}
                    >
                      Back to Dashboard
                    </button>
                  </a>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* </div> */}
        </Layout>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal1"
        >
          <ModalHeader toggle={this.toggle} className="eventModal">
            <h3 className="text-center p-0"> BLASTS (AUTHORS)</h3>
          </ModalHeader>
          <ModalBody>
            <ol>
              <li start="1">
                Send a FREE release day Blast to all followers & members who
                added the book release to their calendar.
              </li>
              <li className="mt-2">
                Earn a free Blast upon earning your first 500 followers & one
                free Blast for every additional 500 followers.
              </li>
              <li className="mt-2">
                Additional Blasts to followers can be purchased, but limited to
                a maximum of one per month.
              </li>
            </ol>
            <p>
              <i>*** Subject to change.</i>
            </p>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal1}
          toggle={this.toggle1}
          className="modal1"
        >
          <ModalHeader toggle={this.toggle1} className="eventModal">
            <h3 className="text-center p-0"> FOLLOWERS (AUTHORS)</h3>
          </ModalHeader>
          <ModalBody>
            <p>
              An exclusive “invite code” will be generated after sign-up to
              share with your fan base. When an invite code is redeemed upon
              member sign up, you will automatically earn that follower.{' '}
            </p>
            <ol>
              <li start="1">
                Increase visibility on the calendar by gaining followers.
              </li>
              <li className="mt-2">
                Send FREE release day Blast to all followers.
              </li>
              <li className="mt-2">
                Reach followers with earned & purchased Blasts.
              </li>
            </ol>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal2}
          toggle={this.toggle2}
          className="modal1"
        >
          <ModalHeader toggle={this.toggle2} className="eventModal">
            {' '}
            <h3 className="text-center p-0"> DAILY TASKS </h3>
          </ModalHeader>
          <ModalBody>
            <p>
              Daily Tasks (with reminders) is a private calendar for organizing
              daily tasks with notes, links & reminders. Create a to-do-list,
              save posting data or track writing goals.
            </p>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal3}
          toggle={this.toggle3}
          className="modal1"
        >
          <ModalHeader toggle={this.toggle3} className="eventModal">
            <h3 className="text-center p-0"> WRITING GOALS</h3>
          </ModalHeader>
          <ModalBody>
            <p>
              Use the Daily Tasks notes feature with reminders to keep track of
              writing goals & to help stay organized.
            </p>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal4}
          toggle={this.toggle4}
          className="modal1"
        >
          <ModalHeader toggle={this.toggle4} className="eventModal">
            <h3 className="text-center p-0">NETWORK WITH VENDORS</h3>
          </ModalHeader>
          <ModalBody>
            <p>
              In addition to the reader calendar accessible to all members, OTR
              enables authors to network with vendors, PR firms & other authors
              with messaging & access to exclusive OTR lists for bloggers, beta
              readers & Vendor Cancellation Openings.
            </p>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal5}
          toggle={this.toggle5}
          className="modal1"
        >
          <ModalHeader toggle={this.toggle5} className="eventModal">
            <h3 className="text-center p-2">
              {' '}
              VENDOR CANCELLATION OPENINGS (VCO)
            </h3>
          </ModalHeader>
          <ModalBody>
            <p>
              Even if a vendor is not accepting new clients, their cancellations
              can be added as Vendor Cancellation Openings (VCO) marked on
              Vendor list. This enables authors to maximize an opportunity to
              reserve services with previously fully booked vendors.
            </p>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal6}
          toggle={this.toggle6}
          className="modal1"
        >
          <ModalHeader toggle={this.toggle6} className="eventModal">
            {' '}
            <h3 className="text-center p-0"> BETA READER LIST </h3>
          </ModalHeader>
          <ModalBody>
            <p>
              All members are able to add themselves to the beta reader list
              accessible by all authors.
            </p>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal7}
          toggle={this.toggle7}
          className="modal1"
        >
          <ModalHeader toggle={this.toggle7} className="eventModal">
            {' '}
            <h3 className="text-center p-0"> FOLLOWERS</h3>
          </ModalHeader>
          <ModalBody>
            <p>
              An exclusive “invite code” will be generated after sign-up to
              share with your fan base. When an invite code is redeemed upon
              member sign up, you will automatically earn that follower.
            </p>
            <ol>
              <li start="1">
                Increase visibility on the calendar by gaining followers.
              </li>
              <li className="mb-2">
                Reach followers with earned & purchased Blasts.
              </li>
            </ol>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal8}
          toggle={this.toggle8}
          className="modal1"
        >
          <ModalHeader toggle={this.toggle8} className="eventModal">
            {' '}
            <h3 className="text-center p-0"> BLASTS </h3>
          </ModalHeader>
          <ModalBody>
            <ol>
              <li start="1">
                Earn a free Blast upon earning your first 500 followers & one
                free Blast for every additional 500 followers.
              </li>
              <li className="mb-2">
                Additional Blasts to followers can be purchased, but limited to
                a maximum of one per month.
              </li>
            </ol>
            <p>
              <i>*** Subject to change.</i>
            </p>
          </ModalBody>
        </Modal>
      </>
    );
  }
  componentDidMount() {
    const isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    console.log('is chrome is:', isChrome);

    var firstheight = $('.firsttr').height();
    $('#table1 tr.secondtr th, #table1 tr.secondtr th').css('top', firstheight);

    var secondheight = $('.secondtr').height();
    $('#table1 tr.thirdtr th, #table1 tr.thirdtr th').css(
      'top',
      firstheight + secondheight
    );

    if (browserName === 'Chrome') {
      var thirdheight = $('.thirdtr').height();
      $('#table1 tr.fourthtr th, #table1 tr.fourthtr th').css(
        'top',
        // firstheight + secondheight + thirdheight
        firstheight + secondheight + thirdheight - 20
      );
    } else {
      var thirdheight = $('.thirdtr').height();
      $('#table1 tr.fourthtr th, #table1 tr.fourthtr th').css(
        'top',
        firstheight + secondheight + thirdheight
        // firstheight + secondheight + thirdheight - 20
      );
    }
  }

  //{======= VideoFrames Component ========}

  componentDidMount() {
    let route = this.props.history.location.pathname;
    route = route.split('/').pop();
    if (route) {
      // console.log("Inside the if route condition");
      this.props.getstatic(`/api/v1/static?page=${route}`);
    }
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => ({
  pages: state.userListData
});

const mapDispatchToProps = dispatch => ({
  getstatic: url => dispatch(getstatic(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(membershipTypes);
