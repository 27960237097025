import axios from 'axios';
import createHistory from 'history/createHashHistory';
import { notify } from 'react-notify-toast';
import {
  eventLoading,
  events,
  eventsummary,
  filterevent,
  getFilterEventData
} from './event-setup';
import { Unauthorized } from './errors';
import { BASEURL } from '../constant';
import { signuploading, userloading } from '../Reducers/users';
import { newLoader, sevenDaysLoading } from '../Reducers/message';

export default createHistory();
const globalHistory = new createHistory();

export function setupEvent() {
  return dispatch => {
    dispatch(profileLoading(false));
  };
}

export function profileLoading(bool) {
  return {
    type: 'PROFILE_LOADING',
    status: bool
  };
}

export function profileError(bool) {
  return {
    type: 'PROFILE_ERROR',
    error: bool
  };
}

export function profileData(data) {
  return {
    type: 'PROFILE_DATA',
    data
  };
}

export function userData(data) {
  return {
    type: 'USER_DATA',
    data
  };
}

export function callListApi(data) {
  return {
    type: 'CALL_API',
    data
  };
}

export function userListData(data) {
  return {
    type: 'USERLIST_DATA',
    data
  };
}

export function AddBetaReader(data) {
  return {
    type: 'BETAREADER_DATA',
    data
  };
}

export function userType(user_type) {
  return {
    type: 'USER_TYPE',
    user_type
  };
}

export function previewProfile(data) {
  return {
    type: 'PREVIEW_PROFILE',
    data
  };
}

export function myProfileData(data) {
  return {
    type: 'MY_PROFILE',
    data
  };
}

export function checkIsFollowed(data) {
  return {
    type: 'IS_FOLLOWED',
    data
  };
}

export function changeMode(mode) {
  return {
    type: 'MODE_CHANGE',
    status: mode
  };
}

export function searchByUsername(data) {
  return {
    type: 'USER_NAME',
    data
  };
}

export function isBetaReaderExist(data) {
  return {
    type: 'IS_BETA_READER',
    data
  };
}

export function getUserDetails(data) {
  return {
    type: 'USER_PROFILE',
    data
  };
}

export function betaUserData(data) {
  return {
    type: 'BETA_DATA',
    data
  };
}

export function editUserData(data) {
  return {
    type: 'EDIT_USER',
    data
  };
}

export function openOtpModal(data) {
  return {
    type: 'OPEN_OTP_MODAL',
    data
  };
}

export function isMemberhsipUpgradable(bool) {
  return {
    type: 'IS_MEMEBERSHIP_UPGRADABLE',
    bool
  };
}

export function getBlastDetails(data) {
  return {
    type: 'BLAST_DETAILS',
    data
  };
}

export function userProfileStatic(data) {
  return {
    type: 'MY_PROFILE_STATIC',
    data
  };
}

export function buyBlast(bool) {
  return {
    type: 'BUY_BLAST',
    bool
  };
}

export function betaAccess(bool) {
  return {
    type: 'BETA_ACCESS',
    bool
  };
}

export function changePopularEvents(status) {
  return {
    type: 'CHANGE_POPULAR_EVENTS',
    status
  };
}

export function saveProfile(url, headers, data, history, edit) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .put(BASEURL + url, data, { headers })
      .then(response => {
        dispatch(previewProfile([]));

        notify.show('Profile saved successfully.', 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
        if (edit) {
          globalHistory.push('/dashboard/my-profile');
        } else {
          dispatch(
            staticGetProfile('/api/v1/user/profile', {
              headers
            })
          );
        }
      })
      .catch(error => {
        dispatch(profileLoading(false));

        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show('Failed to store user profile.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
          localStorage.clear('token');
          globalHistory.push('/dashboard');
        }
      });
  };
}

export function getProfile(url, headers) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .get(BASEURL + url, headers)
      .then(res => {
        if (res.data.result.profile.user_details.length !== 0) {
          if (localStorage.getItem('expirepop') === '1') {
            globalHistory.push('/dashboard/membership');
            localStorage.setItem('profile_setup', 'done');
            dispatch(userProfileStatic(res.data.result));
            localStorage.removeItem('expirepop');
          } else if (
            localStorage.getItem('advertise') === 'advertise' &&
            (localStorage.getItem('membershipId') === '1' ||
              localStorage.getItem('membershipId') === '8' ||
              localStorage.getItem('membershipId') === '5')
          ) {
            localStorage.setItem('profile_setup', 'done');
            dispatch(userProfileStatic(res.data.result));
            globalHistory.push('/dashboard/advertise');
            localStorage.removeItem('advertise');
          } else {
            localStorage.setItem('profile_setup', 'done');
            dispatch(userProfileStatic(res.data.result));
            globalHistory.push('/dashboard');
          }
        } else if (
          res.data.result.profile.membership_id == 2 ||
          res.data.result.profile.membership_id == 9
        ) {
          dispatch(userProfileStatic(res.data.result));
          globalHistory.push('/dashboard');
        } else {
          if (
            res.data &&
            res.data.result &&
            res.data.result &&
            res.data.result.profile
          ) {
            dispatch(userType(res.data.result.profile.type_name));
          }

          if (
            res.data.result &&
            res.data.result.profile &&
            res.data.result.profile.user_details &&
            res.data.result.profile.user_details.length > 0
          ) {
            dispatch(profileData(res.data.result.profile.user_details));
            dispatch(profileLoading(false));
          } else {
            dispatch(
              getProfileSchema(
                `profile_type_${res.data.result.profile.membership_id}`
              )
            );
          }
        }
      })
      .catch(error => {
        dispatch(profileLoading(true));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show('Failed to store user profile.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
          localStorage.removeItem('token');
          globalHistory.push('/');
        }
      });
  };
}

export function getProfileSchema(schema_name) {
  return dispatch => {
    axios
      .get(BASEURL + `/api/v1/form-schema/${schema_name}`)
      .then(response => {
        if (response.data.result.schema.length > 0) {
          dispatch(profileData(response.data.result.schema));
          dispatch(profileLoading(false));
        }
        if (response.data.result.schema.length === undefined) {
          globalHistory.push('/dashboard');
        }
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(
            'Something went wrong please reload page or login again.',
            'custom',
            5000,
            { background: '#f8d7da', text: '#721c24' }
          );
        }
      });
  };
}

export function RemoveBeta(url, headers) {
  return dispatch => {
    axios
      .delete(BASEURL + url, headers)
      .then(res => {
        dispatch(profileData(res));
        dispatch(getBeta('/api/v1/isBetaReader', headers));
        dispatch(getUserList('/api/v1/users?list=beta_readers', headers));
        if (+localStorage.getItem('membershipId') === 1) {
          dispatch(getUserList('/api/v1/users?list=beta_readers', headers));
        }
        dispatch(callListApi(true));
        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response.status === 403) {
          dispatch(Unauthorized);
        }
        //  notify.show("Remove Successfully ", "custom", 5000, { background: '#f8d7da', text: "#721c24" });
        // localStorage.removeItem("token");
      });
  };
}

export function AddBeta(url, body, headers) {
  console.log('jkdshfks   ', url);
  return dispatch => {
    axios
      .post(BASEURL + url, body, headers)
      .then(res => {
        dispatch(profileData(res));
        dispatch(getBeta('/api/v1/isBetaReader', headers));
        dispatch(callListApi(true));
        dispatch(getUserList('/api/v1/users?list=beta_readers', headers));
        if (+localStorage.getItem('membershipId') === 1) {
          dispatch(getUserList('/api/v1/users?list=beta_readers', headers));
        }
        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response.status === 403) {
          dispatch(Unauthorized);
        }
        // notify.show("Added Successfully ", "custom", 5000, { background: '#f8d7da', text: "#721c24" });
        // localStorage.removeItem("token");
        // dispatch(profileLoading(false));
      });
  };
}

export function getUserList(url, headers) {
  return dispatch => {
    //   dispatch(profileLoading(true));
    dispatch(userListData([]));
    axios
      .get(BASEURL + url, headers)
      .then(res => {
        if (res.data.result && res.data.result.userData) {
          dispatch(userListData(res.data.result.userData));
          dispatch(callListApi(false));
          //    dispatch(profileLoading(false));
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show('Failed to store user profile.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
          localStorage.removeItem('token');
        }
        //dispatch(profileLoading(false));
      });
  };
}

export function getProfileDetails(url, header, edit, page) {
  return dispatch => {
    // dispatch(profileLoading(true));
    if (page === 'dashboard') {
      header.headers.name = 'dashboard';
    }
    if (page === 'myProfile' || page === 'dashboard') dispatch(newLoader(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        if (edit) {
          if (page === 'dashboard') dispatch(newLoader(false));
          dispatch(myProfileData(response.data.result));
          globalHistory.push('/user');
          dispatch(profileLoading(false));
          dispatch(signuploading(false));
        } else {
          if (page === 'dashboard') dispatch(newLoader(false));
          dispatch(userProfileStatic(response.data.result));
          dispatch(myProfileData(response.data.result));
          // globalHistory.push("/dashboard");
          dispatch(profileLoading(false));
          dispatch(signuploading(false));
        }
      })
      .catch(error => {
        // dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }

        // dispatch(profileLoading(false));
        // notify.show("Failed To get User Details", "custom", 5000, { background: '#f8d7da', text: "#721c24" });

        //   dispatch(profileLoading(false));
      });
  };
}

export function staticGetProfile(url, header) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(userProfileStatic(response.data.result));
        globalHistory.push('/dashboard');
        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
        // dispatch(profileLoading(false));
        // notify.show("Failed To get User Details", "custom", 5000, { background: '#f8d7da', text: "#721c24" });

        //   dispatch(profileLoading(false));
      });
  };
}

export function isUpgradable(url, header) {
  return dispatch => {
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(isMemberhsipUpgradable(response.data.status));
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }

        // dispatch(profileLoading(false));
        // notify.show("Failed To get User Details", "custom", 5000, { background: '#f8d7da', text: "#721c24" });

        //   dispatch(profileLoading(false));
      });
  };
}

export function cancelMembership(url, header) {
  let respo;
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        respo = response;
        dispatch(profileLoading(false));
        if (response) {
          //localStorage.removeItem('token');
          notify.show('Membership cancelled successfully.', 'custom', 5000, {
            background: '#d4edda',
            text: '#155724',
            borderColor: '#c3e6cb'
          });
          // globalHistory.push('/log-in');
        }
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          dispatch(profileLoading(false));
          notify.show(
            'Something went wrong. May be your request already placed.',
            'custom',
            5000,
            { background: '#f8d7da', text: '#721c24' }
          );
        }
      });
  };
}

export function editDetailsOtpVerify(url, data, header) {
  return dispatch => {
    dispatch(profileLoading(true));

    axios
      .post(BASEURL + url, data, header)
      .then(response => {
        if (response) {
          notify.show(response.data.message, 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
          dispatch(openOtpModal(false));
          dispatch(
            getProfileDetails(
              '/api/v1/user/profile',
              {
                headers: {
                  'x-authorization': localStorage.getItem('token')
                }
              },
              false
            )
          );

          dispatch(profileLoading(false));
        }
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(error.response.data.message, 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        }
      });
  };
}

export function editUserProfile(url, data, header) {
  return dispatch => {
    dispatch(profileLoading(true));

    axios
      .post(BASEURL + url, data, header)
      .then(response => {
        if (response) {
          // if(response.data.message === 'Updated Successfully')
          // {
          //   notify.show("Email successfully updated.", 'custom', 5000, {
          //     background: '#d4edda',
          //     text: '#721c24'
          //   });
          // }else{
          notify.show(response.data.message, 'custom', 5000, {
            background: '#d4edda',
            text: '#721c24'
          });
          // }

          if (response.data.otpsent) {
            dispatch(openOtpModal(true));
            dispatch(profileLoading(false));
          }
          dispatch(profileLoading(false));
          // dispatch(getProfileDetails('/api/v1/user/profile', header, false));
          // window.location.reload();
          dispatch(
            getProfileDetails('/api/v1/user/profile', {
              headers: { 'x-authorization': localStorage.getItem('token') }
            })
          );
        }
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(error.response.data.message, 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        }
      });
  };
}

export function closeOtpMoal(status) {
  return dispatch => {
    dispatch(openOtpModal(status));
  };
}

export function getUserDetailsByPR(url, header) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        if (response.data.data.result.length === 0) {
          dispatch(profileLoading(false));
        }
        dispatch(getUserDetails(response.data.data.result));
        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function previewUserProfile(url, header, data) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .post(BASEURL + url, data, { headers: header })
      .then(response => {
        dispatch(previewProfile(response.data.result.result));
        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }

        dispatch(profileLoading(false));
        // notify.show("Failed To get User Details", "custom", 5000, { background: '#f8d7da', text: "#721c24" });

        //   dispatch(profileLoading(false));
      });
  };
}

export function changeDisplayMode(mode) {
  return dispatch => {
    dispatch(changeMode(mode));
  };
}

export function popularEvents(mode) {
  return dispatch => {
    dispatch(changePopularEvents(mode));
  };
}

export function getFollowingStatus(url, header) {
  return dispatch => {
    dispatch(profileLoading(true));
    dispatch(checkIsFollowed({}));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(checkIsFollowed(response.data.result));
        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
        // dispatch(profileLoading(false));
        // notify.show("Failed To get User Details", "custom", 5000, { background: '#f8d7da', text: "#721c24" });

        //   dispatch(profileLoading(false));
      });
  };
}

export function followUser(url, data, header, status, route) {
  return dispatch => {
    dispatch(profileLoading(true));
    dispatch(checkIsFollowed({}));
    dispatch(events(''));
    dispatch(eventsummary(''));
    dispatch(filterevent(''));
    dispatch(getFilterEventData([]));

    axios
      .post(BASEURL + url, data, header)
      .then(response => {
        if (status && route === 'most_followed') {
          dispatch(getUserList(`/api/v1/users/mostpopular`, header));
          notify.show('Successfully followed.', 'custom', 2000, {
            background: '#d4edda',
            text: '#155724',
            borderColor: '#c3e6cb'
          });
        } else {
          dispatch(getUserList(`/api/v1/users?list=${route}`, header));
          notify.show('Successfully followed.', 'custom', 2000, {
            background: '#d4edda',
            text: '#155724',
            borderColor: '#c3e6cb'
          });
        }
        dispatch(checkIsFollowed(response.data.result.canFollow));

        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function unFollowUser(url, header, status, route) {
  return dispatch => {
    dispatch(profileLoading(true));
    dispatch(checkIsFollowed({}));
    dispatch(events(''));
    dispatch(eventsummary(''));
    dispatch(filterevent(''));
    dispatch(getFilterEventData([]));
    axios
      .delete(BASEURL + url, header)
      .then(response => {
        dispatch(checkIsFollowed(response.data.canFollow));
        // if (status) {
        //   dispatch(getUserList(`/api/v1/users/mostpopular`, header));
        // }
        if (status && route === 'most_followed') {
          dispatch(getUserList(`/api/v1/users/mostpopular`, header));
        } else {
          dispatch(getUserList(`/api/v1/users?list=${route}`, header));
        }
        notify.show('No longer following.', 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function searchUserByUsername(url, header) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(searchByUsername([]));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(eventLoading(false));
        dispatch(searchByUsername(response.data.result));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response.status === 403) {
          dispatch(Unauthorized);
        }
        // dispatch(eventLoading(false));
      });
  };
}

export function editPrefrence(url, data, header) {
  return dispatch => {
    // dispatch(profileLoading(true));
    dispatch(myProfileData({}));
    axios
      .put(BASEURL + url, data, header)
      .then(response => {
        dispatch(getProfileDetails('/api/v1/user/profile', header, false));
        globalHistory.push('/dashboard/prefrences');
        notify.show('Prefrence saved successfully.', 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
        // dispatch(profileLoading(false));
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(
            'Some error occured please try again after sometime.',
            'custom',
            2000,
            {
              background: '#d4edda',
              text: '#155724',
              borderColor: '#c3e6cb'
            }
          );
          dispatch(profileLoading(false));
        }
      });
  };
}

export function addToBetaReaderList(url, header) {
  return dispatch => {
    dispatch(profileLoading(true));
    dispatch(isBetaReaderExist({}));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(isBetaReaderExist(!response.data.result));
        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function removeFromBetaReader(url, header) {
  return dispatch => {
    dispatch(profileLoading(true));
    dispatch(isBetaReaderExist({}));
    axios
      .delete(BASEURL + url, header)
      .then(response => {
        dispatch(isBetaReaderExist(!response.data.result));
        dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function getBetaStatus(url, header) {
  return dispatch => {
    dispatch(profileLoading(true));
    dispatch(isBetaReaderExist({}));
    dispatch(betaUserData({}));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(isBetaReaderExist(response.data.result));
        dispatch(betaUserData(response.data.data));
        // dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function getBeta(url, header) {
  return dispatch => {
    dispatch(profileLoading(true));
    dispatch(isBetaReaderExist({}));
    dispatch(betaUserData({}));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(profileLoading(false));
        dispatch(isBetaReaderExist(response.data.result));
        dispatch(betaUserData(response.data.data));

        // dispatch(profileLoading(false));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function saveSendEmailDetail(url, data, headers) {
  return dispatch => {
    dispatch(profileLoading(true));
    //Blast Created  Successfully
    axios
      .post(BASEURL + url, data, headers)
      .then(response => {
        dispatch(profileLoading(false));
        dispatch(getBlasts('/api/v1/users/blastDetails', headers));

        if (data.now === true)
          notify.show('OTR Blast Successfully sent.', 'custom', 2000, {
            background: '#d4edda',
            text: '#155724',
            borderColor: '#c3e6cb'
          });
        else {
          notify.show('OTR Blast successfully scheduled.', 'custom', 2000, {
            background: '#d4edda',
            text: '#155724',
            borderColor: '#c3e6cb'
          });
        }
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(error.response.data.message, 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        }
      });
  };
}

export function editBlast(url, data, header) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .put(BASEURL + url, data, header)
      .then(response => {
        dispatch(profileLoading(false));
        notify.show('Blast updated  successfully.', 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
        dispatch(getBlasts('/api/v1/users/blastDetails', header));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function getBlasts(url, headers) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .get(BASEURL + url, headers)
      .then(response => {
        dispatch(profileLoading(false));
        dispatch(getBlastDetails(response.data.data));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function changeBetaStatus(status) {
  return dispatch => {
    dispatch(betaAccess(status));
  };
}

export function deleteBlast(url, headers) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .delete(BASEURL + url, headers)
      .then(response => {
        dispatch(profileLoading(false));
        // dispatch(getBlastDetails(response.data));
        if (response.data.status) {
          notify.show('Blast deleted successfully.', 'custom', 2000, {
            background: '#d4edda',
            text: '#155724',
            borderColor: '#c3e6cb'
          });
          dispatch(getBlasts('/api/v1/users/blastDetails', headers));
        }
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(error.response.data.message, 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        }
      });
  };
}

export function buyUserBlast(url, data, headers) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .post(BASEURL + url, data, headers)
      .then(response => {
        dispatch(profileLoading(false));
        // dispatch(getBlastDetails(response.data));

        dispatch(buyBlast(response.data.success));
        notify.show('Blast purchased successfully.', 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
        dispatch(getBlasts('/api/v1/users/blastDetails', headers));
      })
      .catch(error => {
        dispatch(profileLoading(false));
        dispatch(buyBlast(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(error.response.data.message, 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        }
      });
  };
}

export function disableAutoRenew(url, header) {
  return dispatch => {
    dispatch(profileLoading(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(profileLoading(false));
        if (response) {
          dispatch({
            type: 'disabled_msg',
            data: response.data.message
          });
          notify.show(response.data.message, 'custom', 5000, {
            // background: '#f8d7da',
            // text: '#721c24'
            text: '#155724',
            background: '#c3e6cb'
          });
        }
      })
      .catch(error => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          dispatch(profileLoading(false));
          notify.show(
            'Something went wrong please try agian later.',
            'custom',
            5000,
            { background: '#f8d7da', text: '#721c24' }
          );
        }
      });
  };
}
