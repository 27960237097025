import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import defaulUserImg from '../../Utils/Assets/OTR_Default-avatar_v3.png';
import {
  previewProfile,
  getFollowingStatus,
  followUser,
  unFollowUser,
  addToBetaReaderList,
  removeFromBetaReader,
  getBetaStatus,
  getProfileDetails
} from '../../Actions/profile-setup';
import { getEventGroups } from '../../Actions/event-setup';
import Loader from '../../Components/Loader/loader';

class UserProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileKeyData: [],
      userdetail: '',
      isOpenPrivacy: false,
      userId: ''
    };
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  toggleProblemModal = () => {
    this.setState({ showProblemModal: !this.state.showProblemModal });
  };
  togglePrivacyModal = id => {
    this.setState({ userId: id });

    this.setState({ isOpenPrivacy: !this.state.isOpenPrivacy });
  };
  agreePolicy = () => {
    this.togglePrivacyModal();
  };
  handleDelete = async () => {
    try {
      // let route = this.props.history.location.pathname;
      // route = route.split('/').pop();
      // this.props.unFollow(+this.state.user, route);
      let ro = window.location.href;
      let rout = ro.split('/');
      let route = rout[6];
      console.log('route', route);
      // this.props.profileData.id;
      this.props.unFollow(this.state.userId, route);
      this.togglePrivacyModal();
    } catch (err) {
      console.log('err', err);
    }
  };
  //= ==================== RENDER ==========================
  render() {
    // console.log("this.props.profileData---->> ", this.props.profileData)
    // console.log(
    //   'this.props.profileData - > ',
    //   // this.props.myProfile,
    //   this.props.myProfile.profile
    // );
    // console.log('defaulUserImg ', defaulUserImg);
    let fullName = null;

    if (this.props.profileData) {
      fullName = `${this.props.profileData.fname} ${
        this.isJsonString(this.props.profileData.user_details)
          ? JSON.parse(this.props.profileData.user_details)[2].value
          : ''
      } ${this.props.profileData.lname}`;
    }
    const self = this;
    let pathImg = '';
    let eventsData = [];
    let isEmailPreview = false;

    if (!this.props.preview) {
      isEmailPreview = this.props.profileData.is_email_display == 1;

      const profileData = JSON.parse(
        this.props.profileData.profile_details_with_values
      );

      if (profileData) {
        const objImg = profileData.find(_d => _d.profile);
        if (objImg) {
          pathImg = `${localStorage.getItem('baseUrl')}${
            objImg ? objImg.profile.split('\\').join('/') : ''
          }`;

          // console.log('new path image- ', pathImg);
        } else {
          pathImg = defaulUserImg;
        }
      } else {
        pathImg = defaulUserImg;
      }
      eventsData = this.props.eventsData.event;
    } else {
      const profileData = this.props.userKeyData;

      if (profileData) {
        const objImg = profileData.find(_d =>
          _d.profile ? _d.profile : _d.image
        );
        if (objImg) {
          pathImg = `${localStorage.getItem('baseUrl')}${
            objImg ? objImg.profile.split('\\').join('/') : objImg.image
          }`;
        } else {
          pathImg = defaulUserImg;
        }
      }
    }

    let obj = {};
    this.state.profileKeyData &&
      this.state.profileKeyData.forEach(el => {
        const key = Object.keys(el)[0];
        console.log('this is key--->> ', key);
        if (obj[key]) {
          if (Array.isArray(obj[key])) {
            const prevValue = [...obj[key]];
            prevValue.push(el[key]);
            obj[key] = [...prevValue];
          } else {
            obj[key] = [obj[key], el[key]];
          }
        } else {
          obj[key] = el[key];
        }
      });
    let d = obj['Social media'];

    let newLine = [];
    this.state.profileKeyData.forEach(el => {
      if (el['Recent clients/works']) {
        newLine.push(el['Recent clients/works']);
      }
    });

    let obj1 = {};

    newLine.forEach((el, i) => {
      const key = Object.keys(el)[0];
      // console.log("recentvalue",key,el)
      obj1[i] = el;
    });

    let n = Object.values(obj1);

    const startDateForvendor = moment().format('YYYY-MM-DD');
    let urlLink = window.location.href;
    let checkLink = urlLink.split('/')[6];
    // console.log("urlLink",urlLink,checkLink)

    // console.log('here is the profile data in vew modal----> ', {
    //   clickedUserProfile: this.props.profileData,
    //   myProfile: this.props.myProfile.profile
    // });

    // console.log('here  is the  profile obj------------> ', this.state.profileKeyData);

    // console.log(
    //   '+++++++++++++',
    //   JSON.parse(this.props.profileData.user_details)[2].value
    // );

    const VCOLength = obj[
      'Currently featured on Vendor Cancellation Openings (VCO) list'
    ]
      ? obj[
          'Currently featured on Vendor Cancellation Openings (VCO) list'
        ][0].filter(
          el => new Date().getTime() >= new Date(Object.values(el)[0]).getTime()
        )
      : '';

    return (
      <>
        {!this.props.profileLoading ? (
          <>
            {' '}
            <Modal
              isOpen={this.state.isOpenPrivacy}
              toggle={this.togglePrivacyModal.bind(this)}
              id="myModal"
              className="advertise-privacy-popup delete-confirmation-popup"
            >
              <ModalBody>
                <p className="text-center p-0">
                  {' '}
                  Are you sure you want to unfollow?{' '}
                </p>
                <div className="delete-btn">
                  <button
                    className="btn btn-filled align-self-end ml-auto"
                    id="Next"
                    type="button"
                    disabled={this.state.disabled}
                    onClick={() => this.props.toggle()}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-filled align-self-end ml-auto"
                    id="Next"
                    type="button"
                    onClick={() => {
                      this.handleDelete(this.props.profileData.id);
                    }}
                    disabled={this.state.disabled}
                  >
                    Remove
                  </button>
                </div>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.props.isOpen}
              toggle={this.props.toggle}
              id="myModal"
              className="user-profile-modal event-cover-popup"
            >
              {!this.props.preview ? (
                <div className="model_cover profile_model">
                  <ModalHeader
                    className="eventModal"
                    toggle={this.props.toggle}
                  >
                    {this.props.eventData ? (
                      <h3
                        style={{
                          paddingTop: pathImg !== '' ? '250px' : '0px',
                          textAlign: 'center'
                        }}
                      >
                        {this.state.title}
                      </h3>
                    ) : null}

                    {this.props.profileData.membership_id == 3 ? (
                      <></>
                    ) : (
                      <>
                        {/* <div
                          className="profilr_image"
                          style={{
                            backgroundImage:
                              pathImg !== '' ? `url(${pathImg})` : '',
                            backgroundSize: pathImg !== '' ? 'contain' : '',
                            backgroundRepeat: 'no-repeat',
                            height: pathImg !== '' ? '250px' : '100px',
                            width: '100%',
                            backgroundPositionX: 'center'
                          }}
                        /> */}
                        {/* {this.state.profile} */}
                        {pathImg && (
                          <div
                            className={`profile_image profilr_image ${
                              pathImg
                                ? this.state.profile === '1' ||
                                  this.state.profile === '8'
                                  ? 'authorImg'
                                  : this.state.profile === '4'
                                  ? 'vendorImg'
                                  : this.state.profile === '5'
                                  ? 'prFirmImg'
                                  : this.state.profile === '6'
                                  ? 'bloggerImg'
                                  : ''
                                : ''
                            }`}
                            // style={{
                            //   // backgroundImage: pathImg !== '' ? `url(${pathImg})` : '',
                            //   backgroundSize: pathImg !== '' ? 'contain' : '',
                            //   backgroundRepeat: 'no-repeat',
                            //   height: pathImg !== '' ? '250px' : '100px',
                            //   width: '100%',
                            //   backgroundPositionX: 'center'
                            // }}
                          >
                            <img
                              src={pathImg !== '' ? pathImg : defaulUserImg}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </ModalHeader>

                  <ModalBody className="event_body">
                    {this.state.profile == 3 ? (
                      <>
                        <div className="event-popup-body">
                          <ul className="remove-icon">
                            {this.state.profileKeyData &&
                              this.state.profileKeyData
                                .map(el => {
                                  el['name'] = Object.keys(el)[0];
                                  return el;
                                })
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(data => {
                                  delete data.name;
                                  let key = Object.keys(data)[0];
                                  let value = Object.values(data);

                                  if (
                                    key &&
                                    (key.toLowerCase() === 'undefined' ||
                                      key.toLowerCase() === 'end' ||
                                      key.toLowerCase() === 'event_title' ||
                                      key.toLowerCase() === 'image' ||
                                      key.toLowerCase() === 'book-cover' ||
                                      key.toLocaleLowerCase() == 'profile' ||
                                      key ==
                                        'Publicly display email on profile page?' ||
                                      key == 'emailDisplay')
                                  ) {
                                    return;
                                  }
                                  if (key && value == 'undefined') {
                                    return;
                                  }
                                  if (Object.keys(data).length !== 0) {
                                    if (
                                      value[0] &&
                                      Array.isArray(value[0]) &&
                                      value[0].length
                                    ) {
                                      return (
                                        <span>
                                          <li>
                                            <span>
                                              <b>{key}</b>
                                            </span>
                                          </li>
                                          <ul>
                                            {value[0].length &&
                                              value[0].map(sub => {
                                                if (sub) {
                                                  return (
                                                    sub.length > 0 &&
                                                    sub.map(subObj => {
                                                      if (
                                                        key ==
                                                          'More profiles' ||
                                                        key == 'Social media' ||
                                                        key ==
                                                          'Other social media' ||
                                                        key == 'Social Media'
                                                      ) {
                                                        return (
                                                          <li className="more-profile">
                                                            <a
                                                              href={Object.values(
                                                                subObj
                                                              )[0].toString()}
                                                              target="_blank"
                                                              //className="ellipsis"
                                                              rel="noreferrer"
                                                            >
                                                              {
                                                                Object.keys(
                                                                  subObj
                                                                )[0]
                                                              }
                                                            </a>
                                                            &nbsp;|&nbsp;
                                                          </li>
                                                        );
                                                      }

                                                      if (
                                                        key ==
                                                          'Reader groups' ||
                                                        key == 'Fan groups' ||
                                                        key ==
                                                          'PR fan groups: ' ||
                                                        key ==
                                                          'Where to purchase' ||
                                                        key ==
                                                          'Purchase sites' ||
                                                        key == 'Reader Groups'
                                                      ) {
                                                        return (
                                                          <li>
                                                            <span>
                                                              {
                                                                Object.keys(
                                                                  subObj
                                                                )[0]
                                                              }
                                                            </span>
                                                            :
                                                            <span>
                                                              {' '}
                                                              <a
                                                                href={Object.values(
                                                                  subObj
                                                                )[0].toString()}
                                                                target="_blank"
                                                                //className="ellipsis"
                                                                rel="noreferrer"
                                                              >
                                                                {
                                                                  Object.values(
                                                                    subObj
                                                                  )[0]
                                                                }
                                                              </a>
                                                            </span>
                                                          </li>
                                                        );
                                                      }
                                                      return (
                                                        <li>
                                                          <span>
                                                            {
                                                              Object.keys(
                                                                subObj
                                                              )[0]
                                                            }
                                                          </span>
                                                          :
                                                          <span>
                                                            {' '}
                                                            <a
                                                              href={Object.values(
                                                                subObj
                                                              )[0].toString()}
                                                              target="_blank"
                                                              className="ellipsis"
                                                              rel="noreferrer"
                                                            >
                                                              {
                                                                Object.values(
                                                                  subObj
                                                                )[0]
                                                              }
                                                            </a>
                                                          </span>
                                                        </li>
                                                      );
                                                    })
                                                  );
                                                }
                                              })}
                                          </ul>
                                        </span>
                                      );
                                    }
                                    if (
                                      !Array.isArray(value[0]) &&
                                      value[0] !== ''
                                    ) {
                                      if (!isEmailPreview && key == 'Contact') {
                                        return;
                                      }

                                      if (
                                        key == 'Website' ||
                                        key == 'Goodreads' ||
                                        key == 'Goodreads link' ||
                                        key == 'Best site to rech me' ||
                                        key == 'PR Fan groups' ||
                                        key == 'Blog link' ||
                                        key == 'Website link' ||
                                        key ==
                                          'Best site to interact with us' ||
                                        // key == 'Alias' ||
                                        key ==
                                          'Best site for AUTHORS to interact with us' ||
                                        key == 'Master ARC list sign up' ||
                                        key == 'Best site to reach me' ||
                                        key == 'Newsletter sign up' ||
                                        key ==
                                          'Best site for bloggers/readers to interact with us'
                                      ) {
                                        return (
                                          <li className="web-line3">
                                            <span>{key}</span>:
                                            <span>
                                              {' '}
                                              <a
                                                href={value[0].toString()}
                                                target="_blank"
                                                //className="ellipsis"
                                                rel="noreferrer"
                                              >
                                                {value[0].toString()}
                                              </a>
                                            </span>
                                          </li>
                                        );
                                      }
                                      if (key == 'Bio') {
                                        return (
                                          <li>
                                            <span>{key}</span>:
                                            <p
                                              style={{
                                                whiteSpace: 'pre-line',
                                                display: 'block',
                                                'word-break': 'break-all'
                                              }}
                                            >
                                              {' '}
                                              <i>{value[0]}</i>
                                            </p>
                                          </li>
                                        );
                                      }
                                      return (
                                        <li>
                                          <>
                                            <span>{key}</span>:{' '}
                                            <span
                                              style={{
                                                textTransform: 'capitalize'
                                              }}
                                            >
                                              {' '}
                                              <b> {value[0]}</b>
                                            </span>
                                          </>
                                        </li>
                                      );
                                    }
                                  }
                                })}
                          </ul>
                        </div>

                        <div className="event-poup-btn 6"></div>
                      </>
                    ) : this.state.profile == 8 ? (
                      <>
                        {' '}
                        <div className="event-popup-body">
                          <ul className="remove-icon">
                            <>
                              {' '}
                              <li class="bullet-remove">
                                Author:{' '}
                                <b>
                                  {/* {this.props.profileData.fname}{' '}
                                  {JSON.parse(
                                    this.props.profileData.user_details
                                  )[2].value
                                    ? JSON.parse(
                                        this.props.profileData.user_details
                                      )[2].value
                                    : ''}{' '}
                                  {this.props.profileData.lname} */}
                                  {obj.Author}
                                </b>
                              </li>
                              {/* <li class="bullet-remove">
                    {console.log("myconsol",obj)}
                    Contact: <b>{obj.Contact}</b>
                  </li> */}
                              {obj['Publicly display email on profile page?'] &&
                              obj['Publicly display email on profile page?'] ===
                                'Yes' ? (
                                <li class="bullet-remove">
                                  Contact:{''} <b>{obj.Contact}</b>
                                </li>
                              ) : (
                                ''
                              )}
                              {obj.Goodreads && (
                                <li class="bullet-remove">
                                  Goodreads:{' '}
                                  <a
                                    href={obj.Goodreads}
                                    target="_blank"
                                    //className="ellipsis"
                                    rel="noreferrer"
                                  >
                                    {obj.Goodreads.slice(0, 25)}
                                    {'...'}
                                  </a>
                                </li>
                              )}
                              {obj.Website && (
                                <li class="bullet-remove">
                                  Website:{' '}
                                  <a
                                    href={obj.Website}
                                    target="_blank"
                                    //className="ellipsis"
                                    rel="noreferrer"
                                  >
                                    {obj.Website.slice(0, 25)}
                                    {'...'}
                                  </a>
                                </li>
                              )}
                              {obj['Social Media'] &&
                                !!obj['Social Media'][0].length && (
                                  <li class="bullet-remove">
                                    {' '}
                                    <li>
                                      <b>Social Media:</b>
                                    </li>
                                    {obj['Social Media'] &&
                                      obj['Social Media'][0].map(subObj => {
                                        return (
                                          <li className="more-profile">
                                            <a
                                              href={Object.values(
                                                subObj
                                              )[0].toString()}
                                              target="_blank"
                                              //className="ellipsis"
                                              rel="noreferrer"
                                            >
                                              {Object.keys(subObj)[0].slice(
                                                0,
                                                25
                                              )}
                                            </a>
                                            &nbsp;|&nbsp;
                                          </li>
                                        );
                                      })}
                                  </li>
                                )}
                              {obj['Reader Groups'] &&
                                !!obj['Reader Groups'][0].length && (
                                  <li class="bullet-remove">
                                    {' '}
                                    <li>
                                      <b>Reader groups:</b>
                                    </li>
                                    {obj['Reader Groups'] &&
                                      obj['Reader Groups'][0].map(subObj => {
                                        return (
                                          <ul>
                                            <li>
                                              {Object.keys(subObj)[0]}:{' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                )[0].toString()}
                                                target="_blank"
                                                //className="ellipsis"
                                                rel="noreferrer"
                                              >
                                                {Object.values(subObj)[0].slice(
                                                  0,
                                                  35
                                                )}
                                                {'...'}
                                              </a>{' '}
                                            </li>
                                          </ul>
                                        );
                                      })}
                                  </li>
                                )}
                              {obj['Purchase sites'] &&
                                !!obj['Purchase sites'][0].length && (
                                  <li class="bullet-remove">
                                    {' '}
                                    <li>
                                      <b>Purchase sites:</b>
                                    </li>
                                    {obj['Purchase sites'] &&
                                      obj['Purchase sites'][0].map(subObj => {
                                        return (
                                          <ul>
                                            <li>
                                              {Object.keys(subObj)[0]}:{' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                ).toString()}
                                                target="_blank"
                                                //className="ellipsis"
                                                rel="noreferrer"
                                              >
                                                {Object.values(subObj)[0].slice(
                                                  0,
                                                  38
                                                )}
                                                {'...'}
                                              </a>{' '}
                                            </li>
                                          </ul>
                                        );
                                      })}
                                  </li>
                                )}{' '}
                              <li class="bullet-remove">
                                <b>Other:</b>
                              </li>
                              {obj.Alias && (
                                <li class="bullet-remove">
                                  Alias: <b>{obj.Alias}</b>
                                </li>
                              )}
                              {obj['Newsletter sign up'] && (
                                <li class="bullet-remove">
                                  Newsletter sign up:{' '}
                                  <a
                                    href={obj['Newsletter sign up']}
                                    target="_blank"
                                    //className="ellipsis"
                                    rel="noreferrer"
                                  >
                                    {obj['Newsletter sign up'].slice(0, 33)}
                                    {'...'}
                                  </a>
                                </li>
                              )}
                              {obj['Master ARC list sign up'] && (
                                <li class="bullet-remove">
                                  Master ARC list sign up:{' '}
                                  <a
                                    href={obj['Master ARC list sign up']}
                                    target="_blank"
                                    //className="ellipsis"
                                    rel="noreferrer"
                                  >
                                    {obj['Master ARC list sign up'].slice(
                                      0,
                                      35
                                    )}
                                    {'...'}
                                  </a>
                                </li>
                              )}
                              {obj['Best site to reach me'] && (
                                <li class="bullet-remove">
                                  {obj['Best site to reach me'].split(
                                    ':'
                                  )[0] === 'http' ||
                                  obj['Best site to reach me'].split(':')[0] ===
                                    'https' ? (
                                    <>
                                      {' '}
                                      Best site to reach me:{' '}
                                      <a
                                        href={obj['Best site to reach me']}
                                        //className="ellipsis"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {obj['Best site to reach me'].slice(
                                          0,
                                          30
                                        )}
                                        {'...'}
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      Best site to reach me:{' '}
                                      <b>{obj['Best site to reach me']}</b>
                                    </>
                                  )}
                                </li>
                              )}
                              {obj.Bio && (
                                <>
                                  <li>Bio:</li>{' '}
                                  <li>
                                    <i>{obj.Bio}</i>
                                  </li>
                                </>
                              )}
                              {this.props.profileData.follow_code && (
                                <>
                                  <li>
                                    <span>INVITE code: </span>
                                    <span>
                                      {' '}
                                      <b>
                                        {' '}
                                        {
                                          this.props.profileData.follow_code
                                        }{' '}
                                      </b>
                                    </span>
                                  </li>

                                  <li>
                                    Link to profile:{' '}
                                    <Link
                                      to={`/users/${this.props.profileData.follow_code}`}
                                      target="_blank"
                                    >
                                      {' '}
                                      {window.location.host}
                                      /#/users/{'...'}
                                      {/* {this.props.profileData.follow_code.slice(0,5)} */}
                                    </Link>
                                  </li>
                                </>
                              )}
                              {this.props.profileData.membership_id == 3 ? (
                                <></>
                              ) : (
                                <>
                                  <div>
                                    <b>All OTR events added by this profile:</b>
                                  </div>
                                  <ul>
                                    {eventsData && eventsData.length > 0 ? (
                                      <>
                                        <li>
                                          Linked Events page:{' '}
                                          <Link
                                            to={{
                                              pathname: `/dashboard/linked?userId=${this.props.profileData.id}&name=${obj.Author}`
                                            }}
                                            target="_blank"
                                          >
                                            {/* {window.location.href} */}
                                            {/* {window.location.host} */}
                                            {/* /#/dashboard/my-events */}{' '}
                                            otr/linkedeventpage/{'...'}
                                          </Link>
                                        </li>
                                      </>
                                    ) : (
                                      <li>
                                        Linked Events page2:{' '}
                                        <Link
                                          to={{
                                            pathname: `/dashboard/linked?userId=${this.props.profileData.id}&name=${obj.Author}`
                                          }}
                                          target="_blank"
                                        >
                                          otr/linkedeventpage/{'...'}
                                        </Link>
                                      </li>
                                    )}
                                  </ul>
                                </>
                              )}
                            </>
                          </ul>
                        </div>
                        <div className="event-poup-btn 7">
                          <>
                            {this.props.profileData.id ===
                            this.props.myProfile.profile.id ? (
                              <button
                                className="btn mt-2 editBtn"
                                onClick={() => {
                                  localStorage.setItem(
                                    'userId',
                                    this.props.myProfile.profile.id
                                  );
                                  localStorage.setItem('mode', 'edit');
                                  this.props.getUserProfile(
                                    '/api/v1/user/profile',
                                    {
                                      headers: {
                                        'x-authorization': localStorage.getItem(
                                          'token'
                                        )
                                      }
                                    },
                                    true
                                  );
                                }}
                              >
                                EDIT
                              </button>
                            ) : null}
                          </>
                          {this.props.profileData.type_id == 1 ||
                          this.props.profileData.type_id == 5 ||
                          this.props.profileData.membership_id == 6 ? (
                            <>
                              {!this.props.status && (
                                <button
                                  className="btn mt-2"
                                  onClick={() => {
                                    // if (
                                    //   window.confirm(
                                    //     "You will be notified about this member's updates and their events."
                                    //   )
                                    // )
                                    this.onFollow(this);
                                  }}
                                >
                                  FOLLOW
                                </button>
                              )}

                              {this.props.status &&
                                this.props.profileData.id !==
                                  this.props.myProfile.profile.id && (
                                  <button
                                    disabled={
                                      this.props.profileData.id ===
                                      this.props.myProfile.profile.id
                                        ? true
                                        : false
                                    }
                                    className="btn btn-hover btn-danger mt-2"
                                    // onClick={() => {
                                    //   this.onUnFollow(this);
                                    // }}
                                    style={
                                      this.props.profileData.id ===
                                      this.props.myProfile.profile.id
                                        ? { cursor: 'not-allowed' }
                                        : {}
                                    }
                                    onClick={() =>
                                      this.togglePrivacyModal(
                                        this.props.profileData.id
                                      )
                                    }
                                  >
                                    UNFOLLOW
                                  </button>
                                )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : this.props.profileData.membership_id === '4' ? (
                      <>
                        <div className="event-popup-body">
                          <ul className="remove-icon vendorModal-list 1">
                            {console.log('objobjobjobj', obj)}
                            <li class="bullet-remove">
                              Vendor: <b>{obj.Vendor}</b>
                            </li>
                            {obj['Services offered'] &&
                              !!obj['Services offered'].length && (
                                <li class="bullet-remove">
                                  Services offered:{' '}
                                  <b>{obj['Services offered']}</b>
                                </li>
                              )}
                            {obj.emailDisplay && obj.emailDisplay === 'Yes' ? (
                              <li class="bullet-remove">
                                Contact:{''} <b>{obj.Contact}</b>
                              </li>
                            ) : (
                              ''
                            )}
                            {obj['Website'] && (
                              <li class="bullet-remove">
                                Website:{' '}
                                <a
                                  href={obj['Website']}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj.Website.slice(0, 25)}
                                  {'...'}
                                </a>
                              </li>
                            )}

                            {obj['Social media'] &&
                              !!obj['Social media'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Social media:</b>
                                  </li>
                                  {obj['Social media'] &&
                                    obj['Social media'][0].map(subObj => {
                                      return (
                                        <li className="more-profile">
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.keys(subObj)[0]}
                                          </a>
                                          &nbsp;|&nbsp;
                                        </li>
                                      );
                                    })}
                                </li>
                              )}
                            {console.log(
                              'working',
                              obj[
                                'Currently featured on Vendor Cancellation Openings (VCO) list'
                              ] &&
                                obj[
                                  'Currently featured on Vendor Cancellation Openings (VCO) list'
                                ].length
                            )}

                            {obj['Accepting new clients?'] && (
                              <li class="bullet-remove">
                                Are you accepting new clients:{' '}
                                <b>{obj['Accepting new clients?']}</b>
                              </li>
                            )}
                            {obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ] &&
                            obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ].length == '1' ? (
                              <>
                                <li class="bullet-remove">
                                  {' '}
                                  <b>Vendor Cancellation Openings (VCO):</b>
                                </li>
                              </>
                            ) : (
                              <>
                                <li class="bullet-remove">
                                  <b>Vendor Cancellation Openings (VCO):</b>
                                </li>
                                <ul>
                                  <li>No</li>
                                </ul>
                              </>
                            )}

                            {obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ] ? (
                              <div className="mb-1">
                                {obj[
                                  'Currently featured on Vendor Cancellation Openings (VCO) list'
                                ][0].map(subObj => {
                                  return (
                                    <>
                                      {/* <li class="bullet-remove"> <b>Vendor Cancellation Openings (VCO):</b></li> */}
                                      {new Date().getTime() >=
                                      new Date(
                                        Object.values(subObj)[0]
                                      ).getTime() ? (
                                        <>
                                          {/* <ul>
                                            <li>No</li>
                                          </ul> */}
                                        </>
                                      ) : (
                                        <>
                                          <ul>
                                            {' '}
                                            <li>
                                              <b>{Object.keys(subObj)[0]}</b>:{' '}
                                              {moment(
                                                Object.values(subObj)[0]
                                              ).format('MMMM DD, YYYY')}
                                            </li>
                                          </ul>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                                {VCOLength.length ==
                                  obj[
                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                  ][0].length && (
                                  <ul>
                                    <li>No</li>
                                  </ul>
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                            {/* {obj['Profile summary'] && (
                           <li class="bullet-remove">
                           Profile summary:{' '}
                         <b>{obj['Profile summary']}</b> 
                         </li>
                        )} */}
                            {obj['Best site to interact with us'] && (
                              <li class="bullet-remove">
                                {obj['Best site to interact with us'].split(
                                  ':'
                                )[0] === 'http' ||
                                obj['Best site to interact with us'].split(
                                  ':'
                                )[0] === 'https' ? (
                                  <>
                                    {' '}
                                    Best site to interact with us:{' '}
                                    <a
                                      href={
                                        obj['Best site to interact with us']
                                      }
                                      //className="ellipsis"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {obj[
                                        'Best site to interact with us'
                                      ].slice(0, 25)}
                                      {'...'}
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    Best site to interact with us:{' '}
                                    <b>
                                      {obj['Best site to interact with us']}
                                    </b>
                                  </>
                                )}
                              </li>
                            )}
                            {obj['Recent clients/works'] && (
                              <li class="bullet-remove">
                                Recent clients/works:{' '}
                                {obj['Recent clients/works'].map(el => (
                                  <>
                                    {el.split(':')[0] === 'http' ||
                                    el.split(':')[0] === 'https' ? (
                                      <ul>
                                        <li>
                                          <a
                                            href={el}
                                            //className="ellipsis"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {el.length > 50
                                              ? el.slice(0, 50) + '...'
                                              : el}
                                          </a>
                                        </li>
                                      </ul>
                                    ) : (
                                      <ul>
                                        <li>{el}</li>
                                      </ul>
                                    )}
                                  </>
                                ))}
                              </li>
                            )}
                            {obj['Brief introduction'] && (
                              <li class="bullet-remove">
                                Brief introduction:{' '}
                                <b>{obj['Brief introduction']}</b>
                              </li>
                            )}

                            {obj.Bio && (
                              <>
                                <li class="bullet-remove">Bio:</li>{' '}
                                <li class="bullet-remove">
                                  <i>{obj.Bio}</i>
                                </li>
                              </>
                            )}
                            <li>
                              Link to profile:{' '}
                              {console.log(
                                'this.props.profileData',
                                this.props.profileData
                              )}
                              <Link
                                to={`/users/$null?userId=${this.props.profileData.id}`}
                                target="_blank"
                              >
                                {' '}
                                {window.location.host}
                                /#/users/
                                {this.props.profileData.id}
                                {'...'}
                              </Link>
                            </li>
                          </ul>

                          <div>
                            <b style={{ fontSize: 14 }}>
                              All OTR events added by this profile:
                            </b>
                          </div>
                          <ul>
                            {eventsData && eventsData.length > 0 ? (
                              <>
                                <li>
                                  Linked Events page:{' '}
                                  <Link
                                    to={{
                                      pathname: `/dashboard/linked?userId=${this.props.profileData.id}&name=${obj.Vendor}`
                                    }}
                                    target="_blank"
                                  >
                                    {/* {window.location.href} */}
                                    {/* {window.location.host} */}
                                    {/* /#/dashboard/my-events */}{' '}
                                    otr/linkedeventpage/{'...'}
                                  </Link>
                                </li>
                              </>
                            ) : (
                              <li>
                                Linked Events page:{' '}
                                <Link
                                  to={{
                                    pathname: `/dashboard/linked?userId=${this.props.profileData.id}&name=${obj.Vendor}`
                                  }}
                                  target="_blank"
                                >
                                  otr/linkedeventpage/{'...'}
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="event-poup-btn 8 fff">
                          <>
                            {/* {(this.props.profileData.id === this.props.myProfile.profile.id) ?
                                <button
                                    className="btn mt-4"
                                    onClick={() => {}}>
                                    EDIT
                                  </button> : null
                                  } */}
                          </>
                          <>
                            {this.props.profileData &&
                            this.props.profileData.id ===
                              this.props.myProfile.profile.id ? (
                              <button
                                className="btn mt-2 editBtn"
                                onClick={() => {
                                  localStorage.setItem(
                                    'userId',
                                    this.props.myProfile.profile.id
                                  );
                                  localStorage.setItem('mode', 'edit');
                                  this.props.getUserProfile(
                                    '/api/v1/user/profile',
                                    {
                                      headers: {
                                        'x-authorization': localStorage.getItem(
                                          'token'
                                        )
                                      }
                                    },
                                    true
                                  );
                                }}
                              >
                                EDIT
                              </button>
                            ) : null}
                          </>
                          {this.props.profileData.membership_id !== '4' && (
                            <>
                              {!this.props.status && (
                                <button
                                  className="btn mt-0"
                                  onClick={() => {
                                    this.onFollow(this);
                                  }}
                                >
                                  FOLLOW
                                </button>
                              )}

                              {this.props.status &&
                                this.props.profileData.id !==
                                  this.props.myProfile.profile.id && (
                                  <button
                                    disabled={
                                      this.props.profileData.id ===
                                      this.props.myProfile.profile.id
                                        ? true
                                        : false
                                    }
                                    className="btn btn-hover btn-danger unfollow-btn"
                                    style={
                                      this.props.profileData.id ===
                                      this.props.myProfile.profile.id
                                        ? { cursor: 'not-allowed' }
                                        : {}
                                    }
                                    onClick={() =>
                                      this.togglePrivacyModal(
                                        this.props.profileData.id
                                      )
                                    }
                                  >
                                    UNFOLLOW
                                  </button>
                                )}
                            </>
                          )}
                        </div>
                      </>
                    ) : this.props.profileData.membership_id === '5' ? (
                      <>
                        {console.log('3333333333333333->>')}
                        <div className="event-popup-body prFirm-profile-inner">
                          <ul className="remove-icon vendorModal-list 2">
                            <>
                              <li class="bullet-remove">
                                {/* PR Firm: */}
                                Company: <b>{obj.Vendor}</b>
                              </li>
                              {obj['Services offered'] &&
                                !!obj['Services offered'].length && (
                                  <li class="bullet-remove break_word">
                                    Services offered:{' '}
                                    <b>{obj['Services offered']}</b>
                                  </li>
                                )}
                              {obj['emailDisplay'] &&
                              obj['emailDisplay'] === 'Yes' ? (
                                <li class="bullet-remove">
                                  Contact:{''} <b>{obj.Contact}</b>
                                </li>
                              ) : (
                                ''
                              )}
                              {obj.Goodreads && (
                                <li class="bullet-remove">
                                  Goodreads:{' '}
                                  <a
                                    href={obj.Goodreads}
                                    target="_blank"
                                    className="ellipsis"
                                    rel="noreferrer"
                                  >
                                    {obj.Goodreads}
                                  </a>
                                </li>
                              )}
                              {obj.Website && (
                                <li class="bullet-remove">
                                  Website:{' '}
                                  <a
                                    href={obj.Website}
                                    target="_blank"
                                    //className="ellipsis"
                                    rel="noreferrer"
                                  >
                                    {obj.Website}
                                  </a>
                                </li>
                              )}

                              {obj['Social media'] &&
                                !!obj['Social media'][0].length && (
                                  <li class="bullet-remove">
                                    {' '}
                                    <li>
                                      <b>Social Media:</b>
                                    </li>
                                    {obj['Social media'] &&
                                      obj['Social media'][0].map(subObj => {
                                        return (
                                          <li className="more-profile">
                                            <a
                                              href={Object.values(
                                                subObj
                                              )[0].toString()}
                                              target="_blank"
                                              //className="ellipsis"
                                              rel="noreferrer"
                                            >
                                              {Object.keys(subObj)[0]}
                                            </a>
                                            &nbsp;|&nbsp;
                                          </li>
                                        );
                                      })}
                                  </li>
                                )}
                              {obj['PR Fan groups'] && (
                                <li>
                                  {/* <b>PR Fan groups: </b> */}
                                  <b>Fan groups: </b>
                                </li>
                              )}

                              {obj['PR Fan groups'] &&
                                obj['PR Fan groups'][0].map(subObj => {
                                  return (
                                    <ul>
                                      <li>
                                        {Object.keys(subObj)[0]}:{' '}
                                        <a
                                          href={Object.values(
                                            subObj
                                          )[0].toString()}
                                          target="_blank"
                                          //className="ellipsis"
                                          rel="noreferrer"
                                        >
                                          {Object.values(subObj)[0]}
                                        </a>{' '}
                                      </li>
                                    </ul>
                                  );
                                })}
                              {/* {obj['Profile summary'] && (
                           <li class="bullet-remove">
                           Profile summary:{' '}
                         <b>{obj['Profile summary']}</b> 
                         </li>
                        )} */}

                              {obj[
                                'Best site for AUTHORS to interact with us'
                              ] && (
                                <li class="bullet-remove">
                                  {obj[
                                    'Best site for AUTHORS to interact with us'
                                  ].split(':')[0] === 'http' ||
                                  obj[
                                    'Best site for AUTHORS to interact with us'
                                  ].split(':')[0] === 'https' ? (
                                    <>
                                      {' '}
                                      Best site for AUTHORS to interact with us:{' '}
                                      <a
                                        href={
                                          obj[
                                            'Best site for AUTHORS to interact with us'
                                          ]
                                        }
                                        //className="ellipsis"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {obj[
                                          'Best site for AUTHORS to interact with us'
                                        ].slice(0, 15)}
                                        {'...'}
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      Best site for AUTHORS to interact with us:{' '}
                                      <b>
                                        {' '}
                                        {
                                          obj[
                                            'Best site for AUTHORS to interact with us'
                                          ]
                                        }
                                      </b>
                                    </>
                                  )}
                                </li>
                              )}
                              {obj[
                                'Best site for bloggers/readers to interact with us'
                              ] && (
                                <li class="bullet-remove">
                                  {obj[
                                    'Best site for bloggers/readers to interact with us'
                                  ].split(':')[0] === 'http' ||
                                  obj[
                                    'Best site for bloggers/readers to interact with us'
                                  ].split(':')[0] === 'https' ? (
                                    <>
                                      {' '}
                                      Best site for bloggers/readers to
                                      interact:{' '}
                                      <a
                                        href={
                                          obj[
                                            'Best site for bloggers/readers to interact with us'
                                          ]
                                        }
                                        //className="ellipsis"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {obj[
                                          'Best site for bloggers/readers to interact with us'
                                        ].slice(0, 15)}
                                        {'...'}
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      Best site for bloggers/readers to
                                      interact:{' '}
                                      <b>
                                        {
                                          obj[
                                            'Best site for bloggers/readers to interact with us'
                                          ]
                                        }
                                      </b>
                                    </>
                                  )}
                                </li>
                              )}
                              {obj['Are you accepting new clients'] && (
                                <li class="bullet-remove">
                                  Are you accepting new clients:{' '}
                                  <b>{obj['Are you accepting new clients']}</b>
                                </li>
                              )}
                              {obj[
                                'Currently featured on Vendor Cancellation Openings (VCO) list'
                              ] &&
                              obj[
                                'Currently featured on Vendor Cancellation Openings (VCO) list'
                              ].length == '1' ? (
                                <>
                                  <li class="bullet-remove">
                                    {' '}
                                    <b>Vendor Cancellation Openings (VCO):</b>
                                  </li>
                                </>
                              ) : (
                                <>
                                  <li class="bullet-remove">
                                    <b>Vendor Cancellation Openings (VCO):</b>
                                  </li>
                                  <ul>
                                    <li>No</li>
                                  </ul>
                                </>
                              )}

                              {obj[
                                'Currently featured on Vendor Cancellation Openings (VCO) list'
                              ] ? (
                                <div className="mb-1">
                                  {obj[
                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                  ][0].map(subObj => {
                                    console.log(
                                      'hiiiiiiiiiiiiiii --------------> ',
                                      new Date(
                                        Object.values(subObj)[0]
                                      ).getTime()
                                    );
                                    return (
                                      <>
                                        {/*                             
                              <li class="bullet-remove"> <b>Vendor Cancellation Openings (VCO):</b></li> */}
                                        {new Date().getTime() >=
                                        new Date(
                                          Object.values(subObj)[0]
                                        ).getTime() ? (
                                          <></>
                                        ) : (
                                          <>
                                            <ul>
                                              {' '}
                                              <li>
                                                <b>{Object.keys(subObj)[0]}</b>:{' '}
                                                {moment(
                                                  Object.values(subObj)[0]
                                                ).format('MMMM DD, YYYY')}
                                              </li>
                                            </ul>
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                  {VCOLength.length ==
                                    obj[
                                      'Currently featured on Vendor Cancellation Openings (VCO) list'
                                    ][0].length && (
                                    <ul>
                                      <li>No</li>
                                    </ul>
                                  )}
                                </div>
                              ) : (
                                ''
                              )}
                              {obj['Recent clients/works'] && (
                                <li class="bullet-remove">
                                  Recent clients/works:{' '}
                                  {obj['Recent clients/works'] &&
                                    obj['Recent clients/works'].map(el => (
                                      <>
                                        {el.split(':')[0] === 'http' ||
                                        el.split(':')[0] === 'https' ? (
                                          <ul>
                                            <li>
                                              <a
                                                href={el}
                                                //className="ellipsis"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {el.length > 50
                                                  ? el.slice(0, 50) + '...'
                                                  : el}
                                              </a>
                                            </li>
                                          </ul>
                                        ) : (
                                          <ul>
                                            <li>{el}</li>
                                          </ul>
                                        )}
                                      </>
                                    ))}
                                </li>
                              )}
                              {obj['Brief introduction'] && (
                                <li class="bullet-remove">
                                  Brief introduction:{' '}
                                  <b>{obj['Brief introduction']}</b>
                                </li>
                              )}
                              {obj.Bio && (
                                <>
                                  <li class="bullet-remove">Bio:</li>{' '}
                                  <li class="bullet-remove">
                                    <i>{obj.Bio}</i>
                                  </li>
                                </>
                              )}
                            </>
                            {this.props.profileData.follow_code && (
                              <>
                                <li>
                                  <span>INVITE code: </span>
                                  <span>
                                    {' '}
                                    <b>
                                      {' '}
                                      {this.props.profileData.follow_code}{' '}
                                    </b>
                                  </span>
                                </li>

                                <li>
                                  Link to profile:{' '}
                                  <Link
                                    to={`/users/${this.props.profileData.follow_code}`}
                                    target="_blank"
                                  >
                                    {' '}
                                    {window.location.host}
                                    /#/users/
                                    {this.props.profileData.follow_code.slice(
                                      0,
                                      1
                                    )}
                                    {'...'}
                                  </Link>
                                </li>
                              </>
                            )}
                          </ul>
                          {this.props.profileData.membership_id == 3 ? (
                            <></>
                          ) : (
                            <>
                              <div>
                                <b style={{ fontSize: 14 }}>
                                  All OTR events added by this profile:
                                </b>
                              </div>
                              <ul className="mb-0">
                                {eventsData && eventsData.length > 0 ? (
                                  <>
                                    <li>
                                      Linked Events page:{' '}
                                      <Link
                                        to={{
                                          pathname: `/dashboard/linked?userId=${this.props.profileData.id}&name=${obj.Vendor}`
                                        }}
                                        target="_blank"
                                      >
                                        {/* {window.location.href} */}
                                        {/* {window.location.host} */}
                                        {/* /#/dashboard/my-events */}{' '}
                                        otr/linkedeventpage/{'...'}
                                      </Link>
                                    </li>
                                  </>
                                ) : (
                                  <li>
                                    Linked Events page:{' '}
                                    <Link
                                      to={{
                                        pathname: `/dashboard/linked?userId=${this.props.profileData.id}&name=${obj.Vendor}`
                                      }}
                                      target="_blank"
                                    >
                                      otr/linkedeventpage/{'...'}
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </>
                          )}
                        </div>
                        {this.props.profileData.membership_id == 3 ? (
                          <></>
                        ) : (
                          <>
                            <div className="event-poup-btn 8 mt-2">
                              <>
                                {/* {(this.props.profileData.id === this.props.myProfile.profile.id) ?
                                <button
                                    className="btn mt-4"
                                    onClick={() => {}}>
                                    EDIT
                                  </button> : null
                                  } */}
                              </>
                              {console.log(
                                'this is the status of follow ',
                                this.props.status
                              )}

                              {!this.props.status && (
                                <button
                                  className="btn mt-0"
                                  onClick={() => {
                                    this.onFollow(this);
                                  }}
                                >
                                  FOLLOW
                                </button>
                              )}

                              {this.props.status &&
                                this.props.profileData.id !==
                                  this.props.myProfile.profile.id && (
                                  <button
                                    className="btn btn-hover btn-danger unfollow-btn"
                                    onClick={() =>
                                      this.togglePrivacyModal(
                                        this.props.profileData.id
                                      )
                                    }
                                  >
                                    UNFOLLOW
                                  </button>
                                )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="event-popup-body">
                          <ul className="remove-icon vendorModal-list 3">
                            {this.state.profileKeyData &&
                              this.state.profileKeyData.map(data => {
                                let key = Object.keys(data)[0];
                                let value = Object.values(data);
                                if (
                                  key &&
                                  (key.toLowerCase() === 'undefined' ||
                                    key.toLowerCase() === 'end' ||
                                    key.toLowerCase() === 'event_title' ||
                                    key.toLowerCase() === 'image' ||
                                    key.toLowerCase() === 'book-cover' ||
                                    key.toLocaleLowerCase() == 'profile' ||
                                    key ==
                                      'Publicly display email on profile page?' ||
                                    key == 'emailDisplay')
                                ) {
                                  return;
                                }
                                if (key && value == 'undefined') {
                                  return;
                                }
                                if (Object.keys(data).length !== 0) {
                                  if (
                                    value[0] &&
                                    Array.isArray(value[0]) &&
                                    value[0].length
                                  ) {
                                    return (
                                      <span>
                                        <li>
                                          <span>
                                            {console.log('keykeykey', key)}
                                            <b>
                                              {key === 'Reader Groups' &&
                                              this.props.profileData
                                                .member_type === 'Blogger'
                                                ? 'Fan groups'
                                                : key}
                                              :
                                            </b>
                                          </span>
                                        </li>
                                        <ul>
                                          {value[0].length &&
                                            value[0].map(sub => {
                                              if (sub) {
                                                return (
                                                  sub.length > 0 &&
                                                  sub.map(subObj => {
                                                    if (
                                                      key == 'More profiles' ||
                                                      key == 'Social media' ||
                                                      key ==
                                                        'Other social media' ||
                                                      key == 'Social Media'
                                                    ) {
                                                      return (
                                                        <li className="more-profile">
                                                          <a
                                                            href={Object.values(
                                                              subObj
                                                            )[0].toString()}
                                                            target="_blank"
                                                            //className="ellipsis"
                                                            rel="noreferrer"
                                                          >
                                                            {
                                                              Object.keys(
                                                                subObj
                                                              )[0]
                                                            }
                                                          </a>
                                                          &nbsp;|&nbsp;
                                                        </li>
                                                      );
                                                    }
                                                    if (
                                                      key ==
                                                        'Currently featured on Last minite vendor cancellations list' ||
                                                      key ==
                                                        'Currently featured on last minite Vendor cancellations list' ||
                                                      key ==
                                                        'Currently featured on "Last minute vendor cancellation list"' ||
                                                      key ==
                                                        'Currently featured on Last minute vendor cancellation list' ||
                                                      key ==
                                                        'Currently featured on last minute vendor cancellations list' ||
                                                      key ==
                                                        'Currently featured on Last minute vendor cancellations list' ||
                                                      key ==
                                                        'Currently featured on Vendor Cancellation Openings (VCO) list' ||
                                                      key ==
                                                        'Currently featured on "Vendor Cancellation Openings (VCO) list"'
                                                    ) {
                                                      return (
                                                        <>
                                                          {' '}
                                                          {new Date().getTime() >=
                                                          new Date(
                                                            Object.values(
                                                              subObj
                                                            )[0]
                                                          ).getTime() ? (
                                                            ''
                                                          ) : (
                                                            <>
                                                              <li>
                                                                {/* <span>{moment(Object.keys(subObj)[0]).format("MM/DD/YYYY")}</span>: */}
                                                                <span>
                                                                  <b>
                                                                    {
                                                                      Object.keys(
                                                                        subObj
                                                                      )[0]
                                                                    }
                                                                    :
                                                                  </b>{' '}
                                                                  {moment(
                                                                    Object.values(
                                                                      subObj
                                                                    )[0]
                                                                  ).format(
                                                                    'MMMM DD, YYYY'
                                                                  )}
                                                                </span>
                                                              </li>
                                                            </>
                                                          )}
                                                        </>
                                                      );
                                                    }
                                                    if (
                                                      key == 'Reader groups' ||
                                                      key == 'Fan groups' ||
                                                      key ==
                                                        'PR fan groups: ' ||
                                                      key ==
                                                        'Where to purchase' ||
                                                      key == 'Purchase sites' ||
                                                      key == 'Reader Groups'
                                                    ) {
                                                      return (
                                                        <li>
                                                          <span>
                                                            {
                                                              Object.keys(
                                                                subObj
                                                              )[0]
                                                            }
                                                          </span>
                                                          :
                                                          <span>
                                                            {' '}
                                                            <a
                                                              href={Object.values(
                                                                subObj
                                                              )[0].toString()}
                                                              target="_blank"
                                                              //className="ellipsis"
                                                              rel="noreferrer"
                                                            >
                                                              {Object.values(
                                                                subObj
                                                              )[0].slice(0, 30)}
                                                              {'...'}
                                                            </a>
                                                          </span>
                                                        </li>
                                                      );
                                                    }
                                                    return (
                                                      <li>
                                                        <span>
                                                          {
                                                            Object.keys(
                                                              subObj
                                                            )[0]
                                                          }
                                                        </span>
                                                        :
                                                        <span>
                                                          {' '}
                                                          <a
                                                            href={Object.values(
                                                              subObj
                                                            )[0].toString()}
                                                            target="_blank"
                                                            // className="ellipsis"
                                                            rel="noreferrer"
                                                          >
                                                            {Object.values(
                                                              subObj
                                                            )[0].slice(0, 30)}
                                                            {'...'}
                                                          </a>
                                                        </span>
                                                      </li>
                                                    );
                                                  })
                                                );
                                              }
                                            })}
                                        </ul>
                                      </span>
                                    );
                                  }
                                  if (
                                    !Array.isArray(value[0]) &&
                                    value[0] !== ''
                                  ) {
                                    if (!isEmailPreview && key == 'Contact') {
                                      return;
                                    }

                                    if (
                                      key == 'Website' ||
                                      key == 'Goodreads' ||
                                      key == 'Goodreads link' ||
                                      key == 'Best site to rech me' ||
                                      key == 'PR Fan groups' ||
                                      key == 'Blog link' ||
                                      key == 'Website link' ||
                                      key == 'Best site to interact with us' ||
                                      // key == 'Alias' ||
                                      key ==
                                        'Best site for AUTHORS to interact with us' ||
                                      key == 'Master ARC list sign up' ||
                                      key == 'Best site to reach me' ||
                                      key == 'Newsletter sign up' ||
                                      key ==
                                        'Best site for bloggers/readers to interact with us'
                                    ) {
                                      return (
                                        <li className="web-line11">
                                          {/* <span>{key}+++++</span>:
                                      <span>
                                        {' '}
                                        <a
                                          href={value[0].toString()}
                                          target="_blank"
                                          className="ellipsis"
                                          rel="noreferrer"
                                        >
                                          {value[0].toString()}
                                        </a>
                                      </span> */}
                                          <span>
                                            {key ===
                                            'Best site to interact with us'
                                              ? 'Best site to interact on'
                                              : key}
                                          </span>
                                          :{' '}
                                          {(key == 'Best site to reach me' ||
                                          (key ==
                                            'Best site for AUTHORS to interact with us' &&
                                            value[0] ===
                                              value[0].split(':')[0]) ? (
                                            <span
                                              style={{ textDecoration: 'none' }}
                                            >
                                              {value[0].toString()}
                                            </span>
                                          ) : (
                                            key ==
                                            'Best site for bloggers/readers to interact with us'
                                          )) ? (
                                            <span>
                                              <a
                                                href={value[0].toString()}
                                                //className="ellipsis"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {value[0].slice(0, 20)}
                                                {'...'}
                                              </a>
                                            </span>
                                          ) : (
                                            <span>
                                              <a
                                                href={value[0].toString()}
                                                //className="ellipsis"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {value[0].length > 41
                                                  ? value[0].slice(0, 25)
                                                  : value[0].length > 28
                                                  ? value[0].slice(0, 15)
                                                  : value[0].slice(0, 20)}
                                                {'...'}
                                              </a>
                                            </span>
                                          )}
                                        </li>
                                      );
                                    }
                                    if (key == 'Bio') {
                                      return (
                                        <li>
                                          <span>{key}</span>:
                                          <p
                                            style={{
                                              whiteSpace: 'pre-line',
                                              display: 'block',
                                              'word-break': 'break-all'
                                            }}
                                          >
                                            {' '}
                                            <i>{value[0]}</i>
                                          </p>
                                        </li>
                                      );
                                    }
                                    return (
                                      <>
                                        {key !== 'AuthorMiddle' ? (
                                          <li className="fff">
                                            {this.props.profileData
                                              .membership_id == 5 ? (
                                              <>
                                                {key === 'Vendor'
                                                  ? 'PR Firm'
                                                  : key}
                                                :{' '}
                                                {(key ==
                                                  'Recent clients/works' &&
                                                  value[0].split(':')[0] ===
                                                    'http') ||
                                                value[0].split(':')[0] ===
                                                  'https' ? (
                                                  <a
                                                    href={value[0].toString()}
                                                    //className="ellipsis"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    {value[0].toString()}
                                                  </a>
                                                ) : (
                                                  <span
                                                    style={{
                                                      textDecoration: 'none'
                                                    }}
                                                  >
                                                    {value[0].toString()}
                                                  </span>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <span className="kkkk">
                                                  {key}
                                                </span>
                                                :
                                                {key == 'Profile summary' ? (
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        'capitalize'
                                                    }}
                                                  >
                                                    {' '}
                                                    {value[0]}
                                                  </span>
                                                ) : (
                                                  <span
                                                  // style={{
                                                  //   textTransform: 'capitalize',
                                                  //   color: 'red'
                                                  // }}
                                                  >
                                                    {' '}
                                                    <b>
                                                      {key === 'Author' ? (
                                                        <>
                                                          {
                                                            this.props
                                                              .profileData.fname
                                                          }{' '}
                                                          {JSON.parse(
                                                            this.props
                                                              .profileData
                                                              .user_details
                                                          )[2].value
                                                            ? JSON.parse(
                                                                this.props
                                                                  .profileData
                                                                  .user_details
                                                              )[2].value
                                                            : ''}{' '}
                                                          {
                                                            this.props
                                                              .profileData.lname
                                                          }
                                                        </>
                                                      ) : (
                                                        value[0]
                                                      )}
                                                    </b>
                                                  </span>
                                                )}
                                              </>
                                            )}
                                          </li>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    );
                                  }
                                }
                              })}

                            {this.props.profileData.follow_code && (
                              <>
                                <li>
                                  <span>INVITE code: </span>
                                  <span>
                                    {' '}
                                    <b>
                                      {' '}
                                      {this.props.profileData.follow_code}{' '}
                                    </b>
                                  </span>
                                </li>

                                <li>
                                  Link to profile:{' '}
                                  <Link
                                    to={`/users/${this.props.profileData.follow_code}`}
                                    target="_blank"
                                  >
                                    {' '}
                                    {window.location.host}
                                    /#/users/
                                    {this.props.profileData.follow_code.slice(
                                      0,
                                      1
                                    )}
                                    {'...'}
                                  </Link>
                                </li>
                              </>
                            )}
                          </ul>

                          {this.props.profileData.membership_id == 3 ? (
                            <></>
                          ) : (
                            <>
                              {console.log('------------------->>>> ', obj)}
                              <div>
                                <b>All OTR events added by this profile:</b>
                              </div>
                              <ul className="mb-0">
                                {eventsData && eventsData.length > 0 ? (
                                  <>
                                    <li>
                                      Linked Events page:{' '}
                                      <Link
                                        to={{
                                          pathname: `/dashboard/linked?userId=${
                                            this.props.profileData.id
                                          }&name=${
                                            fullName ? fullName : obj.Author
                                          }`
                                        }}
                                        target="_blank"
                                      >
                                        {/* {window.location.href} */}
                                        {/* {window.location.host} */}
                                        {/* /#/dashboard/my-events */}{' '}
                                        otr/linkedeventpage/{'...'}
                                      </Link>
                                    </li>
                                  </>
                                ) : (
                                  <li>
                                    {console.log('1111111--------> ', obj)}
                                    Linked Events page:{' '}
                                    <Link
                                      to={{
                                        pathname: `/dashboard/linked?userId=${
                                          this.props.profileData.id
                                        }&name=${obj.Blog || obj.Author}`
                                      }}
                                      target="_blank"
                                    >
                                      otr/linkedeventpage/{'...'}
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </>
                          )}
                        </div>

                        <div className="event-poup-btn 13">
                          <>
                            {this.props.profileData &&
                            this.props.profileData.id ===
                              this.props.myProfile.profile.id ? (
                              <button
                                className="btn mt-2 editBtn"
                                onClick={() => {
                                  localStorage.setItem(
                                    'userId',
                                    this.props.myProfile.profile.id
                                  );
                                  localStorage.setItem('mode', 'edit');
                                  this.props.getUserProfile(
                                    '/api/v1/user/profile',
                                    {
                                      headers: {
                                        'x-authorization': localStorage.getItem(
                                          'token'
                                        )
                                      }
                                    },
                                    true
                                  );
                                }}
                              >
                                EDIT
                              </button>
                            ) : null}
                          </>
                          {this.props.profileData.type_id == 1 ||
                          this.props.profileData.type_id == 5 ||
                          this.props.profileData.membership_id == 6 ? (
                            <>
                              {!this.props.status && (
                                <button
                                  className="btn mt-2"
                                  onClick={() => {
                                    // if (
                                    //   window.confirm(
                                    //     "You will be notified about this member's updates and their events."
                                    //   )
                                    // )
                                    this.onFollow(this);
                                  }}
                                >
                                  FOLLOW
                                </button>
                              )}

                              {this.props.status &&
                                this.props.profileData.id !==
                                  this.props.myProfile.profile.id && (
                                  <button
                                    disabled={
                                      this.props.profileData.id ===
                                      this.props.myProfile.profile.id
                                        ? true
                                        : false
                                    }
                                    className="btn btn-hover btn-danger mt-2 unfollow-btn"
                                    // onClick={() => {
                                    //   this.onUnFollow(this);
                                    // }}
                                    onClick={() =>
                                      this.togglePrivacyModal(
                                        this.props.profileData.id
                                      )
                                    }
                                    style={
                                      this.props.profileData.id ===
                                      this.props.myProfile.profile.id
                                        ? { cursor: 'not-allowed' }
                                        : {}
                                    }
                                  >
                                    UNFOLLOW
                                  </button>
                                )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </ModalBody>
                </div>
              ) : (
                <div className="model_cover profile_model">
                  <ModalHeader
                    className="eventModal"
                    toggle={this.props.toggle}
                  >
                    {this.props.eventData ? (
                      <h3
                        style={{
                          paddingTop: pathImg !== '' ? '250px' : '0px',
                          textAlign: 'center'
                        }}
                      >
                        {this.state.title}
                      </h3>
                    ) : null}
                    {localStorage.getItem('membershipId') == '3' ? (
                      ''
                    ) : (
                      <>
                        <div
                          className={`profile_image profilr_image ${
                            pathImg
                              ? localStorage.getItem('membershipId') === '1' ||
                                localStorage.getItem('membershipId') === '8'
                                ? 'authorImg'
                                : localStorage.getItem('membershipId') === '4'
                                ? 'vendorImg'
                                : localStorage.getItem('membershipId') === '5'
                                ? 'prFirmImg'
                                : localStorage.getItem('membershipId') === '6'
                                ? 'bloggerImg'
                                : ''
                              : ''
                          }`}
                          // style={{
                          //   backgroundImage:
                          //     pathImg !== '' ? `url(${pathImg})` : '',
                          //   backgroundSize: pathImg !== '' ? 'contain' : '',
                          //   backgroundRepeat: 'no-repeat',
                          //   height: pathImg !== '' ? '250px' : '100px',
                          //   width: '100%',
                          //   backgroundPositionX: 'center'
                          // }}
                        >
                          <img src={pathImg !== '' ? pathImg : defaulUserImg} />
                        </div>
                      </>
                    )}
                  </ModalHeader>
                  <ModalBody className="event_body">
                    <div className="event-popup-body">
                      <ul className="remove-icon">
                        {localStorage.getItem('membershipId') == '4' && (
                          <>
                            <li class="bullet-remove">
                              Vendor: <b>{obj.Vendor}</b>
                            </li>
                            {obj['Services offered'] &&
                              !!obj['Services offered'].length && (
                                <li class="bullet-remove">
                                  Services offered:{' '}
                                  <b>{obj['Services offered']}</b>
                                </li>
                              )}
                            {obj.emailDisplay && obj.emailDisplay === 'Yes' ? (
                              <li class="bullet-remove">
                                Contact:{''} <b>{obj.Contact}</b>
                              </li>
                            ) : (
                              ''
                            )}
                            {obj['Website'] && (
                              <li class="bullet-remove">
                                Website:{' '}
                                <a
                                  href={obj['Website']}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj.Website.slice(0, 25)}
                                  {'...'}
                                </a>
                              </li>
                            )}

                            {obj['Social media'] &&
                              !!obj['Social media'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Social media:</b>
                                  </li>
                                  {obj['Social media'] &&
                                    obj['Social media'][0].map(subObj => {
                                      return (
                                        <li className="more-profile">
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.keys(subObj)[0]}
                                          </a>
                                          &nbsp;|&nbsp;
                                        </li>
                                      );
                                    })}
                                </li>
                              )}

                            {obj['Accepting new clients?'] && (
                              <li class="bullet-remove">
                                Are you accepting new clients:{' '}
                                <b>{obj['Accepting new clients?']}</b>
                              </li>
                            )}
                            {obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ] &&
                            obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ].length == '1' ? (
                              <>
                                <li class="bullet-remove">
                                  {' '}
                                  <b>Vendor Cancellation Openings (VCO):</b>
                                </li>
                              </>
                            ) : (
                              <>
                                <li class="bullet-remove">
                                  <b>Vendor Cancellation Openings (VCO):</b>
                                </li>
                                <ul>
                                  <li>No</li>
                                </ul>
                              </>
                            )}

                            {obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ] ? (
                              <div className="mb-1">
                                {obj[
                                  'Currently featured on Vendor Cancellation Openings (VCO) list'
                                ][0].map(subObj => {
                                  return (
                                    <>
                                      {/* <li class="bullet-remove"> <b>Vendor Cancellation Openings (VCO):</b></li> */}
                                      {new Date().getTime() >=
                                      new Date(
                                        Object.values(subObj)[0]
                                      ).getTime() ? (
                                        <>
                                          {/* <ul>
                                            <li>No</li>
                                          </ul> */}
                                        </>
                                      ) : (
                                        <>
                                          <ul>
                                            {' '}
                                            <li>
                                              <b>{Object.keys(subObj)[0]}</b>:{' '}
                                              {moment(
                                                Object.values(subObj)[0]
                                              ).format('MMMM DD, YYYY')}
                                            </li>
                                          </ul>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                                {VCOLength.length ==
                                  obj[
                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                  ][0].length && (
                                  <ul>
                                    <li>No</li>
                                  </ul>
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                            {obj['Best site to interact with us'] && (
                              <li class="bullet-remove">
                                {obj['Best site to interact with us'].split(
                                  ':'
                                )[0] === 'http' ||
                                obj['Best site to interact with us'].split(
                                  ':'
                                )[0] === 'https' ? (
                                  <>
                                    {' '}
                                    Best site to interact with us:{' '}
                                    <a
                                      href={
                                        obj['Best site to interact with us']
                                      }
                                      //className="ellipsis"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {obj[
                                        'Best site to interact with us'
                                      ].slice(0, 25)}
                                      {'...'}
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    Best site to interact with us:{' '}
                                    <b>
                                      {obj['Best site to interact with us']}
                                    </b>
                                  </>
                                )}
                              </li>
                            )}

                            {obj['Recent clients/works'] && (
                              <li class="bullet-remove">
                                Recent clients/works:{' '}
                                {obj['Recent clients/works'].map(el => (
                                  <>
                                    {el.split(':')[0] === 'http' ||
                                    el.split(':')[0] === 'https' ? (
                                      <ul>
                                        <li>
                                          <a
                                            href={el}
                                            //className="ellipsis"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {el.length > 50
                                              ? el.slice(0, 50) + '...'
                                              : el}
                                          </a>
                                        </li>
                                      </ul>
                                    ) : (
                                      <ul>
                                        <li>{el}</li>
                                      </ul>
                                    )}
                                  </>
                                ))}
                              </li>
                            )}
                            <li class="bullet-remove">
                              Brief introduction:{' '}
                              <b>{obj['Brief introduction']}</b>
                            </li>
                            {obj.Bio && (
                              <>
                                <li class="bullet-remove">Bio:</li>{' '}
                                <li class="bullet-remove">
                                  <i>{obj.Bio}</i>
                                </li>
                              </>
                            )}
                            <li>
                              Link to profile:{' '}
                              {console.log(
                                'this.props.profileData',
                                this.props.profileData
                              )}
                              <Link
                                to={`/users/$null?userId=${localStorage.getItem(
                                  'userId'
                                )}`}
                                target="_blank"
                              >
                                {' '}
                                {window.location.host}
                                /#/users/
                                {localStorage.getItem('userId')}
                                {'...'}
                              </Link>
                            </li>
                          </>
                        )}
                        {localStorage.getItem('membershipId') == '8' && (
                          <>
                            {/* {console.log(
                              '000000000000000000',
                              this.props.profileData
                            )} */}
                            <li class="bullet-remove">
                              Author:{' '}
                              <b>
                                {/* {this.props.myProfile.profile.fname}{' '}
                                {this.props.myProfile.profile.user_details[2]
                                  .value
                                  ? this.props.myProfile.profile.user_details[2]
                                      .value
                                  : ''}{' '}
                                {this.props.myProfile.profile.lname} */}
                                {obj.AuthorFull}
                              </b>
                            </li>
                            {/* <li class="bullet-remove">
                          {console.log("myconsol",obj)}
                          Contact: <b>{obj.Contact}</b>
                        </li> */}
                            {obj['Publicly display email on profile page?'] &&
                            obj['Publicly display email on profile page?'] ===
                              'Yes' ? (
                              <li class="bullet-remove">
                                Contact:{''} <b>{obj.Contact}</b>
                              </li>
                            ) : (
                              ''
                            )}
                            {obj.Goodreads && (
                              <li class="bullet-remove">
                                Goodreads:{' '}
                                <a
                                  href={obj.Goodreads}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj.Goodreads.slice(0, 30)}
                                  {'...'}
                                </a>
                              </li>
                            )}
                            {obj.Website && (
                              <li class="bullet-remove">
                                Website:{' '}
                                <a
                                  href={obj.Website}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj.Website.length > 10
                                    ? obj.Website
                                    : obj.Website.slice(0, 15)}
                                  {'...'}
                                </a>
                              </li>
                            )}
                            {obj['Social Media'] &&
                              !!obj['Social Media'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Social Media:</b>
                                  </li>
                                  {obj['Social Media'] &&
                                    obj['Social Media'][0].map(subObj => {
                                      return (
                                        <li className="more-profile">
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.keys(subObj)[0]}
                                          </a>
                                          &nbsp;|&nbsp;
                                        </li>
                                      );
                                    })}
                                </li>
                              )}
                            {obj['Reader Groups'] &&
                              !!obj['Reader Groups'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Reader groups:</b>
                                  </li>
                                  {obj['Reader Groups'] &&
                                    obj['Reader Groups'][0].map(subObj => {
                                      return (
                                        <ul>
                                          <li>
                                            {Object.keys(subObj)[0]}:{' '}
                                            <a
                                              href={Object.values(
                                                subObj
                                              )[0].toString()}
                                              target="_blank"
                                              //className="ellipsis"
                                              rel="noreferrer"
                                            >
                                              {Object.values(subObj)[0].slice(
                                                0,
                                                40
                                              )}
                                              {'...'}
                                            </a>{' '}
                                          </li>
                                        </ul>
                                      );
                                    })}
                                </li>
                              )}
                            {obj['Purchase sites'] &&
                              !!obj['Purchase sites'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Purchase sites:</b>
                                  </li>
                                  {obj['Purchase sites'] &&
                                    obj['Purchase sites'][0].map(subObj => {
                                      return (
                                        <ul>
                                          <li>
                                            {Object.keys(subObj)[0]}:{' '}
                                            <a
                                              href={Object.values(
                                                subObj
                                              ).toString()}
                                              target="_blank"
                                              //className="ellipsis"
                                              rel="noreferrer"
                                            >
                                              {Object.values(subObj)[0].length >
                                              70
                                                ? Object.values(
                                                    subObj
                                                  )[0].slice(0, 30)
                                                : Object.values(
                                                    subObj
                                                  )[0].slice(0, 30)}
                                              {'...'}
                                            </a>{' '}
                                          </li>
                                        </ul>
                                      );
                                    })}
                                </li>
                              )}{' '}
                            {obj['Best site to reach me'] ||
                            obj.Alias ||
                            obj['Newsletter sign up'] ||
                            obj['Master ARC list sign up'] ? (
                              <li class="bullet-remove">
                                <b>Other:</b>
                              </li>
                            ) : (
                              ''
                            )}
                            {/* <li class="bullet-remove">
                          <b>Other:</b>
                        </li> */}
                            {obj.Alias && (
                              <li class="bullet-remove">
                                Alias: <b>{obj.Alias}</b>
                              </li>
                            )}
                            {obj['Newsletter sign up'] && (
                              <li class="bullet-remove">
                                Newsletter sign up:{' '}
                                <a
                                  href={obj['Newsletter sign up']}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj['Newsletter sign up'].slice(0, 38)}
                                  {'...'}
                                </a>
                              </li>
                            )}
                            {obj['Master ARC list sign up'] && (
                              <li class="bullet-remove">
                                Master ARC list sign up:{' '}
                                <a
                                  href={obj['Master ARC list sign up']}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj['Master ARC list sign up'].slice(0, 35)}
                                  {'...'}
                                </a>
                              </li>
                            )}
                            {obj['Best site to reach me'] && (
                              <li class="bullet-remove">
                                {obj['Best site to reach me'].split(':')[0] ===
                                  'http' ||
                                obj['Best site to reach me'].split(':')[0] ===
                                  'https' ? (
                                  <>
                                    {' '}
                                    Best site to reach me:{' '}
                                    <a
                                      href={obj['Best site to reach me']}
                                      //className="ellipsis"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {obj['Best site to reach me'].slice(
                                        0,
                                        30
                                      )}
                                      {'...'}
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    Best site to reach me:{' '}
                                    <b>{obj['Best site to reach me']}</b>
                                  </>
                                )}
                              </li>
                            )}
                            {obj.Bio && (
                              <>
                                <li class="bullet-remove">Bio:</li>{' '}
                                <li class="bullet-remove">
                                  <i>{obj.Bio}</i>
                                </li>
                              </>
                            )}
                          </>
                        )}

                        {localStorage.getItem('membershipId') == '5' && (
                          <>
                            {console.log(
                              'this is obj of service offered---> ',
                              obj['Services offered']
                            )}
                            <li class="bullet-remove">
                              {/* PR Firm: */}
                              Company name: {''}
                              <b>{obj.Vendor}</b>
                            </li>
                            {obj['Services offered'] &&
                              !!obj['Services offered'].length && (
                                <li class="bullet-remove">
                                  Services offered:{' '}
                                  <b>{obj['Services offered']}</b>
                                </li>
                              )}
                            {obj['emailDisplay'] &&
                            obj['emailDisplay'] === 'Yes' ? (
                              <li class="bullet-remove">
                                Contact:{''} <b>{obj.Contact}</b>
                              </li>
                            ) : (
                              ''
                            )}
                            {console.log('wooneder', obj)}
                            {obj.Goodreads && (
                              <li class="bullet-remove">
                                Goodreads:{' '}
                                <a
                                  href={obj.Goodreads}
                                  target="_blank"
                                  className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj.Goodreads}
                                </a>
                              </li>
                            )}
                            <li class="bullet-remove">
                              Website:{' '}
                              <a
                                href={obj.Website}
                                target="_blank"
                                //className="ellipsis"
                                rel="noreferrer"
                              >
                                {obj.Website}
                              </a>
                            </li>
                            {obj['Social media'] &&
                              !!obj['Social media'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Social Media:</b>
                                  </li>
                                  {obj['Social media'] &&
                                    obj['Social media'][0].map(subObj => {
                                      return (
                                        <li className="more-profile">
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.keys(subObj)[0]}
                                          </a>
                                          &nbsp;|&nbsp;
                                        </li>
                                      );
                                    })}
                                </li>
                              )}
                            {obj['PR Fan groups'] && (
                              <li>
                                {/* <b>PR Fan groups: </b> */}
                                <b>Fan groups: </b>
                              </li>
                            )}

                            {obj['PR Fan groups'] &&
                              obj['PR Fan groups'][0].map(subObj => {
                                return (
                                  <ul>
                                    <li>
                                      {Object.keys(subObj)[0]}:{' '}
                                      <a
                                        href={Object.values(
                                          subObj
                                        )[0].toString()}
                                        target="_blank"
                                        //className="ellipsis"
                                        rel="noreferrer"
                                      >
                                        {Object.values(subObj)[0]}
                                      </a>{' '}
                                    </li>
                                  </ul>
                                );
                              })}

                            {/* {obj[
                          'Currently featured on Vendor Cancellation Openings (VCO) list'
                        ] ? (
                          obj[
                            'Currently featured on Vendor Cancellation Openings (VCO) list'
                          ][0].map(subObj => {
                            return (
                              <ul>
                                <li>
                                  <b>{Object.keys(subObj)[0]}</b>:{' '}
                                  {moment(Object.values(subObj)[0]).format(
                                    'MMMM DD, YYYY'
                                  )}
                                </li>
                              </ul>
                            );
                          })
                        ) : (
                          <ul>
                            <li>No</li>
                          </ul>
                        )} */}

                            {/* {obj['Currently featured on Vendor Cancellation Openings (VCO) list'] &&
                          !!obj['Currently featured on Vendor Cancellation Openings (VCO) list'][0].length && (
                            <li class="bullet-remove">
                              {' '}
                              <li>
                              <b>Vendor Cancellation Openings (VCO):</b>{' '}
                              </li>
                              {console.log('prr', obj)}
                              {obj['Currently featured on Vendor Cancellation Openings (VCO) list'] &&
                                obj['Currently featured on Vendor Cancellation Openings (VCO) list'][0].map(subObj => {
                                  return (
                                    <>
                                  
  
                                      {
                                        new Date().getTime() >=
                                        new Date( Object.values(subObj)[0]).getTime() ? '': (<>
                                        <ul>
                           
                                    
                           
                                    <li>
                                         <b>{Object.keys(subObj)[0]}</b>:{' '}
                                      {moment(Object.values(subObj)[0]).format(
                                        'MMMM DD, YYYY'
                                      )}
                                        </li>
                                  </ul>
                                        </>)
    
                                      }
    
                                  
                                  </>
                                  );
                                })}
                            </li>
                          )} */}
                            {obj[
                              'Best site for AUTHORS to interact with us'
                            ] && (
                              <li class="bullet-remove">
                                {obj[
                                  'Best site for AUTHORS to interact with us'
                                ].split(':')[0] === 'http' ||
                                obj[
                                  'Best site for AUTHORS to interact with us'
                                ].split(':')[0] === 'https' ? (
                                  <>
                                    {' '}
                                    Best site for AUTHORS to interact with us:{' '}
                                    <a
                                      href={
                                        obj[
                                          'Best site for AUTHORS to interact with us'
                                        ]
                                      }
                                      //className="ellipsis"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {obj[
                                        'Best site for AUTHORS to interact with us'
                                      ].slice(0, 15)}
                                      {'...'}
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    Best site for AUTHORS to interact with us:{' '}
                                    <b>
                                      {' '}
                                      {
                                        obj[
                                          'Best site for AUTHORS to interact with us'
                                        ]
                                      }
                                    </b>
                                  </>
                                )}
                              </li>
                            )}
                            {obj[
                              'Best site for bloggers/readers to interact with us'
                            ] && (
                              <li class="bullet-remove">
                                {obj[
                                  'Best site for bloggers/readers to interact with us'
                                ].split(':')[0] === 'http' ||
                                obj[
                                  'Best site for bloggers/readers to interact with us'
                                ].split(':')[0] === 'https' ? (
                                  <>
                                    {' '}
                                    Best site for bloggers/readers to interact:{' '}
                                    <a
                                      href={
                                        obj[
                                          'Best site for bloggers/readers to interact with us'
                                        ]
                                      }
                                      //className="ellipsis"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {obj[
                                        'Best site for bloggers/readers to interact with us'
                                      ].slice(0, 15)}
                                      {'...'}
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    Best site for bloggers/readers to interact:{' '}
                                    <b>
                                      {
                                        obj[
                                          'Best site for bloggers/readers to interact with us'
                                        ]
                                      }
                                    </b>
                                  </>
                                )}
                              </li>
                            )}
                            {obj['Are you accepting new clients'] && (
                              <li class="bullet-remove">
                                Are you accepting new clients:{' '}
                                <b>{obj['Are you accepting new clients']}</b>
                              </li>
                            )}
                            {obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ] &&
                            obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ].length == '1' ? (
                              <>
                                <li class="bullet-remove">
                                  {' '}
                                  <b>Vendor Cancellation Openings (VCO):</b>
                                </li>
                              </>
                            ) : (
                              <>
                                <li class="bullet-remove">
                                  <b>Vendor Cancellation Openings (VCO):</b>
                                </li>
                                <ul>
                                  <li>No</li>
                                </ul>
                              </>
                            )}
                            {obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ] ? (
                              <div className="mb-1">
                                {obj[
                                  'Currently featured on Vendor Cancellation Openings (VCO) list'
                                ][0].map(subObj => {
                                  return (
                                    <>
                                      {/*                             
                              <li class="bullet-remove"> <b>Vendor Cancellation Openings (VCO):</b></li> */}
                                      {new Date().getTime() >=
                                      new Date(
                                        Object.values(subObj)[0]
                                      ).getTime() ? (
                                        <>
                                          {/* <ul>
                                        <li>No</li>
                                      </ul> */}
                                        </>
                                      ) : (
                                        <>
                                          <ul>
                                            {' '}
                                            <li>
                                              <b>{Object.keys(subObj)[0]}</b>:{' '}
                                              {moment(
                                                Object.values(subObj)[0]
                                              ).format('MMMM DD, YYYY')}
                                            </li>
                                          </ul>
                                        </>
                                      )}
                                    </>
                                  );
                                })}

                                {VCOLength.length ==
                                  obj[
                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                  ][0].length && (
                                  <ul>
                                    <li>No</li>
                                  </ul>
                                )}
                              </div>
                            ) : (
                              ''
                            )}

                            {obj['Recent clients/works'] && (
                              <li class="bullet-remove">
                                Recent clients/works:{' '}
                                {obj['Recent clients/works'].map(el => (
                                  <>
                                    {el.split(':')[0] === 'http' ||
                                    el.split(':')[0] === 'https' ? (
                                      <ul>
                                        <li>
                                          <a
                                            href={el}
                                            //className="ellipsis"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {el.length > 50
                                              ? el.slice(0, 50) + '...'
                                              : el}
                                          </a>
                                        </li>
                                      </ul>
                                    ) : (
                                      <ul>
                                        <li>{el}</li>
                                      </ul>
                                    )}
                                  </>
                                ))}
                              </li>
                            )}
                            {obj['Brief introduction'] && (
                              <li class="bullet-remove">
                                Brief introduction:{' '}
                                <b>{obj['Brief introduction']}</b>
                              </li>
                            )}

                            {/* {obj['Recent clients/works'] && (
                          <li class="bullet-remove">
                            Recent clients/works :{' '}
                            <b>{obj['Recent clients/works']}</b>
                          </li>
                        )} */}

                            {/* <li>
                          <b>
                            Currently featured on Vendor Cancellation Openings
                            (VCO) list:
                          </b>
                        </li>
                        {obj[
                          'Currently featured on Vendor Cancellation Openings (VCO) list'
                        ] &&
                          obj[
                            'Currently featured on Vendor Cancellation Openings (VCO) list'
                          ][0].map(subObj => {
                            return (
                              <ul>
                                <li>
                                  <b>{Object.keys(subObj)[0]}</b>:{' '}
                                  {moment(Object.values(subObj)[0]).format(
                                    'MMMM DD, YYYY'
                                  )}
                                </li>
                              </ul>
                            );
                          })} */}

                            {obj.Bio && (
                              <>
                                <li class="bullet-remove">Bio:</li>{' '}
                                <li class="bullet-remove">
                                  <i>{obj.Bio}</i>
                                </li>
                              </>
                            )}

                            <li>
                              Link to profile:{' '}
                              <Link
                                to={`/users/$null?userId=${localStorage.getItem(
                                  'userId'
                                )}`}
                                target="_blank"
                              >
                                {' '}
                                {window.location.host}
                                /#/users/
                                {localStorage.getItem('userId')}
                                {'...'}
                              </Link>
                            </li>
                          </>
                        )}
                        {localStorage.getItem('membershipId') == '6' && (
                          <>
                            <li class="bullet-remove">
                              Blog:{' '}
                              <b>
                                {obj.Blog
                                  ? obj.Blog
                                  : this.props.myProfile
                                  ? this.props.myProfile.profile.user_details[0]
                                      .value
                                  : null}
                              </b>
                            </li>
                            {obj['Publicly display email on profile page?'] &&
                            obj['Publicly display email on profile page?'] ===
                              'Yes'
                              ? obj.Contact && (
                                  <li class="bullet-remove">
                                    Contact:{''}
                                    <b>{obj.Contact}</b>
                                  </li>
                                )
                              : ''}

                            {obj['Blog email'] && (
                              <li class="bullet-remove">
                                Blog email: <b>{obj['Blog email']}</b>
                              </li>
                            )}
                            {obj['Blog link'] && (
                              <li class="bullet-remove">
                                Blog link:{' '}
                                <a
                                  href={obj['Blog link']}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj['Blog link'].slice(0, 30)}
                                  {'...'}
                                  {/* {obj['Blog link'].length >15 ? obj['Blog link'] : obj['Blog link'].slice(0,15)}{'...'} */}
                                </a>
                              </li>
                            )}
                            {obj['Goodreads link'] && (
                              <li class="bullet-remove">
                                Goodreads link:{' '}
                                <a
                                  href={obj['Goodreads link']}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj['Goodreads link'].slice(0, 35)}
                                  {'...'}
                                </a>
                              </li>
                            )}

                            {obj['Social media'] &&
                              !!obj['Social media'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Social Media:</b>
                                  </li>
                                  {obj['Social media'] &&
                                    obj['Social media'][0].map(subObj => {
                                      return (
                                        <li className="more-profile">
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.keys(subObj)[0]}
                                          </a>
                                          &nbsp;|&nbsp;
                                        </li>
                                      );
                                    })}
                                </li>
                              )}

                            {/* {obj['Fan groups'] &&
                          obj['Fan groups'][0].map(subObj => {
                            return (
                              <>
                                     <li>
                          <b>Fan groups: </b>
                        </li>
                              <ul>
                                <li>
                                  {Object.keys(subObj)[0]}:{' '}
                                  <a
                                    href={Object.values(subObj)[0].toString()}
                                    target="_blank"
                                    //className="ellipsis"
                                    rel="noreferrer"
                                  >
                                   
                                    {Object.values(subObj)[0].slice(0,35)}{'...'}
                                                                      </a>{' '}
                                </li>
                                
                              </ul>
                              </>
                            );
                          })} */}
                            {obj['Fan groups'] &&
                              !!obj['Fan groups'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Fan groups:</b>
                                  </li>
                                  {obj['Fan groups'] &&
                                    obj['Fan groups'][0].map(subObj => {
                                      return (
                                        <ul>
                                          <li>
                                            {Object.keys(subObj)[0]}:{' '}
                                            <a
                                              href={Object.values(
                                                subObj
                                              )[0].toString()}
                                              target="_blank"
                                              //className="ellipsis"
                                              rel="noreferrer"
                                            >
                                              {Object.values(subObj)[0]}
                                            </a>{' '}
                                          </li>
                                        </ul>
                                      );
                                    })}
                                </li>
                              )}
                            {obj['Best site to interact with us'] && (
                              <li class="bullet-remove">
                                {obj['Best site to interact with us'].split(
                                  ':'
                                )[0] === 'http' ||
                                obj['Best site to interact with us'].split(
                                  ':'
                                )[0] === 'https' ? (
                                  <>
                                    {' '}
                                    Best site to interact:{' '}
                                    <a
                                      href={
                                        obj['Best site to interact with us']
                                      }
                                      //className="ellipsis"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {obj['Best site to interact with us']
                                        .length > 45
                                        ? obj['Best site to interact with us']
                                        : obj[
                                            'Best site to interact with us'
                                          ].slice(0, 35)}
                                      {'...'}
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    Best site to interact:{' '}
                                    {obj['Best site to interact with us']}
                                  </>
                                )}
                              </li>
                            )}
                          </>
                        )}

                        {localStorage.getItem('membershipId') == '3' && (
                          <>
                            <li class="bullet-remove profileSave">
                              Blog: <b>{obj.Blog}</b>
                            </li>
                            {obj['Blog link'] && (
                              <li class="bullet-remove profileSave">
                                Blog link:{' '}
                                <a
                                  href={obj['Blog link']}
                                  target="_blank"
                                  className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj['Blog link']}
                                </a>
                              </li>
                            )}
                            {obj['Blog email'] && (
                              <li className="bullet-remove profileSave">
                                Blog email: <b>{obj['Blog email']}</b>
                              </li>
                            )}
                          </>
                        )}
                        {localStorage.getItem('membershipId') == '1' && (
                          <>
                            <li class="bullet-remove">
                              Author:{' '}
                              <b>
                                {/* {this.props.myProfile ? (this.props.myProfile.profile.fname+' '+this.props.myProfile.profile.user_details[2].value? this.props.myProfile.profile.user_details[2].value: ''+' '+this.props.myProfile.profile.lname) : obj.AuthorFull} */}
                                {obj.AuthorFull}
                              </b>
                            </li>
                            {obj['Publicly display email on profile page?'] &&
                            obj['Publicly display email on profile page?'] ===
                              'Yes' ? (
                              <li class="bullet-remove">
                                Contact:{''} <b>{obj.Contact}</b>
                              </li>
                            ) : (
                              ''
                            )}
                            {obj.Goodreads && (
                              <li class="bullet-remove">
                                Goodreads:{' '}
                                <a
                                  href={obj.Goodreads}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj.Goodreads}
                                </a>
                              </li>
                            )}
                            {obj.Website && (
                              <li class="bullet-remove">
                                Website:{' '}
                                <a
                                  href={obj.Website}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj.Website}
                                </a>
                              </li>
                            )}
                            {obj['Social Media'] &&
                              !!obj['Social Media'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Social Media:</b>
                                  </li>
                                  {obj['Social Media'] &&
                                    obj['Social Media'][0].map(subObj => {
                                      return (
                                        <li className="more-profile">
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.keys(subObj)[0]}
                                          </a>
                                          &nbsp;|&nbsp;
                                        </li>
                                      );
                                    })}
                                </li>
                              )}
                            {obj['Reader Groups'] &&
                              !!obj['Reader Groups'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Reader groups:</b>
                                  </li>
                                  {obj['Reader Groups'] &&
                                    obj['Reader Groups'][0].map(subObj => {
                                      return (
                                        <ul>
                                          <li>
                                            {Object.keys(subObj)[0]}:{' '}
                                            <a
                                              href={Object.values(
                                                subObj
                                              )[0].toString()}
                                              target="_blank"
                                              //className="ellipsis"
                                              rel="noreferrer"
                                            >
                                              {Object.values(subObj)[0]}
                                            </a>{' '}
                                          </li>
                                        </ul>
                                      );
                                    })}
                                </li>
                              )}
                            {obj['Purchase sites'] &&
                              !!obj['Purchase sites'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Purchase sites:</b>
                                  </li>
                                  {obj['Purchase sites'] &&
                                    obj['Purchase sites'][0].map(subObj => {
                                      return (
                                        <ul>
                                          <li>
                                            {Object.keys(subObj)[0]}:{' '}
                                            <a
                                              href={Object.values(
                                                subObj
                                              )[0].toString()}
                                              target="_blank"
                                              //className="ellipsis"
                                              rel="noreferrer"
                                            >
                                              {Object.values(subObj)[0]}
                                            </a>{' '}
                                          </li>
                                        </ul>
                                      );
                                    })}
                                </li>
                              )}{' '}
                            {obj['Best site to reach me'] ||
                            obj.Alias ||
                            obj['Newsletter sign up'] ||
                            obj['Master ARC list sign up'] ? (
                              <li class="bullet-remove">
                                <b>Other:</b>
                              </li>
                            ) : (
                              ''
                            )}
                            {obj.Alias && (
                              <li class="bullet-remove">
                                Alias: <b>{obj.Alias}</b>
                              </li>
                            )}
                            {obj['Newsletter sign up'] && (
                              <li class="bullet-remove">
                                Newsletter sign up:{' '}
                                <a
                                  href={obj['Newsletter sign up']}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj['Newsletter sign up']}
                                </a>
                              </li>
                            )}
                            {obj['Master ARC list sign up'] && (
                              <li class="bullet-remove">
                                Master ARC list sign up:{' '}
                                <a
                                  href={obj['Master ARC list sign up']}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj['Master ARC list sign up']}
                                </a>
                              </li>
                            )}
                            {obj['Best site to reach me'] && (
                              <li class="bullet-remove">
                                {/* Best site to reach me:{' '}
                            {obj['Best site to reach me']} */}
                                {obj['Best site to reach me'].split(':')[0] ===
                                  'http' ||
                                obj['Best site to reach me'].split(':')[0] ===
                                  'https' ? (
                                  <>
                                    {' '}
                                    Best site to reach me:{' '}
                                    <a
                                      href={obj['Best site to reach me']}
                                      //className="ellipsis"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {obj['Best site to reach me'].slice(
                                        0,
                                        40
                                      )}
                                      {'...'}
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    Best site to reach me:{' '}
                                    <b>{obj['Best site to reach me']}</b>
                                  </>
                                )}
                              </li>
                            )}
                            {obj.Bio && (
                              <>
                                <li class="bullet-remove">Bio:</li>
                                <li class="bullet-remove">
                                  <i>{obj.Bio}</i>
                                </li>
                              </>
                            )}
                          </>
                        )}
                      </ul>
                      {localStorage.getItem('membershipId') == '3' ? (
                        ''
                      ) : (
                        <>
                          <div>
                            <div>
                              <b style={{ fontSize: 14 }}>
                                All OTR events added by this profile:
                              </b>
                            </div>
                            <ul>
                              <li>
                                Linked Events page:{' '}
                                <Link
                                  to={{
                                    pathname: `/dashboard/linked?userId=${localStorage.getItem(
                                      'userId'
                                    )}&name=${obj.Blog}`
                                  }}
                                  target="_blank"
                                >
                                  {/* {window.location.href} */}
                                  {/* {window.location.host} */}
                                  {/* /#/dashboard/my-events */}
                                  otr/linkedeventpage/{'...'}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="event-poup-btn 5">
                      <button
                        className="btn new_btn_save_bt float-md-right"
                        onClick={() => {
                          this.onSave(this);
                        }}
                        ref="btn"
                      >
                        Save
                      </button>

                      <button
                        className="btn new_btn_save_bt float-md-right"
                        onClick={() => {
                          this.props.clearPreviewData();
                        }}
                      >
                        Edit
                      </button>
                    </div>

                    {/* <button ref="btn" className="btn btn_save_bt" onClick={() => { this.onSave(this) }}>Save</button>
                                    <button className="btn btn_save_bt" onClick={() => { this.props.clearPreviewData() }}>Edit</button> */}
                  </ModalBody>
                </div>
              )}
            </Modal>
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }

  getFormattedValue(obj, key) {
    if (obj) {
      if (obj.format && obj.format === 'date' && key !== 'Time') {
        return moment(obj[key]).format('MMMM DD, YYYY');
      } else if (key === 'Author') {
        obj.Editor = obj[key];
        delete obj[key];
      } else if (key === 'Additional roles') {
        delete obj[key];
      } else if (obj[key]) {
        return obj[key];
      } else {
        return obj;
      }
    }
  }

  onSave() {
    this.refs.btn.setAttribute('disabled', 'disabled');
    this.props.confirmSave();
  }

  onFollow() {
    let ro = window.location.href;
    let rout = ro.split('/');
    let route = rout[6];

    let userData = {
      following_user_id: +this.props.profileData.id
    };

    this.props.follow(userData, route);
  }

  // onUnFollow() {
  //   let ro = window.location.href;
  //   let rout = ro.split('/');
  //   let route = rout[6];
  //   this.props.unFollow(this.props.profileData.id, route);
  // }

  addTobetaReaderList() {
    this.props.addToBetaReaderList(this.props.profileData.id);
  }

  removeFromBetaRederList() {
    this.props.removefromBetaReader(this.props.profileData.id);
  }

  componentDidMount() {
    if (this.props.preview && this.props.userKeyData) {
      if (
        localStorage.getItem('membershipId') == 5 ||
        localStorage.getItem('membershipId') == 4
      ) {
        let data23 = this.props.userKeyData;
        let nData = [];
        data23.map(el => {
          if (
            el['Vendor'] ||
            el['Contact'] ||
            el['Website'] ||
            el['Social media']
          ) {
            nData.push(el);
          }
        });

        data23.map(el => {
          if (
            el['Vendor'] ||
            el['Contact'] ||
            el['Website'] ||
            el['Social media']
          ) {
          } else {
            nData.push(el);
          }
        });

        // let obj = {};

        // nData.forEach(el => {
        //   const key = Object.keys(el)[0];
        //   console.log('key1399', key);

        //   if (obj[key]) {
        //     // obj[key] = obj[key] + ' | ' + el[key];
        //   } else {
        //     obj[key] = el[key];
        //   }
        // });
        // console.log('HERE OBJ ', { obj });
        // nData = [];
        // for (let item in obj) {
        //   nData.push({ [item]: obj[item] });
        // }
        console.log('first cond----');
        this.setState({
          profileKeyData: nData
        });
        this.setState({
          userdetail: this.props.newdata
        });
      } else {
        console.log('second cond----', this.props.userKeyData);
        this.setState({
          profileKeyData: this.props.userKeyData
        });
        this.setState({
          userdetail: this.props.newdata
        });
      }
    } else {
      this.props.getUserFollowingStatus(this.props.profileData.id);
      // this.props.getBetaStatus(this.props.profileData.id);
      this.props.getAllEvents(this.props.profileData.id);

      if (
        this.props.profileData.membership_id === '5' ||
        this.props.profileData.membership_id === '4'
      ) {
        console.log(' this.props.profileData3909', this.props.profileData);
        let data23 = JSON.parse(
          this.props.profileData.profile_details_with_values
        );
        // let rel = data23.find(el => el['Contact']);
        // let d = data23.filter(el => !el['Contact'] && el['Vendor']);

        // let vendor = data23.find(el => el['Vendor']);

        // d.unshift(rel);
        // console.log('reel', rel, data23, d);
        const nData = [];
        data23.map(el => {
          if (
            el['Vendor'] ||
            el['Contact'] ||
            el['Website'] ||
            el['Social media'] ||
            el['PR Fan groups']
          ) {
            nData.push(el);
          }
        });
        data23.map(el => {
          if (
            el['Vendor'] ||
            el['Contact'] ||
            el['Website'] ||
            el['Social media'] ||
            el['PR Fan groups']
          ) {
          } else {
            nData.push(el);
          }
        });
        console.log('thi one cond--- ');
        this.setState({
          profileKeyData: nData,
          profile: this.props.profileData.membership_id
        });
      } else {
        console.log('thi one cond--- 22');
        this.setState({
          profileKeyData: JSON.parse(
            this.props.profileData.profile_details_with_values
          ),
          profile: this.props.profileData.membership_id
        });
      }
    }
  }
}

const mapStateToProps = state => ({
  loading: state.eventLoading,
  eventsData: state.getEventsGroup,
  status: state.checkIsFollowed,
  betaStatus: state.isBetaReaderExist,
  myProfile: state.myProfileData,
  profileLoading: state.profileLoading
});

const mapDispatchToProps = dispatch => ({
  clearPreviewData: () => dispatch(previewProfile({})),
  getAllEvents: id =>
    dispatch(
      getEventGroups(`/api/v1/geteventsbygroup?id=${id}`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    ),
  getUserFollowingStatus: id =>
    dispatch(
      getFollowingStatus(`/api/v1/follow_userlist/${id}`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    ),
  follow: (data, route) =>
    dispatch(
      followUser(
        '/api/v1/follow',
        data,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        true,
        route
      )
    ),
  unFollow: (id, route) =>
    dispatch(
      unFollowUser(
        `/api/v1/follow/${id}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        true,
        route
      )
    ),
  addToBetaReaderList: id =>
    dispatch(
      addToBetaReaderList(`/api/v1/beta_readers/${id}`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    ),
  removefromBetaReader: id =>
    dispatch(
      removeFromBetaReader(`/api/v1/delete_beta_readers/${id}`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    ),
  getBetaStatus: id =>
    dispatch(
      getBetaStatus(`/api/v1/isBetaReader/${id}`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    ),
  getUserProfile: (url, header, edit) =>
    dispatch(getProfileDetails(url, header, edit))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileModal);
