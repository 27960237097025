import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { Editor } from '@tinymce/tinymce-react';
//import "react-big-calendar/lib/css/react-big-calendar.css";
// import { HashLoader } from 'react-spinners';
import { saveSendEmailDetail, editBlast } from '../../Actions/profile-setup';
import {
  getBookReleaseForBlast,
  getBookReleaseOfUser
} from '../../Actions/event-setup';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { notify } from 'react-notify-toast';
import moment from 'moment';
import createHistory from 'history/createHashHistory';
import Axios from 'axios';
import { BASEURL } from '../../constant';
// import ReactQuill, { Quill } from 'react-quill';
// import { useState, createRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
//import EditorToolbar, { modules, formats } from "./EditorToolbar";
import 'react-quill/dist/quill.snow.css';

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import ClassicEditor from '@novicov/ckeditor5-build-classic-full';
// import '@ckeditor/ckeditor5-build-classic/build/translations/de';
import ReactTooltip from 'react-tooltip';
import enGb from 'date-fns/locale/en-GB';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';

registerLocale('en-gb', enGb);
// const globalHistory = new createHistory();
let blastarr = [
  {
    key: '28:00',
    value: 'Select Time'
  },
  {
    key: '00:00',
    value: '12:00 AM'
  },
  {
    key: '01:00',
    value: '1:00 AM'
  },
  {
    key: '02:00',
    value: '2:00 AM'
  },
  {
    key: '03:00',
    value: '03:00 AM'
  },
  {
    key: '04:00',
    value: '04:00 AM'
  },
  {
    key: '05:00',
    value: '05:00 AM'
  },
  {
    key: '06:00',
    value: '06:00 AM'
  },
  {
    key: '07:00',
    value: '07:00 AM'
  },
  {
    key: '08:00',
    value: '08:00 AM'
  },
  {
    key: '09:00',
    value: '09:00 AM'
  },
  {
    key: '10:00',
    value: '10:00 AM'
  },
  {
    key: '11:00',
    value: '11:00 AM'
  },

  {
    key: '13:00',
    value: '1:00 PM'
  },
  {
    key: '14:00',
    value: '2:00 PM'
  },
  {
    key: '15:00',
    value: '3:00 PM'
  },
  {
    key: '16:00',
    value: '4:00 PM'
  },
  {
    key: '17:00',
    value: '5:00 PM'
  },
  {
    key: '18:00',
    value: '6:00 PM'
  },
  {
    key: '19:00',
    value: '7:00 PM'
  },
  {
    key: '20:00',
    value: '8:00 PM'
  },
  {
    key: '21:00',
    value: '9:00 PM'
  },
  {
    key: '22:00',
    value: '10:00 PM'
  },
  {
    key: '23:00',
    value: '11:00 PM'
  },
  {
    key: '12:00',
    value: '12:00 PM'
  }
];

let editorHtml = '';
let selectValueDate = '';
class BlastModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_subject: null,
      openSuggestion: false,
      email_body: null,
      timeList: [],
      time: 'Now',
      date: null,
      data: [],
      title: '',
      bookReleaseId: null,
      imageURL: '',
      replaceImage: '',
      blast_date: '',
      completed_date: '',
      // email_body: null,
      calender_date: '',
      blast_open: true,
      openCkEd: '',
      ckId: ''
    };
    //this.quill = React.createRef();
    // this.showTask = this.showTask.bind(this);
    // this.handleEditorChange = this.handleEditorChange.bind(this);
    //this.updateContentImage = this.updateContentImage.bind(this);
    // this.handleChanges = this.handleChanges.bind(this);
    // this.apiPostNewsImage = this.apiPostNewsImage.bind(this);
  }

  // handleChanges(html) {
  //   this.setState({ editorHtml: html });
  // }
  handleChanges = (event, editor) => {
    const data = editor && editor.getData();

    editorHtml = data;
    // this.setState({editorHtml:data})
  };

  handleCalendarDate(data) {
    // let my = moment(date).format('YYYY-MM-DD');
    selectValueDate = data;
    this.setState({
      date: data,
      time: ''
    });
  }

  uploadAdapter = loader => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(file => {
            body.append('file', file);

            Axios.post(`${BASEURL}/api/v1/users/uploadBlastImage`, body, {
              headers: {
                'x-authorization': localStorage.getItem('token')
              }
            })
              .then(res => res.data)
              .then(res => {
                resolve({
                  default: res
                });
              })
              .catch(err => {
                reject(err);
              });
          });
        });
      }
    };
  };

  uploadPlugin = editor => {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return this.uploadAdapter(loader);
    };
  };
  // updateContentImage = (imageURL, replaceImage) => {};

  hideViewModal = e => {
    this.setState({ viewModal: false });
  };

  getTitle = e => {
    this.setState({
      title: e.target.value,
      openSuggestion: true,
      bookReleaseId: e.target.value
    });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleChangeblast = () => {
    this.setState({ blast_open: false });
  };
  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }
  timeZoneAbbreviated(date) {
    const { 1: tz } = new Date(date).toString().match(/\((.+)\)/);

    if (tz.includes(' ')) {
      return tz
        .split(' ')
        .map(([first]) => first)
        .join('');
    } else {
      return tz;
    }
  }

  selectNow = () => {
    this.setState({ time: 'Now' });
  };

  handleSubmit = event => {
    event.preventDefault();
    let subject = this.state.email_subject;
    //  let body = this.state.email_body;
    // let body = this.state.email_body;

    let dateTime;
    let complete = new Date();
    let datenow;
    let latesttime = new Date().toLocaleString();
    // let timee = latesttime.split(',')[1].trim();
    let date = new Date();
    let storeFunValue = this.timeZoneAbbreviated(date);

    if (this.state.time !== 'Now') {
      datenow = moment(selectValueDate).format('YYYY-MM-DD');
      dateTime = this.state.time;
    } else {
      let complee = new Date();
      datenow = moment(complee).format('YYYY-MM-DD');

      dateTime = moment().format('HH:mm');
    }

    if (!this.state.bookReleaseId && this.props.blastMode === 'Release') {
      return notify.show('Please select OTR Book Release.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }

    if (!subject) {
      return notify.show('Please enter Blast subject.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }

    if (!editorHtml) {
      return notify.show('Please add Blast message.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }

    if (!selectValueDate && this.state.time !== 'Now') {
      return notify.show('Please Enter Date.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }

    if (!this.state.time && this.state.time !== 'Now') {
      return notify.show('Please Enter Time.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }

    const data = {
      email_subject: subject,
      email_body: editorHtml,
      blast_date:
        Object.keys(this.props.blastData).length > 0
          ? this.props.blastData.blast_date
          : moment(complete).format('YYYY-MM-DD'),
      blast_time: dateTime,
      blast_mode: this.props.blastMode,
      bookReleaseId: this.state.bookReleaseId ? this.state.bookReleaseId : null,
      completed_date: datenow,
      now: this.state.time === 'Now' ? true : false,
      latesttime: storeFunValue
    };

    if (Object.keys(this.props.blastData).length) {
      this.props.editBlast(
        `/api/v1/editBlast/${this.props.blastData.id}`,
        data,
        {
          headers: {
            'x-authorization': localStorage.getItem('token')
          }
        }
      );
      this.resetForm();
    } else {
      this.props.saveEmailDetails(`/api/v1/send_Email/Details`, data, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });
      this.resetForm();
    }
    this.resetForm();
    this.props.toggle();
  };

  setValue = selectedOption => {
    if (selectedOption) {
      this.setState({
        title: selectedOption.title,
        openSuggestion: false,
        bookReleaseId: selectedOption.id
      });
    }
  };

  selecthandle = event => {
    var selectDate = this.state.date;
    let fDate = moment(selectDate).format('YYYY-MM-DD');

    var date = new Date();
    let fDate1 = moment(date).format('YYYY-MM-DD');

    if (fDate === fDate1) {
      let dd = date.getHours() + 1;
      var minutes = '00';
      let time = dd + ':' + minutes;

      let dd1 = date.getHours();
      var minutes1 = '00';
      let time1 = dd1 + ':' + minutes1;

      if (event.target.value.toString().trim() === time.toString().trim()) {
        notify.show(
          'can be scheduled for same day starting 2 hrs. from current time',
          'custom',
          2000,
          {
            background: '#d4edda',
            text: '#155724',
            borderColor: '#c3e6cb'
          }
        );
      } else if (
        event.target.value.toString().trim() === time1.toString().trim()
      ) {
        notify.show('you choose wrong date', 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
      } else {
        this.setState({
          time: event.target.value
        });
      }
    } else {
      this.setState({
        time: event.target.value
      });
    }
  };

  resetForm() {
    editorHtml = '';
    this.setState({
      email_subject: null,
      email_body: null,
      time: '',
      date: null,
      editorHtml: ''
    });
  }

  setTimeInterval = () => {
    let timeList = [];
    let currentTime = '00:60',
      time;
    let hour = 0,
      min = 60;
    timeList.push('00:00');

    while (currentTime !== '00:00') {
      min = 60;
      if (min >= 60) {
        hour += 1;
        min = min - 60;
      }
      if (hour >= 24) {
        time = '23:59';
        currentTime = '00:00';
      } else {
        currentTime = hour + ':' + `${min}0`;
        time = currentTime;
      }
      timeList.push(moment(time, 'HH:mm').format('HH:mm'));
    }
    this.setState({ timeList: timeList });
  };

  componentDidMount() {
    this.props.getBookReleaseEventOfUser(
      `/api/v1/users/getUserBookReleaseEvent`,
      { headers: { 'x-authorization': localStorage.getItem('token') } }
    );
  }

  componentWillReceiveProps(newProps) {
    editorHtml = '';
    selectValueDate = '';
    if (Object.keys(newProps.blastData).length) {
      let blastData = JSON.parse(newProps.blastData.email_details);
      const dateTime = new Date(
        `${blastData[0].completed_date} ${blastData[0].blast_time} `
      );

      editorHtml = blastData[0].email_body;
      selectValueDate = blastData[0].completed_date;

      this.setState({
        bookReleaseId: newProps.blastData.book_release_id,
        email_subject: blastData[0].email_subject,
        date: dateTime,
        time: blastData[0].blast_time,
        title: newProps.blastData.title,
        editorHtml: blastData[0].email_body
      });
    } else {
      this.setState({
        bookReleaseId: null,
        title: '',
        email_subject: null,
        time: '',
        date: '',
        blast_open: true
      });
    }

    if (newProps.searchData && this.state.openSuggestion) {
      this.setState({ data: newProps.searchData, openSuggestion: true });
    }
  }

  render() {
    // console.log("hello---------------------- > ", this.props.blastData);
    // console.log("this.props.bookReleaseEventBlast === > ", this.props.bookReleaseEventBlast);
    // console.log("Object.keys(this.props.blastData).length:: ", this.props.blastData.blast_date);
    let ccc = this.props.blast.map(el => {
      return el.id;
    });

    let result;
    // let arr = [];
    if (Object.keys(this.props.blastData).length) {
      result = this.props.bookReleaseEventBlast.filter(
        o1 => !this.props.blast.some(o2 => o1.id === o2.book_release_id)
      );
      let fullData = this.props.bookReleaseEventBlast.find(
        el => el.id === this.props.blastData.book_release_id
      );
      if (fullData) result.push({ ...fullData });
    } else {
      result = this.props.bookReleaseEventBlast.filter(
        o1 => !this.props.blast.some(o2 => o1.id === o2.book_release_id)
      );
    }

    // const final = this.props.bookReleaseEventBlast.filter(e => {
    //   return e.id !== ccc;
    // });

    let today22 = new Date();
    let selectDate1 = this.state.date;
    let checkDate = moment(selectDate1).format('YYYY-MM-DD');
    let checkDateToday = moment(today22).format('YYYY-MM-DD');

    let filter1;
    let time22;
    time22 = today22.getHours();

    if (checkDate === checkDateToday) {
      // time22 = today22.getHours();
      filter1 = blastarr.filter(el => {
        let a = el.key.split(':')[0];
        if (a > time22) {
          return time22;
        }
      });
    } else {
      filter1 = blastarr.filter(el => (
        <option value={el.value}>{el.value}</option>
      ));
    }
    //  console.log("here is the selected blast -- > ", result[0]);

    var minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    let exampledate = moment()
      .add(18, 'M')
      .calendar();

    if (result.length > 0) {
      let selectedBlast = result.find(el => el.id == this.state.bookReleaseId);
      if (selectedBlast) {
        // console.log("it is the selectedBlast in blast modal-- -- -- -- -- -- -- > ", selectedBlast);
        let maxDatefRel = selectedBlast.start_date;
        console.log('selected date- ', selectedBlast.start_date);
        const date = moment(maxDatefRel).add(1, 'M');

        const newMaxDate = moment(date).subtract(1, 'day');
        console.log({ date, newMaxDate });

        console.log('new date- ', moment(newMaxDate).format('YYYY MM DD'));
        if (
          this.props.blastMode === 'Release' ||
          this.props.blastMode === 'release'
        ) {
          exampledate = newMaxDate;
        }

        // console.log("console the example date-- -- -- > ", exampledate);
        // console.log("now this is start_date::::--> ", selectedBlast.start_date);
        // console.log("now this is the::::--> ", exampledate.format("YYYY MM DD"));
      }
    }

    // console.log("this.props.blastMode- > ", this.props.blastMode, this.props.blastMode);
    // console.log("this is the result", result)
    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          size="lg"
          className="blast-popup"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            <h5 className="text-center p-0">Send a Blast</h5>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              <div class="row">
                {this.props.blastMode === 'Release' ||
                this.props.blastMode === 'release' ? (
                  <div class="form-group col-md-12 ">
                    <label for="exampleSelect">
                      Book Release Blast to link to*:
                    </label>
                    <select
                      class="form-control"
                      id="bookReleaseId"
                      name="bookReleaseId"
                      value={this.state.bookReleaseId}
                      onChange={this.getTitle}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>

                      {result.length > 0 &&
                        result.map(suggestion => (
                          <option value={suggestion.id}>
                            {suggestion.title}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : null}

                <div class="form-group col-md-12 ">
                  <label for="startDate">
                    Blast Subject: (Blast subject preceded by: OTR BLAST for
                    [username])*:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="email_subject"
                    name="email_subject"
                    maxLength="300"
                    onChange={this.handleChange}
                    value={this.state.email_subject}
                  />
                </div>

                <div class="form-group middle-text col-md-12 blast-msg-grp desktop-change">
                  <label for="title">
                    Blast message*:{' '}
                    <span style={{ display: 'inline-block' }}>
                      <i
                        className="fa fa-question-circle"
                        data-tip
                        data-for={'test'}
                        style={{ fontSize: '1.2em' }}
                      />
                      <ReactTooltip
                        className="toolTip"
                        id={'test'}
                        aria-haspopup="true"
                        effect="solid"
                        place="right"
                      >
                        <p style={{ margin: 0, padding: 0 }}>
                          {`You compose Blast message using Blast Formatter bar below.
Max image size is 3MB. Images can be resized by dragging & a caption added. Use 2 arrow symbols around image to add text fields above or below.
If you'd like to see view Blast before sending, schedule for a future date, then click view/edit. When done opt to "send now" or “schedule” for a later date.
You can view all Blast Sent on your Blasts page. To view Received Blasts, click on Notifications icon and select Blasts.`}
                          <br />

                          {`Default font: Raleway 12 pts.`}
                        </p>
                      </ReactTooltip>
                    </span>
                  </label>

                  {editorHtml ? (
                    <CKEditor
                      editor={FullEditor}
                      data={editorHtml ? editorHtml : ''}
                      onChange={this.handleChanges}
                      // onChange={(event, editor) => {
                      //   const data = editor && editor.getData();

                      //   this.setState({ editorHtml: data });
                      // }}
                      // onReady={event => {
                      //   console.log(
                      //     Array.from(event.ui.componentFactory.names())
                      //   );
                      // }}
                      config={{
                        extraPlugins: [this.uploadPlugin],
                        toolbar: {
                          items: [
                            'fontSize',
                            'fontFamily',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            'imageStyle:full',
                            'imageStyle:side',
                            'imageStyle:alignLeft',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            // 'insertTable',
                            'fontColor'
                          ],
                          image: {
                            // Configure the available styles.
                            styles: ['alignLeft', 'alignCenter', 'alignRight'],
                            sizes: ['50%', '75%', '100%'],
                            toolbar: [
                              'imageStyle:alignLeft',
                              'imageStyle:alignCenter',
                              'imageStyle:alignRight',
                              '|',
                              'imageResize',
                              '|',
                              'imageTextAlternative'
                            ]
                          }
                        },
                        fontFamily: {
                          options: [
                            'Arial, Helvetica, sans-serif',
                            'Calibri',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Raleway, Geneva, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif'
                          ],
                          supportAllValues: true
                        },
                        fontSize: {
                          options: [12, 14, 16, 18, 20, 22, 24]
                        }
                      }}
                    />
                  ) : (
                    <>
                      <CKEditor
                        editor={FullEditor}
                        onChange={this.handleChanges}
                        config={{
                          extraPlugins: [this.uploadPlugin],
                          toolbar: {
                            items: [
                              'fontSize',
                              'fontFamily',
                              '|',
                              'bold',
                              'italic',
                              '|',
                              'alignment',
                              '|',
                              'numberedList',
                              'bulletedList',
                              'imageStyle:full',
                              'imageStyle:side',
                              'imageStyle:alignLeft',
                              '|',
                              'indent',
                              'outdent',
                              '|',
                              'link',
                              'blockQuote',
                              'imageUpload',
                              // 'insertTable',
                              'fontColor'
                            ],
                            image: {
                              // Configure the available styles.
                              styles: [
                                'alignLeft',
                                'alignCenter',
                                'alignRight'
                              ],
                              sizes: ['50%', '75%', '100%'],
                              toolbar: [
                                'imageStyle:alignLeft',
                                'imageStyle:alignCenter',
                                'imageStyle:alignRight',
                                '|',
                                'imageResize',
                                '|',
                                'imageTextAlternative'
                              ]
                            }
                          },
                          fontFamily: {
                            options: [
                              'Arial, Helvetica, sans-serif',
                              'Calibri',
                              'Courier New, Courier, monospace',
                              'Georgia, serif',
                              'Lucida Sans Unicode, Lucida Grande, sans-serif',
                              'Raleway, Geneva, sans-serif',
                              'Tahoma, Geneva, sans-serif',
                              'Times New Roman, Times, serif',
                              'Trebuchet MS, Helvetica, sans-serif',
                              'Verdana, Geneva, sans-serif'
                            ],
                            supportAllValues: true
                          },
                          fontSize: {
                            options: [12, 14, 16, 18, 20, 22, 24]
                          }
                        }}
                      />
                    </>
                  )}
                </div>

                {this.state.date && this.state.time !== '28:00' ? (
                  ''
                ) : (
                  <>
                    {' '}
                    <div class="form-group col-md-12">
                      <button
                        type="submit"
                        className={
                          'btn btn_save_bt send__btn' ||
                          'btn btn_save_bt dark-button'
                        }
                        onClick={this.selectNow}
                      >
                        Send Now
                      </button>
                    </div>
                  </>
                )}

                <div className="in_form fieldset_border">
                  <span>SCHEDULE BLAST</span>
                  <div className="schedule_form">
                    <div className="form-group col-md-6">
                      <label for="title">Send date:</label>

                      {/* <DatePicker
                        className="form-control"
                        // className="calendar-style"
                        format="MM-DD-YYYY"
                        placeholderText="mm-dd-yyyy"
                        highlightDates={new Date()}
                        //  locale="en-US"
                        // minDate={this.state.startDate}
                        // value={moment(this.state.date).format('MM-DD-YYYY')}
                        // minDate={moment().toDate()}
                        minDate={minDate}
                        //selected={this.state.date}
                        id="blastData"
                        name="blastdata"
                        maxDate={new Date(exampledate)}
                        onChange={date => this.handleCalendarDate(date)}
                        locale="en-gb"
                        calendarStartDay={1}
                      /> */}
                      <DatePicker
                        className="form-control"
                        dateFormat="MM-dd-yyyy"
                        placeholderText="mm-dd-yyyy"
                        // format='MM/DD/YYYY'
                        // placeholderText="mm/dd/yyyy"
                        highlightDates={new Date()}
                        minDate={moment()
                          .clone()
                          .add(1, 'days')
                          .toDate()}
                        // value={moment(selectValueDate).format(
                        //   'MM-DD-YYYY'
                        // )}
                        selected={
                          selectValueDate
                            ? moment(selectValueDate).toDate()
                            : ''
                        }
                        maxDate={new Date(exampledate)}
                        id="blastData"
                        name="blastdata"
                        onChange={dt => this.handleCalendarDate(dt)}
                        locale="en"
                        calendarStartDay={1}
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="sel1">Send time:</label>
                      <select
                        class="form-control"
                        value={this.state.time}
                        style={{ height: '39px' }}
                        onChange={event => this.selecthandle(event)}
                        // required
                      >
                        {/* <option value="" selected disabled>
                          Select Time
                        </option> */}
                        {/* {this.state.timeList.map((timeList) => (
                      <option value={timeList}>{timeList}</option>
                    ))} */}
                        {/* <option>Now</option> */}

                        {filter1 &&
                          filter1.map((el, i) => (
                            <option key={el.key} value={el.key}>
                              {el.value}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    {/* {this.state.blast_open === false ? (
                      <>
                        {' '}
                    
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className={
                            'btn btn_save_bt' || 'btn btn_save_bt dark-button'
                          }
                          onClick={this.handleChangeblast}
                        >
                          Schedule
                        </button>
                      </>
                    )} */}

                    <button
                      type="submit"
                      className={
                        'btn btn_save_bt' || 'btn btn_save_bt dark-button'
                      }
                    >
                      {Object.keys(this.props.blastData).length
                        ? 'Update'
                        : 'Schedule'}
                    </button>
                  </div>
                </div>

                <div className="mt-5" style={{ fontSize: '0.7rem' }}>
                  <span>
                    <b>
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{' '}
                      Disclaimer:{' '}
                    </b>
                    Please be aware that Blast content must comply with OTR
                    <Link to="/dashboard/condition" target="_blank">
                      {' '}
                      Terms & Conditions{' '}
                    </Link>
                    and OTR
                    <Link to="/dashboard/privacy" target="_blank">
                      {' '}
                      Privacy Policy{' '}
                    </Link>
                    . OTR is not responsible for any Blast content.
                  </span>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchData: state.getBookReleaseForUsers,
    bookReleaseEventBlast: state.getBookReleaseEventBlast
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveEmailDetails: (url, data, headers) =>
      dispatch(saveSendEmailDetail(url, data, headers)),
    editBlast: (url, data, headers) => dispatch(editBlast(url, data, headers)),
    getUserBookReleaseEvent: (url, headers) =>
      dispatch(getBookReleaseForBlast(url, headers)),
    getBookReleaseEventOfUser: (url, headers) =>
      dispatch(getBookReleaseOfUser(url, headers))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlastModal);
