import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { notify } from 'react-notify-toast';
import Logo from '../../Components/left-logo';
import HTMLParser from '../../Components/HTMLparser/html-parser';
import {
  saveEvent,
  getEvent,
  deleteEvent,
  getEventDetails,
  setToDefault,
  getEventPreview,
  getAllEvents,
  getSingelEventDetail,
  duplicateEvent,
  isSyncChecked,
  eventData,
  getSingleEventDetails,
  searchUser
} from '../../Actions/event-setup';
import AgreeModel from '../Static/agreeModel';

import './event.css';
import eventButtons from '../../Utils/data/event-type-buttons.json';
import EventModal from '../dashboard/eventModal';
import SendEmail from '../dashboard/sendEmailModel';
import Layout from '../layout/layout';
import moment from 'moment';
import axios from 'axios';
import { BASEURL } from '../../constant';
import { ConsoleView } from 'react-device-detect';
import { InputGroup } from 'reactstrap';
import ResetCoversPopup from './resetCoversPopup';

const override = css`
  position: fixed;
  margin-top: 15%;
  left: 45%;
`;

const event_list = {
  event_type_1: 'Book Release',
  event_type_2: 'Giveaway',
  event_type_3: 'Book Promo',
  event_type_4: 'Release Party',
  event_type_5: 'Cover Reveal',
  event_type_6: 'Book Signing',
  event_type_7: 'ARC Sign-up',
  'ticket sales': 'Ticket Sales'
};

class Event extends Component {
  constructor(props) {
    super(props);
    this.checkRef = React.createRef();
    this.state = {
      formJSON: [],
      d: [],
      isSubmitted: false,
      hideGiveaway: false,
      modal: false,
      imgSrc: [],
      title: {},
      coauthor: {},
      hide: 'US Eastern time zone',
      eventImageError: {},
      radiotext: '',
      radioValue: 'Stand-alone',
      typeo: '',
      datevalue: '',
      timevalue: '',
      utcvalue: '',
      fulltime: '',
      istTime: '',
      disableProperty: '',
      hostby: '',
      indexRemove: '',
      standaloneValue: 'Stand-alone',
      imageWork: '',
      eventIdStoreSavedPopup: '',
      startDate: new Date().toISOString().split('T')[0],
      selectTime: '',
      changeValueSearch: '',
      initialFormJson: [],
      content:
        'Please be advised that for any uploaded content, you must either be the owner of such content or adhere to United States Fair Use doctrine. You agree that any upload adheres to OTR',
      disapear: false,
      reset: false,
      resetPopup: false,
      readOnly: false,
      giveawayTz: null,
      isRest: false,
      userSuggestion: [],
      openUserSuggestion: false,
      currentSelectedUser: null,
      linkedAuthorId: null,
      resetClick: false,
      coauthorId: '',
      authorId: ''
    };
    this.textBoxRef = React.createRef();

    this.htmlParser = new HTMLParser();
    this.arrprefillComponents = [
      { parenttag: 'date', tag: '', value: new Date().toDateString() }
    ];
    this.userClickHandler = this.userClickHandler.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.openResetPopup = this.openResetPopup.bind(this);
    this.closeResetPopup = this.closeResetPopup.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onTimeHandler = this.onTimeHandler.bind(this);
    this.onSearchHandler = this.onSearchHandler.bind(this);
    this.onCheckboxChangeHandler = this.onCheckboxChangeHandler.bind(this);
    this.onCustomChangeHandler = this.onCustomChangeHandler.bind(this);
    this.onChangeFileHandler = this.onChangeFileHandler.bind(this);
    this.onChangeFileHandler = this.onChangeFileHandler;
    this.onSubmitFormHandler = this.onSubmitFormHandler.bind(this);
    this.addMoreLinkHandler = this.addMoreLinkHandler.bind(this);
    this.addMoreServiceHandler = this.addMoreServiceHandler.bind(this);
    this.removeServiceHandler = this.removeServiceHandler.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.prefillComponentValueSubmitHandler = this.prefillComponentValueSubmitHandler.bind(
      this
    );
    this.onButtonClickHandler = this.onButtonClickHandler.bind(this);
    this.changeLinkedAuthorId = this.changeLinkedAuthorId.bind(this);
    this.eventDetail = { name: '', event_type: '' };
    this.avtar = null;
  }

  //= ====================== On Change Handlers ================
  onButtonClickHandler(el) {
    if (el.eventTypeId != undefined) {
      this.eventDetail.name = el.value;
      this.eventDetail.event_type = el.eventTypeId;

      // this.props.getSchema("event_type_"+ el.eventTypeId);
      this.props.getSchema(
        '/api/v1/events',
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        `event_type_${el.eventTypeId}`
      );
    }
  }

  changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone
        })
      );
    }

    return new Date(
      date.toLocaleString('en-US', {
        timeZone
      })
    );
  }

  timeZoneAbbreviated(date) {
    const { 1: tz } = new Date(date).toString().match(/\((.+)\)/);

    if (tz.includes(' ')) {
      return tz
        .split(' ')
        .map(([first]) => first)
        .join('');
    } else {
      return tz;
    }
  }

  changeLinkedAuthorId(id) {
    // console.log("hii this is id----->> ", id)
    this.setState({
      linkedAuthorId: id
    });
  }

  onChangeHandler(value, parentIndex, index, type) {
    // console.log("value will be change--->> ", value)
    this.setState({ changeValueSearch: value && value.type });
    if (index === 'author-first-name' && value.length >= 2) {
      this.setState({ openUserSuggestion: true });
      this.props.searchUser(`/api/v1/user_authorsearch?searchString=${value}`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });
    } else if (index === 'author-first-name' && value.length < 2) {
      this.setState({ openUserSuggestion: false });
    }
    // let checkTime = value.getTimezoneOffset();
    // console.log("checkTime",checkTime,value)

    if (type === 'date') {
      this.setState({ datevalue: value });
      let storeFunValue = this.timeZoneAbbreviated(value);
      this.setState({ istTime: storeFunValue });
    } else if (parentIndex === 19) {
      this.setState({ timevalue: value });
    } else {
      console.log('error');
    }
    // let dd = date.toUTCString(value);

    let start = moment(this.state.datevalue).toDate();

    const startAppDate = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');

    let f = this.changeTimeZone(new Date(startAppDate), 'America/New_York');

    let fullt = moment(f).format('hh:mm A');
    if (localStorage.getItem('event_type') === '4') {
      this.setState({ fulltime: fullt });
    }

    if (
      value === 'Stand-alone' ||
      value === 'Stand-alone in a series' ||
      value === 'Book in a series'
    ) {
      this.setState({ standaloneValue: value });
    }

    let utcDate;
    let value1 = moment(new Date(value)).format('YYYY-MM-DD');

    if (type === 'date') {
      const birthday = new Date(value);
      let isoDate = birthday.toISOString();
      let timezoneOffset = birthday.getTimezoneOffset();
      if (timezoneOffset < 0) {
        utcDate = new Date(isoDate);
        utcDate.setMinutes(utcDate.getMinutes() + Math.abs(timezoneOffset));
      } else {
        utcDate = new Date(isoDate);
        utcDate.setMinutes(utcDate.getMinutes() - Math.abs(timezoneOffset));
      }
    }

    if (value && value.type == 'click') {
      return this.getEventDetails();
    }
    let data = this.props.formJSON;

    if (
      index != undefined &&
      data[parentIndex].children != undefined &&
      type === 'date'
    ) {
      data[parentIndex].children[index].value = utcDate.toISOString();
    } else if (index != undefined && data[parentIndex].children != undefined) {
      data[parentIndex].children[index].value = value;
    }
    if (
      data[parentIndex].dateValidationToIndex != undefined &&
      type === 'date'
    ) {
      let i = parentIndex + parseInt(data[parentIndex].dateValidationToIndex);
      var itemDate =
        data[i].value === '' ? new Date() : new Date(data[i].value);
      if (itemDate.getTime() >= new Date(value).getTime()) {
        notify.show(
          'End date must be after or same as Start date.',
          'custom',
          5000,
          { background: '#f8d7da', text: '#721c24' }
        );
        return;
      }
      data[parentIndex].value = utcDate.toISOString();
    } else if (data[parentIndex].dateValidationToIndex != undefined) {
      let i = parentIndex + parseInt(data[parentIndex].dateValidationToIndex);
      var itemDate =
        data[i].value === '' ? new Date() : new Date(data[i].value);
      if (itemDate.getTime() >= new Date(value).getTime()) {
        notify.show(
          'End date must be after or same as Start date.',
          'custom',
          5000,
          { background: '#f8d7da', text: '#721c24' }
        );
        return;
      }
      data[parentIndex].value = utcDate.toISOString();
    }
    if (
      data[parentIndex].dateValidationFromIndex != undefined &&
      type === 'date'
    ) {
      let i = parentIndex + parseInt(data[parentIndex].dateValidationFromIndex);
      var itemDate = new Date(data[i].value);
      if (
        data[i].value === '' &&
        new Date(value).getTime() <= new Date().getTime()
      ) {
        notify.show(
          'Start Date must be ahead of Current Date.',
          'custom',
          5000,
          { background: '#f8d7da', text: '#721c24' }
        );
        return;
      }

      if (new Date(value).getTime() >= itemDate.getTime()) {
        notify.show("Start Date Can't be ahead of End Date.", 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
        return;
      }
      data[parentIndex].value = utcDate.toISOString();
    } else if (data[parentIndex].dateValidationFromIndex != undefined) {
      let i = parentIndex + parseInt(data[parentIndex].dateValidationFromIndex);
      var itemDate = new Date(data[i].value);
      if (
        data[i].value === '' &&
        new Date(value).getTime() <= new Date().getTime()
      ) {
        notify.show(
          'Start Date must be ahead of Current Date.',
          'custom',
          5000,
          { background: '#f8d7da', text: '#721c24' }
        );
        return;
      }

      if (new Date(value).getTime() >= itemDate.getTime()) {
        notify.show("Start Date Can't be ahead of End Date.", 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
        return;
      }
      data[parentIndex].value = utcDate.toISOString();
    }

    if (value === 'Stand-alone') {
      data[16].value = '';
      data[15].value = '';
      data[14].value = '';
    }

    if (data[parentIndex].tag == 'switch') {
      if (data[parentIndex].value == data[parentIndex].valueOn) {
        data[parentIndex].value = data[parentIndex].valueOff;
      } else {
        data[parentIndex].value = data[parentIndex].valueOn;
      }
    } else if (type === 'date') {
      data[parentIndex].value = value1;
    } else {
      data[parentIndex].value = value;
    }
    if (parentIndex === 19) {
      data[parentIndex].value = moment(value).format('hh:mm A');
    }

    if (type === 'date' && !value) {
      data.forEach(el => {
        if (el.tag === 'date') {
          el.value = '';
        }
      });
    }
    this.setState({ formJSON: data });
  }
  onTimeHandler(value, parentIndex, index, type) {
    let value1 = value && value.format('HH:mm a');
    let data = this.props.formJSON;

    this.setState({ selectTime: value1 });
    if (index === 19) {
      data[index].value = value1;
      // console.log('testing', data[parentIndex].value);
    }
    // console.log('testing123', data);
    this.setState({ formJSON: data });
  }

  handleReset(e) {
    e.preventDefault();
    if (localStorage.getItem('mode') === 'edit') {
      let chnangedFormJson = this.props.formJSON.map((el, i) => {
        if (
          el.name === 'title' ||
          el.name === 'author-first-name' ||
          el.name === 'author-last-name' ||
          el.name === 'coauthor'
        ) {
          const updatedObj = { ...el };
          updatedObj.value = '';
          return updatedObj;
        }
        return el;
      });

      if (this.state.eventIdStoreSavedPopup && chnangedFormJson.length) {
        chnangedFormJson = chnangedFormJson.map(el => {
          if (
            el.tag === 'file' &&
            (el.name === 'temporary-book-cover' ||
              el.name === 'book-cover' ||
              el.name === 'alternative-book-cover') &&
            el.index !== 1
          ) {
            const upObj = { ...el };
            upObj.value = '';
            console.log(" upObj.value  ''", upObj);
            return upObj;
          }

          return el;
        });
      }

      console.log('this is changedForm---->>1111111 ', chnangedFormJson);
      this.props.restEvent(chnangedFormJson);
      this.setState({
        title: {},
        readOnly: false,
        imgSrc: [],
        eventImageError: null,
        imageWork: false,
        isRest: true
      });

      return;
    }
    //  console.log("this.props.formJSON--> " , this.props.formJSON)
    //  console.log("this.state.initialoformJSON--> " , this.state.initialFormJson)
    let chnangedFormJson = this.props.formJSON.map((el, i) => {
      if (
        el.name === 'title' ||
        el.name === 'author-first-name' ||
        el.name === 'author-last-name' ||
        el.name === 'coauthor'
      ) {
        const updatedObj = { ...this.state.initialFormJson[i] };
        return updatedObj;
      }
      return el;
    });

    if (this.state.eventIdStoreSavedPopup && chnangedFormJson.length) {
      chnangedFormJson = chnangedFormJson.map(el => {
        if (
          el.tag === 'file' &&
          (el.name === 'temporary-book-cover' ||
            el.name === 'book-cover' ||
            el.name === 'book-cover' ||
            el.name === 'alternative-book-cover') &&
          el.index !== 1
        ) {
          const upObj = { ...el };
          upObj.value = '';
          console.log(" upObj.value  ''", upObj);
          return upObj;
        }

        return el;
      });
    }
    if (localStorage.getItem('event_type') === '1') {
      this.handleForm();
    }
    console.log(
      '=======================================================>>chnangedFormJson ',
      chnangedFormJson
    );
    const changedEventDetails = JSON.parse(
      JSON.stringify(this.props.eventDetails)
    );
    changedEventDetails.event_details = [...chnangedFormJson];
    changedEventDetails.author_first_name = '';
    changedEventDetails.author_last_name = '';
    console.log(
      '=======================================================>>eventDetails ',
      changedEventDetails
    );
    this.props.restEvent(chnangedFormJson);
    this.props.setEventdeatils(changedEventDetails);
    this.setState({
      title: {},
      readOnly: false,
      resetClick: true
    });
    //  this.setState({
    //   title: {},
    //   resetField: true,
    //   readOnly: false
    //  })
  }

  getEventDetails = async check => {
    console.log(
      'here is checkkkkkkkkkk================================================================---> ',
      check
    );
    const apiUrl = 'https://api.ontargetromance.com';
    let filteredArray = this.props.formJSON.filter(
      el =>
        el.name === 'author-first-name' ||
        el.name === 'author-last-name' ||
        el.placeholder === 'Book Title'
    );

    //custom validation for title and author name
    const cond =
      localStorage.getItem('event_type') === '1'
        ? !filteredArray[0].value
        : !(
            filteredArray[0].value &&
            filteredArray[1].value &&
            filteredArray[2].value
          );
    if (this.state.title && !this.state.title.value && cond) {
      const message =
        localStorage.getItem('event_type') !== '1'
          ? 'Please provide book title & author name before sync.'
          : 'Please provide book title before sync.';
      return notify.show(message, 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }
    //closed validation

    // const apiUrl = 'http://localhost:3001';
    if (
      localStorage.getItem('event_type') == '1' &&
      Object.keys(this.state.title).length > 0
    ) {
      if (!check) {
        this.setState({ readOnly: true });
      }
    } else if (
      localStorage.getItem('event_type') !== '1' &&
      filteredArray[0].value &&
      filteredArray[1].value &&
      filteredArray[2].value
    ) {
      if (!check) {
        this.setState({ readOnly: true });
      }
    }

    this.props.checkSync(true);
    console.log('this.state.title552', this.state.title);
    if (Object.keys(this.state.title).length !== 0 && this.state.title.value) {
      if (localStorage.getItem('event_type') == '1') {
        this.setState({ readOnly: false });
      } else {
        if (!check) {
          this.setState({ readOnly: true });
        }
      }
      let data = this.props.getEventDetail(
        `/api/v1/event_detail/${this.state.title.value}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        localStorage.event_type,
        check
      );

      const response = await axios.get(
        BASEURL +
          `/api/v1/event_detail/${this.state.title.value}?event_type=${localStorage.event_type}`,
        {
          headers: {
            'x-authorization': localStorage.getItem('token')
          }
        }
      );

      this.setState({ eventIdStoreSavedPopup: response.data.result.event.id });
      this.setState({ coauthorId: response.data.result.event.co_author_id });
      this.setState({ authorId: response.data.result.event.user_id });

      console.log('response580', response);
      console.log('coauthorIdcoauthorIdcoauthorId', this.state.coauthorId);
      const n = this.props.formJSON.findIndex(el => el.type === type);
      let dan = this.props.formJSON.filter(el => el.tag === 'file');

      let alternate = this.props.formJSON.find(
        el => el.name === 'alternative-book-cover'
      );

      let type = dan.name;
      let hhhh = dan.value;
      let high;
      let type1;
      if (alternate) {
        type1 = alternate.name;
        high = alternate.value;
      }
      let images = [...this.state.imgSrc];

      dan.forEach(el => {
        if (el.name !== 'temporary-book-cover' && el.result) {
          images.push({ type: el.name, result: `${apiUrl}${el.value}` });
        }
      });
      this.setState({
        imgSrc: images,
        eventImageError: null,
        imageWork: false
      });

      // it will be uncomented when she told about image not populatedd
      //  if(check === 'auto')
      //  {
      //  let chnangedFormJson = this.props.formJSON.map((el)=>
      //   {
      //     if(el.tag === 'file' && (el.name === 'temporary-book-cover' || el.name === 'book-cover' || el.name === 'alternative-book-cover'))
      //     {
      //          const upObj = {...el};
      //          upObj.value = '';
      //          return upObj;
      //     }

      //     return el;
      //   });
      //   this.props.restEvent(chnangedFormJson);

      //  }
      //close here

      if (!check) {
        notify.show('Events synced.', 'custom', 5000, {
          background: '#d4edda',
          text: '#155724'
        });
      }

      // console.log();
    } else {
      this.props.duplicate(false);

      // notify.show('Events synced.', 'custom', 5000, {
      //   background: '#f8d7da',
      //   text: '#721c24'
      // });
      notify.show('Events synced.', 'custom', 5000, {
        background: '#d4edda',
        text: '#155724'
      });
    }
  };

  onCheckboxChangeHandler(e, value, parentIndex, buttonIndex) {
    if (value === 'Other time zone' && e) {
      this.setState({ giveawayTz: 'Other time zone' });
      this.setState({ radiotext: 'Other time zone' });
    } else {
      if (value === 'US Eastern time zone' && e) {
        this.setState({ giveawayTz: 'US Eastern time zone' });
      }
      this.setState({ radiotext: '' });
    }
    let data = this.props.formJSON;

    if (data[parentIndex].value == undefined) {
      data[parentIndex].value = '';
    }
    if (data[parentIndex].value.includes(value)) {
      data[parentIndex].value = data[parentIndex].value.replace(
        `${value},`,
        ''
      );

      data[parentIndex].buttons[buttonIndex].value = false;
    } else {
      if (
        data[parentIndex].validation == 'ONLY 3' &&
        data[parentIndex].value.replace(/(^,)|(,$)/g, '').split(',').length >= 3
      ) {
        notify.show('Add up to 3 options.', 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });

        return;
      }

      if (value === 'Other time zone') {
        data[parentIndex].value = value;
        data[parentIndex].required = false;
        data[parentIndex].buttons[buttonIndex].value = true;
      } else if (value === 'US Eastern time zone') {
        data[parentIndex].value = value;
        data[parentIndex].required = false;
      } else {
        data[parentIndex].value = data[parentIndex].value.concat(`${value},`);
        data[parentIndex].buttons[buttonIndex].value = true;
      }
    }

    this.setState({ formJSON: data });
  }

  onCustomChangeHandler = async (value, index, parentIndex, type) => {
    //  console.log("here is  the type of each input---> > > > >:: ", type);

    let utcDate;
    if (type === 'date') {
      const birthday = new Date(value);
      let isoDate = birthday.toISOString();
      let timezoneOffset = birthday.getTimezoneOffset();

      utcDate = new Date(isoDate);
      utcDate.setMinutes(utcDate.getMinutes() + Math.abs(timezoneOffset));
    }
    let data = this.props.formJSON;
    if (
      index != undefined &&
      data[parentIndex].children != undefined &&
      type === 'date'
    ) {
      if (data[parentIndex].dateValidationToIndex != undefined) {
        let i = parentIndex + parseInt(data[parentIndex].dateValidationToIndex);
        var itemDate =
          data[i].value === '' ? new Date() : new Date(data[i].value);
        if (itemDate.getTime() > new Date(data[parentIndex].value).getTime()) {
          notify.show(
            "End Date Can't be before of Start Date.",
            'custom',
            5000,
            { background: '#f8d7da', text: '#721c24' }
          );
          return;
        }
        data[parentIndex].value = utcDate.toISOString();
      }
      if (data[parentIndex].dateValidationFromIndex != undefined) {
        let i =
          parentIndex + parseInt(data[parentIndex].dateValidationFromIndex);
        var itemDate =
          data[i].value === '' ? new Date() : new Date(data[i].value);
        if (itemDate.getTime() < new Date(data[parentIndex].value).getTime()) {
          notify.show(
            "Start Date Can't be ahead of End Date.",
            'custom',
            5000,
            { background: '#f8d7da', text: '#721c24' }
          );
          return;
        }
        data[parentIndex].value = utcDate.toISOString();
      }
    } else if (type === 'date') {
      data[parentIndex].value = utcDate.toISOString();
    } else if (index != undefined && data[parentIndex].children != undefined) {
      if (data[parentIndex].dateValidationToIndex != undefined) {
        let i = parentIndex + parseInt(data[parentIndex].dateValidationToIndex);
        var itemDate =
          data[i].value === '' ? new Date() : new Date(data[i].value);
        if (itemDate.getTime() > new Date(data[parentIndex].value).getTime()) {
          notify.show(
            "End Date Can't be before of Start Date.",
            'custom',
            5000,
            { background: '#f8d7da', text: '#721c24' }
          );
          return;
        }
        data[parentIndex].value = utcDate.toISOString();
      }
      if (data[parentIndex].dateValidationFromIndex != undefined) {
        let i =
          parentIndex + parseInt(data[parentIndex].dateValidationFromIndex);
        var itemDate =
          data[i].value === '' ? new Date() : new Date(data[i].value);
        if (itemDate.getTime() < new Date(data[parentIndex].value).getTime()) {
          notify.show(
            "Start Date Can't be ahead of End Date.",
            'custom',
            5000,
            { background: '#f8d7da', text: '#721c24' }
          );
          return;
        }
        data[parentIndex].value = utcDate.toISOString();
      }
      if (data[parentIndex].children[index].tag == 'switch') {
        if (
          data[parentIndex].children[index].value ==
          data[parentIndex].children[index].valueOn
        ) {
          data[parentIndex].children[index].value =
            data[parentIndex].children[index].valueOff;
        } else
          data[parentIndex].children[index].value =
            data[parentIndex].children[index].valueOn;
      } else {
        data[parentIndex].children[index].value = value;
      }
    } else {
      data[parentIndex].value = value;
    }

    this.setState({ formJSON: data });
  };

  onSearchHandler(value, keyName) {
    this.setState({ [keyName]: value });
  }

  onChangeFileHandler(file, acceptedSize, type, index) {
    console.log('))))))))))))))))))))))))))))>>>>>> ', {
      file,
      acceptedSize,
      type,
      index
    });
    this.setState({ typeo: type });
    if (file && file.size) {
      if (parseInt(acceptedSize) * 1000 <= parseInt(file.size)) {
        notify.show('Image File Too Large.', 'custom', 50000, {
          background: '#f8d7da',
          text: '#721c24'
        });
        return;
      }
      this.avtar = file;

      const img = new Image();
      const rootElement = new FormData();

      const reader = new FileReader();

      const url = reader.readAsDataURL(file);
      img.src = window.URL.createObjectURL(file);

      img
        .decode()
        .then(() => {
          let h = img.height;
          let w = img.width;
          let height = h / w;

          let hii = parseFloat(height.toFixed(1));

          let eventType = localStorage.getItem('event_type');
          if (eventType === '6') {
            let images = [...this.state.imgSrc];
            images.push({ type, result: reader.result });
            let copy = [...this.state.d];
            copy.push(file);

            this.setState({
              d: copy,
              imgSrc: images,
              eventImageError: null
            });
          }

          if (
            (eventType !== '6' && height < 1.4) ||
            (eventType !== '6' && height > 1.7)
          ) {
            const copy = { ...this.state.eventImageError };
            copy[type] =
              'Book cover needs to be in standard dimension of approx. 2,560x1,600 pixels (1.6:1)';
            this.setState({ eventImageError: copy });
          } else {
            let images = [...this.state.imgSrc];
            const n = images.findIndex(el => el.type === type);

            if (n >= 0) {
              images[n] = { type, result: reader.result };
            } else {
              images.push({ type, result: reader.result });
            }

            let copy = [...this.state.d];

            copy.push(file);

            const copyImage = { ...this.state.eventImageError };
            copyImage[type] = '';

            // console.log("images in onChangeFileHandler function -  ", images);
            // console.log("hii typpe-->> >> >> >> >> ", type);
            if (
              index !== 2 &&
              (type === 'book-cover' || type === 'alternative-book-cover')
            ) {
              document.getElementsByClassName('fileInput1212')[0].value = '';
              this.setState({ disapear: true });
            }

            this.setState({
              d: copy,
              imgSrc: images,
              eventImageError: copyImage,
              imageWork: true
            });
          }
        })
        .catch(() => {
          throw new Error('Could not load/decode big image.');
        });
    }
  }

  userClickHandler(user) {
    this.setState({
      openUserSuggestion: false,
      currentSelectedUser: user
    });

    const resetFormJson = this.props.formJSON.map(el => {
      if (el.name === 'author-first-name' || el.name === 'author-last-name') {
        el.value = el.name === 'author-first-name' ? user.fname : user.lname;
        return el;
      } else {
        return el;
      }
    });

    this.props.restEvent(resetFormJson);
  }

  handleForm() {
    let fileInput = document.getElementsByClassName('fileInput1212');
    const resetFormJson = this.props.formJSON.map(el => {
      if (
        el.tag === 'file' &&
        (el.name === 'temporary-book-cover' ||
          el.name === 'book-cover' ||
          el.name === 'alternative-book-cover')
      ) {
        let newObj = { ...el };
        newObj.value = '';
        return newObj;
      }
      let sObj = { ...el };
      if (
        (this.state.isRest && el.name === 'author-first-name') ||
        el.name === 'author-last-name'
      ) {
        sObj.value = '';
      }
      return sObj;
    });
    this.props.checkSync(true);
    this.setState({
      d: [],
      imgSrc: [],
      eventImageError: {},
      imageWork: ''
    });
    this.setState({ typeo: '' });
    this.setState({ reset: true });
    // this.setState({disapear: false});
    this.props.restEvent(resetFormJson);
    for (let i = 0; i < fileInput.length; i++) {
      fileInput[i].value = '';
    }
    // this.props.emptyGetEventdeatils()
  }

  openResetPopup() {
    this.setState({ resetPopup: true });
  }
  closeResetPopup() {
    this.setState({ resetPopup: false });
  }

  onSubmitFormHandler = async (e, history) => {
    e.preventDefault();

    this.setState({ isSubmitted: true });
    let data;
    if (
      localStorage.getItem('event_type') === '3' ||
      localStorage.getItem('event_type') === '5' ||
      localStorage.getItem('event_type') === '7'
    ) {
      if (this.props.formJSON.length == 0) {
        history.push('/dashboard');
        return;
      }

      // this.prefillComponentValueSubmitHandler();
      let formData = new FormData();

      formData.append('event_details', JSON.stringify(this.props.formJSON));

      if (localStorage.getItem('event_type') === '6') {
        formData.append('book-cover', this.avtar);
      } else {
        this.state.imgSrc &&
          this.state.imgSrc.length >= this.state.d.length &&
          this.state.d.forEach((image, i) => {
            this.state.imgSrc[i].type &&
              image &&
              formData.append(
                this.state.imgSrc && this.state.imgSrc[i].type,
                image
              );
          });
      }

      // if( localStorage.getItem('event_type') !== '6')
      // {
      //   this.state.d.forEach((image, i) => {

      //     formData.append(this.state.imgSrc[i].type, image);
      //   });

      // }

      formData.append(
        'event_type',
        `event_type_${localStorage.getItem('event_type')}`
      );

      if (
        localStorage.getItem('event_type') !== '1' &&
        !(
          localStorage.getItem('event_type') === '6' ||
          localStorage.getItem('event_type') === 'ticket sales'
        )
      ) {
        if (Object.keys(this.state.title).length === 0) {
          notify.show('Please Select Title.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
          return;
        }
        formData.append('parent_id', this.state.title.value);
      }

      if (
        Object.keys(this.state.coauthor).length > 0 &&
        localStorage.getItem('event_type') == '1'
      ) {
        formData.append('co_author_id', this.state.coauthor.value);
      }
      const headers = {
        'content-type': 'multipart/form-data',
        'x-authorization': localStorage.getItem('token')
      };

      //   if(localStorage.getItem('event_type') != 6 && localStorage.getItem('event_type') != 1){const firstName = this.props.formJSON.find((el)=> el.name === 'author-first-name')
      //   const lastName = this.props.formJSON.find((el)=> el.name === 'author-last-name')
      //   console.log("firstName: ", firstName)
      //   console.log("lastName: ", lastName)
      //   formData.append('created_date', moment().format('YYYY/MM/DD HH:mm:ss'));
      //   console.log("this.state.currentSelectedUser.id---> ", this.state.currentSelectedUser)
      //   let linkedAuthorId = null;
      //   if(this.state.currentSelectedUser && (firstName.value === this.state.currentSelectedUser.fname && lastName.value === this.state.currentSelectedUser.lname))
      //   {
      //     linkedAuthorId = this.state.currentSelectedUser.id;
      //   }
      //   if(!linkedAuthorId)
      //   {

      //      console.log("this.props.initialAuthorresult--> ",this.props.initialAuthorresult)
      //       const userByFALN = this.props.initialAuthorresult.find((el)=> {
      //       return (el.fname === firstName.value && el.lname === lastName.value) });
      //       linkedAuthorId = userByFALN ? userByFALN.id : null;
      //   }

      //   console.log("hii log 22--->> ", linkedAuthorId)
      //   this.setState({linkedAuthorId: linkedAuthorId})
      //   formData.append('linkedAuthorId', linkedAuthorId);
      // }

      // console.log("here is the form data in the event filter-------------- >>>> ", formData);

      this.props.getPreviewData(
        '/api/v1/preview/event',
        headers,
        formData,
        history
      );
    } else {
      if (this.props.formJSON.length == 0) {
        history.push('/dashboard');
        return;
      }
      let validateForSeries;
      let releaseParty;
      if (localStorage.getItem('event_type') == '4') {
        releaseParty = this.props.formJSON.find(el => el.name === 'start-time');
        if (releaseParty.value === '') {
          notify.show('Please select a start time.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
          return;
        }
      }
      let noneSelectedBookReleaseGenre;
      let noneSelectedBookReleaseCategory;
      let noneSelecettype;

      if (localStorage.getItem('event_type') == '1') {
        noneSelectedBookReleaseGenre = this.props.formJSON.find(
          el => el.name === 'Genre'
        );
        noneSelectedBookReleaseCategory = this.props.formJSON.find(
          el => el.name === 'Category'
        );
        noneSelecettype = this.props.formJSON.find(
          el => el.name === 'Book type'
        );

        if (
          noneSelectedBookReleaseGenre.value === 'None selected' ||
          noneSelectedBookReleaseCategory.value === 'None selected' ||
          noneSelecettype.value === 'None selected'
        ) {
          notify.show('Please select a field.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
          return;
        }
      }

      if (localStorage.getItem('event_type') == '1') {
        validateForSeries = this.props.formJSON.find(
          ele => ele.name == 'Total number of books in the series'
        );

        if (
          (validateForSeries.value == '' &&
            this.state.standaloneValue == 'Stand-alone in a series') ||
          (validateForSeries.value == '' &&
            this.state.standaloneValue == 'Book in a series')
        ) {
          notify.show(
            'Please fill in the total number of books in the series.',
            'custom',
            5000,
            {
              background: '#f8d7da',
              text: '#721c24'
            }
          );
          return;
        }
      }

      let formData = new FormData();

      formData.append('event_details', JSON.stringify(this.props.formJSON));
      if (localStorage.getItem('event_type') === '6') {
        formData.append('book-cover', this.avtar);
      } else {
        this.state.d &&
          this.state.imgSrc.length >= this.state.d.length &&
          this.state.d.forEach((image, i) => {
            formData.append(this.state.imgSrc[i].type, image);
          });
      }

      // if( localStorage.getItem('event_type') !== '6')
      // {
      //   this.state.d.forEach((image, i) => {
      //     console.log('this.state.imgSrc[i].type', this.state.imgSrc[i].type);
      //     formData.append(this.state.imgSrc[i].type, image);
      //   });

      // }

      formData.append(
        'event_type',
        `event_type_${localStorage.getItem('event_type')}`
      );

      if (
        localStorage.getItem('event_type') !== '1' &&
        !(
          localStorage.getItem('event_type') === '6' ||
          localStorage.getItem('event_type') === 'ticket sales'
        )
      ) {
        if (Object.keys(this.state.title).length === 0) {
          notify.show('Please Select Title.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
          return;
        }
        formData.append('parent_id', this.state.title.value);
      }

      if (
        Object.keys(this.state.coauthor).length > 0 &&
        localStorage.getItem('event_type') == '1'
      ) {
        formData.append('co_author_id', this.state.coauthor.value);
      }
      const headers = {
        'content-type': 'multipart/form-data',
        'x-authorization': localStorage.getItem('token')
      };
      // if(localStorage.getItem('event_type') != 6 && localStorage.getItem('event_type') != 1){const firstName = this.props.formJSON.find((el)=> el.name === 'author-first-name')
      // const lastName = this.props.formJSON.find((el)=> el.name === 'author-last-name')
      // console.log("firstName: ", firstName)
      // console.log("lastName: ", lastName)
      // formData.append('created_date', moment().format('YYYY/MM/DD HH:mm:ss'));
      // console.log("this.state.currentSelectedUser.id---> ", this.state.currentSelectedUser)
      // let linkedAuthorId = null;
      // if(this.state.currentSelectedUser && (firstName.value === this.state.currentSelectedUser.fname && lastName.value === this.state.currentSelectedUser.lname))
      // {
      //   linkedAuthorId = this.state.currentSelectedUser.id;
      // }
      // if(!linkedAuthorId)
      // {

      //    console.log("this.props.initialAuthorresult--> ",this.props.initialAuthorresult)
      //     const userByFALN = this.props.initialAuthorresult.find((el)=> {
      //     return (el.fname === firstName.value && el.lname === lastName.value) });
      //     linkedAuthorId = userByFALN ? userByFALN.id : null;
      // }

      // console.log("hii log 22--->> ", linkedAuthorId)
      // this.setState({linkedAuthorId: linkedAuthorId})
      // formData.append('linkedAuthorId', linkedAuthorId);}
      // console.log("form data111--> ", formData)

      this.props.getPreviewData(
        '/api/v1/preview/event',
        headers,
        formData,
        history
      );
    }
  };

  prefillComponentValueSubmitHandler() {
    let data = this.props.formJSON;

    this.arrprefillComponents.forEach(element => {
      data.forEach(jsonelement => {
        if (jsonelement.tag === element.parenttag) {
          if (element.tag != '') {
            jsonelement.children.forEach(childelement => {
              if (childelement.tag == element.tag) {
                if (childelement.value === '' || childelement.value == null) {
                  childelement.value = element.value;
                }
              }
            });
          } else if (jsonelement.value === '' || jsonelement.value == null) {
            jsonelement.value = element.value;
          }
        }
      });
    });

    this.setState({ formJSON: data });
  }

  addMoreLinkHandler(parentIndex, index) {
    let data = this.props.formJSON;
    data[parentIndex].children.push(data[parentIndex].children[index]);
    let el = {
      tag: 'input',
      type: 'text',
      className: 'form-control in_form_control',
      placeholder: 'link',
      value: '',
      validation: false,
      name: ''
    };
    data[parentIndex].children[index] = el;
    data[parentIndex].children[index].name =
      data[parentIndex].linkname + (parseInt(data[parentIndex].links) + 1);
    data[parentIndex].links = parseInt(data[parentIndex].links) + 1;

    this.setState({ formJSON: data });
  }

  addMoreServiceHandler(parentIndex, index) {
    let data = this.props.formJSON;
    let addMoreBtn =
      data[parentIndex].children[data[parentIndex].children.length - 1];
    let minusBtn = { tag: 'minus', type: 'service', placeholder: 'Remove' };

    let arrChildData = data[parentIndex].children;

    arrChildData.splice(index, 1);
    if (data[parentIndex].childrenobj == undefined) {
      // debugger;
      data[parentIndex].childrenobj = [];
      data[parentIndex].childrenobj = data[parentIndex].childrenobj.concat(
        arrChildData
      );
    }
    let arrChildData_deepcopy = JSON.parse(
      JSON.stringify(data[parentIndex].childrenobj)
    );
    arrChildData_deepcopy.forEach((el, i) => {
      // debugger;
      if (
        data[parentIndex].children.count != undefined &&
        i > data[parentIndex].children.count
      ) {
        return;
      }
      el.name += `_${data[parentIndex].service}`;
      el.value = '';
      data[parentIndex].children = data[parentIndex].children.concat([el]);
    });

    // Increase service count
    data[parentIndex].service = parseInt(data[parentIndex].service) + 1;

    // data[parentIndex].children = data[parentIndex].children.concat(data[parentIndex].childrenobj);
    minusBtn.index = data[parentIndex].children.length;
    data[parentIndex].children.push(minusBtn);
    data[parentIndex].children.push(addMoreBtn);

    this.setState({ formJSON: data, indexRemove: 3 });
  }

  removeServiceHandler(parentIndex, index) {
    let data = this.props.formJSON;
    data[parentIndex].children.splice(
      index - data[parentIndex].childrenobj.length,
      data[parentIndex].childrenobj.length + 1
    );
    // Decrease service count
    data[parentIndex].service = parseInt(data[parentIndex].service) - 1;

    this.setState({ formJSON: data });
  }

  saveEvent = async () => {
    let formData = new FormData();
    formData.append('event_details', JSON.stringify(this.props.formJSON));
    // formData.append('timezone', this.state.utcvalue);

    formData.append('time1', this.state.fulltime);
    formData.append('isttime', this.state.istTime);
    if (localStorage.getItem('event_type') === '6') {
      formData.append('book-cover', this.avtar);
    } else {
      this.state.imgSrc.length >= this.state.d.length &&
        this.state.d.forEach((image, i) => {
          formData.append(this.state.imgSrc[i].type, image);
        });
    }

    formData.append(
      'event_type',
      `event_type_${localStorage.getItem('event_type')}`
    );
    formData.append('created_date', moment().format('YYYY/MM/DD HH:mm:ss'));
    // if (localStorage.getItem("event_type") !== "1") {
    if (
      localStorage.getItem('event_type') !== '1' &&
      !(
        localStorage.getItem('event_type') === '6' ||
        localStorage.getItem('event_type') === 'ticket sales'
      )
    ) {
      if (Object.keys(this.state.title).length === 0) {
        notify.show('Please Select Title.', 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
        return;
      }
      formData.append('parent_id', this.state.title.value);
      console.log('initialFormJson', this.state.initialFormJson);

      if (this.state.coauthorId === localStorage.getItem('userId')) {
        console.log('initialFormJson', this.state.initialFormJson);
        formData.append('co_author_id', this.state.authorId);
        console.log('author_author_id', this.state.authorId);

        console.log('co_author_id', this.state.coauthorId);
        console.log('parent_id', this.state.title);
      }
    }
    // }

    if (
      Object.keys(this.state.coauthor).length > 0 &&
      localStorage.getItem('event_type') == '1'
    ) {
      formData.append('co_author_id', this.state.coauthor.value);
      console.log('co_author_id=====1381', this.state.coauthor);
    }
    // this.getJSONFormData(); ytrmz
    // console.log("this.state.currentSelectedUser--->> ", this.state.currentSelectedUser)
    const headers = {
      'content-type': 'multipart/form-data',
      'x-authorization': localStorage.getItem('token')
    };
    let linkedAuthorId = null;
    let midName = null;
    if (
      localStorage.getItem('event_type') != 6 &&
      localStorage.getItem('event_type') != 1
    ) {
      const firstName = this.props.formJSON.find(
        el => el.name === 'author-first-name'
      );
      const lastName = this.props.formJSON.find(
        el => el.name === 'author-last-name'
      );
      // console.log("firstName1: ", firstName)
      // console.log("lastName1: ", lastName)
      // console.log("firstName2: ", this.state.currentSelectedUser)
      // console.log("lastName2: ",  this.state.currentSelectedUser.lname)
      if (
        this.state.currentSelectedUser &&
        firstName.value.toLowerCase() ===
          this.state.currentSelectedUser.fname.toLowerCase() &&
        lastName.value.toLowerCase() ===
          this.state.currentSelectedUser.lname.toLowerCase()
      ) {
        console.log(
          'this.state.currentSelectedUser---> ',
          this.state.currentSelectedUser
        );
        linkedAuthorId = this.state.currentSelectedUser.id;
        midName = JSON.parse(this.state.currentSelectedUser.user_details).find(
          el => el.name === 'middlename'
        ).value;
      }
      if (!linkedAuthorId) {
        // console.log("this.props.initialAuthorresult--> ",this.props.initialAuthorresult)
        const userByFALN = this.props.initialAuthorresult.find(el => {
          return (
            el.fname.toLowerCase() === firstName.value.toLowerCase() &&
            el.lname.toLowerCase() === lastName.value.toLowerCase()
          );
        });
        console.log('hiiiiiiiiii this is userByFALN---> ', userByFALN);
        // console.log("------------>>> ", userByFALN)
        linkedAuthorId = userByFALN ? userByFALN.id : null;
      }

      if (!linkedAuthorId && this.state.linkedAuthorId) {
        linkedAuthorId = this.state.linkedAuthorId;
      }
      this.setState({ linkedAuthorId: linkedAuthorId });
      formData.append('linkedAuthorId', linkedAuthorId);
    } else {
      if (
        localStorage.getItem('event_type') === '1' ||
        localStorage.getItem('event_type') === '6'
      ) {
        formData.append('linkedAuthorId', localStorage.getItem('userId'));
        linkedAuthorId = localStorage.getItem('userId');
      }
    }
    console.log('hii linkedAuthorId---->> ', linkedAuthorId);
    let newDD = this.props.initialAuthorresult.find(
      el => el.id == linkedAuthorId
    );
    midName = newDD
      ? JSON.parse(newDD.user_details).find(el => el.name === 'middlename')
      : null;
    console.log('newDD----->> ', midName);

    formData.append('author_mid_name', midName ? midName.value : null);
    if (
      localStorage.getItem('mode') == 'edit' &&
      localStorage.getItem('eventId')
    ) {
      // formData.append('isttime', this.state.istTime);

      this.props.saveUserEvent(
        `/api/v1/event/${localStorage.getItem('eventId')}`,
        headers,
        formData,
        this.props.history,
        true
      );
    } else {
      this.props.saveUserEvent(
        '/api/v1/event/',
        headers,
        formData,
        this.props.history,
        false
      );
    }
  };

  //= ====================== On Change Handlers ENDS ================

  //= ====================== Common functions ====================

  //= ==================== RENDER ==========================

  render() {
    console.log('userprofile---->> ', this.props.userprofile);
    // console.log("initialAuthorresult--->> ", this.props.initialAuthorresult)
    console.log('currentSelectedUser--->> ', this.state.currentSelectedUser);
    let { isSubmitted } = this.state;
    const event_name = {
      1: 'Book Release',
      2: 'Giveaway',
      3: 'Book Promo',
      4: 'Release Party',
      5: 'Cover Reveal',
      6: 'Book Signing',
      7: 'ARC Sign-up',
      'ticket sales': 'Ticket Sales'
    };

    return (
      <>
        {this.state.imageWork &&
          this.state.imgSrc &&
          this.state.imgSrc.length > 0 &&
          this.state.imgSrc.map(el => (
            <>
              {el.type === 'temporary-book-cover' && el.result && (
                <AgreeModel isOpen={true} content={this.state.content} />
              )}
              {el.type === 'book-cover' && el.result && (
                <AgreeModel isOpen={true} content={this.state.content} />
              )}
              {el.type === 'alternative-book-cover' && el.result && (
                <AgreeModel isOpen={true} content={this.state.content} />
              )}
            </>
          ))}

        <Layout>
          <div
            className={`edit-event edit_event ${event_name[
              localStorage.getItem('event_type')
            ].replace(' ', '')}-eventPage`}
          >
            <div className="container">
              {/* <div className="heading mb-4">
                  <h4>
                    Set-up Event:{" "}
                    {event_name[localStorage.getItem("event_type")]}{" "}
                  </h4>
                </div> */}
              <div className="mb-4">
                <h2 className="text-center heading">
                  {' '}
                  Set up Event:{' '}
                  {event_name[localStorage.getItem('event_type')] ===
                  'Ticket Sales'
                    ? 'Book Signing'
                    : event_name[localStorage.getItem('event_type')] ===
                      'Book Signing'
                    ? 'BOXES & SIGNINGS'
                    : event_name[localStorage.getItem('event_type')]}{' '}
                </h2>
                {/* {this.state.eventImageError} */}
              </div>
              {/* {console.log("---------------------------------------> ", localStorage.event_type)} */}
              <Route
                render={({ history }) => (
                  <form
                    className=""
                    onSubmit={e => {
                      e.preventDefault();
                      if (
                        !(
                          localStorage.event_type === '6' ||
                          localStorage.event_type === 'ticket sales'
                        )
                      )
                        if (!this.props.checkForSync)
                          return notify.show(
                            'Please search/sync.',
                            'custom',
                            5000,
                            {
                              background: '#f8d7da',
                              text: '#721c24'
                            }
                          );

                      this.onSubmitFormHandler(e, history);
                    }}
                  >
                    <div className="row">
                      {this.props.formJSON.length !== 0
                        ? this.props.formJSON.map((el, index) => {
                            return this.htmlParser.getFormElements(
                              el,
                              index,
                              this.onChangeHandler,
                              this.onTimeHandler,
                              this.onSearchHandler,
                              this.onCustomChangeHandler,
                              this.onChangeFileHandler,
                              this.onCheckboxChangeHandler,
                              this.addMoreServiceHandler,
                              this.removeServiceHandler,
                              this.addMoreLinkHandler,
                              this.onButtonClickHandler,
                              0,
                              isSubmitted,
                              this.state.imgSrc.find(
                                ele => ele.type === el.name
                              ),
                              this.state.eventImageError,
                              this.state.startDate,
                              this.state.radiotext,
                              this.state.radioValue,
                              this.state.typeo,
                              event_name[localStorage.getItem('event_type')],
                              this.state.disableProperty,
                              this.state.standaloneValue,
                              this.state.hide,
                              this.state.hideGiveaway,
                              this.state.indexRemove,
                              this.props.formJSON,
                              this.state.disapear,
                              this.handleForm,
                              this.state.reset,
                              this.openResetPopup,
                              this.state.readOnly,
                              this.handleReset,
                              this.checkRef,
                              this.state.giveawayTz,
                              this.state.userSuggestion,
                              this.state.openUserSuggestion,
                              this.textBoxRef,
                              this.userClickHandler
                            );
                          })
                        : null}
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        {this.props.formJSON.length != 0 ? (
                          <div className="">
                            <label>
                              <i>
                                {' '}
                                *All events can be edited by account owner. Go
                                to event page, click on edit box in lower right
                                corner.
                              </i>
                            </label>
                            <div className="form-group event-page-btn">
                              <button
                                type="submit"
                                className="btn bg-btn"
                                style={{
                                  marginRight: '20px'
                                }}
                                disabled={this.props.duplicateEventExist}
                              >
                                Save
                              </button>
                              <button
                                type="submit"
                                onClick={() => {
                                  this.props.history.push('/dashboard');
                                }}
                                className="btn float-md-right new_btn_save_bt m-0"
                                style={{
                                  marginRight: '0% !important'
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="" style={{ marginTop: '14%' }}>
                            <div className="form-group event-page-btn">
                              <button type="submit" className="btn bg-btn">
                                Skip
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
          <ResetCoversPopup
            isOpen={this.state.resetPopup}
            toggle={this.closeResetPopup}
            handleReset={this.handleForm}
          />
        </Layout>
        {/* {console.log("hii this.props.linkedAuthorId---> ", this.state.linkedAuthorId)} */}
        {this.props.preview.length > 0 ? (
          <EventModal
            isOpen={this.props.preview.length > 0}
            eventData={this.props.preview}
            title={
              event_list[this.props.editEventType]
                ? event_list[this.props.editEventType]
                : event_name[localStorage.getItem('event_type')]
            }
            preview
            history={this.props.history}
            parenId={this.state.title.value}
            eventPopupid={this.state.eventIdStoreSavedPopup}
            fullIsttime={this.state.fulltime ? this.state.fulltime : ''}
            istutc={this.state.istTime ? this.state.istTime : ''}
            selectTime={this.state.selectTime ? this.state.selectTime : ''}
            confirmSave={this.saveEvent.bind(this)}
            eventWithLocalStorage={localStorage.getItem('event_type')}
            linkedAuthorId={this.state.linkedAuthorId}
            changeLinkedAuthorId={this.changeLinkedAuthorId}
          />
        ) : null}
      </>
    );
  }

  componentDidMount() {
    this.props.searchUser(
      `/api/v1/user_authorsearch?searchString=${''}`,
      { headers: { 'x-authorization': localStorage.getItem('token') } },
      'cdm'
    );
    document.addEventListener('mousedown', this.handleClickOutside);
    this.props.checkSync(false);
    if (localStorage.getItem('eventId') && !this.props.formJSON.length > 0) {
      if (localStorage.getItem('mode') !== 'edit') {
        // this.props.delete(`/api/v1/event/${localStorage.getItem('eventId')}`, {
        //   headers: { 'x-authorization': localStorage.getItem('token') }
        // });
      } else {
        this.props.editEvent(
          `/api/v1/event/${localStorage.getItem('eventId')}`,
          { headers: { 'x-authorization': localStorage.getItem('token') } },
          this.props.newTab,
          true
        );
      }
    }

    if (
      !this.props.formJSON.length > 0 &&
      !this.props.loading &&
      localStorage.getItem('mode') != 'edit'
    ) {
      if (localStorage.getItem('event_type')) {
        this.props.getSchema(
          '/api/v1/events',
          { headers: { 'x-authorization': localStorage.getItem('token') } },
          `event_type_${localStorage.getItem('event_type')}`
        );
      } else {
        this.props.history.push('/dashboard');
      }
    }

    this.props.setToDefault();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.formJSON.length > 0) {
      if (
        this.state.initialFormJson.length === 0 ||
        this.state.initialFormJson.length !== this.props.formJSON.length
      ) {
        let newArr = JSON.parse(JSON.stringify(this.props.formJSON));
        this.setState({
          initialFormJson: newArr
        });
        if (localStorage.getItem('mode') === 'edit') {
          this.setState({ readOnly: true });
        }
      }
    }
    console.log('prevState', prevState);

    if (prevState.title && prevState.title !== this.state.title) {
      // Now fetch the new data here.
      if (
        this.state.title.value &&
        localStorage.getItem('event_type') !== '1'
      ) {
        console.log('console11111111');
        this.getEventDetails('auto');
      }
    }
    if (
      JSON.stringify(prevProps.usersearchResult) !==
      JSON.stringify(this.props.usersearchResult)
    ) {
      //  console.log("console222222")
      this.setState({
        userSuggestion: this.props.usersearchResult
      });
    }
  }

  componentWillReceiveProps() {
    // if(localStorage.getItem("mode") !== 'edit')
    // {
    // }
    // console.log("this.state.formJSON==> ", this.state.formJSON)
    // console.log("this.props.formJSON==> ", this.props.formJSON)
    // if(this.state.formJSON.length===0)
    // {
    //   console.log("aham sastraam")
    //   this.setState({ formJSON: this.props.formJSON });
    // }

    if (Object.keys(this.props.eventDetails).length !== 0) {
      const eventType = localStorage.getItem('event_type');
      console.log('i am here in cond');
      const setValue = [
        'author-first-name',
        'author-last-name',
        'coauthor',
        'book-cover',
        'alternative-book-cover'
      ];
      setValue.map(value => {
        let jsonObject = this.props.formJSON.find(x => x.name == value);
        console.log('jsonObject-----> ', jsonObject);
        if (jsonObject) {
          //  const setValue=['author-first-name','author-last-name','Co-author(s)','book-cover'];

          if (
            jsonObject &&
            value == 'author-first-name' &&
            this.props.eventDetails.author_first_name
          ) {
            let index = this.props.formJSON.indexOf(jsonObject);
            this.props.formJSON[
              index
            ].value = this.props.eventDetails.author_first_name;
          } else if (
            jsonObject &&
            value == 'author-last-name' &&
            this.props.eventDetails.author_last_name
          ) {
            let index = this.props.formJSON.indexOf(jsonObject);
            this.props.formJSON[
              index
            ].value = this.props.eventDetails.author_last_name;
          } else if (
            jsonObject &&
            value == 'alternative-book-cover' &&
            localStorage.getItem('mode') !== 'edit'
          ) {
            let index = this.props.formJSON.indexOf(jsonObject);
            let eventObject = this.props.eventDetails.event_details.find(
              e => e.name === 'alternative-book-cover'
            );
            if (eventObject) {
              this.props.formJSON[index].value = eventObject.value;
            }
          } else if (
            jsonObject &&
            value == 'book-cover' &&
            localStorage.getItem('mode') !== 'edit'
          ) {
            let index = this.props.formJSON.indexOf(jsonObject);
            let eventObject = this.props.eventDetails.event_details.find(
              e => e.name === 'book-cover'
            );
            if (eventObject) {
              this.props.formJSON[index].value = eventObject.value;
            }
          } else if (
            jsonObject &&
            value == 'alternative-book-cover' &&
            this.state.changeValueSearch === 'click'
          ) {
            let index = this.props.formJSON.indexOf(jsonObject);
            let eventObject = this.props.eventDetails.event_details.find(
              e => e.name === 'alternative-book-cover'
            );
            if (eventObject) {
              this.props.formJSON[index].value = eventObject.value;
            }
          } else if (
            jsonObject &&
            value == 'book-cover' &&
            this.state.changeValueSearch === 'click'
          ) {
            let index = this.props.formJSON.indexOf(jsonObject);
            let eventObject = this.props.eventDetails.event_details.find(
              e => e.name === 'book-cover'
            );
            if (eventObject) {
              this.props.formJSON[index].value = eventObject.value;
            }
          } else {
            let eventObject = this.props.eventDetails.event_details.find(
              e => e.name == value
            );

            let discoauthor = eventObject && eventObject.value;
            this.setState({ disableProperty: discoauthor });

            // if (eventObject) {
            //   console.log("jsonObject",jsonObject,eventObject)
            //   let index = this.props.formJSON.indexOf(jsonObject);
            //   this.props.formJSON[index].value = eventObject.value;
            //   if (value == 'book-cover') {
            //     this.props.formJSON[index].validation = false;
            //   }
            // }
          }
          if (
            jsonObject &&
            value == 'coauthor' &&
            !(eventType === '1' || eventType === '6')
          ) {
            const indexJson = this.props.formJSON.findIndex(
              el => el.name === 'coauthor'
            );
            const indexForm = this.props.eventDetails.event_details.findIndex(
              el => el.name === 'coauthor'
            );
            this.props.formJSON[
              indexJson
            ].value = this.props.eventDetails.event_details[indexForm].value;
          }
        }
      });
    }

    if (
      localStorage.getItem('mode') == 'edit' &&
      localStorage.getItem('event_type') !== '1' &&
      Object.keys(this.props.formJSON).length !== 0
    ) {
      let jsonObject = this.props.formJSON.find(x => x.name == 'title');
      let jsonObject1 = this.props.formJSON.find(x => x.name == 'coauthor');
      console.log('jsonObject', jsonObject);
      console.log('jsonObject1', jsonObject1);

      let timeCheck;
      let releasePartyTime;
      let storeFunValue;
      let fullt;
      if (localStorage.getItem('event_type') === '4') {
        timeCheck = this.props.formJSON.find(
          el => el.display_name === 'Date of release party'
        );
        releasePartyTime = this.props.formJSON.find(
          el => el.display_name === 'Time'
        );

        let start = moment(timeCheck.value).toDate();

        const startAppDate = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');

        let f = this.changeTimeZone(new Date(startAppDate), 'America/New_York');

        fullt = moment(f).format('hh:mm A');
        storeFunValue = this.timeZoneAbbreviated(timeCheck.value);
      }

      if (jsonObject) {
        let data = {
          value: jsonObject.id,
          label: jsonObject.value
        };

        let data1 = {
          value: jsonObject1.id,
          label: jsonObject1.value
        };

        if (
          localStorage.getItem('event_type') === '4' &&
          fullt &&
          !(
            this.state.title &&
            this.state.fulltime &&
            this.state.selectTime &&
            this.state.istTime
          )
        ) {
          this.setState({
            title: data,
            fulltime: fullt,
            selectTime: releasePartyTime.value,
            istTime: storeFunValue,
            coauthor: data1
          });
        } else if (!this.state.title.value) {
          this.setState({ title: data, coauthor: data1 });
        }
      }
    }
    // let jsonObject1 = this.props.formJSON.find(x => x.name == 'coauthor');
    // console.log('jsonObject1985', jsonObject1);
    if (
      Object.keys(this.props.formJSON).length !== 0 &&
      localStorage.getItem('mode') == 'edit' &&
      localStorage.getItem('event_type') === '1'
    ) {
      let jsonObject1 = this.props.formJSON.find(x => x.name == 'coauthor');
      console.log('jsonObject1992', jsonObject1);
      this.props.formJSON.map(el => {
        if (el.name === 'Book type') {
          this.setState({ standaloneValue: el.value });
        }
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.textBoxRef.current &&
      !this.textBoxRef.current.contains(event.target)
    ) {
      if (this.state.userSuggestion) {
        this.setState({ userSuggestion: false });
      }
    }
  }
}

const mapStateToProps = state => ({
  loading: state.eventLoading,
  formJSON: state.eventData,
  eventDetail: state.eventDetail,
  preview: state.previewData,
  editEventType: state.editEventType,
  eventDetails: state.getSingleEventDetails,
  sendMail: state.sendMailModela,
  duplicateEventExist: state.duplicateEventExist,
  checkForSync: state.checkForSync,
  newTab: state.addTab,
  usersearchResult: state.userSearchresult,
  userList: state.userListData,
  initialAuthorresult: state.initialSearchresult,
  userprofile: state.myProfileData
});

const mapDispatchToProps = dispatch => ({
  saveUserEvent: (url, headers, data, history, edit) =>
    dispatch(saveEvent(url, headers, data, history, edit)),
  getSchema: (url, headers, schema_name) =>
    dispatch(getEvent(url, headers, schema_name)),
  delete: (url, headers) => dispatch(deleteEvent(url, headers)),
  getAllEvents: () =>
    dispatch(
      getAllEvents('/api/v1/events', {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    ),
  editEvent: (url, headers, newtab, isEventPage) =>
    dispatch(getEventDetails(url, headers, newtab, isEventPage)),
  getPreviewData: (url, headers, data, history) =>
    dispatch(getEventPreview(url, headers, data, history)),
  getEventDetail: (url, headers, params, check) =>
    dispatch(getSingelEventDetail(url, headers, params, check)),
  setToDefault: () => dispatch(setToDefault()),
  duplicate: data => dispatch(duplicateEvent(data)),
  checkSync: data => dispatch(isSyncChecked(data)),
  restEvent: data => dispatch(eventData(data)),
  emptyGetEventdeatils: () => dispatch(getSingleEventDetails({})),
  setEventdeatils: eventDetails =>
    dispatch(getSingleEventDetails(eventDetails)),
  searchUser: (url, header, callby) => dispatch(searchUser(url, header, callby))
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);
