import React, { Component } from 'react';
import Logo from '../../Components/left-logo';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import createHistory from 'history/createHashHistory';
import Layout from '../layout/layout';
import { GridLoader } from 'react-spinners';
import CONSTANT, { BASEURL } from '../../constant';

import { css } from '@emotion/core';
import {
  signuppage,
  register,
  verifyOTP,
  resendOTP,
  paymentConfirmation,
  verifyPRCode,
  doSocialSignUp
} from '../../Actions/index';
import OTP from './signup-otp';
import PersonalDetails from './signup-personal-details';
import Checkout from './checkout';
import axios from 'axios';
import $ from 'jquery';
import './signup.css';
import Loader from '../../Components/Loader/loader';
const override = css`
  position: fixed;
  margin-top: 15%;
  left: 45%;
`;

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      AuthorTypes: [],
      SelectedAuthor: {},
      AuthorTypeInfo: {},
      MembershipDetails: '<li>Details</li>',
      modal: false,
      urlPrcode: '',
      loader: true
    };

    this.toggle = this.toggle.bind(this);
    this.step_one_details = this.step_one_details.bind(this);
    this.verify_otp = this.verify_otp.bind(this);
    this.resend_otp = this.resend_otp.bind(this);
    this.complete_signup = this.complete_signup.bind(this);
    this.previous = this.previous.bind(this);
    const globalHistory = new createHistory();
  }

  handleAuthorChange(author) {
    this.setState({
      SelectedAuthor: author
    });
  }

  toggle(author) {
    console.log('author', { author, description: author.description });
    this.setState(prevState => ({
      modal: !prevState.modal,
      AuthorTypeInfo: author,
      description:
        author.type_name === 'Author'
          ? author.description[0]
          : author.description
    }));
  }

  previous(page) {
    this.props.handleSignUpPages(page);
  }

  step_one_details(details) {
    let emailRegex ='^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$';
    // console.log('details========>>>>>>', details,details.password.length);
    localStorage.setItem('selected_type', this.state.SelectedAuthor.id);
    if(details)
    {
      // console.log("------------------------- > > > > > > >", details._profile);
       if(!(details._provider === 'google'))
       {
        if(details.email.length == 0)
        {
          console.log("email->>")
          return notify.show(
            'Please enter an email.',
            'custom',
            3000,
            { background: '#f8d7d9', text: '#721c24' }
          );
        }
        if(!(details.email.toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )))
        {
          console.log("valid email--")
          return notify.show(
            'Please enter a valid email.',
            'custom',
            3000,
            { background: '#f8d7d9', text: '#721c24' }
          );
        }
        if(details.display_name.length == 0)
        {
          console.log("username->>")
          return notify.show(
            'Please enter a username.',
            'custom',
            3000,
            { background: '#f8d7da', text: '#721c24' }
          );
        }
        if(details.password.length == 0)
        {
          console.log("password->>");
          return notify.show(
            'Please enter a password.',
            'custom',
            3000,
            { background: '#f8d7d9', text: '#721c24' }
          );
        }
        if(details.country_id == 0)
        {
          console.log("country->>")
          return notify.show(
            'Please select a country.',
            'custom',
            3000,
            { background: '#f8d7d9', text: '#721c24' }
          );
        }
       }
   
    }
   

    console.log("after the validation of signup page-- > > >  ", details._profile);
    if (details._profile) {
      console.log('details', details);
      let socialData = {
        email: details._profile.email ? details._profile.email : null,
        username: `${details._profile.firstName.toLowerCase()}_${details._profile.lastName.toLowerCase()}_${Math.random()
          .toString(36)
          .substr(2, 9)};`,
        social_token: details._token,
        social_type: details.provider,
        type_id: this.state.SelectedAuthor.id,
        firstName: details._profile.firstName
          ? details._profile.firstName
          : null,
        lastName: details._profile.lastName ? details._profile.lastName : null
      };
     
      if (!socialData.email) {
        return notify.show(
          'Email id is not available with Facebook. Please sign-up with Google or another email id.',
          'custom',
          3000,
          { background: '#f8d7da', text: '#721c24' }
        );
      } else {
        this.props.signUpSocial('/api/v1/login/with_facebook', socialData);
      }
    } else {
      this.props.signUp(
        '/api/v1/signup/step/1',
        details.display_name.split(' ').join(''),
        details.email,
        details.password,
        details.country_id,
        this.state.SelectedAuthor.id
      );
    }
  }

  verify_otp(otp) {
    this.props.handleOTPVerification(
      '/api/v1/verify/email',
      localStorage.getItem('username'),
      otp
    );
  }

  resend_otp() {
    console.log('hi under', this.props);
    this.props.handleResendOTP(
      '/api/v1/resend/otp',
      localStorage.getItem('username')
    );
  }

  complete_signup(membership, token, promoCode) {
    this.props.completeSignup('/api/v1/pay/membership/', {
      membershipId: membership,
      prCode: localStorage.getItem('prCode')
        ? localStorage.getItem('prCode')
        : null,
      clientToken: token,
      promoCode
    });
  }

  validatePRCode() {
    console.log('validatePRCode', document.getElementById('code').value);
    if (document.getElementById('code').value !== '')
      this.props.validatePR(
        '/api/v1/follow/check/validity/' +
          document.getElementById('code').value,
        document.getElementById('code').value,
        true
      );
    else
      notify.show('Please enter a valid PR code.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
  }

  // componentDidMount() {
  //   // localStorage.clear();
  //   let urlData = window.location.search.split("?=")[1];
  //   console.log("url", urlData);
  //   // if (urlData) {
  //   //   this.setState({ urlPrcode: urlData });
  //   // } else {
  //   //   this.setState({ urlPrcode: "" });
  //   // }
  // }

  render() {
    console.log('this.state.AuthorTypes', this.state.description);
    // let obj = {};
    // let data = this.state.description ? this.state.description : '';
    // data.forEach(el => {
    //   obj[Object.keys(el)] = el[Object.keys(el)];
    // });
    // console.log('jjjddd', obj);
    // console.log("pagepage88888888888",this.props.page)

    return (
      <Layout>
        {this.props.page > 0 ? this.SignUpProgress(this.props.page) : ''}
        <div className="container">
          <div className="signupmain signup-page-one">
            <div
              className={
                this.props.page > 0 ? 'main_right' : 'main_right author_types'
              }
            >
              {this.props.loading ? (
                ''
              ) : this.props.page === 1 ? (
                <PersonalDetails
                  step_one_details={this.step_one_details}
                  goBack={this.previous}
                />
              ) : this.props.page === 2 ? (
                <OTP otp={this.verify_otp} resend={this.resend_otp} />
              ) : this.props.page === 3 ? (
                <PRCode
                  goBack={this.previous}
                  validatePR={this.validatePRCode.bind(this)}
                  urlPrcode={window.location.search.split('?=')[1]}
                />
              ) : this.props.page === 4 ? (
                <Checkout
                  goBack={this.previous}
                  member={
                    this.state.AuthorTypes.length > 0
                      ? this.state.AuthorTypes.filter(
                          e =>
                            e.id ===
                            parseInt(localStorage.getItem('selected_type'))
                        )[0].type_name
                      : ''
                  }
                  checkout={this.complete_signup}
                  allDetails={this.state.AuthorTypes.map(
                    _type => _type.description
                  )}
                  details={
                    this.state.AuthorTypes.length > 0
                      ? this.state.AuthorTypes.filter(
                          e =>
                            e.id ===
                            parseInt(localStorage.getItem('selected_type'))
                        )[0].description
                      : ''
                  }
                />
              ) : this.props.page === 5 ? (
                <ThankYou />
              ) : (
                this.signUpPage1()
              )}
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          id="myModal"
        >
          <div className="model_cover signup-modal">
            <ModalHeader toggle={this.toggle} className="eventModal">
              <h3 className="text-center p-0">
                {' '}
                {}{' '}
                {this.state.AuthorTypeInfo.type_name==="PR Firm" ?"PR FIRM/BOOKSTORE":this.state.AuthorTypeInfo.type_name}{" "}
                {this.state.AuthorTypeInfo.type_name === 'Author'
                  ? 'Premium'
                  : '' | (this.state.AuthorTypeInfo.type_name === 'Blogger')
                  ? 'Premium'
                  : ''}{' '}
              
                Membership
              </h3>
            </ModalHeader>
            <ModalBody>
              
              {this.state.AuthorTypeInfo.type_name === 'Author' ? (
                <>
                  {/* <b class="mb-3">AUTHOR PREMIUM MEMBERSHIP</b> */}
                  <ul>
                    <li class="font-weight-bold">
                      Add unlimited book releases to the OTR calendar.
                    </li>
                    <li>
                      Customize Author profile page with links to all your
                      calendar events, social media &amp; contact details.
                    </li>
                    <li>
                    3-in-1: Promote using Author/PR functions, network
                            &amp; organize your own reading calendar with daily
                            or weekly reminder options.
                    </li>
                    <li>
                      Add &amp; manage book releases, release parties &amp;
                      giveaways.
                    </li>
                    <li>
                      Bookmark links for pre-orders, book trailers, playlists
                      &amp; teasers.
                    </li>
                    <li>
                      Free release day Blast to followers with option to earn or
                      purchase additional Blasts.
                    </li>
                    <li>
                      Provide bloggers easy access to sign-up forms for PR
                      opportunities such as cover reveals, ARCs, release promo
                      &amp; blog tours.
                    </li>
                    <li>
                      Connect with beta readers &amp; extend ARC signups to all
                      readers.
                    </li>
                    <li>
                      Access to list of all Blogger &amp; Blogger Premium
                      members.
                    </li>
                    <li>Increase exposure by gaining followers.</li>
                    <li>Organize Daily Tasks with reminder options.</li>
                    <li>Track your writing goals.</li>
                    <li>
                      {/* Network with vendors (cover designers, editors etc.), PR
                      firms &amp; other authors. */}
                      Network with vendors (cover designers, editors etc.), PR firms, bookstores & other authors.
                    </li>
                    <li>
                      Easily check for last-minute Vendor Cancellation Openings
                      (VCO) to reserve services with previously fully-booked
                      vendors.
                    </li>
                    <li>
                      <b>$580 USD/year</b>
                    </li>
                  </ul>
                  <h3 className="text-center p-0">
                    AUTHOR (STANDARD) MEMBERSHIP
                  </h3>
                  <ul>
                    <li class="font-weight-bold">
                      Add three book releases per membership year with add-on
                      option at $160 USD per additional release.
                    </li>
                    <li>
                      Customize Author profile page with links to all your
                      calendar events, social media &amp; contact details.
                    </li>
                    <li>
                    3-in-1: Promote using Author/PR functions, network
                            &amp; organize your own reading calendar with daily
                            or weekly reminder options.
                    </li>
                    <li>
                      Add &amp; manage book releases, release parties &amp;
                      giveaways.
                    </li>
                    <li>
                      Bookmark links for pre-orders, book trailers, playlists
                      &amp; teasers.
                    </li>
                    <li>
                      Free release day Blast to followers with option to earn or
                      purchase additional Blasts.
                    </li>
                    <li>
                      Provide bloggers easy access to sign-up forms for PR
                      opportunities such as cover reveals, ARCs, release promo
                      &amp; blog tours.
                    </li>
                    <li>
                      Connect with beta readers &amp; extend ARC signups to all
                      readers.
                    </li>
                    <li>
                      Access to list of all Blogger &amp; Blogger Premium
                      members.
                    </li>
                    <li>Increase exposure by gaining followers.</li>
                    <li>Organize Daily Tasks with reminder options.</li>
                    <li>Track your writing goals.</li>
                    <li>
                      {/* Network with vendors (cover designers, editors etc.), PR
                      firms &amp; other authors. */}
                      Network with vendors (cover designers, editors etc.), PR firms, bookstores & other authors.
                    </li>
                    <li>
                      Easily check for last-minute Vendor Cancellation Openings
                      (VCO) to reserve services with previously fully-booked
                      vendors.
                    </li>
                    <li>
                      <b>$390 USD/year</b>
                    </li>
                  </ul>
                </>
              ) : this.state.AuthorTypeInfo.type_name === 'Reader' ? (
                <>
                  <ul>
                    <li>
                      Full access to all reader calendar events with easy
                      filter/search functions.
                    </li>
                    <li>
                      Organize your reading calendar with options to set
                      daily/weekly reminders.
                    </li>
                    <li>
                      Customize your OTR reader calendar by adding &amp; saving
                      events you wish to follow.
                    </li>
                    <li>
                      Quickly find links to pre-orders, book trailers, teasers,
                      playlists.
                    </li>
                    <li>Bookmark links to release parties &amp; giveaways.</li>
                    <li>
                      Option to register as a beta reader on a list open to
                      authors.
                    </li>
                    <li>Access ARC signups available to all readers.</li>
                    <li>
                      Receive notifications when favorite authors share releases
                      &amp; news.
                    </li>
                    <li>
                      Stay current by quickly viewing new, popular or featured
                      events.
                    </li>
                    <li>
                      <b>$39 USD/year</b>
                    </li>
                  </ul>
                </>
              ) : this.state.AuthorTypeInfo.type_name === 'Blogger' ? (
                <>
                  {/* <b class="mb-3">BLOGGER PREMIUM MEMBERSHIP</b> */}
                  <ul>
                    <li class="font-weight-bold">
                      Customize Blogger profile page with links to social media
                      &amp; blog events.
                    </li>
                    <li class="font-weight-bold">
                      Organize blog postings in Daily Tasks with the option of
                      reminders.
                    </li>
                    <li class="font-weight-bold">
                      Add giveaways &amp; release parties to the OTR calendar.
                    </li>
                    <li class="font-weight-bold">Gain followers.</li>
                    <li class="font-weight-bold">
                      Earn or purchase Blasts to followers.
                    </li>
                    <li>
                      Exclusive blogger access to all Promo &amp; ARC signups.
                    </li>
                    <li>
                      Featured on the blogger list viewable by all authors.
                    </li>
                    <li>Quickly filter events specific to your blog.</li>
                    <li>
                      Easy access to links for pre-orders, book trailers,
                      teasers, playlists.
                    </li>
                    <li>Bookmark links to release parties &amp; giveaways.</li>
                    <li>
                      Option to register as a beta reader on a list open to
                      authors.
                    </li>
                    <li>
                      Full access to all calendar events with easy filter/search
                      functions.
                    </li>
                    <li>
                      Organize your reading calendar with options to set
                      daily/weekly reminders.
                    </li>
                    <li>
                      Receive notifications when favorite authors share news
                      &amp; releases.
                    </li>
                    <li>
                      Stay current by quickly viewing new, popular or featured
                      events.
                    </li>
                    <li>
                      <b>$89 USD/year</b>
                    </li>
                  </ul>
                  {/* <b class="mb-3">BLOGGER STANDARD MEMBERSHIP</b> */}
                  <h3 className="text-center p-0">
                    BLOGGER MEMBERSHIP
                  </h3>

                  <ul>
                    <li>
                      Exclusive blogger access to all Promo &amp; ARC signups.
                    </li>
                    <li>
                      Featured on the blogger list viewable by all authors.
                    </li>
                    <li>Quickly filter events specific to your blog.</li>
                    <li>
                      Easy access to links for pre-orders, book trailers,
                      teasers, playlists.
                    </li>
                    <li>Bookmark links to release parties &amp; giveaways.</li>
                    <li>
                      Option to register as a beta reader on a list open to
                      authors.
                    </li>
                    <li>
                      Full access to all calendar events with easy filter/search
                      functions.
                    </li>
                    <li>
                      Organize your reading calendar with options to set
                      daily/weekly reminders.
                    </li>
                    <li>
                      Receive notifications when favorite authors share news
                      &amp; releases.
                    </li>
                    <li>
                      Stay current by quickly viewing new, popular or featured
                      events.
                    </li>
                    <li>
                      <b>$59 USD/year</b>this.state.AuthorTypes
                    </li>
                  </ul>
                </>
              ) : this.state.AuthorTypeInfo.type_name === 'Vendor' ? (
                <>
                  <ul>
                    <li>
                      Highlight your services, contact &amp; social media links
                      on a customized Vendor page.
                    </li>
                    <li>
                    Offer any of the following services: Agent, Blurb Writer, Book Boxes, Book Cover Designer, Book Formatter, Book Signing Organizer,
                     Cover Model, Editor, Graphic Designer, Merchandising, Personal Assistant (PA), 
                    Photographer, Proofreader, Publisher, Social Media Consultant, Social Media Manager, Swags, Web Design, Workshops.
                    </li>
                    <li>Network with authors &amp; other vendors.</li>
                    <li>
                      Be featured on our vendor list viewable by all authors.
                    </li>
                    <li>
                      Indicate on profile/vendor list if new clients are being
                      accepted.
                    </li>
                    <li>
                      To secure cancelled bookings with new clients, register
                      Vendor Cancellation Openings (VCO), to be featured on
                      Vendor tab.
                    </li>
                    <li>
                      Organize your Daily Tasks with daily or weekly reminder
                      options.
                    </li>
                    <li>Promote book boxes & signings in the OTR calendar.</li>
                    <li>
                      Option to register as a beta reader on a list open to
                      authors.
                    </li>
                    <li>
                      Full access to all reader calendar events with easy
                      filter/search functions.
                    </li>
                    <li>
                      Organize your own reading calendar with options to set
                      daily/weekly reminders.
                    </li>
                    <li>
                      <b>$149 USD/year</b>
                    </li>
                  </ul>
                </>
              ) : this.state.AuthorTypeInfo.type_name === 'PR Firm' ? (
                <>
                  <ul>
                    <li>
                    Feature any of these services: PR Services, Bookstore, Book boxes, Book signing organizer, Merchandising, Publisher, Swags & Workshops.
                    </li>
                    <li>
                    Add events to the calendar, such as PR tours for clients, giveaways, release parties, book boxes & signings.
                    </li>
                    <li>Provide bloggers easy access to sign-up forms for cover reveals, ARCs, release promo & blog tours.</li>
                    <li>
                    Customizable PR profile to highlight services, hosted events, contact & social media links.
                    </li>
                    <li>
                    Gain followers.
                    </li>
                    <li>Earn or purchase Blasts to followers.</li>
                    <li>Organize notes, to-do list & postings in Daily Tasks with reminders.</li>
                    <li>
                    Network with authors & vendors (designers, social media consultants etc.)
                    </li>
                    <li>
                    Get featured on the vendor list viewable by all authors.
                    </li>
                    <li>
                    Indicate on profile/vendor list if new clients are being accepted.
                    </li>
                    <li>
                    Secure canceled bookings with new clients, register Vendor Cancellation Openings (VCO), to get featured on the Vendor tab.
                    </li>
                    <li>
                    Option to register as a beta reader on a list open to authors.
                    </li>
                    <li>
                    Full access to all OTR calendar events with easy filter/search functions.
                    </li>
                    <li>
                    Organize your own reading calendar with options to set daily/weekly reminders.
                    </li>
                    <li>
                      <b>$720 USD/year</b>
                    </li>
                  </ul>
                </>
              ) : (
                ''
              )}
            </ModalBody>
          </div>
        </Modal>
      </Layout>
    );
  }

  SignUpProgress(page) {
    return (
      <div className="headre_progress">
        <ul id="progress-bar" className="progressbar">
          <li id="page1" className={page >= 1 ? 'active' : ''}></li>
          <li id="page2" className={page >= 2 ? 'active' : ''}></li>
          <li id="page3" className={page >= 3 ? 'active' : ''}></li>
          <li id="page4" className={page >= 4 ? 'active' : ''}></li>
          <li id="page5" className={page >= 5 ? 'active' : ''}></li>
        </ul>
      </div>
    );
  }

  signUpPage1() {
    return (
      <React.Fragment>
        <div className="full_title">
          <div className="form-group">
            <div className="in_form">
              <div className="in_title_two">
                <span style={{ textTransform: 'uppercase' }}>
                  Select Member Type:
                </span>
              </div>{' '}
              {this.state.loader ? (
                // <div className="loader-container">
                //   <GridLoader
                //     css={override}
                //     sizeUnit={'px'}
                //     size={25}
                //     color={'#e20f0b'}
                //     loading={this.state.loader}
                //   />
                // </div>
                <Loader />
              ) : (
                ''
              )}
              <div className="radiobuttons">
                {this.state.AuthorTypes.length
                  ? this.getAuthorTypes(this.state.AuthorTypes)
                  : ''}
              </div>
            </div>
          </div>
        </div>
        <p className="text-bottom">
          You must be at least 18 years old to join this platform. OTR uses
          third-party sites for payment. OTR does not collect nor store member
          payment information.
        </p>
        <div className="signuptype_footercover">
          <div className="signup-link-section">
            <button
              className="btn new-btn-bordered compare-plans-btn"
              onClick={() => this.props.history.push('/plans')}
              type="button"
            >
              Compare Plans
            </button>
            <button
              className="btn new-btn-bordered"
              onClick={() => this.props.history.push('/log-in')}
              type="button"
            >
              Already have an Account ?
            </button>
          </div>
          <button
            type="button"
            className="btn new-btn-filled"
            onClick={() => {
              this.state.SelectedAuthor.id
                ? this.props.handleSignUpPages(1)
                : notify.show('Please select member type.', 'custom', 2000, {
                    background: '#f8d7da',
                    text: '#721c24'
                  });
            }}
          >
            JOIN{' '}
          </button>
        </div>
      </React.Fragment>
    );
  }

  getAuthorTypes(Authors) {
    console.log('AuthorsAuthorsAuthorsAuthorsAuthors', Authors);
    return Authors.map((author, index) => {
      return (
        // <label key={author.type_name + "_" + author.id} className="" htmlFor="radio1">PREMIUM BLOGGER MEMBERSHIP
        <div
          key={author.type_name + '_' + author.id}
          className="rdio rdio-primary radio-inline radioninline_3"
        >
          <input
            name="radio"
            id={index}
            value={author.id}
            type="radio"
            defaultChecked={
              this.state.SelectedAuthor.type_name === author.type_name
            }
            onChange={this.handleAuthorChange.bind(this, author)}
            style={{ boxSizing: 'border-box' }}
            className="author-radio"
          /> 
          <label htmlFor={index}>{(author.type_name==="PR Firm"?"PR Firm/Bookstore":author.type_name)}</label>
          <img
            src={require('../../Utils/Assets/info.png')}
            alt="info"
            className="info"
            onClick={() => {
              this.toggle(author);
            }}
          ></img>
        </div>
        // </label>
      );
    });
  }

  componentDidMount() {
    // localStorage.clear();
    let urlData = window.location.search.split('?=')[1];

    this.setState({ urlData: urlData });

    console.log('urlData', urlData);
    localStorage.removeItem('selected_type');
    localStorage.removeItem('username');
    localStorage.removeItem('prCode');
    localStorage.removeItem('username');

    // setTimeout(() => {
    //   this.setState({
    //     loader: false
    //   });
    // }, 2000);

    // this.props.handleSignUpPages(5)
    axios
      .get(BASEURL + '/api/v1/user-type')
      .then(response => {
        this.setState({
          AuthorTypes: response.data.result.userTypes,
          loader: false
        });
      })
      .catch(error => {
        // console.log(error);
      });
  }
}

const ThankYou = () => {
  localStorage.removeItem('selected_type');
  $('#page1').addClass('active');
  $('#page2').addClass('active');
  $('#page3').addClass('active');
  $('#page4').addClass('active');
  $('#page5').addClass('active');
  return (
    <div>
      <div className="thankyou_cover">
        <div className="inner_thankyou">
          <h1>Thank You</h1>
          <p>For Becoming An </p>
          <p>ON Target Romance Member.</p>
          <div className="button_btft" style={{ marginTop: '5%' }}>
            <div className="col-md-12 text-center">
              <Link to="/log-in" className="btn new_btn_save_bt float-md-right">
                Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PRCode = props => {
  console.log('props=============', props);
  $('#page1').addClass('active');
  $('#page2').addClass('active');
  $('#page3').addClass('active');
  return (
    <React.Fragment>
      <div className="signup_text">
        <span>
        If you have and invite code from an author, PR firm, bookstore or blogger, please add here:
        </span>
        {console.log('props', props)}
      </div>
      <div className="code_main">
        <div className="form-group form-group_code">
          <input
            type="text"
            className="form-control code_form"
            id="code"
            placeholder="Enter invite code"
            value={props.urlPrcode}
          />
        </div>
      </div>
      <div className="signup_text note-txt">
        <span>
          <i>
            {' '}
            *Upon using a special invitation code, you will be set to automatically follow the author, PR firm, bookstore or blogger to stay informed of all their events.
          </i>
        </span>
        <span>
          <i>
            {' '}
            To edit or add to your follow list look for following page under List tab.
          </i>
        </span>
      </div>

      <div className="signup-btn-group">
        <button
          className="btn btn-filled m-t-120"
          id="Next"
          onClick={() => props.validatePR()}
          type="button"
        >
          Submit
        </button>

        <button
          className="btn btn-bordered m-r-10 m-t-120 m-l-20"
          onClick={() => props.goBack(4)}
          type="button"
        >
          Skip
          <img
            src={require('../../Utils/Assets/aerrow_right.png')}
            alt="Skip"
          />
        </button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    page: state.signuppage,
    status: state.userloading,
    loading: state.signuploading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSignUpPages: page => dispatch(signuppage(page)),
    signUp: (url, name, email, password, country_id, type_id) =>
      dispatch(register(url, name, email, password, country_id, type_id)),
    handleOTPVerification: (url, email, otp) =>
      dispatch(verifyOTP(url, email, otp)),
    handleResendOTP: (url, email) => dispatch(resendOTP(url, email)),
    validatePR: (url, value, status) =>
      dispatch(verifyPRCode(url, value, status)),
    signUpSocial: (url, data) => dispatch(doSocialSignUp(url, data)),
    completeSignup: (url, body) =>
      dispatch(paymentConfirmation(url, body, true))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
