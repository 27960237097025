import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { authenticate, signuppage } from '../../Actions/index';
import CONSTANT from '../../../constant';
// import lightImg from '../../../Utils/Assets/timeline-img.png';
// import darkImg from '../../../Utils/Assets/timeline-img-dark.png';
import { Tab } from '../../../Actions/event-setup';

// import Logo from '../../Components/left-logo';

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab
    };
  }
  onChangeTab(tab) {
    console.log('tab', tab);
    this.props.changeTab(tab);
  }
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <div className="about-us-page">
          <div className="container">
            <div className="mb-4">
              <h2 className="text-center heading">About Us</h2>
            </div>
            {/* <div className=" text-center">
              <h3>Our Story</h3>
            </div> */}
            <p className="text-justify">
              Once upon a time, when the world discovered e-readers, indie
              publishing and Goodreads, one reader found her way back to
              romance. Though her favorite books always offered a HEA, the most
              important feature was the alpha hero.
            </p>

            <p className="text-justify">
              The reader went on to read, review and create book-inspired art.
              Her passion for reading romance further evolved and prompted her
              to establish a book group. But juggling life, family, career and
              finding time for her favorite hobby proved to be next to
              impossible. Constantly missing book events, belatedly learning
              about release parties and endlessly trying to find ARC signups, an
              idea for change was in the making.
            </p>

            <p className="text-justify">
              On Target Romance (OTR) bloomed from the idea of a one-stop hub
              for the Romance book community. The wide range of Romance book
              events and the publication journey necessitated the creation of a
              personalized planner to help readers track events, while authors
              and industry professionals utilize tools for networking and
              optimizing productivity, all neatly organized in one place.
            </p>

            <p className="text-justify">
              With OTR organizational tools, members can enjoy what the Romance
              book world has to offer with less effort, and without sacrificing
              time to do what matters the most. Read, write, and network
              seamlessly because your target is our aim. Optimize your book life
              with OTR.
            </p>
            <div className="target-plan">
              <p className="d-inline-block mr-2">
                {' '}
                Target the right plan for you:{' '}
              </p>

              {/* <Link to="/dashboard/membership-types">
            className="btn new-btn-filled  ">
                See Plans{' '}
             
            </Link> */}
              <div
                onClick={() => {
                  this.onChangeTab('plans');
                }}
                className={
                  this.props.activeTab === 'plans'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <a
                  href="#/dashboard/plans"
                  target="_blank"
                  rel="noreferrer"
                  className="btn planBtn new-btn-filled"
                >
                  SEE PLANS{' '}
                </a>
              </div>

              {!localStorage.getItem('token') ? (
                <Link to="/join">
                  <button
                    type="button"
                    className="btn planBtn new-btn-filled  "
                  >
                    Join{' '}
                  </button>
                </Link>
              ) : (
                ''
              )}
            </div>

            <div className="mt-2 social-links">
              <p className="d-inline-block mr-2 ">Follow us on social media:</p>
              <a href={CONSTANT.INSTAGRAM} target="_blank" rel="noreferrer" className="mr-1 ">
                Instagram |
              </a>
              <a href={CONSTANT.FACEBOOK} target="_blank" rel="noreferrer" className="mr-1">
                Facebook |
              </a>
              <a href={CONSTANT.TWITTER_URL} target="_blank" rel="noreferrer" className="mr-1">
                Twitter |
              </a>
              <a href={CONSTANT.YOUTUBE_URL} target="_blank" rel="noreferrer" className="mr-1">
                Youtube{' '}
              </a>
            </div>

            {/* <section className="timeline-section">
              <div className="row">
                <div className="col-12 main-title text-center mb-3">
                  <h3>Timeline</h3>
                  <h5 className="subTitle d-block">
                    Your Romance book life, optimized.
                  </h5>
                </div>
                <div className="col-12 text-center">
                  <div className="divImg">
                    {localStorage.getItem('light') ? (
                      <img
                        src={
                          localStorage.getItem('light') === 'true'
                            ? lightImg
                            : darkImg
                        }
                        alt="Timeline"
                      />
                    ) : (
                      <img src={lightImg} alt="Timeline" />
                    )}
                  </div>
                </div>
              </div>
            </section> */}

            <div className="edit-profile-btn mt-2">
              <a href="/" class="float-md-right">
                <button
                  type="submit"
                  className="btn float-md-right new_btn_save_bt m-0"
                  style={{
                    marginRight: '0% !important'
                  }}
                >
                  Back to Dashboard
                </button>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {}
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => {
  return {
    activeTab: state.Tab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeTab: tab => dispatch(Tab(tab))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
