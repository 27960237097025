import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { css } from '@emotion/core';
import { Table, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import { connect } from 'react-redux';
import {
  signuppage,
  register,
  verifyOTP,
  resendOTP,
  paymentConfirmation,
  verifyPRCode,
  // doSocialSignUp
  GetAllDiscounts
} from '../../Actions/index';
import ConditionsModel from '../Static/conditionsModel';
import { notify } from 'react-notify-toast';
import PaymentModal from './paymentModal';
import PrivacyModal from '../Static/privacyModal';
import CookieModal from '../Static/cookieModal';
import { BASEURL } from '../../constant';
import payment from '../../Utils/Assets/PaymentOptions.png';
import braintree from '../../Utils/Assets/braintree-img.png';

import axios from 'axios';

export class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipType: null,
      showPrivacyModal: false,
      showPaymentModal: false,
      showConditionModal: false,
      // showPrivacyModal: false,
      planCost: 0,
      plansCost: {},
      RealCost: 0,
      prCode: localStorage.getItem('prCode')
        ? localStorage.getItem('prCode')
        : null,
      radioData: '',
      ischeck: false,
      isPrivacyCheck: false,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
      modal7: false,
      modal7: false,
      promoCode: '',
      dataset: '',
      brainTreeDiscounts: [],
      discountAmount: '',
      selected: '',
      allDiscountData: {},
      formCheck: { a:false,b:false}
    };
    this.confirmPayment = this.confirmPayment.bind(this);
    this.togglePaymentModal = this.togglePaymentModal.bind(this);
    this.addClientToken = this.addClientToken.bind(this);
  }

  toggle = () => this.setState({ modal: !this.state.modal });
  toggle1 = () => this.setState({ modal1: !this.state.modal1 });
  toggle2 = () => this.setState({ modal2: !this.state.modal2 });
  toggle3 = () => this.setState({ modal3: !this.state.modal3 });
  toggle4 = () => this.setState({ modal4: !this.state.modal4 });
  toggle5 = () => this.setState({ modal5: !this.state.modal5 });
  toggle6 = () => this.setState({ modal6: !this.state.modal6 });
  toggle7 = () => this.setState({ modal7: !this.state.modal7 });
  toggle8 = () => this.setState({ modal8: !this.state.modal8 });

  onCheck = () => {

    this.setState({ ischeck: !this.state.ischeck });
    if (this.state.ischeck) {
      this.openModal();
    }
  };

  onPrivacyCheck = () => {
    this.setState({ isPrivacyCheck: !this.state.isPrivacyCheck });
    if (this.state.isPrivacyCheck) {
      this.viewPrivacyModal();
    }
  };

  handleRadioChange = event => {
    console.log("this is the --", event.target.value);
    this.planCostHandller(event.target.value);
    this.setState({ selected: event.target.value });
  };

  handlePromoCode = event => {
    this.setState({ promoCode: event.target.value });
  };

  planCostHandller = membershipId => {
    let planDetails = [];
    console.log({ alldetails: this.props.allDetails });

    this.props.allDetails.forEach(_plan => {
      if (Array.isArray(_plan[0])) {
        _plan.forEach(_p => {
          if (Array.isArray(_p[0])) {
            _p.forEach(n => planDetails.push(n));
          } else planDetails.push(_p);
        });
      } else {
        planDetails.push(_plan);
      }
    });

    console.log({ planDetails });

    planDetails = planDetails.find(_plan => {
      if (_plan.length) {
        return _plan[0].membershipId == membershipId;
      }
    });

    const costProp = planDetails.find(_planProp => !!_planProp.planCost);

    const planCost = costProp.launchPrice
      ? costProp.launchPrice
      : costProp.planCost;
    this.setState({
      membershipType: membershipId,
      planCost: planCost,
      RealCost: costProp.planCost
    });
  };

  openModal = () => {
    this.setState({ showConditionModal: true });
  };

  hideModal = () => {
    this.setState({ showConditionModal: false });
  };

  viewPrivacyModal = () => {
    this.setState({ showPrivacyModal: true });
  };

  hidePrivacyModal = () => {
    this.setState({ showPrivacyModal: false });
  };

  confirmPayment(e) {
    e.preventDefault();
    console.log("------>",this.state.formCheck.a , this.state.formCheck.b,this.state.ischeck, this.state.isPrivacyCheck)
    // if (!(this.state.formCheck.a || this.state.formCheck.b || this.state.ischeck || this.state.isPrivacyCheck))
    if (!(this.state.formCheck.a ))
    {
      notify.show('Please agree in order to proceed.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
      return;
    }
    if (!(this.state.formCheck.b ))
    {
      notify.show('Please agree in order to proceed.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
      return;
    }
    if (!(this.state.ischeck))
    {
      notify.show('Please agree in order to proceed.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
      return;
    }
    if (!(this.state.isPrivacyCheck))
    {
      notify.show('Please agree in order to proceed.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
      return;
    }
    // if (this.state.planCost === 0) {
    //   // if( localStorage.getItem('selected_user') )
    //   this.planCostHandller('1');
    // }

    this.togglePaymentModal();

    // this.props.checkout(this.state.membershipType);
  }
  onCookieCheck = () => {
    this.setState({ isccheck: !this.state.isccheck });
    if (this.state.isccheck) {
      this.openCookieModal();
    }
  };
  openCookieModal = () => {
    this.setState({ showCookieModal: true });
  };

  hideCookieModal = () => {
    this.setState({ showCookieModal: false });
  };

  togglePaymentModal() {
    if (this.state.planCost === 0) {
      const local = localStorage.getItem('selected_type');

      if (local === '1') {
        this.planCostHandller('1');
      }
      if (local === '3') {
        this.planCostHandller('6');
      }
      if (local === '5') {
        this.planCostHandller('5');
      }
    }
    this.setState({
      showPaymentModal: !this.state.showPaymentModal
    });
  }

  addClientToken(token) {
    this.props.checkout(this.state.membershipType, token, this.state.promoCode);
    this.setState({ showPaymentModal: false });
  }

  validatePRCode() {
    if (document.getElementById('code').value !== '')
      this.props.validatePR(
        '/api/v1/follow/check/validity/' +
          document.getElementById('code').value,
        document.getElementById('code').value,
        false
      );
    else
      notify.show('Please enter a valid PR code.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
  }

  handlePromoApply = async () => {
    const { promoCode } = this.state;
    this.setState({ dataset: promoCode });
    const payload = {
      promoCode
    };
    try {
      const Promodata = await axios.post(
        BASEURL + '/api/v1/PromoCodeValidate',
        payload
      );

      const { code, message, checkValidPromo } = Promodata.data;
      if (code === 200) {
        this.setState({ discountAmount: checkValidPromo[0].amount });
        this.setState({ allDiscountData: checkValidPromo[0] });
        notify.show(message, 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
      } else {
        this.setState({ discountAmount: '' });
        this.setState({ allDiscountData: '' });

        notify.show(message, 'custom', 2000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleInputCheck = (e, v) => {
    if(v == 'a')
    {
   console.log("A block", e.target.checked);
   this.setState({ formCheck: {...this.state.formCheck, a:e.target.checked}});
    }
    else if (v=='b')
    {    
      console.log("b block", e.target.checked)
      this.setState({ formCheck: {...this.state.formCheck, b:e.target.checked}});
    }
  }

  render() {
    console.log({ selected: this.state.selected });
    const membershipDetails =
      this.state.selected === '1' ? this.props.details[0] : this.props.details;

    let membershipId = null;

    const { discountAmount } = this.state;
    let promoCode = this.state.RealCost - this.state.planCost;
    let promoCode1 = promoCode.toFixed(2);

    let addDiscount = this.state.planCost - discountAmount;
    let addDiscount1 = addDiscount.toFixed(2);
    let addPlanCost1 = this.state.planCost;

    console.log('addPlanCost1', addPlanCost1);
    let addPlanCost = addPlanCost1 ? (addPlanCost1 * 1).toFixed(2) : '';
    // let addPlanCost = parseFloat(
    //   Math.round(this.state.planCost * 100) / 100
    // ).toFixed(2);
    console.log('hiii', promoCode1, addDiscount1, addPlanCost);

    return (
      <div className="">
        <div className="row signupmain payment-page membership-page signup-member-page">
          <div className="main_right">
            <form onSubmit={e => this.confirmPayment(e)}>
              <div className="author_main_s">
                <div className="col-md-12 mt-2">
                  <div className="row-m-5">
                    <fieldset
                      className={
                        this.props.displayMode
                          ? 'scheduler-border border-light'
                          : 'scheduler-border border-dark'
                      }
                    >
                      <legend className="scheduler-border">
                        {console.log("this.props.member====>>>>>>>>>>",this.props.member)}
                        <b>{this.props.member==="PR Firm"?"PR FIRM/BOOKSTORE":this.props.member} Membership Options</b>
                      </legend>
                      <span className="select-one">Select one</span>
                      {console.log(membershipDetails)}
                      {membershipDetails &&
                        membershipDetails.map(result => {
                          if (result.length) {
                            console.log('length', result.length);
                            return result.map(data => {
                              let key = Object.keys(data)[0];
                              let value = Object.values(data)[0];

                              if (key == 'membershipId') {
                                membershipId = value;
                              }
                              if (key == 'membershipId') {
                                this.state.radioData = value;
                              }
                              // if (key == "planCost") {
                              //   this.state.plansCost[membershipId] = value;
                              // }

                              if (Object.keys(data).length !== 0) {
                                if (
                                  key &&
                                  (key.toLowerCase() === 'undefined' ||
                                    key ==
                                      'Publicly display email on profile page?' ||
                                    key === 'membershipId' ||
                                    key == 'emailDisplay' ||
                                    key == 'planCost')
                                ) {
                                  return;
                                }
                                // if (key == "header") {
                                //   return (
                                //     <>
                                //       <input
                                //         type="radio"
                                //         value={membershipId}
                                //         name="gender"
                                //         onChange={this.handleRadioChange.bind(this)}
                                //         required
                                //       />
                                //       <span>
                                //         <b>{value}</b>
                                //       </span>
                                //     </>
                                //   );
                                // } else {
                                //   return (
                                //     <li>
                                //       <span>{value}</span>
                                //     </li>
                                //   );
                                // }
                              }
                            });
                          } else {
                            let key = Object.keys(result)[0];
                            let value = Object.values(result)[0];
                            // if (key == "membershipId") {
                            //   this.state.membershipType = value;
                            // }
                            if (key == 'membershipId') {
                              this.state.radioData = value;
                            }
                            // if (key == "planCost") {
                            //   this.state.planCost = value;
                            // }

                            if (Object.keys(result).length !== 0) {
                              if (
                                key &&
                                (key.toLowerCase() === 'undefined' ||
                                  key.toLowerCase() === 'header' ||
                                  key === 'membershipId' ||
                                  key ==
                                    'Publicly display email on profile page?' ||
                                  key == 'emailDisplay')
                              ) {
                                return;
                              }
                              // if (key == "subheader") {
                              //   return (
                              //     <span>
                              //       <b>{value}</b>
                              //     </span>
                              //   );
                              // } else {
                              //   return (
                              //     <li>
                              //       <span>{value}</span>
                              //     </li>
                              //     // {/* <span> <b>{value[0]}</b></span></li> */}
                              //   );
                              // }
                            }
                          }
                        })}
                      {this.state.radioData == 5 && (
                        <div className="row row-m-10">
                        <Col
                          md="12"
                          sm="12"
                          xs="12"
                          id="scroll author-scroll"
                        >
                          <Table
                            bordered
                            className="mt-3 equal-width "
                            id="table1"
                          >
                            <tr className="text-center">
                              <th id="heading" className="head">
                                <div className="margin">
                                  <span className="main_head">
                                    <span className="pink">
                                      <b>PR FIRM/BOOKSTORE</b>
                                    </span>
                                  </span>
                                </div>
                                <div className="btn1">
                                  <p className="font-weight-bold txt">
                                    <input
                                      type="radio"
                                      value="5"
                                      name={'plan'}
                                      onChange={this.handleRadioChange.bind(
                                        this
                                      )}
                                      checked={this.state.selected === '5'}
                                      required
                                    />{' '}
                                    Launch Special:{' '}
                                    <span className="price_1">$540</span> USD
                                    for 1<sup>st</sup>&nbsp;year
                                    <span className="launch"> </span>
                                    <br />
                                    <span className="usd">
                                      Regular: $720 USD/year
                                    </span>{' '}
                                    <i>
                                      <span
                                        className="usd"
                                        style={{ color: '#FF0000' }}
                                      >
                                        Save $180 USD first year!
                                      </span>
                                    </i>
                                  </p>
                                </div>
                              </th>
                            </tr>

                            {/* <tr className="text-center">
                      <th id="heading" className="head">
                        <div className="margin">
                          <span className="main_head">
                            <input
                              type="radio"
                              value="5"
                              name={"plan"}
                              onChange={this.handleRadioChange.bind(this)}
                              required
                            />{" "}
                            <span className="pink">
                              <b>PR FIRM</b>
                            </span>
                          </span>
                        </div>
                        <div className="btn1">
                          <p className="font-weight-bold txt">
                            <strike className="pink">
                              <span className="black price_1">$720 </span>
                            </strike>
                            <span className="launch">LAUNCH: </span>
                            <span className="price_1">TBD</span>
                            <br />
                            <span className="usd">USD/year</span>
                          </p>
                        </div>
                      </th>
                    </tr> */}
                    
                            <tr>
                              <td>
                                <ul className="text_data">
                                  <li>
                                  Feature any of these services: PR Services, Bookstore, Book boxes, Book signing organizer, Merchandising, Publisher, Swags & Workshops.
                                  </li>
                                  <li>
                                  Add events to the calendar, such as PR tours for clients, giveaways, release parties, book boxes & signings.
                                  </li>
                                  <li>
                                    Provide bloggers easy access to sign-up forms for cover reveals, ARCs, release promo & blog tours.
                                  </li>
                                  {/* <li>
                                    Organize your postings in Daily Tasks.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle2}
                                    ></i>
                                  </li> */}
                                  <li>
                                    {/* Customizable PR profile to highlight PR
                                    services, hosted events, contact &amp;
                                    social media links. */}
                                    Customizable PR profile to highlight services, hosted events, contact & social media links.
                                  </li>
                                  {/* <li>
                                    Network with authors &amp; vendors
                                    (designers, social media consultants etc.){' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle4}
                                    ></i>
                                
                                  </li> */}
                                  <li>
                                    Gain followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle7}
                                    ></i>
                                  </li>
                                  <li>
                                  Earn or purchase Blasts to followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle8}
                                    ></i>
                                  </li>
                                  <li>Organize notes, to-do list & postings in Daily Tasks with reminders.</li>
                                  <li>
                                  Network with authors & vendors (designers, social media consultants etc.)
                                  </li>
                                  <li>
                                    {/* Be featured on the vendor list viewable by
                                    all authors. */}
                                    Get featured on the vendor list viewable by all authors.
                                  </li>
                                  <li>
                                    {/* Indicate on profile/vendor list if new
                                    clients are being accepted. */}
                                    Indicate on profile/vendor list if new clients are being accepted.
                                  </li>
                                  <li>
                                    {/* To secure cancelled bookings with new
                                    clients, register Vendor Cancellation
                                    Openings (VCO), to be featured on Vendor
                                    tab.{' '} */}
                                    Secure canceled bookings with new clients, register Vendor Cancellation Openings (VCO), to get featured on the Vendor tab.{" "}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle5}
                                    ></i>
                                  </li>
                                  <li>
                                    Option to register as a beta reader on a list open to authors.
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle5}
                                    ></i>
                                  </li>
                                  <li>
                                    Full access to all OTR calendar events with easy filter/search functions.
                                  </li>
                                  <li>
                                  Organize your own reading calendar with options to set daily/weekly reminders.
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </Table>
                        </Col>
                        </div>
                      )}
                      {this.state.radioData == 4 && (
                        <div className="row row-m-10">
                        <Col
                          md="12"
                          sm="12"
                          xs="12"
                          id="scroll author-scroll"
                        >
                          <Table
                            bordered
                            className="mt-3 equal-width "
                            id="table1"
                          >
                            <tr className="text-center">
                              <th id="heading" className="head">
                                <div className="margin">
                                  <span className="main_head">
                                    <span className="green">
                                      <b>VENDOR</b>
                                    </span>
                                  </span>
                                </div>
                                <div className="btn1">
                                  <p className="font-weight-bold txt">
                                    {/* <strike className="blue"> */}
                                    <input
                                      type="radio"
                                      value="4"
                                      name={'plan'}
                                      onChange={this.handleRadioChange.bind(
                                        this
                                      )}
                                      checked={this.state.selected === '4'}
                                      required
                                    />{' '}
                                    Launch Special:{' '}
                                    <span className="price_1">$99</span> USD for
                                    1<sup>st</sup>&nbsp;year
                                    {/* </strike> */}
                                    <span className="launch"> </span>
                                    <br />
                                    <span className="usd">
                                      {/* Regular: $120 USD/year  */}
                                      Regular: $149 USD/year 
                                    </span>{' '}
                                    <i>
                                      <span
                                        className="usd"
                                        style={{ color: '#FF0000' }}
                                      >
                                        {/* Save $30 USD first year! */}
                                        Save $50 USD first year!
                                      </span>
                                    </i>
                                  </p>
                                </div>
                              </th>
                              <th id="heading" className="head">
                                <div className="margin">
                                  <span className="main_head">
                                    <span className="pink">
                                      <b>PR FIRM/BOOKSTORE</b>
                                    </span>
                                  </span>
                                </div>
                                <div className="btn1">
                                  <p className="font-weight-bold txt">
                                    {/* <strike className="blue"> */}
                                    <input
                                      type="radio"
                                      value="5"
                                      name={'plan'}
                                      onChange={this.handleRadioChange.bind(
                                        this
                                      )}
                                      // checked={this.state.selected === '5'}
                                      required
                                    />{' '}
                                    Launch Special:{' '}
                                    <span className="price_1">$540</span> USD
                                    for 1<sup>st</sup>&nbsp;year
                                    {/* </strike> */}
                                    <span className="launch"> </span>
                                    <br />
                                    <span className="usd">
                                      Regular: $720 USD/year
                                    </span>{' '}
                                    <i>
                                      <span
                                        className="usd"
                                        style={{ color: '#FF0000' }}
                                      >
                                        Save $180 USD first year!
                                      </span>
                                    </i>
                                  </p>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <ul className="text_data">
                                  <li>
                                    Highlight your services, contact &amp;
                                    social media links on a customized Vendor
                                    page.
                                  </li>
                                  <li>
                                  {/* Offer any of the following services: Agent, Blurb Writer, 
                                  Book Cover Designer, Book Formatter, Book Signing Organizer, 
                                  Cover Model, Editor, Graphic Designer, Personal Assistant (PA), 
                                  Photographer, Proofreader, Publisher, Social Media Consultant, 
                                  Social Media Manager, Web Design, Workshops. */}

                                  Offer any of the following services: Agent, Blurb Writer, Book Boxes, Book Cover Designer,
                                   Book Formatter, Book Signing Organizer, Cover Model, Editor, Graphic Designer,
                                   Merchandising, Personal Assistant (PA), Photographer, Proofreader, Publisher,
                                    Social Media Consultant, Social Media Manager, Swags, Web Design, Workshops.
                                  </li>
                                  <li>
                                    Network with authors &amp; other vendors.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle4}
                                    ></i>
                                  </li>
                                  <li>
                                    Be featured on our vendor list viewable by
                                    all authors.
                                  </li>
                                  <li>
                                    Indicate on profile/vendor list if new
                                    clients are being accepted.
                                  </li>
                                  <li>
                                    To secure canceled bookings with new
                                    clients, register Vendor Cancellation
                                    Openings (VCO), to be featured on the Vendor
                                    tab.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle5}
                                    ></i>
                                  </li>
                                  <li>
                                    Organize your Daily Tasks with daily or
                                    weekly reminder options.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle2}
                                    ></i>
                                  </li>
                                  <li>Promote book boxes & signings in the OTR calendar.</li>
                                  <li>
                                    Option to register as a beta reader on a
                                    list open to authors.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle6}
                                    ></i>
                                  </li>
                                  <li>
                                    Full access to all reader calendar events
                                    with easy filter/search functions.
                                  </li>
                                  <li>
                                    Organize your own reading calendar with
                                    options to set daily/weekly reminders.
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <ul className="text_data">
                                  <li>
                                  Feature any of these services: PR Services, Bookstore, Book boxes, Book signing organizer, Merchandising, Publisher, Swags & Workshops.
                                  </li>
                                  <li>
                                  {/* Add events to the OTR calendar, such as PR tours, giveaways or release parties. */}
                                  Add events to the calendar, such as PR tours for clients, giveaways, release parties, book boxes & signings.
                                  </li>
                                  <li>
                                    {/* Provide bloggers easy access to sign-up
                                    forms for cover reveals, ARCs, release promo
                                    &amp; blog tours. */}

                                      Provide bloggers easy access to sign-up forms for cover reveals, ARCs, release promo & blog tours.
                                  </li>
                                  {/* <li>
                                    Organize your postings in Daily Tasks.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle2}
                                    ></i>
                                  </li> */}
                                  <li>
                                    {/* Customizable PR profile to highlight PR
                                    services, hosted events, contact &amp;
                                    social media links. */}
                                    Customizable PR profile to highlight services, hosted events, contact & social media links.
                                  </li>
                                  {/* <li>
                                    Network with authors &amp; vendors
                                    (designers, social media consultants etc.){' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle4}
                                    ></i>
                                  </li> */}
                                  <li>
                                    Gain followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle7}
                                    ></i>
                                  </li>
                                  <li>
                                    Earn or purchase a Blast to followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle8}
                                    ></i>
                                  </li>
                                   <li>
                                   Organize notes, to-do list & postings in Daily Tasks with reminders.{' '}
                                  
                                  </li>
                                  <li>
                                  Network with authors & vendors (designers, social media consultants etc.)
                                  </li>
                                  <li>
                                    {/* Be featured on the vendor list viewable by
                                    all authors. */}
                                    Get featured on the vendor list viewable by all authors.
                                  </li>
                                  <li>
                                  Indicate on profile/vendor list if new clients are being accepted.
                                  </li>
                                  <li>
                                    {/* To secure cancelled bookings with new
                                    clients, register Vendor Cancellation
                                    Openings (VCO), to be featured on Vendor
                                    tab.{' '} */}
                                    Secure canceled bookings with new clients, register Vendor Cancellation Openings (VCO), to get featured on the Vendor tab. 
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle5}
                                    ></i>
                                  </li>

                                  <li>
                                    {/* Option to register as a beta reader on a
                                    list open to authors. */}
                                    Option to register as a beta reader on a list open to authors
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle5}
                                    ></i>
                                  </li>
                                  <li>
                               
                                    Full access to all OTR calendar events with easy filter/search functions.
                                  </li>
                                  <li>
                                    {/* Organize your own reading calendar with
                                    options to set daily/weekly reminders. */}
                                    Organize your own reading calendar with options to set daily/weekly reminders.
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </Table>
                        </Col>
                        </div>
                      )}
                      {this.state.radioData == 6 && (
                        <div className="row row-m-10">
                        <Col
                          md="12"
                          sm="12"
                          xs="12"
                          id="scroll author-scroll"
                        >
                          <Table
                            bordered
                            className="mt-3 equal-width "
                            id="table1"
                          >
                            <tr className="text-center">
                              <th id="heading" className="head">
                                <div className="margin">
                                  <span className="main_head">
                                    <span className="orange">
                                      <b>BLOGGER</b>
                                    </span>
                                  </span>
                                </div>
                                <div className="btn1">
                                  <p className="font-weight-bold txt">
                                    {/* <strike className="blue"> */}
                                    <input
                                      type="radio"
                                      value="3"
                                      name={'plan'}
                                      onChange={this.handleRadioChange.bind(
                                        this
                                      )}
                                      required
                                    />{' '}
                                    Launch Special:{' '}
                                    <span className="price_1">$49</span> USD for
                                    1<sup>st</sup>&nbsp;year
                                    {/* </strike> */}
                                    <span className="launch"> </span>
                                    <br />
                                    <span className="usd">
                                      {/* Regular: $39 USD/year */}
                                      Regular: $59 USD/year 

                                    </span>{' '}
                                    <i>
                                      <span
                                        className="usd"
                                        style={{ color: '#FF0000' }}
                                      >
                                        {/* Limited time offer! */}
                                        Save $10 USD first year!
                                      </span>
                                    </i>
                                  </p>
                                  {/* <p className="font-weight-bold">
                                  <strike className="orange">
                                    <span className="black price_1">$39 </span>
                                  </strike>
                                  <span className="launch">LAUNCH: </span>
                                  <span className="price_1">$29</span>
                                  <br />
                                  <span className="usd">USD/year</span>
                                </p> */}
                                </div>
                              </th>
                              <th id="heading" className="head">
                                <div className="margin">
                                  <span className="main_head">
                                    <span className="yellow">
                                      <b>BLOGGER PREMIUM</b>
                                    </span>
                                  </span>
                                </div>
                                <div className="btn1">
                                  <p className="font-weight-bold txt">
                                    {/* <strike className="blue"> */}
                                    <input
                                      type="radio"
                                      value="6"
                                      name={'plan'}
                                      onChange={this.handleRadioChange.bind(
                                        this
                                      )}
                                      required
                                      checked={this.state.selected === '6'}
                                    />{' '}
                                    Launch Special:{' '}
                                    <span className="price_1">$59</span> USD for
                                    1<sup>st</sup>&nbsp;year
                                    {/* </strike> */}
                                    <span className="launch"> </span>
                                    <br />
                                    <span className="usd">
                                      {/* Regular: $59 USD/year */}
                                       Regular: $89 USD/year 

                                    </span>{' '}
                                    <i>
                                      <span
                                        className="usd"
                                        style={{ color: '#FF0000' }}
                                      >
                                        {/* Limited time offer! */}
                                        Save $30 USD first year!
                                      </span>
                                    </i>
                                  </p>
                                  {/* <p className="font-weight-bold txt">
                                  <strike className="yellow">
                                    <span className="black price_1">$59 </span>
                                  </strike>
                                  <span className="launch">LAUNCH: </span>
                                  <span className="price_1">$49</span>
                                  <br />
                                  <span className="usd">USD/year</span>
                                </p> */}
                                </div>
                              </th>
                              <th id="heading" className="head">
                                <div className="margin">
                                  <span className="main_head">
                                    <span className="pink">
                                      <b>PR FIRM/BOOKSTORE</b>
                                    </span>
                                  </span>
                                </div>
                                <div className="btn1">
                                  <p className="font-weight-bold txt">
                                    {/* <strike className="blue"> */}
                                    <input
                                      type="radio"
                                      value="5"
                                      name={'plan'}
                                      onChange={this.handleRadioChange.bind(
                                        this
                                      )}
                                      required
                                    />{' '}
                                    Launch Special:{' '}
                                    <span className="price_1">$540</span> USD
                                    for 1<sup>st</sup>&nbsp;year
                                    {/* </strike> */}
                                    <span className="launch"> </span>
                                    <br />
                                    <span className="usd">
                                      Regular: $720 USD/year
                                    </span>{' '}
                                    <i>
                                      <span
                                        className="usd"
                                        style={{ color: '#FF0000' }}
                                      >
                                        Save $180 USD First Year!
                                      </span>
                                    </i>
                                  </p>
                                  {/* <p className="font-weight-bold txt">
                                  <strike className="pink">
                                    <span className="black price_1">$720 </span>
                                  </strike>
                                  <span className="launch">LAUNCH: </span>
                                  <span className="price_1">$540</span>
                                  <br />
                                  <span className="usd">USD/year</span>
                                </p> */}
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <ul className="text_data">
                                  <li>
                                    Exclusive blogger access to all Promo &amp;
                                    ARC sign ups.
                                  </li>
                                  <li>
                                    Featured on the blogger list viewable by all
                                    authors.
                                  </li>
                                  <li>
                                    Quickly filter events specific to your blog.
                                  </li>
                                  <li>
                                    Easy access to links for pre-orders, book
                                    trailers, teasers, playlists.
                                  </li>
                                  <li>
                                    Bookmark links to release parties &amp;
                                    giveaways.
                                  </li>
                                  <li>
                                    Option to register as a beta reader on a
                                    list open to authors.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle6}
                                    ></i>
                                  </li>
                                  <li>
                                    Full access to all calendar events with easy
                                    filter/search functions.
                                  </li>
                                  <li>
                                    Organize your reading calendar with options
                                    to set daily/weekly reminders.
                                  </li>
                                  <li>
                                    Receive notifications when favorite authors
                                    share news &amp; releases.
                                  </li>
                                  <li>
                                    Stay current by quickly viewing new, popular
                                    or featured events.
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <p className="text_data">
                                <i><b>Everything in Blogger plus:</b></i>
                                </p>
                                <ul className="ul text_data">
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Customize Blogger profile page with links to
                                    social media &amp; blog events.
                                    </div>
                                  </li>
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Organize blog postings in Daily Tasks with
                                    the option of reminders.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle2}
                                    ></i>
                                    </div>
                                  </li>
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Add giveaways &amp; release parties to the
                                    OTR calendar.
                                    </div>
                                  </li>
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Gain followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle7}
                                    ></i>
                                    </div>
                                  </li>
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Earn or purchase Blasts to followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle8}
                                    ></i>
                                    </div>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <ul className="text_data">
                                  <li>
                                  Feature any of these services: PR Services, Bookstore, Book boxes, Book signing organizer, Merchandising, Publisher, Swags & Workshops.
                                  </li>
                                  <li>
                                  Add events to the calendar, such as PR tours for clients, giveaways, release parties, book boxes & signings.
                                  </li>
                                  <li>
                                  Provide bloggers easy access to sign-up forms for cover reveals, ARCs, release promo & blog tours.
                                  </li>
                                 
                                  <li>
                                  Customizable PR profile to highlight services, hosted events, contact & social media links.
                                  </li>
                               
                                  <li>
                                    Gain followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle7}
                                    ></i>
                                  </li>
                                  <li>
                                    Earn or purchase a Blast to followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle8}
                                    ></i>
                                  </li>
                                  <li>
                                   Organize notes, to-do list & postings in Daily Tasks with reminders.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle2}
                                    ></i>
                                  </li>
                                  <li>
                                  Network with authors & vendors (designers, social media consultants etc.){' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle4}
                                    ></i>
                                  </li>
                                  <li>
                                  Get featured on the vendor list viewable by all authors.
                                  </li>
                                  <li>
                                  Indicate on profile/vendor list if new clients are being accepted.
                                  </li>
                                  <li>
                                  Secure canceled bookings with new clients, register Vendor Cancellation Openings (VCO), to get featured on the Vendor tab.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle5}
                                    ></i>
                                  </li>
                                  <li>
                                  Option to register as a beta reader on a list open to authors.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle5}
                                    ></i>
                                  </li>
                                  <li>
                                  Full access to all OTR calendar events with easy filter/search functions.
                                  </li>
                                  <li>
                                  Organize your own reading calendar with options to set daily/weekly reminders.
                                  </li>
                                </ul>
                                {/* <p className="text_data">
                                *With our launch we offer 3-month barter
                                membership with PR firms, please contact SueBee:
                                xxx
                              </p> */}
                              </td>
                            </tr>
                          </Table>
                        </Col>
                        </div>
                      )}
                      {this.state.radioData == 2 && (
                        <div className="row row-m-10">
                        <Col
                          md="12"
                          sm="12"
                          xs="12"
                          id="scroll author-scroll"
                        >
                          <Table
                            bordered
                            className="mt-3 equal-width "
                            id="table1"
                          >
                            <tr className="text-center">
                              <th id="heading" className="head">
                                <div className="margin">
                                  <span className="main_head">
                                    <span className="red">
                                      <b>READER</b>
                                    </span>
                                  </span>
                                </div>
                                <div className="btn1">
                                  <p className="font-weight-bold txt">
                                    {/* <strike className="blue"> */}
                                    <input
                                      type="radio"
                                      value="2"
                                      name={'plan'}
                                      onChange={this.handleRadioChange.bind(
                                        this
                                      )}
                                      checked={this.state.selected === '2'}
                                      required
                                    />{' '}
                                    Launch Special:{' '}
                                    <span className="price_1">FREE</span> for 1
                                    <sup>st</sup>&nbsp;year
                                    {/* </strike> */}
                                    <span className="launch"> </span>
                                    <br />
                                    <span className="usd">
                                      {/* Regular: $29 USD/year */}
                                      Regular: $39 USD/year 

                                    </span>{' '}
                                    <i>
                                      <span
                                        className="usd"
                                        style={{ color: '#FF0000' }}
                                      >
                                        {/* Limited time offer! */}
                                        Save $39 USD first year!
                                      </span>
                                    </i>
                                  </p>
                                </div>
                              </th>
                              <th id="heading" className="head">
                                <div className="margin">
                                  <span className="main_head">
                                    <span className="orange">
                                      <b>BLOGGER</b>
                                    </span>
                                  </span>
                                </div>
                                <div className="btn1">
                                  <p className="font-weight-bold txt">
                                    {/* <strike className="blue"> */}
                                    <input
                                      type="radio"
                                      value="3"
                                      name={'plan'}
                                      onChange={this.handleRadioChange.bind(
                                        this
                                      )}
                                      checked={this.state.selected === '3'}
                                      required
                                    />{' '}
                                    Launch Special:{' '}
                                    <span className="price_1">$49</span> USD for
                                    1<sup>st</sup>&nbsp;year
                                    {/* </strike> */}
                                    <span className="launch"> </span>
                                    <br />
                                    <span className="usd">
                                      {/* Regular: $39 USD/year */}
                                      Regular: $59 USD/year 
                                    </span>{' '}
                                    <i>
                                      <span
                                        className="usd"
                                        style={{ color: '#FF0000' }}
                                      >
                                        Save $10 USD first year!
                                      </span>
                                    </i>
                                  </p>
                                </div>
                              </th>
                              <th id="heading" className="head">
                                <div className="margin">
                                  <span className="main_head">
                                    <span className="yellow">
                                      <b>BLOGGER PREMIUM</b>
                                    </span>
                                  </span>
                                </div>
                                <div className="btn1">
                                  <p className="font-weight-bold txt">
                                    {/* <strike className="blue"> */}
                                    <input
                                      type="radio"
                                      value="6"
                                      name={'plan'}
                                      onChange={this.handleRadioChange.bind(
                                        this
                                      )}
                                      checked={this.state.selected === '6'}
                                      required
                                    />{' '}
                                    Launch Special:{' '}
                                    <span className="price_1">$59</span> USD for
                                    1<sup>st</sup>&nbsp;year
                                    {/* </strike> */}
                                    <span className="launch"> </span>
                                    <br />
                                    <span className="usd">
                                      {/* Regular: $59 USD/year */}
                                       Regular: $89 USD/year 
                                    </span>{' '}
                                    <i>
                                      <span
                                        className="usd"
                                        style={{ color: '#FF0000' }}
                                      >
                                        Save $30 USD first year!
                                      </span>
                                    </i>
                                  </p>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <ul className="text_data">
                                  <li>
                                    Full access to all reader calendar events
                                    with easy filter/search functions.
                                  </li>
                                  <li>
                                    Organize your reading calendar with options
                                    to set daily/weekly reminders.
                                  </li>
                                  <li>
                                    Customize your OTR reader calendar by adding
                                    & saving events you wish to follow.
                                  </li>
                                  <li>
                                    Quickly find links to pre-orders, book
                                    trailers, teasers, playlists.
                                  </li>
                                  <li>
                                    Bookmark links to release parties &
                                    giveaways.
                                  </li>
                                  <li>
                                    Option to register as a beta reader on a
                                    list open to authors.
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle6}
                                    ></i>
                                  </li>
                                  <li>
                                    Access ARC sign-ups available to all
                                    readers.
                                  </li>
                                  <li>
                                    Receive notifications when favorite authors
                                    share releases & news.
                                  </li>
                                  <li>
                                    Stay current by quickly viewing new, popular
                                    or featured events.
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <ul className="text_data">
                                  <li>
                                    Exclusive blogger access to all Promo & ARC
                                    sign ups.
                                  </li>
                                  <li>
                                    Featured on the blogger list viewable by all
                                    authors.
                                  </li>
                                  <li>
                                    Quickly filter events specific to your blog.
                                  </li>
                                  <li>
                                    Easy access to links for pre-orders, book
                                    trailers, teasers, playlists.
                                  </li>
                                  <li>
                                    Bookmark links to release parties &
                                    giveaways.
                                  </li>
                                  <li>
                                    Option to register as a beta reader on a
                                    list open to authors.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle6}
                                    ></i>
                                  </li>
                                  <li>
                                    Full access to all calendar events with easy
                                    filter/search functions.
                                  </li>
                                  <li>
                                    Organize your reading calendar with options
                                    to set daily/weekly reminders.
                                  </li>
                                  <li>
                                    Receive notifications when favorite authors
                                    share news &amp; releases.
                                  </li>
                                  <li>
                                    Stay current by quickly viewing new, popular
                                    or featured events.
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <p className="text_data">
                                <i><b>Everything in Blogger plus:</b></i>
                                </p>
                                <ul className="ul text_data">
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Customize Blogger profile page with links to
                                    social media &amp; blog events.
                                    </div>
                                  </li>
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Organize blog postings in Daily Tasks with
                                    the option of reminders.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle2}
                                    ></i>
                                    </div>
                                  </li>
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Add giveaways &amp; release parties to the OTR
                                    calendar.
                                    </div>
                                  </li>
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Gain followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle7}
                                    ></i>
                                    </div>
                                  </li>
                                  <li className="mt-2 mb-3">
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    <div>
                                    Earn or purchase Blasts to followers.{' '}
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                      onClick={this.toggle8}
                                    ></i>
                                    </div>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </Table>
                        </Col>
                        </div>
                      )}
                      {this.state.radioData == 8 && (
                        <div className="row row-m-10">
                          <Col md="6" sm="12" xs="12" id="scroll author-scroll">
                            <Table
                              bordered
                              className="mt-3 equal-width"
                              id="table1"
                            >
                              <tr className="text-center">
                                <th id="heading" className="head">
                                  <div className="margin">
                                    <span className="main_head">
                                      <span className="blue">
                                        <b>AUTHOR</b>
                                      </span>
                                    </span>
                                  </div>
                                  <div className="btn1">
                                    <p className="font-weight-bold txt">
                                      {/* <strike className="blue"> */}
                                      <input
                                        type="radio"
                                        value="8"
                                        name={'plan'}
                                        onChange={this.handleRadioChange.bind(
                                          this
                                        )}
                                        required
                                      />{' '}
                                      Launch Special:{' '}
                                      <span className="price_1"> $260</span> USD
                                      for 1<sup>st</sup>&nbsp;year
                                      {/* </strike> */}
                                      <span className="launch"> </span>
                                      <br />
                                      <span className="usd">
                                        Regular:  $390 USD/year
                                      </span>{' '}
                                      <i>
                                        <span
                                          className="usd"
                                          style={{ color: '#FF0000' }}
                                        >
                                          Save  $130  USD first year!
                                        </span>
                                      </i>
                                    </p>
                                  </div>
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <ul className="text_data">
                                    <li>
                                      Add three book releases per membership
                                      year with add-on option at $160 USD per
                                      additional release.
                                    </li>
                                    <li>
                                      Customize Author profile page with links
                                      to all your calendar events, social media
                                      &amp; contact details.
                                    </li>
                                    <li>
                                    3-in-1: Promote using Author/PR functions, network
                            &amp; organize your own reading calendar with daily
                            or weekly reminder options.
                                    </li>
                                    <li>
                                      Add &amp;&nbsp; manage book releases,
                                      release parties &amp; giveaways.
                                    </li>
                                    <li>
                                      Bookmark links for pre-orders, book
                                      trailers, playlists &amp; teasers.
                                    </li>
                                    <li>
                                      Free release day Blast to followers with
                                      option to earn or purchase additional
                                      Blasts.{' '}
                                      <i
                                        class="fa fa-info-circle"
                                        aria-hidden="true"
                                        onClick={this.toggle}
                                      ></i>
                                    </li>
                                    <li>
                                      Provide bloggers easy access to sign-up
                                      forms for PR opportunities such as cover
                                      reveals, ARCs, release promo &amp; blog
                                      tours.
                                    </li>
                                    <li>
                                      Connect with beta readers &amp; extend ARC
                                      signups to all readers.
                                    </li>
                                    <li>
                                      Access to list of all Blogger and Blogger
                                      Premium members.
                                    </li>
                                    <li>
                                      Increase exposure by gaining followers.{' '}
                                      <i
                                        class="fa fa-info-circle"
                                        aria-hidden="true"
                                        onClick={this.toggle1}
                                      ></i>
                                    </li>
                                    <li>
                                      Organize Daily Tasks with reminder
                                      options.{' '}
                                      <i
                                        class="fa fa-info-circle"
                                        aria-hidden="true"
                                        onClick={this.toggle2}
                                      ></i>
                                    </li>
                                    <li>
                                      Track your writing goals.{' '}
                                      <i
                                        class="fa fa-info-circle"
                                        aria-hidden="true"
                                        onClick={this.toggle3}
                                      ></i>
                                    </li>
                                    <li>
                                    Network with vendors (cover designers, editors etc.), PR firms, bookstores & other authors.{' '}
                                      <i
                                        class="fa fa-info-circle"
                                        aria-hidden="true"
                                        onClick={this.toggle4}
                                      ></i>
                                    </li>
                                    <li>
                                      Easily check for last-minute Vendor
                                      Cancellation Openings to reserve services
                                      with previously fully-booked vendors.{' '}
                                      <i
                                        class="fa fa-info-circle"
                                        aria-hidden="true"
                                        onClick={this.toggle5}
                                      ></i>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </Table>
                          </Col>
                          <Col md="6" sm="12" xs="12" id="scroll author-scroll">
                            <Table
                              bordered
                              className="mt-3 equal-width"
                              id="table1"
                            >
                              <tr className="text-center">
                                <th id="heading" className="head">
                                  <div className="margin">
                                    <span className="main_head">
                                      <span className="purple">
                                        <b>AUTHOR PREMIUM</b>
                                      </span>
                                    </span>
                                  </div>

                                  <div className="btn1">
                                    <p className="font-weight-bold txt">
                                      <input
                                        type="radio"
                                        value="1"
                                        name={'plan'}
                                        onChange={this.handleRadioChange.bind(
                                          this
                                        )}
                                        checked={this.state.selected === '1'}
                                        required
                                      />{' '}
                                      Launch Special:{' '}
                                      <span className="price_1"> $390 </span> USD
                                      for 1<sup>st</sup>&nbsp;year
                                      {/* </strike> */}
                                      <span className="launch"> </span>
                                      <span className="price_1"></span>
                                      <br />
                                      <span className="usd">
                                        Regular:  $580 USD/year
                                      </span>{' '}
                                      <i>
                                        <span
                                          className="usd"
                                          style={{ color: '#FF0000' }}
                                        >
                                          Save  $190 USD first year!
                                        </span>
                                      </i>
                                    </p>
                                  </div>
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <p className="text_data">
                                    <i><b>Everything in Author plus:</b></i>
                                  </p>
                                  <ul className="ul text_data">
                                    <li className="mt-2">
                                      <i
                                        className="fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      <div>
                                      Add <i>unlimited</i> book releases to the
                                      OTR calendar.
                                      </div>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </Table>
                          </Col>
                        </div>
                      )}{' '}
                    </fieldset>
                    {/* </ul> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 member-consent-section promo-code-section col-p-10">
                    <fieldset
                      className={
                        this.props.displayMode
                          ? 'scheduler-border border-light'
                          : 'scheduler-border border-dark'
                      }
                    >
                      <legend className="scheduler-border">
                        <b>Member Consent</b>
                      </legend>
                      {membershipDetails &&
                        membershipDetails.map(result => {
                          if (result.length) {
                            return result.map(membershipDetails => {
                              let key = Object.keys(membershipDetails)[0];
                              let value = Object.values(membershipDetails)[0];

                              if (key == 'membershipId') {
                                this.state.radioData = value;
                              }
                            });
                          }
                        })}
                      <div className="row">
                        <Col md="12" sm="12" xs="12" id="scroll">
                          <div class="form-check mb-2 mr-sm-2">
                            <div class="mb-2 check-box">
                              <input
                                type="checkbox"
                                name="acknowledge"
                                className="agreeTerms"
                                id="old"
                                // required
                                // oninvalid="this.setCustomValidity('Please check box before proceed...')"
                                // oninvalid="this.setCustomValidity('Please Enter valid email')"
                                // oninput="setCustomValidity('')"
                                onChange={(e) => {this.handleInputCheck(e, 'a')}}
                              />
                              <label
                                for="old"
                                style={{ display: 'inline' }}
                                class="form-check-label ml-2"
                              >
                                {' '}
                                I am 18 years of age or older. I acknowledge OTR
                                may have adult themes and adult content
                                including, but not limited to, sexual
                                situations, violence, dubious or non-consent
                                and/or drug/alcohol use.{' '}
                              </label>
                            </div>

                            <div class="mb-2">
                              <input
                                type="checkbox"
                                name="acknowledge"
                                className="agreeTerms"
                                id="acknowledge"
                                onChange={(e) => {this.handleInputCheck(e, 'b')}}
                                // required
                              />
                              <label
                                for="acknowledge"
                                style={{ display: 'inline' }}
                                class="form-check-label ml-2"
                              >
                                {' '}
                                I understand that all money paid to OTR is
                                non-refundable.
                              </label>
                            </div>

                            <div class="mb-2" onClick={this.openModal}>
                              <input
                                type="checkbox"
                                name="acknowledge"
                                className="agreeTerms"
                                id="terms"
                                checked={this.state.ischeck}
                                // required
                              />
                              <label
                                for="terms"
                                style={{ display: 'inline' }}
                                class="form-check-label ml-2"
                              >
                                I agree{' '}
                                <strong
                                  // onClick={this.openModal}
                                  className="mousechange"
                                >
                                  {' '}
                                  <Link to={`/condition`} target="_blank">
                                    <u> {'OTR Terms & Conditions'}</u>
                                  </Link>
                                </strong>
                              </label>
                            </div>

                            <div class="mb-2">
                              <input
                                type="checkbox"
                                name="acknowledge"
                                className="agreeTerms"
                                id="terms"
                                checked={this.state.isPrivacyCheck}
                                // required
                                onClick={this.viewPrivacyModal}
                              />
                              <label
                                for="terms"
                                style={{ display: 'inline' }}
                                class="form-check-label ml-2"
                              >
                                I agree{' '}
                                <strong
                                  // onClick={this.openPrivacyModal}
                                  className="mousechange"
                                >
                                  {' '}
                                  <Link to={`/privacy`} target="_blank">
                                    <u> {'OTR Privacy Policy'}</u>{' '}
                                  </Link>
                                </strong>
                              </label>
                            </div>
                            {/* <div className="mt-5" style={{ fontSize: "0.7rem" }}>
                      <span>
                        <b>
                          <i
                            class="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>{" "}
                          Disclaimer:{" "}
                        </b>
                        OTR uses a third-party site for payment. OTR does not
                        collect or store member payment information or personal
                        member data other than the email and country used for
                        membership enrollment. Location is asked to help us
                        offer better services based on various regions. A
                        member’s username is permanent, but email and password
                        can be changed at any time.
                      </span>
                    </div> */}
                          </div>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset
                      className={
                        this.props.displayMode
                          ? 'scheduler-border border-light'
                          : 'scheduler-border border-dark'
                      }
                    >
                      <legend className="scheduler-border">
                        <b>Promo Code</b>
                      </legend>
                      {membershipDetails &&
                        membershipDetails.map(result => {
                          if (result.length) {
                            return result.map(membershipDetails => {
                              let key = Object.keys(membershipDetails)[0];
                              let value = Object.values(membershipDetails)[0];

                              if (key == 'membershipId') {
                                this.state.radioData = value;
                              }
                            });
                          }
                        })}

                      <div className="row">
                        <Col md="12" sm="12" xs="12" id="scroll">
                          <div class="form-check pl-0">
                            <div className="promo-code">
                              <button
                                type="button"
                                className="btn bg-btn"
                                onClick={this.handlePromoApply.bind(this)}
                              >
                                Apply
                              </button>
                              <input
                                type="text"
                                value={this.state.promoCode}
                                name="promoCode"
                                onChange={this.handlePromoCode.bind(this)}
                              />
                            </div>
                          </div>
                          <p>
                            <i>
                              Promo Codes are valid for one-time use only.
                              Renewal will be at the regular membership rate at
                              time of renewal. Offer may not be combined with
                              any other promo codes, coupons, discounts, offers,
                              or promotions.
                            </i>
                          </p>
                        </Col>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-6 auto-renewal-section member-purchase-section col-p-10">
                    <fieldset
                      className={
                        this.props.displayMode
                          ? 'scheduler-border border-light'
                          : 'scheduler-border border-dark'
                      }
                    >
                      <legend className="scheduler-border">
                        <b>Auto Renewal</b>
                      </legend>
                      {membershipDetails &&
                        membershipDetails.map(result => {
                          if (result.length) {
                            return result.map(membershipDetails => {
                              let key = Object.keys(membershipDetails)[0];
                              let value = Object.values(membershipDetails)[0];

                              if (key == 'membershipId') {
                                this.state.radioData = value;
                              }
                            });
                          }
                        })}
                      <div className="row  auto-renewal">
                        <Col md="12" sm="12" xs="12" id="scroll">
                          <div className="auto-renewal-input">
                            <span>Auto renewal</span>
                            <span>Yes</span> {''}
                            <input
                              type="radio"
                              value="3"
                              name={'authorenewal'}
                              onChange={this.handleRadioChange.bind(this)}
                              checked={true}
                              required
                            />{' '}
                            <span>No</span> {''}
                            <input
                              type="radio"
                              value="3"
                              name={'authorenewal'}
                              onChange={this.handleRadioChange.bind(this)}
                              required
                            />{' '}
                          </div>
                          <p>
                           { `You can update auto-renewal ${(this.state.selected === '8' || this.state.selected === '1') ? 'and/or upgrade membership' : ''} on Membership Info page in Settings.`}
                          </p>
                          <p>
                            Disclaimer:
                            <i>
                              On the day of one-year membership expiration you
                              will auto-renew at the regular membership rate at
                              the time of renewal
                            </i>
                          </p>
                        </Col>
                      </div>
                    </fieldset>
                    {this.state.dataset ? (
                      <fieldset
                        className={
                          this.props.displayMode
                            ? 'scheduler-border border-light'
                            : 'scheduler-border border-dark'
                        }
                      >
                        <legend className="scheduler-border">
                          <b>Purchase</b>
                        </legend>
                        {membershipDetails &&
                          membershipDetails.map(result => {
                            if (result.length) {
                              return result.map(membershipDetails => {
                                let key = Object.keys(membershipDetails)[0];
                                let value = Object.values(membershipDetails)[0];

                                if (key == 'membershipId') {
                                  this.state.radioData = value;
                                }
                              });
                            }
                          })}
                        <div className="row">
                          <Col md="12" sm="12" xs="12" id="scroll">
                            {this.state.selected === '8' && (
                              <div className="purchase-section">
                                <p>
                                  Author Standard Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
                                  Membership Total, 1<sup></sup>t year{' '}
                                  </p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '1' && (
                              <div className="purchase-section">
                                <p>
                                  Author Premium Membership, 1 year{' '}  
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
                                    Membership Total, 1<sup>st</sup>&nbsp;year{' '}
                                  </p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}

                            {this.state.selected === '2' && (
                              <div className="purchase-section">
                                <p>
                                  Reader Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Reader Membership $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
                                    Membership Total, 1<sup>st</sup>&nbsp;year{' '}
                                  </p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '3' && (
                              <div className="purchase-section">
                                <p>
                                  Blogger Premium Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Blogger Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
                                    Membership Total, 1<sup>st</sup>&nbsp;year{' '}
                                  </p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '4' && (
                              <div className="purchase-section">
                                <p>
                                  Vendor Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Vendor Membership $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
                                    Membership Total, 1<sup>st</sup>&nbsp;year{' '}
                                  </p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '5' && (
                              <div className="purchase-section">
                                <p>
                                  Author Premium Membership, 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                <p>
                                    Membership Total, 1<sup>st</sup>&nbsp;year{' '}
                                  </p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '6' && (
                              <div className="purchase-section">
                                <p>
                                  Blogger Premium Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Blogger Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
                                    Membership Total, 1<sup>st</sup>&nbsp;year{' '}
                                  </p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                          </Col>
                        </div>
                      </fieldset>
                    ) : (
                      <fieldset
                        className={
                          this.props.displayMode
                            ? 'scheduler-border border-light'
                            : 'scheduler-border border-dark'
                        }
                      >
                        <legend className="scheduler-border">
                          <b>Purchase</b>
                        </legend>
                        {membershipDetails &&
                          membershipDetails.map(result => {
                            if (result.length) {
                              return result.map(membershipDetails => {
                                let key = Object.keys(membershipDetails)[0];
                                let value = Object.values(membershipDetails)[0];

                                if (key == 'membershipId') {
                                  this.state.radioData = value;
                                }
                              });
                            }
                          })}
                        <div className="row">
                          <Col md="12" sm="12" xs="12" id="scroll">
                            {this.state.selected === '8' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Author Standard Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
                                    Membership Total, 1<sup>st</sup>&nbsp;year{' '}
                                  </p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}

                              {console.log("this cart for the ",this.state.RealCost)}
   
                            {this.state.selected === '1' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Author Premium Membership, 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
      Membership Total, 1<sup>st</sup>&nbsp;year{' '}
</p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}

                            {this.state.selected === '2' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Reader Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Reader Membership $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
      Membership Total, 1<sup>st</sup>&nbsp;year{' '}
</p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '3' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Blogger Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Blogger Membership $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
      Membership Total, 1<sup>st</sup>&nbsp;year{' '}
</p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '4' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Vendor Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Vendor Membership $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
      Membership Total, 1<sup>st</sup>&nbsp;year{' '}
</p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '5' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Author Premium Membership, 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
      Membership Total, 1<sup>st</sup>&nbsp;year{' '}
</p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '6' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Blogger Premium Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Blogger Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1<sup>st</sup>&nbsp;year
                                  </i>
                                  <span>${promoCode1}</span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  <p>
      Membership Total, 1<sup>st</sup>&nbsp;year{' '}
</p>
                                  <p>
                                    <span>
                                      $
                                      {discountAmount
                                        ? addDiscount1
                                        : addPlanCost}
                                    </span>
                                  </p>
                                </h6>
                              </div>
                            )}
                          </Col>
                        </div>
                      </fieldset>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 mt-2 promo-code-section col-p-10">
                    <fieldset
                      className={
                        this.props.displayMode
                          ? 'scheduler-border border-light'
                          : 'scheduler-border border-dark'
                      }
                    >
                      <legend className="scheduler-border">
                        <b>Promo Code</b>
                      </legend>
                      {membershipDetails &&
                        membershipDetails.map(result => {
                          if (result.length) {
                            return result.map(membershipDetails => {
                              let key = Object.keys(membershipDetails)[0];
                              let value = Object.values(membershipDetails)[0];

                              if (key == 'membershipId') {
                                this.state.radioData = value;
                              }
                            });
                          }
                        })}

                      <div className="row">
                        <Col md="12" sm="12" xs="12" id="scroll">
                          <div class="form-check pl-0">
                            <div className="promo-code">
                              <button
                                type="button"
                                className="btn bg-btn"
                                onClick={this.handlePromoApply.bind(this)}
                              >
                                Apply
                              </button>
                              <input
                                type="text"
                                value={this.state.promoCode}
                                name="promoCode"
                                onChange={this.handlePromoCode.bind(this)}
                              />
                            </div>
                          </div>
                          <p>
                            <i>
                              Promo codes are valid for one time use only.
                              Renewal will be at the regular membership rate at
                              the time of renewal. Offer may not be combined
                              with any other promo codes, coupons, discounts,
                              offers, or promotions.
                            </i>
                          </p>
                        </Col>
                      </div>
                    </fieldset>
                  </div>
                  {this.state.dataset ? (
                    <div className="col-md-6 mt-2 member-purchase-section col-p-10">
                      <fieldset
                        className={
                          this.props.displayMode
                            ? 'scheduler-border border-light'
                            : 'scheduler-border border-dark'
                        }
                      >
                        <legend className="scheduler-border">
                          <b>Purchase</b>
                        </legend>
                        {membershipDetails &&
                          membershipDetails.map(result => {
                            if (result.length) {
                              return result.map(membershipDetails => {
                                let key = Object.keys(membershipDetails)[0];
                                let value = Object.values(membershipDetails)[0];

                                if (key == 'membershipId') {
                                  this.state.radioData = value;
                                }
                              });
                            }
                          })}
                        <div className="row">
                          <Col md="12" sm="12" xs="12" id="scroll">
                            {this.state.selected === '8' && (
                              <div className="purchase-section">
                                <p>
                                  Author Standard Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}

                            {console.log({ selecte: this.state.selected })}

                            {this.state.selected === '1' && (
                              <div className="purchase-section">
                                <p>
                                  Author Premium Membership, 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}

                            {this.state.selected === '2' && (
                              <div className="purchase-section">
                                <p>
                                  Reader Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Reader Membership $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '3' && (
                              <div className="purchase-section">
                                <p>
                                  Blogger Premium Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Blogger Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '4' && (
                              <div className="purchase-section">
                                <p>
                                  Vendor Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Vendor Membership $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '5' && (
                              <div className="purchase-section">
                                <p>
                                  Author Premium Membership, 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '6' && (
                              <div className="purchase-section">
                                <p>
                                  Blogger Premium Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Blogger Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                          </Col>
                        </div>
                      </fieldset>
                    </div>
                  ) : (
                    <div className="col-md-6 mt-2 member-purchase-section col-p-10">
                      <fieldset
                        className={
                          this.props.displayMode
                            ? 'scheduler-border border-light'
                            : 'scheduler-border border-dark'
                        }
                      >
                        <legend className="scheduler-border">
                          <b>Purchase</b>
                        </legend>
                        {membershipDetails &&
                          membershipDetails.map(result => {
                            if (result.length) {
                              return result.map(membershipDetails => {
                                let key = Object.keys(membershipDetails)[0];
                                let value = Object.values(membershipDetails)[0];

                                if (key == 'membershipId') {
                                  this.state.radioData = value;
                                }
                              });
                            }
                          })}
                        <div className="row">
                          <Col md="12" sm="12" xs="12" id="scroll">
                            {this.state.selected === '8' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Author Standard Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}

                            {console.log({ selecte: this.state.selected })}

                            {this.state.selected === '1' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Author Premium Membership, 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}

                            {this.state.selected === '2' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Reader Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Reader Membership $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '3' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Blogger Premium Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Blogger Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '4' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Vendor Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Vendor Membership $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '5' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Author Premium Membership, 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Author Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                            {this.state.selected === '6' && (
                              <div className="purchase-sectionnnnn">
                                <p>
                                  Blogger Premium Membership 1 year{' '}
                                  <span>${this.state.RealCost}</span>
                                </p>
                                <h5>
                                  <i>
                                    Launch Promo Discount: Blogger Premium $
                                    {this.state.RealCost - this.state.planCost}{' '}
                                    OFF 1st year
                                  </i>
                                  <span>
                                    ${this.state.RealCost - this.state.planCost}
                                  </span>
                                </h5>
                                {discountAmount && (
                                  <h5>
                                    <i> Promo Code discount </i>
                                    <span>${discountAmount}</span>
                                  </h5>
                                )}
                                <h6>
                                  Membership Total, 1st year{' '}
                                  <span>
                                    <span>
                                      $
                                      {discountAmount
                                        ? this.state.planCost - discountAmount
                                        : this.state.planCost}
                                    </span>
                                  </span>
                                </h6>
                              </div>
                            )}
                          </Col>
                        </div>
                      </fieldset>
                    </div>
                  )}
                </div>
              </div> */}

              {/* <div class="form-check mb-2 mr-sm-2">
            <div class="mb-2">
              <input
                type="checkbox"
                name="acknowledge"
                className="agreeTerms"
                id="terms"
                onClick={this.onCheck}
                required
              />
              <label
                for="terms"
                style={{ display: "inline" }}
                class="form-check-label ml-2"
              >
                I have read and agreed to the{" "}
                <strong onClick={this.openModal} className="mousechange">
                  <u> {"Terms of use and privacy policy."}</u>
                </strong>
              </label>
            </div>

            <div class="mb-2">
              <input
                type="checkbox"
                name="acknowledge"
                className="agreeTerms"
                id="terms"
                onClick={this.onPrivacyCheck}
                required
              />
              <label
                for="terms"
                style={{ display: "inline" }}
                class="form-check-label ml-2"
              >
                I have agreed to the{" "}
                <strong onClick={this.viewPrivacyModal} className="mousechange">
                  <u> {"privacy policy."}</u>
                </strong>
              </label>
            </div>

            <div class="mb-2">
              <input
                type="checkbox"
                name="acknowledge"
                className="agreeTerms"
                id="old"
                required
              />
              <label
                for="old"
                style={{ display: "inline" }}
                class="form-check-label ml-2"
              >
                {" "}
                I am 18 years old.{" "}
              </label>
            </div>

            <div class="mb-2">
              <input
                type="checkbox"
                name="acknowledge"
                className="agreeTerms"
                id="acknowledge"
                required
              />
              <label
                for="acknowledge"
                style={{ display: "inline" }}
                class="form-check-label ml-2"
              >
                {" "}
                I acknowledge that OTR content includes books that may have
                adult themes and adult content including, but not limited to,
                sexual situations, violence, dubious or non-consent and/or
                drug/alcohol use
              </label>
            </div>
          </div> */}

              <ConditionsModel
                isOpen={this.state.showConditionModal}
                check={this.onCheck}
                toggle={this.hideModal.bind(this)}
              />

              <PrivacyModal
                isOpen={this.state.showPrivacyModal}
                cross={this.onPrivacyCheck}
                toggle={this.hidePrivacyModal.bind(this)}
              />
              <CookieModal
                isOpen={this.state.showCookieModal}
                toggle={this.hideCookieModal.bind(this)}
              />
              <PaymentModal
                isOpen={this.state.showPaymentModal}
                addClientToken={this.addClientToken}
                planCost={
                  this.state.discountAmount
                    ? this.state.planCost - this.state.discountAmount
                    : this.state.planCost
                }
                toggle={this.togglePaymentModal.bind(this)}
                promoCode={this.state.promoCode}
                allDiscountData={this.state.allDiscountData}
              />

              <div></div>
              <div className="thanktwo_main">
                {/* <form className="form_custom"> */}
                <div className="form-group">
                  <button
                    className="btn button_jon_sign"
                    style={{ float: 'left' }}
                    onClick={() => {
                      this.props.goBack(3);
                    }}
                    type="button"
                  >
                    <img
                      src={require('../../Utils/Assets/aerrow_left.png')}
                      alt="join"
                    />{' '}
                    Go Back
                  </button>
                  {/* <button type="button" className="btn btn-filled pull-right" onClick={this.confirmPayment}>PAY NOW</button> */}
                  <div className="membership-btn">
                    <div className="otr-text">
                      OTR uses third-party sites for payment. OTR does not
                      collect nor store member payment information.
                    </div>
                    <img className="braintree" src={braintree} alt="payment" />
                    <img src={payment} alt="payment" />
                    <input
                      type="submit"
                      className="btn btn-filled pull-right"
                      value="PAY NOW"
                    />
                  </div>
                </div>
                {/* </form> */}
              </div>
            </form>

            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal1"
            >
              <ModalHeader toggle={this.toggle} className="eventModal">
                BLASTS (AUTHORS)
              </ModalHeader>
              <ModalBody>
                <ol>
                  <li start="1">
                    Send a FREE release day Blast to all followers and members
                    who added the book release to their calendar.
                  </li>
                  <li className="mt-2">
                    Earn a free Blast upon earning your first 500 followers &
                    one free Blast for every additional 500 followers.
                  </li>
                  <li className="mt-2">
                    Additional Blasts to followers can be purchased, but limited
                    to a maximum of one per month.
                  </li>
                </ol>
                <p>*** subject to change.</p>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modal1}
              toggle={this.toggle1}
              className="modal1"
            >
              <ModalHeader toggle={this.toggle1} className="eventModal">
                FOLLOWERS (AUTHORS)
              </ModalHeader>
              <ModalBody>
                <p>
                  An exclusive “invite code” will be generated after sign-up to
                  share with your fan base. When an invite code is redeemed upon
                  member sign up, you will automatically earn that follower.{' '}
                </p>
                <ol>
                  <li start="1">
                    Increase visibility on the calendar by gaining followers.
                  </li>
                  <li className="mt-2">
                    Send FREE release day Blast to all followers.
                  </li>
                  <li className="mt-2">
                    Reach followers with earned and purchased Blasts.
                  </li>
                </ol>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modal2}
              toggle={this.toggle2}
              className="modal1"
            >
              <ModalHeader toggle={this.toggle2} className="eventModal">
                DAILY TASKS
              </ModalHeader>
              <ModalBody>
                <p>
                  Daily Tasks (with reminders) is a private calendar for
                  organizing daily tasks with notes, links and reminders. Create
                  a to-do-list, save posting data or track writing goals.
                </p>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modal3}
              toggle={this.toggle3}
              className="modal1"
            >
              <ModalHeader toggle={this.toggle3} className="eventModal">
                WRITING GOALS
              </ModalHeader>
              <ModalBody>
                <p>
                  Use the Daily Tasks notes feature with reminders to keep track
                  of writing goals and to help stay organized.
                </p>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modal4}
              toggle={this.toggle4}
              className="modal1"
            >
              <ModalHeader toggle={this.toggle4}>
                NETWORK WITH VENDORS
              </ModalHeader>
              <ModalBody>
                <p>
                  In addition to the reader calendar accessible to all members,
                  OTR enables authors to network with vendors, PR firms &amp;
                  other authors with messaging &amp; access to exclusive OTR
                  lists for bloggers, beta readers &amp; Vendor Cancellation
                  Openings.
                </p>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modal5}
              toggle={this.toggle5}
              className="modal1"
            >
              <ModalHeader toggle={this.toggle5} className="eventModal">
                VENDOR CANCELLATION OPENINGS VCO
              </ModalHeader>
              <ModalBody>
                <p>
                  Even if a vendor is not accepting new clients, their
                  cancellations can be added as Vendor Cancellation Openings
                  (VCO) marked on Vendor list. This enables authors to maximize
                  an opportunity to reserve services with previously fully
                  booked vendors.
                </p>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modal6}
              toggle={this.toggle6}
              className="modal1"
            >
              <ModalHeader toggle={this.toggle6} className="eventModal">
                BETA READER LIST
              </ModalHeader>
              <ModalBody>
                <p>
                  All members are able to add themselves to the beta reader list
                  accessible by all authors.
                </p>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modal7}
              toggle={this.toggle7}
              className="modal1"
            >
              <ModalHeader toggle={this.toggle7}>FOLLOWERS</ModalHeader>
              <ModalBody>
                <p>
                  An exclusive “invite code” will be generated after sign-up to
                  share with your fan base. When an invite code is redeemed upon
                  member sign up, you will automatically earn that follower.
                </p>
                <ol>
                  <li start="1">
                    Increase visibility on the calendar by gaining followers.
                  </li>
                  <li className="mb-2">
                    Reach followers with earned and purchased Blasts.
                  </li>
                </ol>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modal8}
              toggle={this.toggle8}
              className="modal1"
            >
              <ModalHeader toggle={this.toggle8} className="eventModal">
                {' '}
                BLASTS{' '}
              </ModalHeader>
              <ModalBody>
                <ol>
                  <li start="1">
                    Earn a free Blast upon earning your first 500 followers &
                    one free Blast for every additional 500 followers.
                  </li>
                  <li className="mb-2">
                    Additional Blasts to followers can be purchased, but limited
                    to a maximum of one per month.
                  </li>
                </ol>
                <p>*** subject to change.</p>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    $('#page1').addClass('active');
    $('#page2').addClass('active');
    $('#page3').addClass('active');
    $('#page4').addClass('active');

    const local = localStorage.getItem('selected_type');

    console.log("local----------------- > > > > >", { local });

    if (local === '1') {
      console.log('inside local');
      this.setState({ selected: '1' });
      this.planCostHandller('1');
    } else if (local === '2') {
      this.setState({ selected: '2' });
      this.planCostHandller('2');
    } else if (local === '4') {
      this.setState({ selected: '4' });
      this.planCostHandller('4');
    } else if (local === '5') {
      this.setState({ selected: '5' });
      this.planCostHandller('5');
    }
    else if (local === '3') {
      this.setState({ selected: '6' });
      this.planCostHandller('6');
    }
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    validatePR: (url, value, status) =>
      dispatch(verifyPRCode(url, value, status)),
    handleGetAllDiscounts: url => dispatch(GetAllDiscounts(url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
