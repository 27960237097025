import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashLoader } from 'react-spinners';
import { UncontrolledTooltip } from 'reactstrap';
import { openBlastModal } from '../../Actions/event-setup';
import { Route, Link, Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  getBlasts,
  deleteBlast,
  buyUserBlast
} from '../../Actions/profile-setup';
import { withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';

import moment from 'moment';
import SendEmail from '../dashboard/sendEmailModel';
import { AgGridReact } from 'ag-grid-react';
import PaymentModal from '../SignUp/paymentModal';
import BlastPurchaseModel from '../Static/blastPurchaseModel';

import BlastModal from '../dashboard/blastModal';
import { data } from 'jquery';

class showBlastlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBlastModal: false,
      showPaymentModal: false,
      showBlastPurchaseModal: false,
      blastMode: null,
      blastData: {},
      blast: false,
      isOpenPrivacy: false,
      deleteEvent: false,
      userId: '',
      show: false,
      blast_type: '',

      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },

        {
          headerName: 'Date created',
          field: 'blast_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.blastCreated.bind(this)
        },

        {
          headerName: 'Date sent',
          field: 'completed_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.sentdateRender.bind(this)
        },

        {
          headerName: 'Timezone',
          field: 'timezone',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.timezoneRender.bind(this)
        },

        {
          headerName: 'Send time',
          field: 'blast_time',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.timeRender.bind(this)
        },

        {
          headerName: 'Blast subject',
          field: 'email_details',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.emailSubject.bind(this)
        },

        // {
        //   headerName: "Date",
        //   field: "blast_date",
        //   sortable: true,
        //   filter: true,
        //   resizable: true,
        //   cellRenderer: this.dateRender.bind(this),
        // },

        {
          headerName: 'Type of Blast',
          field: 'type',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.typeOfBlast.bind(this)
        },
        {
          headerName: 'Details',
          field: 'title',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'ACTION',
          field: 'edit',
          cellRenderer: this.editEvents.bind(this)
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      showMenu: false
    };
    this.hideModal = this.hideModal.bind(this);
  }

  emailSubject(params) {
    // console.log('jjkj', params);
    let data = JSON.parse(params.data.email_details);
    // console.log('data23', data);
    return data[0].email_subject;
  }

  blastCreated(params) {
    //   console.log('params', params.data);
    let create = params.data.blast_date.substr(0, 10);
    return moment(create).format('MM/DD/YYYY');
  }

  emailSubject(params) {
    let data = JSON.parse(params.data.email_details);
    return data[0].email_subject;
  }

  typeOfBlast(params) {
    //  console.log('payy', params.data.blast_type);
    if (params.data.blast_type === 'release') {
      return (
        params.data.blast_type.charAt(0).toUpperCase() +
        params.data.blast_type.slice(1)
      );
    } else if (params.data.blast_type === 'purchased') {
      return (
        params.data.blast_type.charAt(0).toUpperCase() +
        params.data.blast_type.slice(1)
      );
    } else {
      return (
        params.data.blast_type.charAt(0).toUpperCase() +
        params.data.blast_type.slice(1)
      );
    }
  }
  emailBody(params) {
    // console.log('params.data.email_details', params.data.email_details);
    let data = JSON.parse(params.data.email_details);
    return data[0].email_body;
  }

  dateRender(params) {
    return moment(params.data.blast_date).format('MM/DD/YYYY');
  }
  sentdateRender(params) {
    if (params.value !== null) {
      // console.log('params.data1', params.data.completed_date);
      return moment(params.data.completed_date).format('MM/DD/YYYY');
    } else {
      return '-';
    }
  }

  timezoneRender(params) {
    if (params.value !== null) {
      // console.log('params.data1', params.data.completed_date);
      return params.data.timezone;
    } else {
      return 'NA';
    }
  }
  timeRender(params) {
    const dateTime = new Date(
      `${params.data.blast_date} ${params.data.blast_time}`
    );
    return moment(dateTime).format('hh:mm A');
  }

  convertUTCDateToLocalDate = date => {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  };

  showEditButton(params) {
    //  console.log('blast', params);
    if (!params.data.is_completed)
      return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Edit</button>`;
    else return 'N/A';
    // let data = JSON.parse(params.data.email_details);
    // console.log(params.data);

    // const dateTime = new Date(
    //   `${params.data.blast_date} ${params.data.blast_time}`
    // );

    // console.log(dateTime);
    // const expectedDate = moment(dateTime)
    //   .add(15, "minutes")
    //   .format("YYYY-MM-DD HH:mm");

    // console.log(expectedDate);

    // const calculateisAfter = moment().isAfter(expectedDate);

    // console.log(calculateisAfter);
    // console.log(this.props.displayMode);
    // if (!calculateisAfter && !params.data.is_completed) {
    //   if (this.props.displayMode) {

    //   } else {

    //   }
    // } else {
    //   return "-";
    // }
  }

  showDeleteButton(params) {
    if (!params.data.is_completed)
      return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Delete</button>`;
    else return 'N/A';
    // let data = JSON.parse(params.data.email_details);
    // const dateTime = new Date(
    //   `${params.data.blast_date} ${params.data.blast_time} UTC`
    // );
    // const expectedDate = moment(dateTime)
    //   .add(2, "minutes")
    //   .format("YYYY-MM-DD HH:mm");

    // const calculateisAfter = moment().isAfter(expectedDate);

    // if (!calculateisAfter && !params.data.is_completed) {
    //   // return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Delete</button>`;

    //   if (this.props.displayMode) {
    //     return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Delete</button>`;
    //   } else {
    //     return `<button type="button" data-action-type="follow" class="btn btn-sm new_btn_save_bt_users_dark py-0">Delete</button>`;
    //   }
    // } else {
    //   return "-";
    // }
  }
  hideModal() {
    this.setState({ show: false });
  }
  editEvents(params) {
    // console.log('params', { params });
    let details = params.data;
    // console.log('params.data', params.data);
    return `<button type="button" data-action-type="edit"  class="btn btn-sm  new_btn_save_bt_users_light  py-0">View</button>`;
  }
  // showBlast(bufferBlast) {
  //   console.log('task12', bufferBlast.column.colId);
  //   if (bufferBlast.column.colId === 'edit') {
  //     const blastContent = JSON.parse(bufferBlast.data.email_details)[0]
  //       .email_body;
  //     console.log(
  //       'bufferBlast.data.email_details',
  //       JSON.parse(bufferBlast.data.email_details)[0].email_body
  //     );
  //     this.setState({ show: true, blastContent });
  //   }
  // }

  toggleBlastModal = () => {
    this.setState({ blastData: {} });
    this.setState({ showBlastModal: !this.state.showBlastModal });
  };

  DeleteBlast = data => {
    //console.log('hhhhhhhhhh', data);
    if (!data.is_completed) {
      // if (window.confirm('Are you sure you want to delete the blast?'))
      //   this.handleRemoveBlast(data.id);
      this.togglePrivacyModal(data.id, data.blast_type);
    }
  };
  togglePrivacyModal = (data, blast_type) => {
    // console.log('my', blast_type);
    this.setState({ userId: data, blast_type: blast_type });
    this.setState({ isOpenPrivacy: !this.state.isOpenPrivacy });
  };

  // call delete api to delete the blast
  handleRemoveBlast = id => {
    // console.log('myyy', this.state.blast_type);
    this.props.deleteBlast(
      `/api/v1/deleteblast/${this.state.userId}/${this.state.blast_type}`,
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );
    this.togglePrivacyModal();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
  };

  refreshGrid() {
    this.props.getCases();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  showEvent(e) {
    // console.log('e.column.colId', e.column.colId);
    if (e.column.colDef.headerName == 'Edit') {
      // console.log(
      //   'df0',
      //   e.data,
      //   JSON.parse(e.data.email_details)[0].email_body
      // );
      if (!e.data.is_completed) {
        this.setState(
          {
            blastData: {
              ...e.data,
              email_body: JSON.parse(e.data.email_details)[0].email_body
            },
            blastMode: e.data.blast_type
          },
          this.toggleBlastModal()
        );
      }
    }

    if (e.column.colDef.headerName == 'Delete') {
      if (!e.data.is_completed) {
        this.DeleteBlast(e.data);
      }
    }
    if (e.column.colId == 'edit') {
      // console.log('bbbbbbbbbbbbbbbb', e.data);
      const blastSubject = JSON.parse(e.data.email_details)[0].email_subject;

      const blastContent = JSON.parse(e.data.email_details)[0].email_body;
      const first = e.data.fname;
      const last = e.data.lname;
      const displayName = first + ' ' + last;

      this.setState({ show: true, blastSubject });
      this.setState({ show: true, blastContent });
      this.setState({ show: true, displayName });
    }
  }

  togglePaymentModal = () => {
    this.setState({
      showPaymentModal: !this.state.showPaymentModal
      // profileData: {}
    });
  };
  toggleBlastPurchaseModal = () => {
    this.setState({
      showBlastPurchaseModal: !this.state.showBlastPurchaseModal
      // profileData: {}
    });
  };
  addClientToken = token => {
    // console.log('Clinet token will be ', token);
    this.togglePaymentModal();

    this.props.buyBlast(
      '/api/v1/users/buyblast',
      {
        amount: 200,
        clientToken: token
      },
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );
  };

  //===================== RENDER ==========================
  render() {
    // console.log('blast details: ', this.props.blastDetails);
    const isUser =
      +localStorage.getItem('membershipId') == 6 ||
      +localStorage.getItem('membershipId') == 3 ||
      +localStorage.getItem('membershipId') == 5
        ? true
        : false;
    // filterData = data.filter(
    //   el => el.added_on >= createdDate && el.added_on <= EndDate
    // );
    // filterData.sort(function(a, b) {
    //   return new Date(a.added_on) - new Date(b.added_on);
    // });
    //  let dAte = new Date()
    let filterData = '';
    const createdDate = moment().format('YYYY-MM-DD');

    // console.log('createdDate', createdDate, this.props.blastDetails.blast);
    let blassst = this.props.blastDetails.blast;
    let today = new Date();
    let time = today.getHours() + ':' + today.getMinutes();

    if (blassst) {
      let dd = this.props.blastDetails.blast
        .filter(el => !el.is_completed)
        .sort(function(a, b) {
          let aDate = new Date(`${a.completed_date} ${a.blast_time}`);
          // console.log('a.b =>', a.completed_date, a.blast_time);
          let bDate = new Date(`${b.completed_date} ${b.blast_time}`);
          // console.log(aDate, bDate, aDate - bDate);
          return aDate - bDate;
        });

      let dd1 =
        this.props.blastDetails.blast &&
        this.props.blastDetails.blast
          .filter(el => el.is_completed)
          .sort(function(a, b) {
            let aDate = new Date(`${a.completed_date} ${a.blast_time}`);
            //  console.log('a.b =>', a.completed_date, a.blast_time);
            let bDate = new Date(`${b.completed_date} ${b.blast_time}`);

            return bDate - aDate;
          });
      // console.log('dd1', dd1);
      let newD = this.props.blastDetails.blast.filter(el => el.is_completed);
      let comDate = this.props.blastDetails.blast.filter(
        ele => ele['is_completed'] == 1
      );
      //  console.log('newD', this.props.blastDetails.blast, newD, comDate);
      filterData = [...dd, ...dd1];
    }

    // console.log('this.props.blastDetails.blast', this.props.blastDetails.blast);
    //console.log('filterData', this.props.blastDetails.blast);

    return (
      <>
        <Modal isOpen={this.state.show} toggle={this.hideModal}>
          <ModalHeader toggle={this.hideModal} className="eventModal">
            <h5 className="text-center p-0">Blast Details4</h5>
          </ModalHeader>

          <ModalBody>
            <b>From:</b>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.displayName }}
            ></div>
            <b>Subject:</b>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.blastSubject }}
            ></div>
            <b>Message:</b>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.blastContent }}
            ></div>
          </ModalBody>
        </Modal>

        <div className="container blast-details-page">
          <div className="mb-4">
            <h2 className="text-center heading"> MY BLASTS</h2>
          </div>

          {Object.keys(this.props.blastDetails).length ? (
            <>
              <div className="blast-details-boxes mb-4">
                {/* second container */}
                <div className="details-box"></div>

                {/* 3rd container */}
                <div className="details-box"></div>
              </div>

              <div
                className={
                  this.props.displayMode
                    ? 'ag-theme-balham'
                    : 'ag-theme-dark home-ag'
                }
              >
                <AgGridReact
                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={filterData}
                  pagination={true}
                  reactNext={true}
                  animateRows
                  onCellClicked={this.showEvent.bind(this)}
                  paginationPageSize={this.state.paginationPageSize}
                  paginationNumberFormatter={
                    this.state.paginationNumberFormatter
                  }
                />
                <div className="test-header" style={{ float: 'right' }}>
                  Page Size:
                  <select
                    onChange={this.onPageSizeChanged.bind(this)}
                    id="page-size"
                    defaultValue="10"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <BlastModal
                isOpen={this.state.showBlastModal}
                toggle={this.toggleBlastModal}
                blastData={this.state.blastData}
                blastMode={this.state.blastMode}
                blast={this.props.blastDetails.blast}
              />
            </>
          ) : null}
        </div>
      </>
    );
  }

  componentDidMount() {
    this.props.getBlasts(`/api/v1/users/blastDetails`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }

  componentWillReceiveProps(newProps) {
    // console.log('newProps', newProps.blastDetails.blast);
  }
}

const mapStateToProps = state => {
  return {
    blastDetails: state.getBlastDetails,
    displayMode: state.changeMode,
    buyStatus: state.buyBlastStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBlasts: (url, headers) => dispatch(getBlasts(url, headers)),
    deleteBlast: (url, headers) => dispatch(deleteBlast(url, headers)),
    buyBlast: (url, data, header) => dispatch(buyUserBlast(url, data, header))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(showBlastlist);
