import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { getstatic } from '../../Actions/index';
import CONSTANT from '../../constant';
import './aboutUs.css';
import { Link } from 'react-router-dom';
import '../Landing/landing.css';
import axios from 'axios';
import { BASEURL } from '../../constant';

import Layout from '../layout/layout';

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consent_body: []
    };
  }
  componentDidMount = async () => {
    let route = this.props.history.location.pathname;
    route = route.split('/').pop();

    if (route) {
      const headers = {
        'x-authorization': localStorage.getItem('token')
      };
      const getPrivacy = await axios.get(BASEURL + `/api/v1/about`, {
        headers: headers
      });
      if (getPrivacy.status === 200) {
        // const { title, content } = getPrivacy.data.result;
        this.setState({
          consent_body:
            getPrivacy.data.result &&
            getPrivacy.data.result.aboutUs[0] &&
            getPrivacy.data.result.aboutUs[0].consent_body
        });
      }
    }
  };
  render() {
    window.scrollTo(0, 0);
    return (
      <Layout>
        <div className="about-page">
          <div className="container">
            <div className="mb-4">
              <h2 className="text-center">About Us</h2>
            </div>
            {/* <div className="setup_p_hed">
                <h4>AboutUs</h4>
              </div> */}
            <div className="">
              {/* <div className=" text-center">
                <h3>Our Story</h3>
              </div> */}
              {/* <div
                  contentEditable="true"
                  className="msg-text"
                  dangerouslySetInnerHTML={{ __html: this.state.consent_body }}
                ></div> */}
              <p className="text-justify"></p>

              <p className="text-justify">
                The reader went on to read, review and create book-inspired art.
                Her passion for reading romance further evolved and prompted her
                to establish a book group. But juggling life, family, career and
                finding time for her favorite hobby proved to be next to
                impossible. Constantly missing book events, belatedly learning
                about release parties and endlessly trying to find ARC signups,
                an idea for change was in the making.
              </p>

              <p className="text-justify">
                On Target Romance (OTR) bloomed from the idea of a one-stop hub
                for the Romance book community. The wide range of Romance book
                events and the publication journey necessitated the creation of
                a personalized planner to help readers track events, while
                authors and industry professionals utilize tools for networking
                and optimizing productivity, all neatly organized in one place.
              </p>

              <p className="text-justify">
                With OTR organizational tools, members can enjoy what the
                Romance book world has to offer with less effort, and without
                sacrificing time to do what matters the most. Read, write, and
                network seamlessly because your target is our aim. Optimize your
                book life with OTR.
              </p>
              <div className="target-plan">
                <p className="d-inline-block mr-2">
                  {' '}
                  Target the right plan for you:{' '}
                </p>
                <Link to="/plans">
                  <button
                    type="button"
                    className="btn new-btn-filled"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SEE PLANS{' '}
                  </button>
                </Link>

                <Link to="/join">
                  <button type="button" className="btn new-btn-filled">
                    Join{' '}
                  </button>
                </Link>
              </div>
              <div className="mt-2">
                <p className="d-inline-block mr-2">
                  Follow us on social media:
                </p>
                <a
                  href={CONSTANT.INSTAGRAM}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-1"
                >
                  Instagram |
                </a>
                <a
                  href={CONSTANT.FACEBOOK}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-1"
                >
                  Facebook |
                </a>
                <a
                  href={CONSTANT.TWITTER_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-1"
                >
                  Twitter |
                </a>
                <a
                  href={CONSTANT.YOUTUBE_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-1"
                >
                  Youtube
                </a>
              </div>

              {/* <section className="timeline-section">
                <div className="row">
                  <div className="col-12 main-title text-center mb-3">
                    <h3>Timeline</h3>
                    <h5 className="subTitle d-block">
                      Your Romance book life, optimized.
                    </h5>
                  </div>
                  <div className="col-12 text-center">
                    <div className="divImg">
                      <img src={lightImg} alt="Timeline" />
                    </div>
                  </div>
                </div>
              </section> */}
              {!localStorage.getItem('token') ? (
                <>
                  <div class="row">
                    <div className="full_title">
                      <div className="form-group text-center">
                        <a href="/" class="float-md-right">
                          <button
                            type="submit"
                            className="btn float-md-right new_btn_save_bt m-0"
                            style={{
                              marginRight: '0% !important'
                            }}
                          >
                            Back to Dashboard
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* footer */}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  componentDidMount() {
    let route = this.props.history.location.pathname;
    route = route.split('/').pop();

    if (route) {
      // console.log("Inside the if route condition");

      this.props.getstatic(`/api/v1/static?page=${route}`);
    }
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => ({
  pages: state.userListData
});

const mapDispatchToProps = dispatch => ({
  getstatic: url => dispatch(getstatic(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
