import React from 'react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import defaultImg from '../Utils/Assets/OTR_default_book_cover_v2.jpg';

const TextBox = props => (
  <div>
    <label>
      {props.attributes.placeholder === 'Sign-up link'
        ? 'Sign-up link*:'
        : props.attributes.placeholder === 'Goodreads link'
        ? 'Goodreads:'
        : props.attributes.placeholder === 'Book playlist link'
        ? 'Book playlist link:'
        : props.attributes.placeholder === 'Link to teasers'
        ? 'Link to teasers:'
        : props.attributes.placeholder === 'Book trailer link'
        ? 'Book trailer link:'
        : props.attributes.placeholder === 'Co-author(s)'
        ? 'Co-author(s) (if applicable):'
        : props.attributes.placeholder === 'Author first name' &&
          props.urlLink !== 'user'
        ? 'Author first name*:'
        : props.attributes.placeholder === 'Author last name' &&
          props.urlLink !== 'user'
        ? 'Author last name*:'
        : props.attributes.placeholder === 'Party link'
        ? 'Release Party link*:'
        : props.attributes.placeholder === 'Hosted by'
        ? 'Hosted by*:'
        : props.attributes.placeholder === 'Host link'
        ? 'Host link:'
        : props.attributes.placeholder === 'City'
        ? 'City (or Virtual)*:'
        : props.attributes.placeholder === 'Hosted by'
        ? 'Hosted by*:'
        : props.attributes.placeholder === 'Link to Giveaway'
        ? 'Link to Giveaway*:'
        : props.attributes.placeholder === 'Co-author(s)'
        ? 'Co-author(s) (if applicable):'
        : props.attributes.placeholder === 'Name of Giveaway'
        ? 'Name of Giveaway (should include book title)*:'
        : props.attributes.placeholder === 'Host contact'
        ? 'Host contact:'
        : props.attributes.placeholder ===
          'Link where book cover can be viewed on day of reveal'
        ? 'Link where book cover can be viewed on day of reveal*:'
        : props.attributes.placeholder === 'Link'
        ? ''
        : props.attributes.placeholder === 'Release party event name'
        ? 'Release Party event name (should include book title)*:'
        : props.attributes.placeholder === 'Name of Book Signing event'
        ? 'Name of Book Box or Book Signing event*:'
        : props.attributes.placeholder === 'Series name'
        ? 'Series name*:'
        : props.attributes.placeholder === 'Book #'
        ? 'Book#*:'
        : props.attributes.placeholder === 'Time'
        ? ''
        : props.attributes.placeholder ===
          'Start time (list time in your time zone, OTR will feature in Universal time zone)'
        ? 'Start time (list time in your time zone, OTR will feature in Universal time zone)*:'
        : props.attributes.placeholder === 'Release party timezone'
        ? 'Time zone clarification (to help members across the globe specify time zone)*:'
        : props.attributes.placeholder === 'Specify Giveaway time zone'
        ? 'Specify Giveaway time zone*:'
        : ''}

      {props.attributes.name === 'link-to-event'
        ? 'Link to event*:'
        : props.attributes.name === 'link-to-ticket-sales'
        ? 'Link to Ticket/Book box sales:'
        : ''}
    </label>
    {localStorage.getItem('event_type') === '1' ? (
      <>
        {props.attributes.name === 'Link' ? (
          <>
            <input
              type={props.attributes.type}
              className={`${props.attributes.className} ${
                props.attributes.text === 'Search/Sync' ? 'sync' : ''
              } ${props.attributes.type === 'Party-link' ? 'part' : ''}`}
              placeholder={props.attributes.placeholder}
              value={props.attributes.value ? props.attributes.value : ''}
              onChange={e =>
                props.IsUnderCustom
                  ? props.onCustomHandler(
                      e.target.value,
                      props.index,
                      props.parentIndex,
                      props.attributes.name
                    )
                  : props.onChangeTextHandler(
                      e.target.value,
                      props.index,
                      props.attributes.name
                    )
              }
              onClick={e =>
                props.attributes.type === 'button'
                  ? props.onChangeTextHandler(e, props.index)
                  : null
              }
              disabled={
                props.workingIndex && props.workingIndex.length > 0
                  ? false
                  : true
              }
              required={
                props.workingIndex && props.workingIndex.length > 0
                  ? true
                  : props.attributes.validation
              }
              step="any"
              min="0"
            />
          </>
        ) : (
          <input
            type={props.attributes.type}
            className={`${props.attributes.className} ${
              props.attributes.text === 'Search/Sync' ? 'sync' : ''
            } ${props.attributes.type === 'Party-link' ? 'part' : ''}`}
            placeholder={props.attributes.placeholder}
            value={props.attributes.value ? props.attributes.value : ''}
            onChange={e =>
              props.IsUnderCustom
                ? props.onCustomHandler(
                    e.target.value,
                    props.index,
                    props.parentIndex,
                    props.attributes.name
                  )
                : props.onChangeTextHandler(
                    e.target.value,
                    props.index,
                    props.attributes.name
                  )
            }
            onClick={e =>
              props.attributes.type == 'button'
                ? props.onChangeTextHandler(e, props.index)
                : null
            }
            required={props.attributes.validation}
            step="0.5"
            min="0.5"
          />
        )}
      </>
    ) : props.attributes.placeholder == 'PR Services' ? (
      <>
        <input
          type={props.attributes.type}
          className={`${props.attributes.className} ${
            props.attributes.text === 'Search/Sync' ? 'sync' : ''
          } ${props.attributes.type === 'Party-link' ? 'part' : ''}`}
          placeholder={props.attributes.placeholder}
          value={'PR Services'}
          onChange={e =>
            props.IsUnderCustom
              ? props.onCustomHandler(
                  'PR Services',
                  props.index,
                  props.parentIndex
                )
              : props.onChangeTextHandler('PR Services', props.index)
          }
          onClick={e =>
            props.attributes.type == 'button'
              ? props.onChangeTextHandler(e, props.index)
              : null
          }
          disabled={true}
          required={props.attributes.validation}
          // step="any"
          // min="0"
        />
      </>
    ) : props.attributes.name == 'coauthor' ? (
      <>
        <input
          type={props.attributes.type}
          className={`${props.attributes.className} ${
            props.attributes.text === 'Search/Sync' ? 'sync' : ''
          } ${props.attributes.type === 'Party-link' ? 'part' : ''}`}
          placeholder={props.attributes.placeholder}
          value={props.attributes.value ? props.attributes.value : ''}
          onChange={e =>
            props.IsUnderCustom
              ? props.onCustomHandler(
                  e.target.value,
                  props.index,
                  props.parentIndex
                )
              : props.onChangeTextHandler(e.target.value, props.index)
          }
          onClick={e =>
            props.attributes.type == 'button'
              ? props.onChangeTextHandler(e, props.index)
              : null
          }
          disabled={true}
          required={props.attributes.validation}
          step="any"
          min="0"
        />
      </>
    ) : props.attributes.name == 'blogname' ||
      props.attributes.name == 'PRFirmName' ? (
      <>
        <div
          className="emojipicker"
          // onBlur={this.handleEmojiPicker}
          onClick={props.onChangeEmoji}
        >
          😀
        </div>
        {/* {this.state.chosenEmoji &&  />} */}
        {props.chosenEmoji && <Picker onSelect={props.onSelectEmoji} />}

        <input
          type={props.attributes.type}
          className={`blog-name ${props.attributes.className} ${
            props.attributes.text === 'Search/Sync' ? 'sync' : ''
          } ${props.attributes.type === 'Party-link' ? 'part' : ''}`}
          placeholder={
            props.attributes.placeholder === 'PR firm name'
              ? 'Company name'
              : props.attributes.placeholder
          }
          value={props.attributes.value ? props.attributes.value : ''}
          onChange={e =>
            props.IsUnderCustom
              ? props.onCustomHandler(
                  e.target.value,
                  props.index,
                  props.parentIndex
                )
              : props.onChangeTextHandler(e.target.value, props.index)
          }
          onClick={e =>
            props.attributes.type == 'button'
              ? props.onChangeTextHandler(e, props.index)
              : null
          }
          //disabled={true}
          required={props.attributes.validation}
          step="any"
          min="0"
        />
      </>
    ) : props.attributes.title == 'Brief introduction' ? (
      <div className="d-flex flex-column align-items-end">
        <input
          type={props.attributes.type}
          className={props.attributes.className}
          placeholder={props.attributes.placeholder}
          value={props.attributes.value ? props.attributes.value : ''}
          onChange={e => {
            props.onChangeTextHandler(
              e.target.value,
              props.index,
              props.attributes.value
            );
          }}
        />
        <p className="me-2 fs-6 max-word">{props.attributes.value.length}/30</p>
      </div>
    ) : (
      <>
        <input
          readOnly={
            props.attributes.name === 'author-last-name' ||
            props.attributes.name === 'author-first-name'
              ? props.readOnly
              : false
          }
          type={props.attributes.type}
          className={`${props.attributes.className} ${
            props.attributes.text === 'Search/Sync' ? 'sync' : ''
          } ${props.attributes.type === 'Party-link' ? 'part' : ''}`}
          placeholder={
            props.attributes.placeholder === 'Name of Book Signing event'
              ? 'Name of event'
              : props.attributes.placeholder
          }
          // disabled={
          //   props.workingIndex && props.workingIndex.length > 0 ? false : true
          // }
          value={props.attributes.value ? props.attributes.value : ''}
          onChange={e => {
            props.IsUnderCustom
              ? props.onCustomHandler(
                  e.target.value,
                  props.index,
                  props.parentIndex,
                  props.attributes.name
                )
              : props.onChangeTextHandler(
                  e.target.value,
                  props.index,
                  props.attributes.name
                );
          }}
          onClick={e =>
            props.attributes.type == 'button'
              ? props.onChangeTextHandler(e, props.index)
              : null
          }
          //disabled={true}
          required={
            props.attributes.placeholder === 'link' ||
            props.attributes.placeholder === 'Link'
              ? props.workingIndex && props.workingIndex.length > 0
                ? true
                : props.attributes.validation
              : ''
          }
          step="any"
          min="0"
        />
      </>
    )}
    {props.attributes.name === 'author-first-name' &&
    props.openUserSuggestion ? (
      <div
        ref={props.textBoxRef}
        class="picomplete-items author-names"
        style={{ width: '100%' }}
      >
        {props.userSuggestion &&
          props.userSuggestion.map(suggestion => (
            <div
              class="picomplete-item"
              onClick={() => {
                props.userClickHandler(suggestion);
              }}
            >
              <div className="authorAvatar">
                <img
                  // src={defaultImg}
                  src={
                    JSON.parse(suggestion.profile_details_with_values)[2]
                      .profile
                      ? localStorage.getItem('baseUrl') +
                        JSON.parse(suggestion.profile_details_with_values)[2]
                          .profile.split('\\')
                          .join('/')
                      : defaultImg
                  }
                  alt="avatar"
                />
              </div>
              {`${suggestion.fname} ${
                JSON.parse(suggestion.user_details)[2].value
                  ? JSON.parse(suggestion.user_details)[2].value
                  : ''
              } ${suggestion.lname ? suggestion.lname : ''}`}
            </div>
          ))}
      </div>
    ) : null}
  </div>
);
export default TextBox;
