import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashLoader } from 'react-spinners';
import { UncontrolledTooltip } from 'reactstrap';
import { openBlastModal } from '../../Actions/event-setup';
import { Route, Link, Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  getBlasts,
  deleteBlast,
  buyUserBlast
} from '../../Actions/profile-setup';
import { withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';

import moment from 'moment';
import SendEmail from '../dashboard/sendEmailModel';
import { AgGridReact } from 'ag-grid-react';
import PaymentModal from '../SignUp/paymentModal';
import BlastPurchaseModel from '../Static/blastPurchaseModel';

import BlastModal from '../dashboard/blastModal';
import { data } from 'jquery';
import { defaultSort } from '../../Utils/useFunction';

class Blast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBlastModal: false,
      showPaymentModal: false,
      showBlastPurchaseModal: false,
      blastMode: null,
      blastData: {},
      blast: false,
      isOpenPrivacy: false,
      deleteEvent: false,
      userId: '',
      show: false,
      blast_type: '',
      blastTitle: '',
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },

        {
          headerName: 'Date created',
          field: 'blast_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.blastCreated.bind(this)
        },

        {
          headerName: 'Date sent',
          field: 'completed_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.sentdateRender.bind(this)
        },

        {
          headerName: 'Timezone',
          field: 'timezone',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.timezoneRender.bind(this)
        },

        {
          headerName: 'Send time',
          field: 'blast_time',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.timeRender.bind(this)
        },

        {
          headerName: 'Blast subject',
          field: 'blast_subject',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.emailSubject.bind(this)
        },

        // {
        //   headerName: "Date",
        //   field: "blast_date",
        //   sortable: true,
        //   filter: true,
        //   resizable: true,
        //   cellRenderer: this.dateRender.bind(this),
        // },

        {
          headerName: 'Edit',
          field: 'email_details',
          suppressMovable: true,
          unSortIcon: false,
          sortable: false,
          cellRenderer: this.showEditButton.bind(this)
        },

        {
          headerName: 'Delete',
          field: 'email_details',
          suppressMovable: true,
          unSortIcon: false,
          sortable: false,
          cellRenderer: this.showDeleteButton.bind(this)
        },
        {
          headerName: 'Type of Blast',
          field: 'blast_type',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.typeOfBlast.bind(this)
        },
        {
          headerName: 'Details',
          field: 'title',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          hide:
            localStorage.getItem('membershipId') === '2' ||
            localStorage.getItem('membershipId') === '5'
              ? true
              : false
          // hide: true
        },
        {
          headerName: 'ACTION',
          field: 'edit',
          cellRenderer: this.editEvents.bind(this)
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 5,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      showMenu: false
    };
    this.hideModal = this.hideModal.bind(this);
  }

  emailSubject(params) {
    let data = JSON.parse(params.data.email_details);
    console.log('here is other one ----> ', data[0].title);
    return data[0].email_subject ? data[0].email_subject : '-';
  }

  blastCreated(params) {
    let create = params.data.blast_date.substr(0, 10);
    return moment(create).format('MM/DD/YYYY');
  }

  emailSubject(params) {
    let data = JSON.parse(params.data.email_details);
    return data[0].email_subject ? data[0].email_subject : '-';
  }

  typeOfBlast(params) {
    if (params.data.blast_type === 'release') {
      return (
        params.data.blast_type.charAt(0).toUpperCase() +
        params.data.blast_type.slice(1)
      );
    } else if (params.data.blast_type === 'purchased') {
      return (
        params.data.blast_type.charAt(0).toUpperCase() +
        params.data.blast_type.slice(1)
      );
    } else {
      return (
        params.data.blast_type.charAt(0).toUpperCase() +
        params.data.blast_type.slice(1)
      );
    }
  }
  emailBody(params) {
    let data = JSON.parse(params.data.email_details);
    return data[0].email_body;
  }

  dateRender(params) {
    return moment(params.data.blast_date).format('MM/DD/YYYY');
  }
  sentdateRender(params) {
    if (params.value !== null) {
      return moment(params.data.completed_date).format('MM/DD/YYYY');
    } else {
      return '-';
    }
  }

  timezoneRender(params) {
    if (params.value !== null) {
      return params.data.timezone;
    } else {
      return 'NA';
    }
  }
  timeRender(params) {
    const dateTime = new Date(
      `${params.data.blast_date} ${params.data.blast_time}`
    );
    return moment(dateTime).format('hh:mm A');
  }

  convertUTCDateToLocalDate = date => {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  };

  showEditButton(params) {
    let time1 = new Date(
      `${params.data.completed_date} ${params.data.blast_time}`
    ).toLocaleString();
    let time2 = new Date().toLocaleString();
    if (
      params.data.blast_type === 'purchased' ||
      params.data.blast_type === 'release'
    ) {
      if (new Date(time1) >= new Date(time2)) {
        return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Edit</button>`;
      } else {
        return 'N/A';
      }
    } else if (!params.data.is_completed)
      //&& new Date(time1) >= new Date(time2)(i have removed from else if condition)
      return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Edit</button>`;
    else return 'N/A';
  }

  showDeleteButton(params) {
    let time1 = new Date(
      `${params.data.completed_date} ${params.data.blast_time}`
    ).toLocaleString();
    let time2 = new Date().toLocaleString();
    if (
      params.data.blast_type === 'purchased' ||
      params.data.blast_type === 'release'
    ) {
      if (new Date(time1) >= new Date(time2)) {
        return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Delete</button>`;
      } else {
        return 'N/A';
      }
    }
    // && new Date(time1) >= new Date(time2)(removed from the bottom if block)
    if (!params.data.is_completed)
      return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Delete</button>`;
    else return 'N/A';
  }
  hideModal() {
    this.setState({ show: false });
  }
  editEvents(params) {
    let details = params.data;
    return `<button type="button" data-action-type="edit"  class="btn btn-sm  new_btn_save_bt_users_light  py-0">View</button>`;
  }

  toggleBlastModal = key => {
    //this.setState({ blastData: {} });
    if (key === 'blastValue') {
      console.log('hii i am here1');
      this.setState({ blastData: {} });
      this.setState({ showBlastModal: !this.state.showBlastModal });
    } else {
      console.log('hii i am here2');
      this.setState({ showBlastModal: !this.state.showBlastModal });
    }
  };

  DeleteBlast = data => {
    if (!data.is_completed) {
      this.togglePrivacyModal(data.id, data.blast_type);
    }
  };
  togglePrivacyModal = (data, blast_type) => {
    this.setState({ userId: data, blast_type: blast_type });
    this.setState({ isOpenPrivacy: !this.state.isOpenPrivacy });
  };

  // call delete api to delete the blast
  handleRemoveBlast = id => {
    this.props.deleteBlast(
      `/api/v1/deleteblast/${this.state.userId}/${this.state.blast_type}`,
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );
    this.togglePrivacyModal();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
  };

  refreshGrid() {
    this.props.getCases();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  showEvent(e) {
    if (e.column.colDef.headerName == 'Edit') {
      if (!e.data.is_completed) {
        let time1 = new Date(
          `${e.data.completed_date} ${e.data.blast_time}`
        ).toLocaleString();
        let time2 = new Date().toLocaleString();
        if (
          e.data.blast_type === 'purchased' ||
          e.data.blast_type === 'release'
        ) {
          if (new Date(time1) >= new Date(time2)) {
            this.setState({
              blastData: {
                ...e.data,
                email_body: JSON.parse(e.data.email_details)[0].email_body
              },
              blastMode: e.data.blast_type
            });
            this.toggleBlastModal();
          }
        } else {
          this.setState({
            blastData: {
              ...e.data,
              email_body: JSON.parse(e.data.email_details)[0].email_body
            },
            blastMode: e.data.blast_type
          });
          this.toggleBlastModal();
        }
      }
    }

    if (e.column.colDef.headerName == 'Delete') {
      if (!e.data.is_completed) {
        let time1 = new Date(
          `${e.data.completed_date} ${e.data.blast_time}`
        ).toLocaleString();
        let time2 = new Date().toLocaleString();
        if (
          e.data.blast_type === 'purchased' ||
          e.data.blast_type === 'release'
        ) {
          if (new Date(time1) >= new Date(time2)) {
            this.DeleteBlast(e.data);
          }
        } else {
          this.DeleteBlast(e.data);
        }
      }
    }
    if (e.column.colId == 'edit') {
      const blastSubject = JSON.parse(e.data.email_details)[0].email_subject;
      console.log('------------------------------------>> ', e.data);

      const blastContent = JSON.parse(e.data.email_details)[0].email_body;
      // console.log('I am here', JSON.parse(e.data.email_details)[0]);
      const blastType = JSON.parse(
        e.data.email_details
      )[0].blast_mode.toLowerCase();
      // console.log('I am here ---', blastType);
      const first = e.data.fname;
      const last = e.data.lname;
      const displayName = first + ' ' + last;
      const blastTitle = e.data.title;

      this.setState({ show: true, blastSubject });
      this.setState({ show: true, blastContent });
      this.setState({ show: true, displayName });
      this.setState({ show: true, blastType });
      this.setState({ show: true, blastTitle });
    }
  }

  togglePaymentModal = () => {
    this.setState({
      showPaymentModal: !this.state.showPaymentModal
      // profileData: {}
    });
  };
  toggleBlastPurchaseModal = () => {
    this.setState({
      showBlastPurchaseModal: !this.state.showBlastPurchaseModal
      // profileData: {}
    });
  };
  addClientToken = token => {
    this.togglePaymentModal();

    this.props.buyBlast(
      '/api/v1/users/buyblast',
      {
        amount: 200,
        clientToken: token
      },
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );
  };

  handleRefresh = () => {
    window.location.reload();
  };
  //===================== RENDER ==========================
  render() {
    // console.log("hii ia , m sjbbf called-")
    const isUser =
      +localStorage.getItem('membershipId') == 6 ||
      +localStorage.getItem('membershipId') == 3 ||
      +localStorage.getItem('membershipId') == 5
        ? true
        : false;

    let filterData = '';
    const createdDate = moment().format('YYYY-MM-DD');

    let blassst = this.props.blastDetails.blast;
    let today = new Date();
    let time = today.getHours() + ':' + today.getMinutes();

    if (blassst) {
      let dd = this.props.blastDetails.blast
        .filter(el => !el.is_completed)
        .sort(function(a, b) {
          let aDate = new Date(`${a.completed_date} ${a.blast_time}`);

          let bDate = new Date(`${b.completed_date} ${b.blast_time}`);

          return aDate - bDate;
        });

      let dd1 =
        this.props.blastDetails.blast &&
        this.props.blastDetails.blast
          .filter(el => el.is_completed)
          .sort(function(a, b) {
            let aDate = new Date(`${a.completed_date} ${a.blast_time}`);

            let bDate = new Date(`${b.completed_date} ${b.blast_time}`);

            return bDate - aDate;
          });

      let newD = this.props.blastDetails.blast.filter(el => el.is_completed);
      let comDate = this.props.blastDetails.blast.filter(
        ele => ele['is_completed'] == 1
      );

      filterData = [...dd, ...dd1];
    }
    if (filterData.length > 0) {
      filterData = filterData.map(el => {
        let e = { ...el };
        e.blast_subject = JSON.parse(
          e.email_details
        )[0].email_subject.toUpperCase();
        return e;
      });

      filterData = filterData.sort((a, b) =>
        new Date(a.completed_date) > new Date(b.completed_date) ? -1 : 1
      );
    }

    // console.log("here is the filter data in blast page - - - - - - > ", filterData);

    // if(filterData.length > 0)
    // {
    //   console.log("dnvhiurhivfhisjrdvgsrb ", JSON.parse(filterData[0].email_details)[0])
    // }
    // console.log('filter data === >', filterData);

    let result = [];
    let arr = [];
    if (Object.keys(this.state.blastData).length) {
      result = this.props.bookReleaseEventBlast.filter(
        o1 =>
          !this.props.blastDetails.blast.some(
            o2 => o1.id === o2.book_release_id
          )
      );
      result.push({
        title: this.state.blastData.title,
        id: this.state.blastData.book_release_id
      });
    } else {
      result = this.props.bookReleaseEventBlast.filter(
        o1 =>
          !this.props.blastDetails.blast.some(
            o2 => o1.id === o2.book_release_id
          )
      );
    }
    let avalBlast =
      Object.keys(this.state.blastData).length > 0
        ? result.length - 1
        : result.length;

    // console.log('this is blast title', title);

    return (
      <>
        <Modal
          isOpen={this.state.show}
          toggle={this.hideModal}
          className={`blastdetails-modal ${this.state.blastType}`}
        >
          <ModalHeader toggle={this.hideModal} className="eventModal">
            <h5 className="text-center p-0">Blast Details</h5>
          </ModalHeader>

          <ModalBody>
            <b>From:</b>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.displayName }}
            ></div>
            {this.state.blastType === 'release' && (
              <>
                <b>Title:</b>
                <div>{this.state.blastTitle}</div>
              </>
            )}
            <b>Subject:</b>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.blastSubject }}
            ></div>
            <b>Message:</b>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.blastContent }}
            ></div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.isOpenPrivacy}
          toggle={this.togglePrivacyModal.bind(this)}
          id="myModal"
          className="advertise-privacy-popup delete-confirmation-popup"
        >
          {/* <ModalHeader toggle={this.state.toggle} className="eventModal">
           
          </ModalHeader> */}
          <ModalBody>
            <p className="text-center p-0">
              {' '}
              Are you sure you want to delete the Blast?{' '}
            </p>
            <div className="delete-btn">
              <button
                className="btn btn-filled"
                id="Next"
                type="button"
                disabled={this.state.disabled}
                onClick={this.togglePrivacyModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-filled "
                id="Next"
                type="button"
                // onClick={() => this.handleRemoveBlast}
                onClick={id => {
                  {
                    this.handleRemoveBlast(id);
                  }
                }}
                disabled={this.state.disabled}
              >
                DELETE
              </button>
            </div>
          </ModalBody>
        </Modal>
        <div className="container blast-details-page">
          <div className="mb-2">
            <h2 className="text-center heading">Blast Details</h2>
          </div>
          <PaymentModal
            isOpen={this.state.showPaymentModal}
            addClientToken={this.addClientToken}
            planCost={200}
            toggle={this.togglePaymentModal.bind(this)}
            blast="blast"
          />
          <BlastPurchaseModel
            isOpen={this.state.showBlastPurchaseModal}
            addClientToken={this.addClientToken}
            planCost={200}
            toggle={this.toggleBlastPurchaseModal.bind(this)}
            blast="blast"
          />
          {Object.keys(this.props.blastDetails).length ? (
            <>
              <div
                className={`blast-details-boxes mb-4${
                  localStorage.getItem('membershipId') === '5' ||
                  localStorage.getItem('membershipId') === '6'
                    ? ' pr-blogger'
                    : ''
                }`}
              >
                {localStorage.getItem('membershipId') === '5' ||
                localStorage.getItem('membershipId') === '6' ? (
                  ''
                ) : (
                  <div className="details-box">
                    <fieldset
                      class={
                        this.props.displayMode
                          ? 'scheduler-border border-light blast-legend blastFieldSet'
                          : 'scheduler-border border-dark blast-legend blast-legend.blastFieldSet.dark'
                      }
                    >
                      <legend class="scheduler-border blastTitle">
                        <b>RELEASE BLASTS </b>
                      </legend>

                      <div class="control-group blast-list blastContainer">
                        <div>
                          <p className="blastP">
                            Send or schedule to send a FREE release day Blast to
                            all followers and members who have added the book
                            release to their calendar.{' '}
                            <em>
                              *Release Blast must be sent within 30 days of Book
                              Release.
                            </em>
                          </p>
                        </div>
                        {/* {console.log("welcome", this.props.blastDetails.blastStats.release,this.props.blastDetails.book_release)} */}
                        <div className="blastRow2">
                          <div>
                            <p className="blastP">
                              Available Book Release Blasts:{' '}
                              {/* {this.props.blastDetails.book_release -
                              this.props.blastDetails.blastStats.release >
                            0
                              ? this.props.blastDetails.book_release -
                                this.props.blastDetails.blastStats.release
                              : '0'} */}
                              {/* {result ? result.length : 0}
                               */}
                              {result ? avalBlast : 0}
                            </p>
                          </div>

                          <div className="">
                            <button
                              className={
                                this.props.displayMode
                                  ? 'btn new_btn_save_bt blast-button'
                                  : 'btn btn_save_bt white-button pull-right blast-button'
                              }
                              onClick={() => {
                                this.setState({ blastMode: 'Release' }, () =>
                                  this.toggleBlastModal('blastValue')
                                );
                              }}
                              disabled={
                                // this.props.blastDetails.blastStats.release >=
                                // this.props.blastDetails.book_release
                                avalBlast <= 0
                              }
                            >
                              SEND /SCHEDULE
                            </button>
                          </div>
                        </div>
                        <div>
                          <p className="blastP">
                            Total Blasts sent/scheduled:{' '}
                            {this.props.blastDetails.blastStats.release}
                          </p>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                )}

                {/* second container */}
                <div className="details-box">
                  <fieldset
                    class={
                      this.props.displayMode
                        ? 'scheduler-border border-light blast-legend blastFieldSet'
                        : 'scheduler-border border-dark blast-legend blast-legend.blastFieldSet.dark '
                    }
                  >
                    <legend class="scheduler-border blastTitle">
                      <b>EARNED BLASTS</b>
                    </legend>
                    <div class="control-group blast-list blastContainer">
                      <p className="blastP">
                        Earn a free Blast upon earning you first 500 followers &
                        one free Blast for every additional 500 followers.
                      </p>
                      <div className="blastRow2">
                        <div>
                          <p className="blastP">
                            Available Earned Blasts:{' '}
                            {this.props.blastDetails.followersTotal}
                          </p>
                        </div>
                        <div className="">
                          <button
                            className={
                              this.props.displayMode
                                ? 'btn new_btn_save_bt pull-right blast-button'
                                : 'btn btn_save_bt white-button pull-right blast-button'
                            }
                            onClick={() => {
                              this.setState({ blastMode: 'earned' }, () =>
                                this.toggleBlastModal()
                              );
                            }}
                            disabled={
                              this.props.blastDetails.blastStats.earned >=
                              this.props.blastDetails.followersTotal
                            }
                          >
                            SEND /SCHEDULE
                          </button>
                        </div>
                      </div>
                      <div className="blastRow2">
                        <div>
                          <p className="blastP">
                            Total earned Blasts sent/scheduled:{' '}
                            {this.props.blastDetails.blastStats.earned}
                          </p>
                          <p className="mt-0 mb-0">
                            <i>*At first 500, 1,000, 1,500 etc.</i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                {/* 3rd container */}
                <div className="details-box purchase-blast">
                  <fieldset
                    class={
                      this.props.displayMode
                        ? 'scheduler-border border-light blast-legend blastFieldSet'
                        : 'scheduler-border border-dark blast-legend blast-legend.blastFieldSet.dark '
                    }
                  >
                    <legend class="scheduler-border blastTitle">
                      <b>PURCHASE BLASTS</b>
                    </legend>
                    <div class="control-group blast-list blastContainer">
                      <div>
                        <p className="blastP">
                          Additional Blasts sent to followers can be purchased.{' '}
                        </p>
                        <pre className="blastP">
                          <i>*Max one Purchased Blast per month. </i>
                        </pre>
                      </div>

                      <div className="blastRow2">
                        <div>
                          <p className="blastP">
                            Available Purchased Blasts:{' '}
                            {this.props.blastDetails.buyedBlast}
                          </p>
                        </div>
                        <div>
                          <button
                            className={
                              this.props.displayMode
                                ? 'btn new_btn_save_bt pull-right blast-button'
                                : 'btn btn_save_bt white-button pull-right blast-button'
                            }
                            onClick={() => {
                              this.setState({ blastData: {} });
                              this.setState({ blastMode: 'purchased' }, () =>
                                this.toggleBlastModal()
                              );
                            }}
                            disabled={!this.props.blastDetails.buyedBlast}
                          >
                            SEND /SCHEDULE
                          </button>
                        </div>
                      </div>

                      <div className="blastRow2">
                        <div>
                          <p className="blastP">
                            Total Purchased Blasts:{' '}
                            {this.props.blastDetails.totalBuyedBlast}
                          </p>
                        </div>
                        <div className="">
                          <button
                            className={
                              this.props.displayMode
                                ? 'btn new_btn_save_bt btn-sm pull-right blast-button '
                                : 'btn btn_save_bt btn-sm white-button  pull-right blast-button '
                            }
                            onClick={this.toggleBlastPurchaseModal}
                            disabled={
                              this.props.blastDetails.currentMonthBlastBuyed
                            }
                          >
                            <span href="#" id="UncontrolledTooltipExample">
                              PURCHASE BLAST
                            </span>
                          </button>
                        </div>
                      </div>

                      {this.props.blastDetails.currentMonthBlastBuyed && (
                        <UncontrolledTooltip
                          placement="top"
                          target="UncontrolledTooltipExample"
                        >
                          You can buy one Blast per month.
                        </UncontrolledTooltip>
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>

              <div
                className={
                  this.props.displayMode
                    ? 'ag-theme-balham'
                    : 'ag-theme-dark home-ag'
                }
              >
                <AgGridReact
                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={filterData}
                  pagination={true}
                  reactNext={true}
                  animateRows
                  onCellClicked={this.showEvent.bind(this)}
                  paginationPageSize={this.state.paginationPageSize}
                  paginationNumberFormatter={
                    this.state.paginationNumberFormatter
                  }
                />
                <div className="search-features" style={{ marginTop: '10px' }}>
                  <p>
                    <i>
                      Each column has search feature. Column width can be
                      changed by dragging. Hit refresh to reset.
                    </i>
                    <i
                      style={{
                        marginLeft: '10px',
                        cursor: 'pointer'
                      }}
                      className="fa fa-refresh"
                      onClick={this.handleRefresh}
                    />
                  </p>
                </div>
                <div
                  className="test-header"
                  style={{ float: 'right', marginTop: 2 }}
                >
                  Page Size:
                  <select
                    onChange={this.onPageSizeChanged.bind(this)}
                    id="page-size"
                    defaultValue="5"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <BlastModal
                isOpen={this.state.showBlastModal}
                toggle={this.toggleBlastModal}
                blastData={this.state.blastData}
                blastMode={this.state.blastMode}
                blast={this.props.blastDetails.blast}
              />
            </>
          ) : null}
        </div>
      </>
    );
  }

  componentDidMount() {
    this.props.getBlasts(`/api/v1/users/blastDetails`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }

  // componentWillReceiveProps(newProps) {
  //   console.log('newProps', newProps.blastDetails.blast);
  // }
}

const mapStateToProps = state => {
  return {
    blastDetails: state.getBlastDetails,
    displayMode: state.changeMode,
    buyStatus: state.buyBlastStatus,
    bookReleaseEventBlast: state.getBookReleaseEventBlast
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBlasts: (url, headers) => dispatch(getBlasts(url, headers)),
    deleteBlast: (url, headers) => dispatch(deleteBlast(url, headers)),
    buyBlast: (url, data, header) => dispatch(buyUserBlast(url, data, header))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blast);
