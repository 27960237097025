import React, { Component } from 'react';
import { Link, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { authenticate, signuppage } from '../../Actions/index';
import { css } from '@emotion/core';
import Logo from '../../Components/left-logo';
import { GridLoader, BounceLoader } from 'react-spinners';
import Layout from '../layout/layout';
import search from '../../Utils/Assets/search_icon.png';

import '../Landing/landing.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
  position: relative;
`;

class MyVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout>
        <div className="help-section ">
          <div className="container">
            <div className="mb-4">
              <h2 className="text-center heading">How to Videos</h2>
              <p className="text-center mb-0"></p>
            </div>
            <div className="col-md-12 mb-5">
              <div id="video-section" className="video-section mb-5">
                <fieldset class="scheduler-border border-light">
                  <legend class="scheduler-border">
                    <b>HOW-TO-VIDEOS</b>
                  </legend>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <p>Using OTR calendar</p>
                      <div class="video">
                        <iframe
                          width="100%"
                          src="https://www.youtube.com/embed/yodw5jIOBt4"
                          frameborder="0"
                          title="video 1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p>What are benefits to following</p>
                      <div class="video">
                        <iframe
                          width="100%"
                          src="https://www.youtube.com/embed/yodw5jIOBt4"
                          frameborder="0"
                          title="video 1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p>How do a change my reminders</p>
                      <div class="video">
                        <iframe
                          width="100%"
                          src="https://www.youtube.com/embed/yodw5jIOBt4"
                          frameborder="0"
                          title="video 1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p>How do a change my reminders</p>
                      <div class="video">
                        <iframe
                          width="100%"
                          src="https://www.youtube.com/embed/yodw5jIOBt4"
                          frameborder="0"
                          title="video 1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p>How do a change my reminders</p>
                      <div class="video">
                        <iframe
                          width="100%"
                          src="https://www.youtube.com/embed/yodw5jIOBt4"
                          frameborder="0"
                          title="video 1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p>How do a change my reminders</p>
                      <div class="video">
                        <iframe
                          width="100%"
                          src="https://www.youtube.com/embed/yodw5jIOBt4"
                          frameborder="0"
                          title="video 1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p>How do a change my reminders</p>
                      <div class="video">
                        <iframe
                          width="100%"
                          src="https://www.youtube.com/embed/yodw5jIOBt4"
                          frameborder="0"
                          title="video 1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p>How do a change my reminders</p>
                      <div class="video">
                        <iframe
                          width="100%"
                          src="https://www.youtube.com/embed/yodw5jIOBt4"
                          frameborder="0"
                          title="video 1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  componentDidMount() {
    // this.props.resetSignupPage();
    // console.log('componentDidMount');
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch => {};

export default connect(mapStateToProps, mapDispatchToProps)(MyVideos);
