import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import createHistory from 'history/createHashHistory';
import { getstatic } from '../../../Actions/index';
import { getProfileDetails } from '../../../Actions/profile-setup';
import axios from 'axios';
import { Form, FormGroup, Input } from 'reactstrap';

// import "../Landing/landing.css";
import { notify } from 'react-notify-toast';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import LinkScroll from 'react-scroll';
import { BASEURL } from '../../../constant';

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select: '',
      message: '',
      email: '',
      display_name: '',
      url: window.location.href
    };

    this.notifyHandle = this.notifyHandle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    const profileData = this.props.profileData.profile;
    //  console.log("ejkjkkkkkkjjkjkjkjkjkjkjk",profileData);

    this.setState({
      // email: profileData.email,
      display_name: profileData.display_name
    });

    console.log(this.state);
  };

  notifyHandle = () => {
    notify.show('Email sent successfully.', 'custom', 3000, {
      background: '#d4edda',
      text: '#155724',
      borderColor: '#c3e6cb'
    });
  };

  handleSubmit = async e => {
    console.log("abcccc");
    e.preventDefault();
    const { select, message, email, display_name } = this.state;
    const name = email.substring(0, email.indexOf('@'));

    const res = await axios.post(
      'https://websul.zendesk.com//api/v2/requests.json',

      {
        request: {
          requester: { name: name, email: email },
          subject: select,
          comment: { body: message }
        }
      }
    );
    console.log('res', res);
    if (res) {
      const { id } = res.data.request;
      const response = await axios.post(
        BASEURL + '/api/v1/help',
        {
          select,
          message,
          email,
          display_name,
          requestId: id
        },
        { headers: { 'x-authorization': localStorage.token } }
      );

      await this.setState({ message: '', select: '' });

      if (response.status === 200)
        notify.show('Email sent successfully.', 'custom', 3000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
    }
  };

  render() {
    // window.scrollTo(0, 0);
    // const profileData = this.props.profileData.profile;
    // const globalHistory = new createHistory();
    const data = [
      {
        title: 'GENERAL',
        questions: [
          {
            ques: 'Is this a social media site?',
            ans: (
              <>
                <div>
                  <p>
                    On Target Romance (OTR) is an organizational reading
                    community platform that enables authors to target and
                    organize the marketing and launch of a book release and
                    readers and bloggers to quickly browse, filter and search
                    the OTR calendar to organize their own private reading
                    calendar with reminders.
                  </p>
                  <p>
                    OTR is a centralized hub for Romance book events data,
                    taking the guesswork out of the process to free up time for
                    reading, writing, RL and social media.
                  </p>
                </div>
              </>
            )
          },
          {
            ques: 'What do the different event colors mean?',
            ans: (
              <>
                <div>
                  <p>
                    Colors on the calendar are linked to specific events as
                    follows:
                    <ul>
                      <li>Blue: Book release</li>
                      <li>Orange: Book promo</li>
                      <li>Yellow: Cover reveal</li>
                      <li>Red: ARC sign-up</li>
                      <li>Green: Giveaway</li>
                      <li>Pink: Release party</li>
                      <li>Purple: Book Boxes & Signings</li>
                  <li>Lilac: Ticket/box sales (live)</li>
                    </ul>
                  </p>
                  <p>
                    In addition, OTR calendar view indicates the total number of
                    events for each category.
                  </p>
                </div>
              </>
            )
          },
          {
            ques:
              'Is OTR available in all countries? How do I pay for my membership?',
            ans: (
              <>
                <div>
                  <p>
                    OTR is a global company. We accept PayPal and all major
                    credit cards via a third-party payment processor.
                  </p>
                </div>
              </>
            )
          }
        ]
      },
      {
        title: 'READERS',
        questions: [
          {
            ques: 'Will I be able to sign-up for ARCs?',
            ans: (
              <>
                <div>
                  <p>
                    Depending on if an ARC (Advance Reader Copy) is available to
                    the public, authors and PR teams can choose to extend ARC
                    sign-ups to all OTR members, including readers, or limit
                    them to bloggers and the PR industry.
                  </p>
                  <p>
                    <em>
                      *Pro tip: Consider adding yourself to the OTR Beta reader
                      list if you are interested in beta reading for authors. If
                      chosen, you get to provide input and feedback before the
                      ARC phase.
                    </em>
                  </p>
                </div>
              </>
            )
          },
          {
            ques: 'Why should I follow authors, premium bloggers, PR firms or bookstores?',
        ans: (
          <>
            <div>
              <p>
              By following authors, premium bloggers, PR firms, and bookstores on On Target Romance (OTR), you gain several advantages helping you stay on target: 
              <ul className='mt-2'>
                <li>Notifications: Following authors, premium bloggers, PR firms, and bookstores also ensures that you receive notifications specific to their activities. You'll be promptly alerted about any new book release events, or other engagements they share on the platform. This keeps you connected with their work and helps you engage with the romance community.</li>
                <li className='mt-2'>OTR Blasts: Following these members enables you to receive OTR Blasts, written by the member and serving as a comprehensive source of information, consolidating important announcements and updates from the authors, premium bloggers, PR firms and bookstores you follow. It's a convenient way to stay informed and never miss out on exciting opportunities.</li>
              </ul>
              </p>
            </div>
          </>
        )
          }
        ]
      },
      {
        title: 'BLOGGERS',
        questions: [
          {
            ques:
              'What is the difference between Blogger and Blogger Premium memberships?',
            ans: (
              <>
                <div>
                  <p>
                    Both Blogger and Blogger Premium memberships have full
                    access to all OTR calendar events and an automatic feature
                    on the Blogger list.
                  </p>
                  <p>
                    Blogger Premium members have an OTR profile page with links,
                    info, the ability to gain followers and earn (or purchase)
                    OTR Blasts to their followers. They can also add Giveaways
                    and Parties to the OTR calendar and stay organized with
                    Daily Tasks.
                  </p>
                  <p>
                    Learn more{' '}
                    <Link
                      target="_blank"
                      className="link-span"
                      to="/dashboard/plans"
                    >
                      here.
                    </Link>
                  </p>
                </div>
              </>
            )
          },
          {
            ques: 'How do I ensure that I don’t miss a blog tour or sign-up?',
            ans: (
              <>
                <div>
                  <p>
                    To ensure you don’t miss a blog tour or sign-up, follow your
                    favorite PR firms and authors. Click on their profile, then
                    go to their Linked Events page to view links to every event
                    they have added to the OTR calendar.
                  </p>
                  <p>
                    You can filter the calendar for recently added events to
                    quickly scan events added since your last session.
                  </p>
                </div>
              </>
            )
          }
        ]
      },
      {
        title: 'VENDOR',
        questions: [
          {
            ques:
              'I’d like to grow my business. How can OTR assist me with that?',
            ans: (
              <>
                <div>
                  <p>
                    OTR offers a networking section for authors,  PR firms, bookstores, and
                    vendors to discover each other and interact (via messaging).
                    All vendors are featured in the vendor tab where authors can
                    search, view profiles, and reach out via direct message
                    and/or other contact. Vendors can also add upcoming VCOs
                    (Vendor Cancellation Openings) to show availability on the
                    Vendor list.
                  </p>
                  <p>
                    Additionally, you can stay organized with Daily Tasks, a
                    private calendar to manage your daily routine with to-do
                    lists, notes, links, posting data & reminders.
                  </p>
                </div>
              </>
            )
          },
          {
            ques:
              'As a vendor, do I need a membership for each service I provide? Is there a limit to the number of services I can list?',
            ans: (
              <>
                <div>
                  <p>
                  As a vendor, you don't need separate memberships for each service you provide. With a Vendor membership, you have the flexibility to showcase multiple services under your profile. When setting up your vendor profile, you can checkmark all the services you offer from a comprehensive list of options. These services include Agent, Blurb writer, Book Boxes, Book cover designer, Book formatter, Book signing organizer, Cover model, Editor, Graphic designer, Merchandising, Personal Assistant (PA), Photographer, Proofreader, Publisher, Social media consultant, Social media manager, Swags, Web design, Workshops. You can select as many services as you provide, ensuring that you highlight your full range of expertise to potential clients.
                  </p>
                  <p>
              You can feature booking openings as Vendor Cancellation Openings (VCO) visible to authors, vendors, PR firms and bookstores on the OTR Vendor list. 
              </p>
                </div>
              </>
            )
          }
        ]
      },
      {
        title: 'AUTHORS',
        questions: [
          {
            ques:
              'What is the difference between the Author and Author Premium membership?',
            ans: (
              <>
                <div>
                  <p>
                    Both plans share similar features. However, the Author plan*
                    is limited to three Book Releases per membership year,
                    whereas the Author Premium membership offers unlimited Book
                    Release per membership year. Learn more{' '}
                    <Link
                      target="_blank"
                      className="link-span"
                      to="/dashboard/plans"
                    >
                      here.
                    </Link>
                  </p>
                  <p>
                    <em>
                      *Author members can purchase add-on Book Release events or
                      upgrade to the Author Premium plan at any point. When
                      upgrading you’ll pay the current Author Premium membership
                      fee and receive prorated credit for the unused portion of
                      what you paid for the Author membership.
                    </em>
                  </p>
                </div>
              </>
            )
          },
          {
            ques: 'How can OTR help my writing career?',
            ans: (
              <>
                <div>
                  <p>
                    OTR aims to free up your time so you can focus on writing!
                  </p>
                  <ul>
                    <li>
                      Your author profile holds everything in one convenient
                      place: contact info, links to your newsletter and master
                      ARC sign-ups, as well as a link to the Linked event page
                      which features all the current and future events you have
                      added to the OTR calendar.
                    </li>
                    <li>
                      You can add every type of event to the OTR calendar and
                      update them as needed (e.g., you can add the book when you
                      start outlining, before you have a blurb). Simply
                      checkmark up to three tropes and three sub-genres to help
                      readers find it.
                    </li>
                    <li>
                      Each of your Book releases have a Linked event page that
                      features links to all events tied to that book release,
                      including those added by another member e.g. a giveaway
                      hosted by a blogger.
                    </li>
                    <li>
                      You get a free release day Blast per book to alert
                      followers who added your book release. You can earn more
                      Blasts by reaching follower milestones and also purchase
                      additional Blasts to keep your followers updated.
                    </li>
                    <li>
                      You have access to the OTR Blogger and Beta reader lists.
                    </li>
                    <li>
                      You can network with  vendors, PR firms and bookstores. Discover them
                      on the vendor list, then use OTR messaging to connect.
                      Alternatively, obtain their contact info and social media
                      links via their profile.
                    </li>
                    <li>
                      You have full use of OTR Daily Tasks, a private calendar
                      for organizing your daily routine with notes, links &
                      reminders. Create a to-do-list, save posting data or track
                      writing goals. Learn more{' '}
                      <Link
                        target="_blank"
                        className="link-span"
                        to="/dashboard/daily-task"
                      >
                        here.
                      </Link>
                    </li>
                    <li>
                      Lastly, consider advertising your book release on OTR with
                      a week-long banner on the website and mobile app. Learn
                      more{' '}
                      <Link
                        target="_blank"
                        className="link-span"
                        to="/dashboard/advertise"
                      >
                        here.
                      </Link>
                    </li>
                  </ul>
                </div>
              </>
            )
          },
          {
            ques:
              'Who can upload a book cover? What types of covers can be uploaded?',
            ans: (
              <>
                <div>
                  <p>
                    {/* Although the author is the only one who can add their Book
                    Release event to the OTR calendar, any Author, Blogger
                    Premium or PR firm can add the book cover to an event tied
                    to the book release. The book cover will then sync to all
                    events linked to that book release event. */}
                    When it comes to uploading a book cover, the author holds the responsibility of adding their Book Release event to the OTR calendar. However, any Author, Blogger Premium, or PR Firm/Bookstore adding an event associated with the book can add the book cover to their event then sync to all events. This ensures that the book cover is visible across all events connected to that particular book release.
                  </p>
                  <p>
                    OTR allows for temporary, official, and alternate (e.g.
                    limited edition) book covers.
                  </p>
                  <p>
                    Temporary covers are automatically replaced when an official
                    or alternate cover is uploaded.
                  </p>
                </div>
              </>
            )
          }
        ]
      },
      {
        title: 'PR FIRMS/BOOKSTORE',
        questions: [
          {
            ques:
              'Can we share an ARC master sign-up link with readers and bloggers?',
            ans: (
              <>
                <div>
                  <p>
                    In addition to links to website, social media, and fangroup
                    there are several fields in a PR Firm/Bookstore profile where you can
                    add a master sign-up link such as best site for
                    bloggers/readers to interact with you and the BIO section.
                  </p>
                  <p>
                    When PR firms add book promo events for their clients that
                    event is centered around the sign-up link and dates.
                  </p>
                </div>
              </>
            )
          },
          {
            ques: 'Can we advertise on OTR on behalf of a client?',
            ans: (
              <>
                <div>
                  <p>
                    Yes, PR Firms can purchase week-long banner ad on the OTR
                    website/mobile app to promote an upcoming/recent book
                    release event* on behalf of a client. Limited spots are
                    available each week.
                  </p>
                  <p>
                    Learn more{' '}
                    <Link
                      target="_blank"
                      className="link-span"
                      to="/dashboard/advertise"
                    >
                      here.
                    </Link>
                  </p>
                  <p>
                    <em>
                      *The book release must first be added to OTR by the
                      author.
                    </em>
                  </p>
                </div>
              </>
            )
          }
        ]
      }
    ];

    return (
      <div className="help-section">
        <div className="container">
          <div className="mb-4">
            <h2 className="text-center heading">Help</h2>
          </div>
          <div className="col-12 text-center">
            <ul className="top-links list-unstyled d-flex justify-content-center">
              <li>
                <LinkScroll.Link to="faq-section" spy={true} smooth={true}>
                  faq
                </LinkScroll.Link>
              </li>{' '}
              |
              <li>
                <LinkScroll.Link to="video-section" spy={true} smooth={true}>
                  How-to-videos
                </LinkScroll.Link>
              </li>{' '}
              |
              <li>
                <LinkScroll.Link to="contact-section" spy={true} smooth={true}>
                  Contact us
                </LinkScroll.Link>
              </li>
            </ul>
            <div id="faq-section" className="faq-section mb-5">
              <fieldset class="scheduler-border border-light">
                <legend class="scheduler-border">
                  <b>FAQ</b>
                </legend>

                <div className="row mb-5">
                  <div className="col-md-10 col-lg-8 mx-auto">
                    <Accordion allowZeroExpanded>
                      {data.map((memberr, index) => {
                        return (
                          <div className="AccordionPannel">
                            <h4 className="text-center m-2">{memberr.title}</h4>
                            <div className="AccordionList">
                              {memberr.questions.map((item, index) => {
                                return (
                                  <AccordionItem key={index}>
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        {item.ques}
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      {item.ans}
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                );
                              })}
                            </div>
                            <a href="/#/dashboard/faq" className="moreLink">
                              More <i class="fa fa-arrow-right"></i>
                            </a>
                          </div>
                        );
                      })}
                      {/* <div className="AccordionPannel">
                        <h4 className="text-center m-2">GENERAL</h4>
                        <div className="AccordionList">
                        <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                              Do the colors of event have a meaning?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                              Yes. To give members a quick overview of the calendar we have color coded events plus each color event line contains a number indicating the number of events for each. The colors are:
Book release is blue. Book promo is orange. Cover reveal is yellow. ARC sign-up is red. Giveaway is green. Release party is pink. Book signing is purple. Book signing ticket sale start is lilac.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                              Do the colors of event have a meaning?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                              Yes. To give members a quick overview of the calendar we have color coded events plus each color event line contains a number indicating the number of events for each. The colors are:
Book release is blue. Book promo is orange. Cover reveal is yellow. ARC sign-up is red. Giveaway is green. Release party is pink. Book signing is purple. Book signing ticket sale start is lilac.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                              Do the colors of event have a meaning?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                              Yes. To give members a quick overview of the calendar we have color coded events plus each color event line contains a number indicating the number of events for each. The colors are:
Book release is blue. Book promo is orange. Cover reveal is yellow. ARC sign-up is red. Giveaway is green. Release party is pink. Book signing is purple. Book signing ticket sale start is lilac.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          
                     
                        </div>
                        <a href="/#/dashboard/faq" className="moreLink">
                          More <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>
                      <div className="AccordionPannel">
                        <h4 className="text-center m-2">READERS</h4>
                        <div className="AccordionList">
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Is this a social media site?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                No this is an organizational reading community
                                platform that enables authors to target and
                                organize the marketing and launch of a book
                                release and enables readers and bloggers to
                                quickly browse calendar, filter and search to
                                organize their own personal calendar with
                                reminders. It’s a centralized hub for all book
                                release data navigating and focusing readers,
                                taking the guessing out of the process and
                                freeing up time for reading, writing, RL and
                                social media.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                My life is busy I don’t want more emails. Can I
                                change settings on reminders?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Calendar reminders show up as site
                                notifications. You have the option in setting
                                change email reminders to: Off, daily or weekly.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </div>
                        <a href="/#/dashboard/faq" className="moreLink">
                          More <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>

                      <div className="AccordionPannel">
                        <h4 className="text-center m-2">BLOGGERS</h4>
                        <div className="AccordionList">
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What happens when an author changes the release
                                date or other info?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                The event will move in the OTR calendar and
                                reflect accordingly for members who have added
                                the event to their personal calendars. All data
                                will sync accordingly.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                I’d like to do a party of giveaway to celebrate
                                an event. Can I add to calendar?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Yes if you have a bloggers Premium membership
                                you can add parties and giveaways. This is an
                                excellent way to promote your blog and your
                                social media accounts, build your fanbase,
                                establish reports with authors while celebrating
                                the books you enjoyed.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </div>
                        <a href="/#/dashboard/faq" className="moreLink">
                          More <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>
                      
                      <div className="AccordionPannel">
                        <h4 className="text-center m-2">VENDOR</h4>
                        <div className="AccordionList">
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                              I’d like to grow my business what do you offer to assist with that?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                              There is a networking section for authors, PR firms and vendors to interact (via messaging). All vendors are featured in a searchable vendor list where authors can search, view profiles and reach out via direct message and/or other contact.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                              I’d like to grow my business what do you offer to assist with that?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                              There is a networking section for authors, PR firms and vendors to interact (via messaging). All vendors are featured in a searchable vendor list where authors can search, view profiles and reach out via direct message and/or other contact.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </div>
                        <a href="/#/dashboard/faq" className="moreLink">
                          More <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>

                      <div className="AccordionPannel">
                        <h4 className="text-center m-2">AUTHORS</h4>
                        <div className="AccordionList">
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What are Daily Tasks?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Daily Tasks (with reminders) is a private
                                calendar for organizing daily tasks with notes,
                                links and reminders. Create a to-do-list, save
                                posting data or track writing goals. Use the
                                Daily Tasks notes feature with reminders to keep
                                track of writing goals and to help stay
                                organized.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                I’m a new author and only plan to release one
                                book this year, can I pay to add one book
                                release only?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                We offer two types of author memberships: Author
                                and Author Premium. The added benefit to the
                                Author Premium plan is that members can add
                                unlimited Book Releases to the calendar, while
                                an Author membership can add up to three Book
                                Releases per membership year (with the option of
                                paying extra for additional).
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What is Vendor Cancellation Openings list (VCO)?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Vendors and PR Firms can register on
                                Cancellation Openings (VCO) is a list exclusive
                                to OTR networking members who can subscribe to
                                list to reserve services with previously booked
                                vendors.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </div>
                        <a href="/#/dashboard/faq" className="moreLink">
                          More <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>
                      <div className="AccordionPannel">
                        <h4 className="text-center m-2">PR FIRMS</h4>
                        <div className="AccordionList">
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Do the colors of event have a meaning?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Yes. To give members a quick overview of the
                                calendar we have color coded events plus each
                                color dot contains a number indicating the
                                number of events for each. The colors are: Book
                                release is blue. Book promo is orange. Cover
                                reveal is yellow. ARC signup is red. Giveaway is
                                green. Release party is pink. Book signing is
                                purple. Book signing ticket sale start is lilac.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </div>
                        <a href="/#/dashboard/faq" className="moreLink">
                          More <i class="fa fa-arrow-right"></i>
                        </a>
                      </div> */}
                    </Accordion>
                  </div>
                </div>
              </fieldset>
            </div>
            <div id="video-section" className="video-section mb-5">
              <fieldset class="scheduler-border border-light">
                <legend class="scheduler-border">
                  <b>HOW-TO-VIDEOS</b>
                </legend>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <p>Using OTR calendar</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>What are benefits to following</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>How do a change my reminders</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-right mb-3">
                    <a href="/#/dashboard/howto" class="moreLink">
                      More <i class="fa fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </fieldset>
            </div>
            <div id="contact-section" className="contact-section mb-5">
              <fieldset class="scheduler-border border-light">
                <legend class="scheduler-border">
                  <b>CONTACT US</b>
                </legend>
                <div className="row mb-5">
                  <div className="col-md-10 col-lg-8 mx-auto">
                    <div className="frame-heading">
                      Still have not found what you are looking for? Contact us:
                    </div>
                    <div className="frame-container">
                      <div className="frame-content">
                        <Form onSubmit={this.handleSubmit}>
                          <FormGroup>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              value={this.state.select}
                              onChange={this.handleChange}
                              required
                            >
                              <option value="">--Select type of issue--</option>
                              <option>Membership</option>
                              <option>Tech support</option>
                              <option>Billing</option>
                              <option>Customer service</option>
                              <option>Advertising</option>
                              <option>Report an issue or bug </option>
                              <option>Privacy and security</option>
                            </Input>
                          </FormGroup>

                          <FormGroup>
                            <Input
                              type="text"
                              name="email"
                              id="email"
                              onChange={this.handleChange}
                              value={this.state.email}
                              placeholder="Preferred email"
                              required
                            ></Input>
                          </FormGroup>

                          <FormGroup>
                            <Input
                              type="textarea"
                              name="message"
                              id="exampleText"
                              placeholder="Message"
                              rows="5"
                              value={this.state.message}
                              onChange={this.handleChange}
                              required
                            />
                          </FormGroup>
                          <FormGroup className="text-right">
                            <button className="btn btn_save_bt btn-dark">
                              Submit
                            </button>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>{' '}
            <div className="edit-profile-btn mt-2">
              <a href="/" class="">
                <button
                  type="submit"
                  className="btn new_btn_save_bt m-0"
                  style={{
                    marginRight: '0% !important'
                  }}
                >
                  Back to Dashboard
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // console.log("window.location.search", window.location.href)
    window.scrollTo(0, 0);
    const id = window.location.href.split('?id=')[1];
    if (id) {
      let ele = document.getElementById(id);
      if (ele) ele.scrollIntoView();
    }
    // console.log("jjjjj",id);
  }
}

//{======= VideoFrames Component ========}

const mapStateToProps = state => {
  return {
    pages: state.userListData,
    profileData: state.myProfileData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getstatic: url => dispatch(getstatic(url)),
    getUserProfile: (url, header, edit) =>
      dispatch(getProfileDetails(url, header, edit))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
