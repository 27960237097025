import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import 'react-quill/dist/quill.snow.css';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment-timezone';

import CONSTANT from '../../constant';
//import "react-big-calendar/lib/css/react-big-calendar.css";
import { HashLoader } from 'react-spinners';
import {
  saveDetail,
  getAllTasks,
  addEventFilter
} from '../../Actions/dailytask';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Route, Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
//import moment from 'moment';
import '../dailyTask/dailyTask.css';
import ViewTask from './viewTaskModal';
import { notify } from 'react-notify-toast';
import createHistory from 'history/createHashHistory';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Axios from 'axios';
import { BASEURL } from '../../constant';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';
import ReactTooltip from 'react-tooltip';
// import moment from 'moment-timezone';
// moment.tz.setDefault('America/New_York');
const globalHistory = new createHistory();

//Calendar week starts with monday

moment.locale('ko', {
  week: {
    dow: 1,
    doy: 1
  }
});
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

moment.tz.setDefault(timezone);

const localizer = momentLocalizer(moment);

class DailyTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileKeyData: [],
      show: false,
      task_details: '',
      startDate: null,
      isCalendar: true,
      allDetails: [],
      title: '',
      viewModal: false,
      calender_date: ''
    };
    this.quill = React.createRef();
    this.apiPostNewsImage = this.apiPostNewsImage.bind(this);
    // this.showTask = this.showTask.bind(this);
  }

  handleEditorChange = (event, editor) => {
    // this.setState({ task_details: e });
    const data = editor && editor.getData();
   

    this.setState({ task_details: data });
  };
  // handleChanges = (event, editor) => {
  //   const data = editor && editor.getData();

  //   this.setState({ editorHtml: data });
  // };
  handleCalendarDate(date) {
    this.setState({
      calender_date: date
    });
  }
  hideViewModal = e => {
    this.setState({ viewModal: false });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
   
      const formData = new FormData();
   
      formData.append('file', file);
      const Quill = this.quill.current.getEditor();

      const range = Quill.getSelection(true);

      // Quill.insertEmbed(
      //   range.index,
      //   "image",
      //   `${window.location.origin}/uploads/images/${file.name}`
      // );

      // Quill.setSelection(range.index + 1);

      const res = await this.apiPostNewsImage(formData);
      // Quill.deleteText(range.index, 1);
      Quill.insertEmbed(range.index, 'image', res.data);
    };
  };
  apiPostNewsImage = async data => {
    const response = await Axios.post(
      `${BASEURL}/api/v1/users/uploadBlastImage`,
      data,
      {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      }
    );
    return response;
  };

  uploadAdapter = loader => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(file => {
            body.append('file', file);
         

            Axios.post(`${BASEURL}/api/v1/users/uploadBlastImage`, body, {
              headers: {
                'x-authorization': localStorage.getItem('token')
              }
            })
              .then(res => res.data)
              .then(res => {
            
                resolve({
                  default: res
                });
              })
              .catch(err => {
                reject(err);
              });
          });
        });
      }
    };
  };
  uploadPlugin = editor => {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return this.uploadAdapter(loader);
    };
  };
  handleSubmit = event => {
    event.preventDefault();
    let date = this.state.calender_date;
    let task_data = this.state.task_details;

    if (date) {
      date = moment(date).format('YYYY-MM-DD');
    }

    if (task_data) {
      task_data = task_data;
    }
    if(!this.state.task_details )
    {
      notify.show('Please enter Daily Task details.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });

    }
    else if(!this.state.title)
    {
      notify.show('Please enter Daily Task title.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }
    else if(!date)
    {
      notify.show('Please enter Daily Task date.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });

    }
     else{
    const data = {
      task_message: task_data,
      added_on: moment(date).format('YYYY-MM-DD'),
      title: this.state.title
    };

    this.props.saveDailyTask(`/api/v1/daily_task/addTask`, data, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    this.hideModal();
    this.resetForm();
  }
    
  };

  resetForm() {
    this.setState({
      task_details: '',
      title: '',
      startDate: null,
      calender_date: ''
    });
  }

  saveTask = () => {
    const details = this.state.task_details;
    const date = this.state.startDate;
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
    // this.props.getAllTask(`/api/v1/daily_task/getTask`, { headers: { 'x-authorization': localStorage.getItem("token") } });
  };

  showEvent = task => {
    this.setState({
      viewData: task,
      viewModal: true
    });
  };

  eventStyleGetter = () => {
    //change add task colour
    var backgroundColor = '#ffff8b';
    var style = {
      backgroundColor: backgroundColor,
      color: 'black'
    };
    return {
      style: style
    };
  };

  render() {
    let data = this.props.taskData ? this.props.taskData : [];

    // const Link = Quill.import('formats/link');
    // Link.sanitize = function(url) {
    //   // quill by default creates relative links if scheme is missing.
    //   if (!url.startsWith('http://') && !url.startsWith('https://')) {
    //     return `http://${url}`;
    //   }
    //   return url;
    // };
    // Quill.register(Link, true);

    let filterData = '';

    if (data.length > 0) {
      data.forEach(element => {
        element['start'] = moment(element['added_on']).format(
          'YYYY-MM-DD hh:mm:ss'
        );
        element['end'] = moment(element['added_on']).format(
          'YYYY-MM-DD hh:mm:ss'
        );
        element['title'] = element['title'];
      });
    }

    const endDate = new Date(moment().format('MM/DD/YYYY'));

    const endDate1 = new Date();
    const createDate1 = endDate1.setDate(endDate1.getDate() - 7);
    const createdDate3 = moment(createDate1).format('YYYY-MM-DD');

    const TaskEnd = endDate.setMonth(endDate.getMonth() + 18);
    const EndDate = moment(TaskEnd).format('YYYY-MM-DD');
    filterData = data.filter(
      el => el.added_on >= createdDate3 && el.added_on <= EndDate
    );

    const updatedDate = [];
    data.forEach(el => {
      const added_on = el.added_on;
      updatedDate.push({ ...el, added_on });
    });
  
    const exampledate = moment()
    .add(18, 'M')
    .calendar()

     

    return (
      <div className="container">
        <div className="daily-task-page">
          <div className="mb-4">
            <h2 className="text-center heading">Daily Tasks</h2>
          </div>
          <div className="add-btn">
            <button
              onClick={this.showModal}
              className={
                this.props.displayMode
                  ? 'btn btn_save_bt  daily'
                  : 'btn btn_save_bt white-button daily'
              }
            >
              <span>Add Task</span>
              <img
                src={require('../../Utils/Assets/plus.png')}
                alt="cup-icon"
              />
            </button>
          </div>
          <div
            className={
              this.props.isTheameLight
                ? 'calendar light-calendar'
                : 'calendar calendar-dark dark-calendar'
            }
          >
            <div className="daily-calendar">
              <Calendar
              // style={{ height: 800 }}
                className="calhight"
                // views={allViews}
                events={updatedDate}
                step={720}
                localizer={localizer}
                defaultView="month"
                views={['month']}
                defaultDate={new Date()}
                selectable="ignoreEvents"
                onSelectEvent={task => this.showEvent(task)}
                eventPropGetter={this.eventStyleGetter}
                popupOffset={{ x: 30, y: 20 }}
                onSelectSlot={e => {
                  globalHistory.push(
                    `/dashboard/dailyTaskDetails?startDate=${moment(
                      e.start
                    ).format('YYYY-MM-DD')}`
                  );
                }}
                onNavigate={(date, flipUnit, prevOrNext) => {
                  if (prevOrNext === 'DATE') {
                    globalHistory.push(
                      `/dashboard/dailyTaskDetails?startDate=${moment(
                        date
                      ).format('YYYY-MM-DD')}`
                    );
                  }
                  this.props.childToPropsDaily(prevOrNext);
                }}
              />
            </div>
          </div>

          <ViewTask
            isOpen={this.state.viewModal}
            toggle={this.hideViewModal.bind(this)}
            data={this.state.viewData}
            viewMode={true}
          />
          <Modal
            isOpen={this.state.show}
            toggle={this.hideModal}
            className="daily-task-popup"
            size="lg"
          >
            <ModalHeader toggle={this.hideModal} className="eventModal">
              <h5 className="text-center p-0">Daily Tasks</h5>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={this.handleSubmit}>
                <div class="row">
                  <div class="form-group col-md-6 ">
                    <label for="startDate">Date*:</label>
                    <div className="custom-datepicker">

                      <DatePicker
                        // className="calendar-style"
                       format='MM/DD/YYYY'
                        placeholderText="mm/dd/yyyy"
                        highlightDates={new Date()}
                        //locale="en-US"
                        // minDate={this.state.startDate}
                        minDate={moment().toDate()}
                        selected={this.state.calender_date}
                        maxDate={new Date(EndDate)}
                        id="startDate"
                        name="startDate"
                        locale="en"
                        onChange={date => this.handleCalendarDate(date)}
                      />
                      <i class="fa fa-calendar-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="form-group col-md-6 ">
                    <label for="title">Task Title*:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      name="title"
                      onChange={this.handleChange}
                      value={this.state.title}
                    />
                  </div>
                  <div class="form-group col-md-12 ">
                    <label for="startDate">Task Detail*:</label>
                    <span style={{ display: 'inline-block' }}>
                      <i
                        className="fa fa-question-circle"
                        data-tip
                        data-for={'test'}
                        style={{ fontSize: '1.2em' }}
                      />
                      <ReactTooltip
                        className="toolTip"
                        id={'test'}
                        aria-haspopup="true"
                        effect="solid"
                        place="right"
                      >
                        <p style={{ margin: 0, padding: 0 }}>
                          {`Daily Tasks can be used for personal notes, To-do, posting text, campaign notes, writing goals etc.
                      Compose using Text Formatter bar below. Max image size is 3MB. Images can be resized by dragging & a caption added. Use 2 arrow symbols around image to add text fields above or below.
                         Daily Tasks can be edited & deleted. Click on LIST view to see all your Daily Tasks from last 7 days and 18 months into the future. To view older Daily Tasks; Scroll in DT calendar view and click on individual days or Daily Tasks. To adjust Daily Task Reminders; Go to Preference page under Settings, turn Reminders on or off and/or set to Daily or Weekly Digest.

                         `}
                         <br/>

                         {`Default font: Raleway 12 pts.`}
                        </p>
                        {/* {paras.map(el => {
            return [<p style={{ margin: 0, padding: 0 }}>{el}</p>]
          })} */}
                      </ReactTooltip>
                    </span>
                

                    <CKEditor
                      editor={FullEditor}
                      onChange={this.handleEditorChange}
                      config={{
                        extraPlugins: [this.uploadPlugin],
                        toolbar: {
                          items: [
                            'fontSize',
                            'fontFamily',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            'imageStyle:full',
                            'imageStyle:side',
                            'imageStyle:alignLeft',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            // 'insertTable',
                            'fontColor'
                          ],
                          image: {
                            // Configure the available styles.
                            styles: [
                              'alignLeft',
                              'alignCenter',
                              'alignRight'
                            ],
                            sizes: ['50%', '75%', '100%'],
                            toolbar: [
                              'imageStyle:alignLeft',
                              'imageStyle:alignCenter',
                              'imageStyle:alignRight',
                              '|',
                              'imageResize',
                              '|',
                              'imageTextAlternative'
                            ]
                          }
                        },
                        fontFamily: {
                          options: [
                            'Arial, Helvetica, sans-serif',
                            'Calibri',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Raleway, Geneva, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif',                                         
                          ],
                          supportAllValues: true
                        },
                        fontSize: {
                          options: [12,14, 16, 18, 20, 22, 24],
                      }
                      }}
                    />
                    <button
                      type="submit"
                      onClick={this.saveTask}
                      className={
                        'btn btn_save_bt' || 'btn btn_save_bt dark-button'
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.props.getAllTask(`/api/v1/daily_task/getTask`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }

  componentWillReceiveProps(newProps) {
    let newData = newProps;
    if (newData) {
      if (this.state.value !== newData.email_notification) {
        this.setState({
          value: newData.email_notification,
          prefrence: newData.emails_frequency
        });
      }
      if (this.state.prefrence !== newData.email_notification) {
        this.setState({
          value: newData.email_notification,
          prefrence: newData.emails_frequency
        });
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    taskData: state.filterTask,
    displayMode: state.changeMode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveDailyTask: (url, data, headers) =>
      dispatch(saveDetail(url, data, headers)),
    getAllTask: (url, headers) => dispatch(getAllTasks(url, headers))
    //addFilterData: (data) => dispatch(addEventFilter(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyTask);
