import React, { Component } from 'react';
import { Link, StaticRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import createHistory from 'history/createHashHistory';
// import { authenticate, signuppage } from '../../Actions/index';
import { css } from '@emotion/core';
// import Logo from '../../Components/left-logo';
// import { GridLoader, BounceLoader } from 'react-spinners';
import Layout from '../layout/layout';
// import search from '../../Utils/Assets/search_icon.png';
import ReactDOMServer from 'react-dom/server';

import '../Landing/landing.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
  position: relative;
`;
// const globalHistory = new createHistory();
const data = [
  {
    title: 'GENERAL',
    questions: [
      {
        ques: 'Is this a social media site?',
        ans: (
          <>
            <div>
              <p>
                On Target Romance (OTR) is an organizational reading community
                platform that enables authors to target and organize the
                marketing and launch of a book release and readers and bloggers
                to quickly browse, filter and search the OTR calendar to
                organize their own private reading calendar with reminders.
              </p>
              <p>
                OTR is a centralized hub for Romance book events data, taking
                the guesswork out of the process to free up time for reading,
                writing, RL and social media.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What do the different event colors mean?',
        ans: (
          <>
            <div>
              <p>
                Colors on the calendar are linked to specific events as follows
                <ul>
                  <li>Blue: Book release</li>
                  <li>Orange: Book promo</li>
                  <li>Yellow: Cover reveal</li>
                  <li>Red: ARC sign-up</li>
                  <li>Green: Giveaway</li>
                  <li>Pink: Release party</li>
                  <li>Purple: Book Boxes & Signings</li>
                  <li>Lilac: Ticket/box sales (live)</li>
                </ul>
              </p>
              <p>
                In addition, OTR calendar view indicates the total number of
                events for each category.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Is OTR available in all countries? How do I pay for my membership?',
        ans: (
          <>
            <div>
              <p>
                OTR is a global company. We accept PayPal and all major credit
                cards via a third-party payment processor.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Can I switch memberships before my current plan expires? What happens to data from my previous account?',
        ans: (
          <>
           <div>
              <p>
              Switching between membership types, due to the intricate nature of membership features, is not a viable option. Nevertheless, you have the flexibility to establish a new membership type whenever you desire.
              </p>
              <p>
              It is possible for a member to hold multiple types of memberships concurrently. Each plan necessitates a distinct login, carries its own fee, and retains its individual set of saved data.
              </p>
              <p>
              If you decide to transition to a new membership, events stored in your OTR private calendar can be exported to the new membership.
              </p>
              <p>
                {/* <em>*Author can upgrade to Author Premium.</em> */}
                <em>Note: Authors have the ability to upgrade to Author Premium.</em>
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How do I change my email reminder settings?',
        ans: (
          <>
            <div>
              <p>
                OTR members receive calendar reminders as onsite notifications.
                You can turn off email reminders or choose to receive
                daily/weekly (digest) reminders via the{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/prefrences"
                >
                  Preference
                </Link>{' '}
                page, under Settings.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What is a Beta Reader and the Beta Reader list?',
        ans: (
          <>
            <div>
              <p>
                A beta reader is someone who agrees to read early, unpublished
                draft versions of a book to provide the author with detailed
                feedback about the plot, characters, etc
              </p>
              <p>
                The OTR Beta reader list is accessible to Author and Author
                Premium members. Any member can add themselves to the Beta
                Reader list.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I search for books by keyword and/or sub-genre?',
        ans: (
          <>
            <div>
              <p>
                {/* Yes. Under the event filter tab, there are filter and search
                options available. */}
                Yes, absolutely.  You can search for book releases by keywords/tropes and/or sub-genres by utilizing the event filter function on OTR. To access, go to the OTR event filter or calendar tab, 
                look for and click on the filter button next to search in the toolbar. In the pop-up simply check-mark words and hit “SUBMIT”.

              </p>
              <p>
              By leveraging these filter and search functionalities, you can easily narrow down your search and discover books that align with your interests and preferences.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What types of memberships do you offer?',
        ans: (
          <>
            <div>
              <p>
                OTR offers seven types of memberships. Learn more{' '}
                <Link target="_blank" className="link-span" to="/plans">
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What happens when a book release date is changed?',
        ans: (
          <>
            <div>
              <p>
                When an event is changed, the OTR calendar will sync
                accordingly. No action is needed by the event follower.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Why should I enter an invite code when joining?',
        ans: (
          <>
              <div>
            <p>
            Anyone who joins On Target Romance (OTR) will be asked to enter an invite code. By entering a code you will automatically follow the Author, Blogger Premium, PR Firm or bookstore who invited you. 
            </p>
                <p>
                You will be kept informed of news and book release  events added by that member via OTR notifications and OTR Blasts sent to their followers.
                </p>
                <p>
                <em>*You can manually follow as many members as you’d like.</em>
                </p>
            </div>
          </>
        )
      },
      {
        ques: 'How can I edit my profile?',
        ans: (
          <>
            <div>
              <p>
                All OTR profiles can be edited via Settings &gt;{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/my-profile"
                >
                  Profile
                </Link>{' '}
                &gt; Edit
              </p>
              <p>
                Public OTR profiles are available with the following
                memberships:
                <ul>
                  <li>Blogger Premium</li>
                  <li>Author</li>
                  <li>Author Premium</li>
                  <li>PR Firm/Bookstore</li>
                </ul>
              </p>
              <p>Bloggers have contact info that is visible to Authors.</p>
              <p>
                Vendors have a full profile that is visible to Authors, PR
                Firms, and other Vendors.
              </p>
              <p>
                Learn more{' '}
                <Link target="_blank" className="link-span" to="/plans">
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'How can I export my OTR private reading calendar to a new type of membership?',
        ans: (
          <>
            <div>
              <ul>
                <li>Ensure both memberships are active.</li>
                <li>
                  Go to Settings &gt;{' '}
                  <Link
                    target="_blank"
                    className="link-span"
                    to="/dashboard/membership"
                  >
                    Membership info
                  </Link>
                  .
                </li>
                <li>
                  Scroll down to “import private calendar” and follow the
                  prompts.
                </li>
                <li>
                  A verification email will be sent to complete the process.
                </li>
              </ul>
            </div>
          </>
        )
      },
      {
        ques:
          'If I miss my renewal notice and my membership expires, will I lose my profile and the events added to my OTR calendar?',
        ans: (
          <>
            <div>
              <p>
                OTR stores your profile, private calendar, and events for two
                weeks after your membership expires. Your profile will be
                restored if you renew within this time.
              </p>
            </div>
          </>
        )
      },
      {
        // ques: `I am publishing my first book and I will be signing up for author membership, but I don't want to lose the events I've saved to my private OTR calendar. Can I transfer my current calendar to my new membership?`,
        ques:`I am publishing my first book, how can I transfer my OTR reading calendar to my new author membership?`,
        ans: (
          <>
            <div>
              <p>
                OTR offers members to export their OTR reading calendar (saved
                events) from one membership type to another. While both
                memberships are active look for the{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/membership"
                >
                  Membership info
                </Link>{' '}
                page under Settings. Scroll down to “Import private calendar”
                and follow the prompts. A verification email will be sent to
                complete the process.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Will I be notified if a book goes live early?',
        ans: (
          <>
            <div>
              <p>
                It depends on the author. We recommend that authors use their
                Release Day Blast to notify fans when the book is live, but
                authors can choose when to send the Blast.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I cancel my account?',
        ans: (
          <>
            <div>
              <p>
                You can cancel your account at any time. You will continue to
                have full access to your account until your membership expires.
                Membership fees are non-refundable.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I pay monthly instead of yearly?',
        ans: (
          <>
            <div>
              <p>Sorry, we do not offer monthly memberships</p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I interact with other members?',
        ans: (
          <>
            <div>
              {/* <p>
                Currently, not all OTR memberships have a public profile or
                messaging options. OTR membership plans with networking options
                include:
                <ul>
                  <li>Author</li>
                  <li>Author Premium</li>
                  <li>PR Firm</li>
                  <li>Vendor</li>
                </ul>
              </p>
              <p>
                Additionally, Authors can access Blogger and Beta reader email
                addresses via Blogger and Beta reader lists.
              </p> */}
              <p>Interacting with other members on OTR is dependent on the specific membership plan you have. It's important to note that not all OTR memberships provide public profiles or messaging options. 

</p><p>These are membership plans that offer networking features and opportunities:

</p><p>Author membership: Connect and interact with other authors, vendors, PR firms, and bookstores, fostering relationships within the OTR platform.

</p><p>Author Premium plan: Like the Author membership,OTR offers enhanced networking capabilities with other authors, PR Firms, bookstores and vendors on OTR.

</p><p>PR Firm/Bookstore membership: Specifically designed to facilitate interactions and networking with other members, including authors and vendors.

</p><p>Vendor membership: Networking options enabling engagement with authors, PR firms, and other vendors, creating collaborative opportunities.

</p><p>Readers and bloggers do not have messaging capabilities within the OTR platform. However, authors can still reach out to bloggers via the email address listed on their Blogger profile. And they can contact potential beta readers via email addresses listed on Beta reader list.

</p><p>Additionally, Author, Blogger Premium and PR Firm/Bookstore profiles (accessible to all OTR members) provide a field indicating “best site to interact” with them.
</p>
              <p>
                Learn more{' '}
                <Link target="_blank" className="link-span" to="/plans">
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What are Daily Tasks?',
        ans: (
          <>
            <div>
              {/* <p>
                Daily Tasks is a private calendar for organizing daily tasks
                with notes, links & reminders. Create a to-do-list, save posting
                data or track writing goals.
              </p>
              <p>
                The Daily Tasks option is available in the following membership
                plans:
                <ul>
                  <li>Blogger Premium</li>
                  <li>Vendor</li>
                  <li>Author</li>
                  <li>Author Premium</li>
                  <li>PR Firm</li>
                </ul>
              </p> */}
              <p>Daily Tasks is a feature within OTR that provides a private calendar specifically designed to help you organize your daily routine. It offers a user-friendly interface with two viewing options: calendar view and list view.

</p><p>With Daily Tasks, you can effectively manage your daily to-do lists, jot down important notes, store posting data, save links, and even track your writing goals. It serves as a versatile tool to enhance your productivity and keep you on track with your tasks and objectives.

</p><p>The Daily Tasks feature is accessible to members with the following membership plans:  <ul className='mt-2'>
                  <li>Blogger Premium: Bloggers with a Premium membership can utilize the Daily Tasks feature to streamline their daily routines.</li>
                  <li>Vendor: Vendors on OTR can take advantage of Daily Tasks to stay organized and efficient in their business activities.</li>
                  <li>Author: Authors have access to the Daily Tasks feature, enabling them to effectively manage their writing schedules and marketing campaigns.</li>
                  <li>Author Premium: Similar to the Author membership, Author Premium members can leverage Daily Tasks to enhance their daily productivity.</li>
                  <li>PR Firm/Bookstore: Members can utilize the Daily Tasks feature to stay organized, manage book box subscriptions and/or their client’s PR campaigns effectively.</li>
                </ul>
                </p>
              <p> 
                Learn more{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/daily-task"
                >
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What is Vendor Cancellation Openings (VCO)?',
        ans: (
          <>
            <div>
              {/* <p>
                If a vendor (or PR firm) is not accepting new clients, they can
                add booking cancellations as Vendor Cancellation Openings (VCO)
                visible on the OTR Vendor list. This maximizes opportunities for
                authors to reserve services with previously fully booked
                vendors.
              </p> */}
               <p>
              VCO stands for Vendor Cancellation Opening.</p><p>

VCO refers to a unique functionality designed to enhance the booking process between authors and vendors, (including PR Firms and bookstores) on our platform. In situations where a vendor,  PR firm or bookstore is currently unable to accept new clients, they have the option to list their booking cancellations as VCO openings on the OTR Vendor list. Once the date expires, the list is automatically updated.

</p><p>By doing so, these vendors make their cancellations visible to authors, providing them with valuable opportunities to reserve services with vendors who were previously fully booked. This feature maximizes the chances for authors to secure the services of in-demand vendors, PR firms and bookstores, even if they were initially unavailable. 

              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can members post Affiliate pre-order or purchase links on OTR?',
        ans: (
          <>
            <div>
              <p>
                Although OTR does not prohibit affiliate links, you may want to
                check the terms of your affiliate program before posting
                affiliate links. OTR is not a public site and cannot take
                responsibility for any affiliate program policy violations that
                may arise.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How do I switch to dark mode on OTR?',
        ans: (
          <>
            <div>
              <p>
                OTR offers Light and Dark mode.  To adjust visit the Preference page under Settings.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Who can add book boxes to OTR?',
        ans: (
          
            <div>
              <p>
           <b>Book Boxes & Signings</b>  events can be be featured in the OTR calendar by the following membership plans:
            </p>
            <p className='p-0 m-0'>
-PR Firm/Bookstore </p><p className='p-0 m-0'> 
-Vendor</p><p className='p-0 m-0'>
-Author</p><p className='p-0 m-0'>
-Author Premium
</p>
<p className='pt-2'>
Book Boxes, limited edition or subscription, are featured in the OTR calendar twice. Marked in purple for the sale period and lilac for when boxes go on live.
</p>
            </div>
      
        )
      },
       {
        ques: 'What type of membership is available to book box companies?',
        ans: (
          
            <div>
              <p>
              Book box companies have two membership options available:
            </p>
           <p>
           <b>Vendor:</b> This plan is specifically designed for Vendors to promote book boxes and book signings on the OTR calendar, accessible to all members. With a vendor membership you can feature all your services on your profile, visible on the vendor tab to authors, PR firms, bookstores, and other vendors. You can  network with authors and industry professionals using OTR messaging.
</p><p>
<b>PR Firm/Bookstore:</b> For bookstores & larger Book box companies, the PR Firm/Bookstore is a more comprehensive membership allowing you to feature your book boxes, network with authors & vendors, gain followers, build a fanbase, and communicate directly with them via OTR Blasts.
           </p>
<p className='pt-2'>
Both memberships offer full use of the OTR reading calendar, with search, filter and sort functions, alerts, reminders and much more. PR Firm/Bookstore can also access PR tour events.
</p>
            </div>
      
        )
      },
      {
        ques: 'As a publisher what type of OTR membership should we get?',
        ans: (
          
            <div>
              <p>
              Publishers have two membership options designed to cater to their needs:
            </p>
           <p>
           <b>Vendor:</b> This plan is specifically designed for Vendors to feature all  their services on their profile visible within the vendor tab,  making them easily visible to authors, PR firms, bookstores and other vendors. You can  network with authors and industry professionals using OTR messaging.  Vendors can promote book Boxes & Signings in the OTR calendar, accessible to all members. </p><p>
<b>PR Firm/Bookstore:</b>  For bookstores & larger Book box companies, the PR Firm/Bookstore is a more comprehensive membership enabling you to feature all your services on a public OTR profile. Utilize the vendor tab and network with authors & vendors. Increase your follower base, reach them with timely alerts, gain followers, reach them with alerts and communicate directly with  them via OTR Blasts.  On the OTR calendar you can set-up  promo tours (Cover reveal, ARC signups, Book promo), Giveaways, Release parties as well as Book Boxes & Signings.
 </p>
<p className='pt-2'>
Both memberships offer full access to the OTR reading calendar, with search, filter and sort functions, alert, reminders and much more. PR Firm/Bookstore can also access PR tour events.
</p>
            </div>
      
        )
      },
      {
        ques: 'What is the Boxes & Signings event in the OTR calendar?',
        ans: (
          
            <div>
              <p>
              Boxes & Signings events on the OTR calendar include:
              <ul className='mt-2'>
                <li><b>Book Boxes</b>, limited edition or subscription  </li>
                <li><b>Book Signings</b>, across-the-globe or virtual  </li>
              </ul>
            </p>
        
           
<p className='mt-2'>
The event is featured twice in the OTR calendar twice: Marked in purple for the event/sale period and lilac when tickets/boxes go live.
</p>
            </div>
      
        )
      },
      {
        ques: 'Will I get notified when a new event is added to the OTR calendar?',
        ans: (
          
            <div>
              <p>
              If you follow an author, Blogger Premium, PR Firm/Bookstore you will be notified when they add an event to the OTR calendar.  Here is how it works:
             
            </p>
        
           
<p >
<b>Onsite Notifications:</b> You will receive onsite notifications whenever they add a new event to the OTR calendar. The events will appear as “New Events by members I follow” in Notifications.
</p>
<p>
<b>Email Notifications:</b> In addition to onsite notifications, you also have the option to receive email notifications for new events. Within the Preference page, which can be accessed through the Settings section, you can customize your notification settings. You can choose to receive daily or weekly (digested) email notifications, ensuring that you stay updated with the latest events.
</p>
            </div>
      
        )
      },
      {
        ques: 'Does OTR have promo discounts?',
        ans: (
          
            <div>
              <p>
              OTR occasionally offers special promotional discounts to members and/or the public. These promotional offers can apply to various aspects of the platform, including memberships, renewals, advertising, and Blast purchases. Whether you're interested in any of the seven member plans or specific add-on options, there may be promotional offers available for you.
            </p> 
<p >
To stay updated on the latest promo offers and discounts, visit the <a href='https://ontargetromance.com/offers'>OTR Promo Offer tab </a>
</p>

            </div>
      
        )
      },
      {
        ques: 'How do I find members to follow on the OTR platform?',
        ans: (
          
            <div>
              <p>
              To find authors, Blogger Premium, PR Firms and bookstores on the OTR platform, you can utilize the "All Profiles" list, which includes all members with public profiles. 
            </p> 
<p >
Within this list, you have the option to sort, filter and search columns to a specific member type. By clicking on their profiles, you can view more information and follow them at a click of a button.
</p>

            </div>
      
        )
      },
      {
        ques: 'Don’t see your question here?',
        ans: (
          
            <div>
              <p>
              Visit the <a href='https://ontargetromance.zendesk.com/hc/en-us'>OTR Help Center</a> with many more questions, in-depth answers and search functions. 
            </p> 
            </div>
      
        )
      },
    ]
  },
  {
    title: 'READERS',
    questions: [
      {
        ques: 'Will I be able to sign-up for ARCs?',
        ans: (
          <>
            <div>
              <p>
                Depending on if an ARC (Advance Reader Copy) is available to the
                public, authors and PR teams can choose to extend ARC sign-ups
                to all OTR members, including readers, or limit them to bloggers
                and the PR industry.
              </p>
              <p>
                <em>
                  *Pro tip: Consider adding yourself to the OTR Beta reader list
                  if you are interested in beta reading for authors. If chosen,
                  you get to provide input and feedback before the ARC phase.
                </em>
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Why should I follow authors, premium bloggers, PR firms or bookstores?',
        ans: (
          <>
            <div>
              <p>
              By following authors, premium bloggers, PR firms, and bookstores on On Target Romance (OTR), you gain several advantages helping you stay on target: 
              <ul className='mt-2'>
                <li>Notifications: Following authors, premium bloggers, PR firms, and bookstores also ensures that you receive notifications specific to their activities. You'll be promptly alerted about any new book release events, or other engagements they share on the platform. This keeps you connected with their work and helps you engage with the romance community.</li>
                <li className='mt-2'>OTR Blasts: Following these members enables you to receive OTR Blasts, written by the member and serving as a comprehensive source of information, consolidating important announcements and updates from the authors, premium bloggers, PR firms and bookstores you follow. It's a convenient way to stay informed and never miss out on exciting opportunities.</li>
              </ul>
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'How does OTR help me save time and organize my reading schedule?',
        ans: (
          <>
            <div>
              <p>
              OTR offers several features and functionalities that aim to streamline your reading experience and enhance your organizational capabilities. Here's how OTR can assist you in saving time and organizing your reading schedule:
                <ul>
                  <li>
                  Onsite & Email Reminders: OTR provides onsite reminders triggered by events saved to your private OTR calendar. Additionally, you can choose to receive daily or weekly (digested) email reminders by leaving email reminders turned on. These reminders ensure that you never miss important book releases and associate events.
                  </li>
                  <li>
                  Follow Your Favorite Authors: By following your favorite authors on OTR, you will be notified when they add new events to the OTR calendar. This keeps you updated with their latest releases, signings, and other activities. You can also receive author Blasts, which provide comprehensive updates and news directly from the authors you follow. You can find your favorite OTR authors on the All Profiles page.
                  </li>
                  <li>
                  Follow PR Firms, Bookstores & Bloggers: Following your favorites allows you to receive reminders and Blasts related to their events. This ensures that you stay informed about the latest promotions, blog tours, reviews, and other exciting activities organized by these industry professionals.
                  </li>
                  <li>
                  Most Followed Members: You can choose to follow the most followed members on OTR. This allows you to stay connected with popular authors, bloggers, PR firms, and bookstores, and receive updates on their events and activities.
                  </li>
                  <li>Sort & Filter Functions: The OTR calendar provides powerful sort and filter functions that help you find exactly what you're looking for. You can filter events by favorite tropes, sub-genres, and even view events added since your last visit by selecting the appropriate time frame (e.g., last 7, 15, or 30 days).</li>
                  <li>
                  Quick Access & Trending Events: You can quickly view today's events by visiting the OTR Event filter. Additionally, the Trending Events list allows you to discover popular and buzzworthy book releases and promotions.
                  </li>
                  <li>
                  OTR Newsletter & Help Resources: Stay in the loop about new features and updates by subscribing to the OTR newsletter, available on the Preference page under Settings. You can also access helpful How-To videos on the OTR Help tab for more ideas and tips. Furthermore, following OTR on social media provides you with additional tips and news.
                  </li>
                  {/* <li>Check out the Trending Events list.</li>
                  <li>
                    Browse OTR ad banners on the website and mobile app
                    (Calendar, Event filter and List tabs).
                  </li>
                  <li>
                    Subscribe to the OTR newsletter to stay in the loop about
                    new features (
                    <Link
                      target="_blank"
                      className="link-span"
                      to="/dashboard/prefrences"
                    >
                      Preference
                    </Link>{' '}
                    page, under Settings).
                  </li>
                  <li>
                    To get more ideas and tips, check out the How-To videos on
                    the OTR{' '}
                    <Link target="_blank" className="link-span" to="/help">
                      Help
                    </Link>{' '}
                    tab.
                  </li>
                  <li>Follow OTR on social media for more tips and news.</li> */}
                </ul>
              </p>
            </div>
          </>
        )
      },
      {
        ques: `I'm a reader. Can I sign up for blogger membership?`,
        ans: (
          <>
            <div>
              <p>
                Absolutely! Avid readers, Bookstagrammers and romance book
                bloggers can opt for Blogger membership and use their Goodreads,
                Amazon or social media profiles as the blog link.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Why should I enter an invite code when joining?',
        ans: (
          <>
            <div>
            <p>
            Anyone who joins On Target Romance (OTR) will be asked to enter an invite code. By entering a code you will automatically follow the Author, Blogger Premium, PR Firm or bookstore who invited you. 
            </p>
                <p>
                You will be kept informed of news and book release  events added by that member via OTR notifications and OTR Blasts sent to their followers.
                </p>
                <p>
                <em>*You can manually follow as many members as you’d like.</em>
                </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Will I get daily notifications when I add something to my private OTR calendar?',
        ans: (
          <>
            <div>
              <p>
                You will receive daily calendar reminders via OTR onsite
                notifications. You can turn off email reminders or choose to
                receive daily/weekly (digest) reminders via the{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/prefrences"
                >
                  Preference
                </Link>{' '}
                page, under Settings.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I search for books by keyword and/or sub-genre?',
        ans: (
          <>
            <div>
              <p>
                Yes. Filter and search options are available under the event
                filter tab.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Do authors know when I follow them?',
        ans: (
          <>
            <div>
              <p>
                Reader memberships do not currently have a profile page,
                therefore members only see the total number of followers.
              </p>
            </div>
          </>
        )
      }
    ]
  },
  {
    title: 'BLOGGERS',
    questions: [
      {
        ques:
          'What is the difference between Blogger and Blogger Premium memberships?',
        ans: (
          <>
            <div>
              <p>
                Both Blogger and Blogger Premium memberships have full access to
                all OTR calendar events and an automatic feature on the Blogger
                list.
              </p>
              <p>
                Blogger Premium members have an OTR profile page with links,
                info, the ability to gain followers and earn (or purchase) OTR
                Blasts to their followers. They can also add Giveaways and
                Parties to the OTR calendar and stay organized with Daily Tasks
              </p>
              <p>
                Learn more{' '}
                <Link target="_blank" className="link-span" to="/plans">
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques: `How do I ensure that I don't miss a blog tour or sign-up?`,
        ans: (
          <>
            <div>
              <p>
                To ensure you don't miss a blog tour or sign-up, follow your
                favorite PR firms and authors. Click on their profile, then go
                to their Linked Events page to view links to every event they
                have added to the OTR calendar.
              </p>
              <p>
                You can filter the calendar for recently added events to quickly
                scan events added since your last session.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I gain followers as a blogger?',
        ans: (
          <>
            <div>
              <p>
                If you have opted for the Blogger Premium membership you can
                gain followers on the OTR platform!
              </p>
              <p>
                Additionally, you can earn (or purchase) Blasts to your
                followers. You may also be featured on the Most Followed list
                for additional exposure
              </p>
              <p>
                Your followers will be notified anytime you add a new event to
                the OTR calendar.
              </p>
              <p>
                Be sure to pass out your “invite code” to potential followers.
                If they use your invite code at sign-up, they'll automatically
                follow you. You can access your Invite code from the{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/membership"
                >
                  Membership info
                </Link>{' '}
                page.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Our blog would like to hold a party or giveaway to celebrate a book release. Can I add it to the OTR calendar?',
        ans: (
          <>
            <div>
              <p>
                Yes! Blogger Premium members can add parties and giveaways to
                the OTR calendar. This is an excellent way to promote your blog
                and social media accounts, build your fanbase, and establish
                rapport with authors while celebrating the books you enjoy.
              </p>
            </div>
          </>
        )
      },
      {
        ques: `Do I need author's permission to do a giveaway tied to a book release?`,
        ans: (
          <>
            <div>
              <p>
                No. If you are giving away ebooks, paperbacks, or audiobooks of
                an upcoming or recent book release, you can add a giveaway on
                the OTR calendar that links to that book release and gets
                featured on the Linked event page for that book release.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Can an author post a blogger master list sign-up on their profile?',
        ans: (
          <>
            <div>
              <p>
                Yes. The author profile page has a field for ARC master list
                sign-up. The Book Promo event will also feature the link for the
                sign-up.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What membership options do I have as a Bookstagrammer??',
        ans: (
          <>
            <div>
              <p>
                Bookstagrammers can join as Blogger Premium, Blogger or Reader.
                If you offer promo packets, you can also consider a  PR Firm/Bookstore
                membership.
              </p>
              <p>
                Learn more{' '}
                <Link target="_blank" className="link-span" to="/plans">
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques: `If I'm not a blogger yet, can I still join as a Blogger?`,
        ans: (
          <>
            <div>
              <p>
                If you are interested in reading ARCs, writing reviews,
                promoting author events, and/or cover reveals, you can
                absolutely sign up for an OTR Blogger membership (using your
                Goodreads, Amazon or social media profiles as blog link).
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'How can the Daily Tasks help our blog?',
        ans: (
          <>
            <div>
              <p>
              Daily Tasks with reminders, included with the Blogger Premium plan, is a private calendar for organizing your daily routine with to-do lists, notes, links, posting data, tracking projects & reminders. Learn more{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/daily-task"
                >
                  here.
                </Link>
              </p>
              <p>
                <em>
                  *It is available with Blogger Premium, Author, Vendor and PR Firm/Bookstore.
                </em>
              </p>
              <p>
                Learn more{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/plans"
                >
                  here.
                </Link>
              </p>
            </div>
         
          </>
        )
      }
    ]
  },
  {
    title: 'VENDOR',
    questions: [
      {
        ques: `I'd like to grow my business. How can OTR assist me with that?`,
        ans: (
          <>
            <div>
              <p>
              OTR offers a networking section for authors, PR firms, bookstores and vendors to discover each other and interact (via messaging).
               All vendors are featured in the vendor tab where authors can search, view profiles, and reach out via direct message and/or other contact.
               Vendors can also add upcoming VCOs (Vendor Cancellation Openings) to show availability on the Vendor list.
              </p>
              <p>
                Additionally, you can stay organized with Daily Tasks, a private
                calendar to manage your daily routine with to-do lists, notes,
                links, posting data & reminders.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'As a vendor, do I need a membership for each service I provide? Is there a limit to the number of services I can list?',
        ans: (
          <>
            <div>
              <p>
              As a vendor, you don't need separate memberships for each service you provide. With a Vendor membership, you have the flexibility to showcase multiple services under your profile. When setting up your vendor profile, you can checkmark all the services you offer from a comprehensive list of options. These services include Agent, Blurb writer, Book Boxes, Book cover designer, Book formatter, Book signing organizer, Cover model, Editor, Graphic designer, Merchandising, Personal Assistant (PA), Photographer, Proofreader, Publisher, Social media consultant, Social media manager, Swags, Web design, Workshops. You can select as many services as you provide, ensuring that you highlight your full range of expertise to potential clients.
              </p>
              <p>
              You can feature booking openings as Vendor Cancellation Openings (VCO) visible to authors, vendors, PR firms and bookstores on the OTR Vendor list. 
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can Vendors add events to the OTR calendar?',
        ans: (
          <>
            <div>
              <p>
                Yes, vendors can add Book Boxes & Signing events to the OTR calendar.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I add my contact info to my vendor profile?',
        ans: (
          <>
            <div>
              <p>
                Yes. vendors have profile pages with contact info that can be
                updated as needed. Vendor profiles are visible to all authors,
                PR firms, bookstores, and other vendors.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What is a VCO?',
        ans: (
          <>
               <div>
              <p>VCO stands for Vendor Cancellation Opening.</p>
              <p>
              Even if a vendor is not accepting new clients, their booking cancellations can be added as Vendor Cancellation Openings (VCOs) on the Vendor list. This enables authors to reserve services with previously fully booked vendors.
              </p>
              <p>
              You can add a VCO from your profile page. Once the date expires, the list is automatically updated.
              </p>
            </div>
          </>
        )
      }
    ]
  },
  {
    title: 'AUTHORS',
    questions: [
      {
        ques:
          'What is the difference between the Author and Author Premium membership?',
        ans: (
          <>
            <div>
              <p>
                Both plans share similar features. However, the Author plan* is
                limited to three Book Releases per membership year, whereas the
                Author Premium membership offers unlimited Book Release per
                membership year. Learn more{' '}
                <Link target="_blank" className="link-span" to="/plans">
                  here.
                </Link>
              </p>
              <p>
                <em>
                  *Author members can purchase add-on Book Release events or
                  upgrade to the Author Premium plan at any point. When
                  upgrading you’ll pay the current Author Premium membership fee
                  and receive prorated credit for the unused portion of what you
                  paid for the Author membership.
                </em>
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How can OTR help my writing career?',
        ans: (
          <>
            <div>
              <p>OTR aims to free up your time so you can focus on writing!</p>
              <ul>
                <li>
                  Your author profile holds everything in one convenient place:
                  contact info, links to your newsletter and master ARC
                  sign-ups, as well as a link to the Linked event page which
                  features all the current and future events you have added to
                  the OTR calendar.
                </li>
                <li>
                  You can add every type of event to the OTR calendar and update
                  them as needed (e.g., you can add the book when you start
                  outlining, before you have a blurb). Simply checkmark up to
                  three tropes and three sub-genres to help readers find it.
                </li>
                <li>
                  Each of your Book releases have a Linked event page that
                  features links to all events tied to that book release,
                  including those added by another member e.g. a giveaway hosted
                  by a blogger.
                </li>
                <li>
                  You get a free release day Blast per book to alert followers
                  who added your book release. You can earn more Blasts by
                  reaching follower milestones and also purchase additional
                  Blasts to keep your followers updated
                </li>
                <li>
                  You have access to the OTR Blogger and Beta reader lists.
                </li>
                <li>
                  You can network with  vendors, PR firms and bookstores. Discover them on
                  the vendor list, then use OTR messaging to connect.
                  Alternatively, obtain their contact info and social media
                  links via their profile.
                </li>
                <li>
                  You have full use of OTR Daily Tasks, a private calendar for
                  organizing your daily routine with notes, links & reminders.
                  Create a to-do-list, save posting data or track writing goals.
                  Learn more{' '}
                  <Link
                    target="_blank"
                    className="link-span"
                    to="/dashboard/daily-task"
                  >
                    here.
                  </Link>
                </li>
                <li>
                  Lastly, consider advertising your book release on OTR with a
                  week-long banner on the website and mobile app. Learn more{' '}
                  <Link target="_blank" className="link-span" to="/advertise">
                    here.
                  </Link>
                </li>
              </ul>
            </div>
          </>
        )
      },
      {
        ques:
          'Who can upload a book cover? What types of covers can be uploaded?',
        ans: (
          <>
            <div>
              <p>
                {/* Although the author is the only one who can add their Book
                Release event to the OTR calendar, any Author, Blogger Premium
                or PR firm can add the book cover to an event tied to the book
                release. The book cover will then sync to all events linked to
                that book release event. */}
                When it comes to uploading a book cover, the author holds the responsibility of adding their Book Release event to the OTR calendar. However, any Author, Blogger Premium, or PR Firm/Bookstore adding an event associated with the book can add the book cover to their event then sync to all events. This ensures that the book cover is visible across all events connected to that particular book release.
              </p>
              <p>
                OTR allows for temporary, official, and alternate (e.g. limited
                edition) book covers
              </p>
              <p>
                Temporary covers are automatically replaced when an official or
                alternate cover is uploaded.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I advertise on OTR?',
        ans: (
          <>
              <div>
              <p>
              If you're interested in advertising on OTR, you have the opportunity to place week-long banner ads on the OTR website and mobile app. This advertising option is available for both authors and PR firms, allowing you to effectively promote your upcoming or recent book releases.
              </p>
              <p>By utilizing OTR's advertising services, you can enhance the visibility of your book releases and connect with a wider audience of romance enthusiasts.</p>
              <p>
                Learn more{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/advertise"
                >
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'When adding a Book Release, should I add the optional keywords/tropes and sub-genre?',
        ans: (
          <>
            <div>
              <p>
                OTR highly recommends that you do. Book descriptions (genre,
                category, sub-genre, keywords/tropes) help members discover new
                book releases via the Event filter and Search functions. Many
                avid readers look for books based on favorite tropes.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Is there any way for me to discover and connect with bloggers through OTR?',
        ans: (
          <>
            <div>
              <p>
                Yes, as an author, you have access to the Blogger list which
                features all blogger members and their contact info.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Help. My book cover will not upload.',
        ans: (
          <>
            <div>
              <p>
                Please double-check the image size and dimensions of the cover
                you are trying to upload:
                <ul>
                  <li>
                  <b>Image Size & Dimensions:</b> Ensure that the image size and dimensions of your book cover meet the required specifications. For traditional book covers, the recommended size is 9x6 inches with a 1.5:1 ratio. For eBooks, the suggested dimensions are 2,560x1,600 pixels with a 1.6:1 ratio. Adjusting your cover to match these specifications should facilitate a successful upload.
                  </li>
                  <li>
                  <b>File Size:</b> Verify that the file size of your book cover does not exceed the maximum upload limit of 3 MB. Most cover designers provide files within the range of 750 KB to 3 MB, which is generally suitable for upload. If your file size exceeds the limit, you may need to compress or resize the image before attempting to upload it.
                  </li>
                  <li>
                  <b>File Format:</b> Ensure that your book cover is in a compatible file format. Accepted formats are  JPEG and  PNG. If your cover is saved in a different format, consider converting it to one of the supported file formats before attempting the upload.
                  </li>
                </ul>
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'What can I do to increase my chances of landing on OTR’s "Most Popular" and "Most Followed" lists?',
        ans: (
          <>
            <div>
              <ul>
                <li>
                  Be sure to pass out your invite code to fans and friends (see{' '}
                  <Link
                    target="_blank"
                    className="link-span"
                    to="/dashboard/membership"
                  >
                    Membership info
                  </Link>{' '}
                  page for your code). You earn an automatic follower when they
                  sign up using your code.
                </li>
                <li>
                  Post about OTR in social media and add a link to your OTR
                  profile page so fans can easily follow you (see{' '}
                  <Link
                    target="_blank"
                    className="link-span"
                    to="/dashboard/membership"
                  >
                    Membership info
                  </Link>{' '}
                  page for your link).
                </li>
                <li>
                  Add events as soon as possible and update them so that readers
                  can catch your events early on.
                </li>
                <li>
                  When adding book releases, be sure to add keywords/tropes and
                  sub-genres as many readers base their searches on that.
                </li>
                <li>
                  Consider advertising your book release on OTR. Learn more{' '}
                  <Link target="_blank" className="link-span" to="/advertise">
                    here.
                  </Link>
                </li>
                <li>
                  Utilize OTR Blasts to keep your fan base up to date and engage
                  them with special offers. Learn more{' '}
                  <Link
                    target="_blank"
                    className="link-span"
                    to="/dashboard/blasts"
                  >
                    here.
                  </Link>
                </li>
                <li>
                  When posting about a book release on social media, include a
                  link to the Linked event page, so readers can find all events
                  tied to the book release and add them all (link will require
                  member log-in to view).
                </li>
                <li>
                  Connect and stay in touch with readers by utilizing OTR's Beta
                  reader list.
                </li>
                <li>
                  Let OTR's Daily Tasks feature help you stay on target with
                  marketing, writing and life. Learn more{' '}
                  <Link
                    target="_blank"
                    className="link-span"
                    to="/dashboard/daily-task"
                  >
                    here.
                  </Link>
                </li>
                <li>
                  The more organized you are, the more time you will have to
                  write and interact with your fans.
                </li>
              </ul>
            </div>
          </>
        )
      },
      {
        ques: 'Why should I hand out my invite code to fans and friends?',
        ans: (
          <>
            <div>
              <p>
                Anyone who joins OTR is asked to enter an invite code. When a
                new member uses the code, they automatically follow the member
                whose code they entered.
              </p>
              <p>
                Members can manually follow more members, but this is the only
                automatic follow.
              </p>
              <p>
                Your followers will be notified anytime you add a new event to
                the OTR calendar.
              </p>
              <p>
                When reaching milestone followers, you will earn free Blasts to
                your follow base.
              </p>
              <p>
                You can send a Release Day Blast to all followers, as well as
                other members who saved the event.
              </p>
              <p>OTR also features the Most Followed members.</p>
              <p>
                Be sure to pass out your invite code to potential followers.
                Your member invite code is listed on the{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/membership"
                >
                  Membership info
                </Link>{' '}
                page.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How can I edit an event after I have added it to the OTR site',
        ans: (
          <>
            <div>
              <p>
                Go to{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/my-events"
                >
                  My events
                </Link>{' '}
                tab (at the top), open the event, and click on the edit button.
              </p>
              <p>
                All book data on current and future events can be edited by the
                creator of the event for up to seven days past the event date.
              </p>
              <p>
                We encourage authors to add book releases to the calendar as
                soon as possible and keep updating the data as needed.
              </p>
              <p>
                Readers can add the events to their private OTR calendars and
                set reminders.
                <br />
                If the event date changes it syncs automatically.
              </p>
            </div>
          </>
        )
      },
      {
        ques: `I'm a new author and plan to release just one book this year. Can I pay to add one book release only?`,
        ans: (
          <>
            <div>
              <p>
                We offer two types of author memberships:
                <ul>
                  <li>
                    The Author membership offers up to three book releases per
                    membership year (with the option of paying extra for
                    additional book releases).
                  </li>
                  <li>
                    The Author Premium membership offers unlimited book releases
                    per membership year.
                  </li>
                </ul>
              </p>
              <p>
                We recommend the Author membership if you are planning to
                release just one book for the year.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I feature teasers, book trailers and/or a book playlist?',
        ans: (
          <>
            <div>
              <p>
                You can feature links for teasers, playlists, trailers as well
                as pre-order and purchase links in the Book Release event.
              </p>
            </div>
          </>
        )
      },
      {
        ques: `What networking options does OTR offer?`,
        ans: (
          <>
            <div>
              <p>
                In addition to the OTR reader calendar feature utilized by all
                members, authors can leverage OTR to establish valuable
                connections with vendors,  PR firms, bookstores, and other authors. Authors
                can access the vendor list, send messages, utilize blogger and
                beta reader lists, and also take advantage of Vendor
                Cancellation Openings (VCOs).
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Can OTR help me feature an ARC master list and newsletter sign-up?',
        ans: (
          <>
            <div>
              <p>
                Yes. In addition to an author's social media links, the author
                profile features links for newsletter and master ARC sign-ups.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Is there a limit to the number of books I can add to the OTR calendar?',
        ans: (
          <>
            <div>
              <p>
                Author Premium members can add unlimited Book releases to the
                OTR calendar.
              </p>
              <p>
                Author members can add three book releases per membership year
                with add-on options* for additional book releases.
              </p>
              <p>
                Author members can also upgrade* to Author Premium membership
                mid-membership.
              </p>
              <p>
                <em>
                  *See{' '}
                  <Link
                    target="_blank"
                    className="link-span"
                    to="/dashboard/membership"
                  >
                    Membership info
                  </Link>{' '}
                  page under Settings.
                </em>
              </p>
            </div>
          </>
        )
      },
      {
        ques: `I'm co-writing a book with another author. How does that work when adding a book release?`,
        ans: (
          <>
            <div>
              <p>
                Either author can add the book release to the OTR calendar and
                list the co-author. Sync to make sure it was not already added
                by co-author. The Book Release event will link to both authors
                and is searchable by name.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Will readers and bloggers get notified when I add events to the OTR calendar',
        ans: (
          <>
            <div>
              <p>
                Yes. All your followers receive notifications onsite when you
                add a new event to the OTR calendar and can opt for daily or
                weekly (digested) email notifications.
              </p>
              <p>
                <em>
                  *Be sure to pass out your “invite code” to potential
                  followers. If they use your invite code at sign-up, they’ll
                  automatically follow you. You can access your invite code from
                  the{' '}
                  <Link
                    target="_blank"
                    className="link-span"
                    to="/dashboard/membership"
                  >
                    Membership info
                  </Link>{' '}
                  page.
                </em>
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What happens when the release date changes?',
        ans: (
          <>
            <div>
              <p>
                If you change the book release date, the OTR will automatically
                sync the new date on the OTR calendar and member’s private OTR
                calendars.
              </p>
              <p>
                All OTR events can be edited up to seven days past the event
                date, after which they will be locked.
              </p>
              <p>
                To edit an event added to OTR, access event{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/my-events"
                >
                  My Events
                </Link>{' '}
                tab, open the event, then click on the edit button.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I receive messages from readers or bloggers via OTR?',
        ans: (
          <>
            <div>
              <p>
                Currently, readers and bloggers do not have messaging options
                within OTR.
              </p>
              <p>
                Bloggers are featured on the Blogger list, so authors can reach
                them via the email listed.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'My PR firm is handling my release, can they add my book release to the OTR calendar?',
        ans: (
          <>
            <div>
              <p>
              Only authors can add, edit, and update Book Releases on the OTR calendar.
              </p>
              <p>
                The author owns the Book Release event, which connects all
                associated events. The Book Release page features a link to the
                Linked Event page which links all events connected to that book
                release, from the Book promo tour and ARC sign-up to parties and
                giveaways. The Book Release Linked Event page displays events
                hosted by the author, PR Firms, bookstores, Blogger Premium, or fellow
                authors hosting a release party or giveaway for the author.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How can I send an OTR Blast to my fans?',
        ans: (
          <>
            <div>
              <p>
                Authors can:
                <ul>
                  <li>
                    Send a FREE release day Blast to all followers* and members
                    who added the book release to their calendar.
                  </li>
                  <li>
                    Earn a free Blast upon earning the first 500 followers & one
                    free Blast for each additional 500 followers.
                  </li>
                  <li>
                    Purchase additional Blasts (maximum of one per month).
                  </li>
                </ul>
              </p>

              <p>
                Learn more{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/blasts"
                >
                  here.
                </Link>
              </p>
              <p>
                <em>
                  *Be sure to pass out your invite code (found on your{' '}
                  <Link
                    target="_blank"
                    className="link-span"
                    to="/dashboard/membership"
                  >
                    Membership info
                  </Link>{' '}
                  page) to your fans. When a new member uses your invite code at
                  sign- up, you will earn an automatic follower.
                </em>
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'As an author, what types of members will I be able to connect with on OTR?',
        ans: (
          <>
            <div>
              <p>
                Author members can send messages to other author members (both
                Author and Author Premium),  PR Firms/Bookstores, and Vendors.
              </p>
              <p>
                Learn more{' '}
                <Link target="_blank" className="link-span" to="/plans">
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'If I remove an event from the OTR calendar, what happens to the members who saved that event to their private OTR calendar?',
        ans: (
          <>
            <div>
              <p>
                If you remove an event from the OTR calendar, all members who
                added the event to their private OTR calendar will be notified
                that the event is no longer on the OTR calendar.
              </p>
              <p>
                OTR highly recommends updating the event instead of removing it.
                This will prevent you from losing members who saved it to their
                private OTR calendar. You may not catch the attention of those
                members again if you add it back later.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can I pay monthly instead of yearly?',
        ans: (
          <>
            <div>
              <p>
                Monthly options are not a practical option as members may add
                book events up to 18 months in advance.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'Is this platform connected to Amazon, Apple books, Goodreads and/or Bookbub?',
        ans: (
          <>
            <div>
              <p>
                OTR is not sponsored, endorsed, or associated with any purchase
                sites, publishing houses, marketing firms, book clubs or
                Goodreads. Members may choose to feature links to these sites
                and more on their profiles and event pages. OTR is not
                responsible for these links nor receiving compensation for them.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'What is a VCO?',
        ans: (
          <>
               <div>
              <p>VCO stands for Vendor Cancellation Opening.</p>
              <p>
              Even if a vendor is not accepting new clients, their booking cancellations can be added as Vendor Cancellation Openings (VCOs) on the Vendor list. This enables authors to reserve services with previously fully booked vendors.
              </p>
              <p>
              You can add a VCO from your profile page. Once the date expires, the list is automatically updated.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How can Daily Tasks help me with my writing and marketing?',
        ans: (
          <>
            <div>
              <p>
                Daily Tasks (with reminders) is a private calendar to help you
                organize your routine with notes, links & reminders. Create a
                to-do-list, save posting data or track writing goals. Learn more{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/daily-task"
                >
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'If I do not renew my membership, will I lose my profile, the events I added to OTR, and my private reading calendar?',
        ans: (
          <>
            <div>
              <p>
                OTR stores your membership profile, private calendar, and events
                for two weeks after your membership expiration date. Your
                account will be restored if renewed within this time.
              </p>
              <p>
                OTR is unable to feature upcoming calendar events from expired
                memberships. All members who have saved events posted by expired
                memberships will be notified that the event is no longer on the
                OTR calendar.
              </p>
            </div>
          </>
        )
      }
    ]
  },
  {
    title: 'PR FIRMS/BOOKSTORE',
    questions: [
      {
        ques:
          'Can we share an ARC master sign-up link with readers and bloggers?',
        ans: (
          <>
            <div>
              <p>
                In addition to links to website, social media, and fangroup
                there are several fields in a PR Firm/Bookstore profile where you can add
                a master sign-up link such as best site for bloggers/readers to
                interact with you and the BIO section.
              </p>
              <p>
                When PR firms add book promo events for their clients that event
                is centered around the sign-up link and dates.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can we advertise on OTR on behalf of a client?',
        ans: (
          <>
            <div>
              <p>
                Yes, PR Firms can purchase week-long banner ad on the OTR
                website/mobile app to promote an upcoming/recent book release
                event* on behalf of a client. Limited spots are available each
                week.
              </p>
              <p>
                Learn more{' '}
                <Link target="_blank" className="link-span" to="/advertise">
                  here.
                </Link>
              </p>
              <p>
                <em>
                  *The book release must first be added to OTR by the author.
                </em>
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can we add a book cover to OTR?',
        ans: (
          <>
            <div>
              <p>
                Although the author is the only one who can add their Book
                Release event to the OTR calendar, any member who is adding an
                event tied to the book release can upload the book cover. The
                book cover will then sync to all events linked to that book
                release event.
              </p>
              <p>
                OTR allows for temporary, official, and alternate (e.g. limited
                edition) book covers.
              </p>
              <p>
                If a temporary cover is used, it will automatically be replaced
                once an official or alternate cover is uploaded.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'We are currently not accepting new clients. Can we still add a Vendor Cancellation Opening (VCO)?',
        ans: (
          <>
            <div>
              <p>
                Yes, even if you are not accepting new clients, you can add a
                Vendor Cancellation Opening (VCO) via your profile page.
              </p>
              <p>
                Authors can quickly find VCOs by sorting the Vendor list based
                on that field. Once the date expires, the list is automatically
                updated.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How will we benefit from passing out our “invite code” to fans?',
        ans: (
          <>
            <div>
              <p>
                Anyone who joins On Target Romance (OTR) will be asked to enter
                an invite code. When a new member uses the code, they will
                automatically follow the member whose code they entered.
              </p>
              <p>
                Members can manually follow more members, but this is the only
                automatic follow.
              </p>
              <p>
                When reaching milestone followers, you will earn free Blasts to
                your followers.
              </p>
              <p>
                Your followers will be notified anytime you add a new event to
                the OTR calendar.
              </p>
              <p>OTR also features the Most Followed members.</p>
              <p>
                Be sure to pass out your invite code to potential followers.
                Your invite code is listed on your{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/membership"
                >
                  Membership info
                </Link>{' '}
                page.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How can Daily Tasks help us stay organized?',
        ans: (
          <>
            <div>
              <p>
                Daily Tasks with reminders is your private calendar for
                organizing your daily routine with to-do lists, notes, posting
                data, links & reminders. Learn more{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/daily-task"
                >
                  here.
                </Link>
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How can we edit an event?',
        ans: (
          <>
            <div>
              <p>
                To edit an event you added to OTR, access{' '}
                <Link
                  target="_blank"
                  className="link-span"
                  to="/dashboard/my-events"
                >
                  My Events
                </Link>{' '}
                tab, open the event, then click on the edit button. Events can
                be edited up to seven days past the event date, after which they
                lock.
              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'What happens to a book promo tour, giveaway, or party when an author changes the release date?',
        ans: (
          <>
            <div>
              <p>
                If the date or data changes on your event go to the event page
                and click on edit button.
              </p>
              <p>
                Once your event is synced to a book release title, it will link
                even if the author changes the book release date.
              </p>
              <p>
                All events tied to a book release page are featured on the
                Linked event page of that book release (accessed via the book
                release page).
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'Can anyone contact us on this platform?',
        ans: (
          <>
            <div>
              <p>
              At this time, PR firms, bookstores, authors and vendors can utilize OTR messaging. 
              Your PR Firm/Bookstore profile (accessible to all OTR members) features a link for the best site to interact with you.
              </p>
            </div>
          </>
        )
      },
      {
        ques: 'How do we find authors on the OTR platform?',
        ans: (
          <>
            <div>
              <p>
              The “All Profiles” list features all members with OTR public profiles (Blogger Premium, Authors and PR Firms/Bookstores).
               Sort and filter this list to find authors. View profiles and follow members at a click of a button.

              </p>
            </div>
          </>
        )
      },
      {
        ques:
          'If I do not renew, will the future events we added remain on the OTR calendar?',
        ans: (
          <>
            <div>
              <p>
                To ensure accuracy, OTR is unable to feature upcoming calendar
                events from expired memberships.
              </p>
              <p>
                All members who have added an event to their private OTR
                calendar will be notified if that event is no longer in the OTR
                calendar.
              </p>
            </div>
          </>
        )
      }
    ]
  }
];
class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      filterFaq: data,
      updFilterFaq: data
    };
  }

  convertHtmlToText(html) {
    if((typeof html) === 'string')
    {
      return html;
    }
    const children = html.props.children;
    let text = "";
  
    if (Array.isArray(children)) {
      // If the element has multiple children, recursively convert each one to text
      text = children.map((child) => this.convertHtmlToText(child)).join("");
    } else if (React.isValidElement(children)) {
      // If the child is a React element, recursively convert it to text
      text = this.convertHtmlToText(children);
    } else {
      // If the child is a text string, append it to the output
      if(children)
      {
        text = children.toString();
      }
    }
  
    if (html.type && html.type.displayName === "Link") {
      // If the element is a Link component, append its "to" value to the output
      text += html.props.to;
    }
  
    // Remove HTML tags from the final text string
    const cleanString = text.replace(/(<([^>]+)>)/gi, "");
  
    return cleanString;
  }

  handleChange = event => {
    this.setState({ search: event.target.value });
    const old = [...this.state.updFilterFaq];
    const serchLower = event.target.value;
    // console.log(event.target.value);

    // let data = [];
    // data = old.filter(
    //   el => el.title.toLowerCase().indexOf(serchLower.toLowerCase()) !== -1
    // );
    // console.log('eeeeee', data.length);
    // if (data.length === 0) {
      let data = old.reduce((r, { title, questions }) => {
        let o = questions.filter(({ ques, ans }) => {
         let str = this.convertHtmlToText(ans);
          //old code**************
          // var str = ReactDOMServer.renderToStaticMarkup( 
          //   <StaticRouter location="/my-page">{ans}</StaticRouter>
          // );
          // str = str.replace(/(<([^>]+)>)/gi, '');
          // console.log("this is html string ", ques, "    ", str);
          //*********** */

          return (
            ques.toLowerCase().indexOf(serchLower.toLowerCase()) !== -1 ||
            str.toLowerCase().indexOf(serchLower.toLowerCase()) !== -1
          );
        });
        let s = title.toLowerCase().indexOf(serchLower.toLowerCase()) !== -1;
        if (o && o.length) {
          r.push({ title: s, questions: [...o] });
        }
        return r;
      }, []);
    // }

    // console.log('this is length data', data.length);
    this.setState({ filterFaq: data });
  };

  render() {
    // console.log("-----------",data[0].questions[0].ans.outerHTML);
    const { search, filterFaq } = this.state;
    return (
      <Layout>
        <div className="help-section sssss">
          <div className="container">
            <div className="mb-4">
              <h2 className="text-center heading">FAQ</h2>
              <p className="text-center mb-0">Hello, how can we help you?</p>
              <div className="faq-search">
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  name="title"
                  value={this.state.search}
                  placeholder="Search for answers"
                  onChange={this.handleChange}
                />
                {/* <img src={search} alt="search" /> */}
              </div>
            </div>
            <div className="col-md-12 mb-5">
              <div id="faq-section" className="faq-section mb-5 faq-page">
                <fieldset class="scheduler-border border-light">
                  <div className="row mb-5">
                    <div className="col-md-10 col-lg-8 mx-auto">
                      <Accordion allowZeroExpanded>
                        {filterFaq && filterFaq.length
                          ? filterFaq.map(el => {
                              const { title, questions } = el;
                              return (
                                <div className="AccordionPannel mb-4">
                                  <h4 className="text-center m-2">{title}</h4>
                                  <div className="AccordionList ">
                                    {questions.map((elem, index) => {
                                      const { ques, ans } = elem;
                                      // console.log('jkhh', ques, ans);
                                      return (
                                        <AccordionItem>
                                          <AccordionItemHeading>
                                            <AccordionItemButton>
                                              {ques}
                                            </AccordionItemButton>
                                          </AccordionItemHeading>
                                          <AccordionItemPanel>
                                            {ans}
                                          </AccordionItemPanel>
                                        </AccordionItem>
                                      );
                                    })}
                                  </div>
                                  {/* <a href="!#" className="moreLink">
                            More <i class="fa fa-arrow-right"></i>
                          </a> */}
                                </div>
                              );
                            })
                          : 'Sorry no matching results found.'}
                      </Accordion>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  componentDidMount() {
    // this.props.resetSignupPage();
    // console.log('componentDidMount');
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch => {};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
