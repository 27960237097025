import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './dashboard.css';
import { getEvent, getEventButton } from '../../Actions/event-setup';

class CreateEventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formJSON: [],
      currentFormObj: {},
      isSubmitted: false,
      showModal: false,
      history: []
      //   toggle:true
    };
    // this.htmlParser = new HTMLParser();
  }

  //= ==================== RENDER ==========================
  render() {
    if (
      this.props.eventButtons &&
      localStorage.getItem('userType') &&
      (localStorage.getItem('membershipId') === '8' ||
        localStorage.getItem('membershipId') === '1')
    ) {
      let events = this.props.eventButtons;
      events.forEach((el, i) => {
        if (i === 1) {
          el.id = 5;
          el.event_type_id = 5;
          el.event_list = 'Cover Reveal';
          el.event_color = '#9fff81,#75ffc9';
          el.event_id = 5;
        } else if (i === 2) {
          el.id = 2;
          el.event_type_id = 2;
          el.event_list = 'Giveaway';
          el.event_color = '#9fff81,#75ffc9';
          el.event_id = 2;
        } else if (i === 3) {
          el.id = 3;
          el.event_type_id = 3;
          el.event_list = 'Book Promo';
          el.event_color = '#ff964f,#ff8181';
          el.event_id = 3;
        } else if (i === 4) {
          el.id = 4;
          el.event_type_id = 4;
          el.event_list = 'Release Party';
          el.event_color = '#ff79bc,#ff65ff';
          el.event_id = 4;
        } else if (i === 5) {
          el.id = 7;
          el.event_type_id = 7;
          el.event_list = 'ARC Signup';
          el.event_color = '#ff5b5b,#ff5b9d';
          el.event_id = 7;
        } else if (i === 6) {
          el.id = 6;
          el.event_type_id = 6;
          el.event_list = 'Book Signing';
          el.event_color = '#b469ff,#8e5bff';
          el.event_id = 6;
        }
      });

      return (
        <>
          <Modal
            isOpen={this.props.isOpen}
            toggle={this.props.toggle}
            id="myModal"
            size="lg"
            className="create-event-popup"
          >
            <div className="model_cover create_event">
              <ModalHeader toggle={this.props.toggle}>
                <h3>Set up event</h3>
              </ModalHeader>
              <ModalBody>
                <div className="row">
                  {events.map((el, index) => (
                    <>
                      {index === 0 ? (
                        <div className="col-md-10 mt-2 ml-1">
                          <div className="book-release-btn">
                            <button
                              type="button"
                              onClick={this.onButtonClickHandler.bind(this, el)}
                              id="player-list-button"
                              className={`btn btn-main-style w-100 setup_btn event-type-${el.event_type_id}`}
                              disabled={
                                el.event_type_id === '1' &&
                                this.props.countStatus
                              }
                              data-toggle="dropdown"
                            >
                              {el.event_list}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-5 mt-2 ml-1">
                          <button
                            type="button"
                            onClick={this.onButtonClickHandler.bind(this, el)}
                            id="player-list-button"
                            className={`btn btn-main-style w-100 setup_btn event-type-${el.event_type_id}`}
                            disabled={
                              el.event_type_id === '1' && this.props.countStatus
                            }
                            data-toggle="dropdown"
                          >
                            {el.event_list === 'Book Signing'
                              ? 'BOXES & SIGNINGS'
                              : el.event_list}
                            {/* {el.event_list} */}
                          </button>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </ModalBody>
            </div>
          </Modal>
        </>
      );
    } else {
      let events = this.props.eventButtons;

      return (
        <>
          {' '}
          <Modal
            isOpen={this.props.isOpen}
            toggle={this.props.toggle}
            id="myModal"
            size="lg"
            className="create-event-popup"
          >
            <div className="model_cover create_event">
              <ModalHeader toggle={this.props.toggle}>
                <h3>Set up event</h3>
              </ModalHeader>
              <ModalBody>
                <div className="row">
                  {events &&
                    events.map(el => (
                      <div className="col-md-5 mt-2 ml-1">
                        <button
                          type="button"
                          onClick={this.onButtonClickHandler.bind(this, el)}
                          id="player-list-button"
                          className={`btn btn-main-style w-100 setup_btn event-type-${el.event_type_id}`}
                          disabled={
                            el.event_type_id === '1' && this.props.countStatus
                          }
                          data-toggle="dropdown"
                        >
                          {el.event_list === 'Book Signing'
                            ? 'BOXES & SIGNINGS'
                            : el.event_list}
                        </button>
                      </div>
                    ))}
                </div>
              </ModalBody>
            </div>
          </Modal>
        </>
      );
    }
  }

  componentDidMount() {
    this.props.getEventButtons('/api/v1/events/list', {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    if (localStorage.getItem('event_type')) {
      localStorage.removeItem('event_type');
    }
  }

  onButtonClickHandler(el) {
    if (el.event_type_id !== undefined) {
      localStorage.setItem('event_type', el.event_type_id);
      this.props.getSchema(
        '/api/v1/events',
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        `event_type_${el.event_type_id}`
      );
      this.props.history.push('/setup-event');
    }
  }
}

const mapStateToProps = state => ({
  loading: state.eventLoading,
  formJSON: state.eventData,
  eventDetail: state.eventDetail,
  eventButtons: state.getEventButtons,
  countStatus: state.disableButtonRelease
});

const mapDispatchToProps = dispatch => ({
  getSchema: (url, headers, schema_name) =>
    dispatch(getEvent(url, headers, schema_name)),
  getEventButtons: (url, headers) => dispatch(getEventButton(url, headers))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventModal);
