import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { getTaskDateWise } from '../../Actions/dailytask';
import axios from 'axios';
import { BASEURL } from '../../constant';
// import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
// import CONSTANT from '../../constant';
//import "react-big-calendar/lib/css/react-big-calendar.css";
// import { HashLoader } from 'react-spinners';
import { saveDetail, getAllTasks } from '../../Actions/dailytask';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../dailyTask/dailyTask.css';
import ViewTask from './viewTaskModal';
// import { getNotificationlist } from '../../Actions/messages';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';
import { notify } from 'react-notify-toast';
import ReactTooltip from 'react-tooltip';
import en from 'date-fns/locale/es';
registerLocale('en', en);

class DailyTaskDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      eventData: {},
      rowData: [],
      profileKeyData: [],
      show: false,
      task_details: '',
      isCalendar: true,
      allDetails: [],
      title: '',
      viewModal: false,
      isCalenderView: true,
      isDailyTaskView: true,
      message: '',
      added_on: null,
      title: '',
      date: null,
      taskMessage: null,
      calender_date: null,
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true
        },
        {
          headerName: 'DATE',
          field: 'added_on',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: function(addedOn) {
            if (addedOn.data.added_on) {
              return moment(addedOn.data.added_on).format('MM/DD/YYYY');
            } else {
              return '-';
            }
          }
        },
        {
          headerName: 'TASK TITLE',
          field: 'title',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true
        },
        // {
        //   headerName: 'Description',
        //   field: 'task_message',
        //   sortable: true,
        //   filter: true,
        //   resizable: true,
        //   suppressMovable: true,
        //   unSortIcon: true,
        //   cellRenderer: this.nameCellRenderer.bind(this)
        // },
        {
          headerName: 'ACTION',
          field: 'edit',
          cellRenderer: this.editEvents.bind(this)
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0
    };
    this.hideEvent = this.hideEvent.bind(this);
  }
  nameCellRenderer(params) {
    return `  <div
    
    className="msg-text"
    dangerouslySetInnerHTML={{
      __html: ${params.data.task_message},
    }}
  ></div>`;
  }
  editEvents(params) {
    // let details = params.data;
    return `<button type="button" data-action-type="edit"  class="btn btn-sm  new_btn_save_bt_users_light  py-0">View</button>`;
  }

  showEvent(e) {
    this.setState({
      eventData: e.data,
      modal: true
    });
  }

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }
  handleCalendarDate(date) {
    this.setState({
      calender_date: date
    });
  }

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
  };

  refreshGrid() {
    this.props.getCases();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  handleEditorChange = (event, editor) => {
    // this.setState({ task_details: e });
    const data = editor && editor.getData();

    this.setState({ task_details: data });
  };

  uploadAdapter = loader => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(file => {
            body.append('file', file);

            axios
              .post(`${BASEURL}/api/v1/users/uploadBlastImage`, body, {
                headers: {
                  'x-authorization': localStorage.getItem('token')
                }
              })
              .then(res => res.data)
              .then(res => {
                resolve({
                  default: res
                });
              })
              .catch(err => {
                reject(err);
              });
          });
        });
      }
    };
  };
  uploadPlugin = editor => {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return this.uploadAdapter(loader);
    };
  };

  hideViewModal = e => {
    this.setState({ viewModal: false });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    let date = this.state.calender_date;
    let task_data = this.state.task_details;

    if (date) {
      date = moment(date).format('YYYY-MM-DD');
    }

    if (task_data) {
      task_data = task_data;
    }
    if (!this.state.task_details) {
      notify.show('Please enter Daily Task details.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    } else if (!this.state.title) {
      notify.show('Please enter Daily Task title.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    } else if (!date) {
      notify.show('Please enter Daily Task date.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    } else {
      const data = {
        task_message: task_data,
        added_on: moment(date).format('YYYY-MM-DD'),
        title: this.state.title
      };

      this.props.saveDailyTask(`/api/v1/daily_task/addTask`, data, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });
      this.hideModal();
      this.resetForm();
    }
  };
  resetForm() {
    this.setState({
      task_details: '',
      title: '',
      startDate: null
    });
  }

  saveTask = () => {
    const details = this.state.task_details;
    const date = this.state.startDate;
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
    // this.props.getAllTask(`/api/v1/daily_task/getTask`, { headers: { 'x-authorization': localStorage.getItem("token") } });
  };

  showTask = async task => {
    let data = task.data;

    data.start = moment(data.added_on).format('MM/DD/YYYY');

    if (task.column.colId === 'edit') {
      let data = task.data;
      // let actionType = task.event.target.getAttribute('data-action-type');
      this.setState({
        viewData: data,
        viewModal: true
      });
    }
  };

  eventStyleGetter = () => {
    //change add task colour
    var backgroundColor = '#ffff8b';
    var style = {
      backgroundColor: backgroundColor,
      color: 'black'
    };
    return {
      style: style
    };
  };

  //===================== RENDER ==========================
  render() {
    console.log(
      'getDateWiseTasks============>>>> ',
      this.props.getDateWiseTasks
    );

    let active = window.location.href;
    const splitData = active.split('/');
    const breakUrl = splitData[5];

    const breakUrl12 = breakUrl && breakUrl.split('?')[0];
    const endDate = new Date(moment().format('MM/DD/YYYY'));

    const endDate1 = new Date();
    const createDate1 = endDate1.setDate(endDate1.getDate() - 7);
    const createdDate3 = moment(createDate1).format('YYYY-MM-DD');

    const TaskEnd = endDate.setMonth(endDate.getMonth() + 18);
    const EndDate = moment(TaskEnd).format('YYYY-MM-DD');

    return (
      <>
        <div className="filter_row">
          <div className="row">
            <div className="col-sm-12">
              <div className="filter_options">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="filter-section">
                      <div className="calender_list" style={{ color: 'black' }}>
                        <a
                          href="javascript:;"
                          onClick={() =>
                            this.props.history.push('/dashboard/daily-task')
                          }
                          className="calender cursor-pointer"
                        >
                          <span className="header_text">Calendar View</span>
                          <img
                            className="header_mobile_img"
                            src={
                              this.props.displayMode
                                ? require('../../Utils/Assets/calender_logo.png')
                                : require('../../Utils/Assets/calender_logo_white.png')
                            }
                            alt="join"
                          />
                        </a>
                        <span className="devider">|</span>
                        <a
                          href="javascript:;"
                          // onClick={() => this.props.history.push('/dashboard/daily_task_list')}
                          className="list cursor-pointer"
                        >
                          <span
                            className={`header_text ${breakUrl12 ===
                              'dailyTaskDetails' && 'activeOutline'}`}
                          >
                            List View
                          </span>
                          <img
                            className="header_mobile_img"
                            src={
                              this.props.displayMode
                                ? require('../../Utils/Assets/listView_logo.png')
                                : require('../../Utils/Assets/listView_logo_white.png')
                            }
                            alt="join"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mb-4">
            <h2 className="text-center heading">DAILY TASK</h2>
          </div>
          <div className="add-btn mb-2">
            <button
              onClick={this.showModal}
              className={
                this.props.displayMode
                  ? 'btn btn_save_bt  daily'
                  : 'btn btn_save_bt white-button daily'
              }
            >
              <span>Add Task</span>
              <img
                src={require('../../Utils/Assets/plus.png')}
                alt="cup-icon"
              />
            </button>
          </div>
          <div
            className={
              this.props.isTheameLight
                ? 'ag-theme-balham'
                : 'ag-theme-dark home-ag'
            }
            style={{}}
          >
            <AgGridReact
              onGridReady={this.onGridReady}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={this.props.getDateWiseTasks}
              pagination={true}
              reactNext={true}
              animateRows
              onCellClicked={this.showTask.bind(this)}
              paginationPageSize={this.state.paginationPageSize}
              paginationNumberFormatter={this.state.paginationNumberFormatter}
            />
            <div className="test-header" style={{ float: 'right' }}>
              Page Size:
              <select
                onChange={this.onPageSizeChanged.bind(this)}
                id="page-size"
                defaultValue="10"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          {/* modals */}

          <ViewTask
            isOpen={this.state.viewModal}
            toggle={this.hideViewModal.bind(this)}
            data={this.state.viewData}
            viewMode={true}
          />
          <Modal
            isOpen={this.state.show}
            toggle={this.hideModal}
            className="daily-task-popup modal-lg"
          >
            <ModalHeader toggle={this.hideModal} className="eventModal">
              <h5 className="text-center p-0">Daily Tasks </h5>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={this.handleSubmit}>
                <div class="row">
                  <div class="form-group col-md-6 ">
                    <label for="startDate">Date*:</label>
                    <div className="custom-datepicker">
                      <DatePicker
                        // className="calendar-style"
                        format="YYYY-MM-DD"
                        placeholderText="mm-dd-yyyy"
                        highlightDates={new Date()}
                        //locale="en-US"
                        // minDate={this.state.startDate}
                        minDate={moment().toDate()}
                        selected={this.state.calender_date}
                        maxDate={new Date(TaskEnd)}
                        id="startDate"
                        name="startDate"
                        locale="en"
                        onChange={date => this.handleCalendarDate(date)}
                      />
                      <i class="fa fa-calendar-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="form-group col-md-6 ">
                    <label for="title">Task Title*:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      name="title"
                      onChange={this.handleChange}
                      value={this.state.title}
                    />
                  </div>
                  <div class="form-group col-md-12 ">
                    <label for="startDate">Task Detail*:</label>
                    <span style={{ display: 'inline-block' }}>
                      <i
                        className="fa fa-question-circle"
                        data-tip
                        data-for={'test'}
                        style={{ fontSize: '1.2em' }}
                      />
                      <ReactTooltip
                        className="toolTip"
                        id={'test'}
                        aria-haspopup="true"
                        effect="solid"
                        place="right"
                      >
                        <p style={{ margin: 0, padding: 0 }}>
                          {`Daily Tasks can be used for personal notes, To-do, posting text, campaign notes, writing goals etc.
                      Compose using Text Formatter bar below. Max image size is 3MB. Images can be resized by dragging & a caption
                       added. Use 2 arrow symbols around image to add text fields above or below.
                         Daily Tasks can be edited & deleted. Click on LIST view to see all your Daily Tasks from last 7 days and 18 
                         months into the future. To view older Daily Tasks; Scroll in DT calendar view and click on individual days or Daily Tasks. To adjust Daily Task Reminders; Go to Preference page under Settings, turn Reminders on or off and/or set to Daily or Weekly Digest.`}

                          <br />

                          {`Default font: Raleway 12 pts.`}
                        </p>
                        {/* {paras.map(el => {
            return [<p style={{ margin: 0, padding: 0 }}>{el}</p>]
          })} */}
                      </ReactTooltip>
                    </span>

                    <CKEditor
                      editor={FullEditor}
                      onChange={this.handleEditorChange}
                      config={{
                        extraPlugins: [this.uploadPlugin],
                        toolbar: {
                          items: [
                            'fontSize',
                            'fontFamily',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            'imageStyle:full',
                            'imageStyle:side',
                            'imageStyle:alignLeft',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            // 'insertTable',
                            'fontColor'
                          ],
                          image: {
                            // Configure the available styles.
                            styles: ['alignLeft', 'alignCenter', 'alignRight'],
                            sizes: ['50%', '75%', '100%'],
                            toolbar: [
                              'imageStyle:alignLeft',
                              'imageStyle:alignCenter',
                              'imageStyle:alignRight',
                              '|',
                              'imageResize',
                              '|',
                              'imageTextAlternative'
                            ]
                          }
                        },
                        fontFamily: {
                          options: [
                            'Arial, Helvetica, sans-serif',
                            'Calibri',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Raleway, Geneva, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif'
                          ],
                          supportAllValues: true
                        },
                        fontSize: {
                          options: [12, 14, 16, 18, 20, 22, 24]
                        }
                      }}
                    />
                    <button
                      type="submit"
                      onClick={this.saveTask}
                      className={
                        'btn btn_save_bt' || 'btn btn_save_bt dark-button'
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
  componentWillReceiveProps() {}

  componentDidMount = async () => {
    const response = await axios.put(
      BASEURL + '/api/v1/daily_task/updateDailyTaskSeen',
      { date: moment().format('YYYY-MM-DD') },
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );
    const query = new URLSearchParams(this.props.props.location.search);

    const token = query.get('startDate');

    if (token) {
      this.props.getListView(`/api/v1/daily_task/getDayWise/${token}`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });
    }
    this.props.getAllTask(`/api/v1/daily_task/getTask`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  };
}

const mapStateToProps = state => {
  return {
    eventsData: state.filterevent,
    getDateWiseTasks: state.getDateWiseTasks,
    displayMode: state.changeMode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getListView: (url, headers) => dispatch(getTaskDateWise(url, headers)),
    saveDailyTask: (url, data, headers) =>
      dispatch(saveDetail(url, data, headers)),
    getAllTask: (url, headers) => dispatch(getAllTasks(url, headers))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyTaskDetails);
