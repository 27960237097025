import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';
import { Table, Col, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import {
  getUserMembershipDetails,
  verifyPRCode,
  paymentConfirmation,
  getFollowedUserPrCode,
  paymentSucess,
  GetAllDiscounts
} from '../../Actions/index';
import ConditionsModel from '../Static/conditionsModel';
import PrivacyModal from '../Static/privacyModal';
import CookieModal from '../Static/cookieModal';
import PaymentModal from './paymentModal';
import Layout from '../layout/layout';
import { BASEURL } from '../../constant';

import axios from 'axios';

import payment from '../../Utils/Assets/PaymentOptions.png';
import braintree from '../../Utils/Assets/braintree-img.png';
import './signup.css';
class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipType: null,
      showTermModal: false,
      showPrivacyModal: false,
      showCookieModal: false,
      showPaymentModal: false,
      planCost: 0,
      plansCost: {},
      RealCost: 0,
      radioData: '',
      ischeck: false,
      ispcheck: false,
      isccheck: true,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
      modal7: false,

      dataset: '',
      selected: '1',
      promoCode: '',
      brainTreeDiscounts: [],
      discountAmount: '',
      allDiscountData: {}
    };
    this.confirmPayment = this.confirmPayment.bind(this);
    this.addClientToken = this.addClientToken.bind(this);
    this.togglePaymentModal = this.togglePaymentModal.bind(this);
  }
  toggle = () => this.setState({ modal: !this.state.modal });
  toggle1 = () => this.setState({ modal1: !this.state.modal1 });
  toggle2 = () => this.setState({ modal2: !this.state.modal2 });
  toggle3 = () => this.setState({ modal3: !this.state.modal3 });
  toggle4 = () => this.setState({ modal4: !this.state.modal4 });
  toggle5 = () => this.setState({ modal5: !this.state.modal5 });
  toggle6 = () => this.setState({ modal6: !this.state.modal6 });
  toggle7 = () => this.setState({ modal7: !this.state.modal7 });
  toggle8 = () => this.setState({ modal8: !this.state.modal8 });

  // handleRadioChange = event => {
  //   this.planCostHandller(event.target.value);
  //   // this.setState({
  //   //   membershipType: event.target.value,
  //   //   planCost: this.state.plansCost[event.target.value],
  //   // });
  //   this.setState({ selected: event.target.value });
  //   console.log('event.target.value', event.target.value);
  // };

  handleChangeRadio = e => {
    console.log('hellooo', e.target.value);
  };
  handleRadioChange = event => {
    console.log('event.target.value');
    this.planCostHandller(event.target.value);
    this.setState({ selected: event.target.value });
  };

  componentDidUpdate(prevProps) {
    // compare props
    if (this.props.membershipDetails !== prevProps.membershipDetails) {
      const local = localStorage.getItem('userType');
      if (local === '1') {
        this.setState({ selected: '1' });
        this.planCostHandller('1');
      } else if (local === '3' || local === '2') {
        this.setState({ selected: '6' });
        this.planCostHandller('6');
      } else if (local === '4') {
        this.setState({ selected: '4' });
        this.planCostHandller('4');
      } else if (local === '5') {
        this.setState({ selected: '5' });
        this.planCostHandller('5');
      }
      // this.planCostHandller('1');
    }
  }
  planCostHandller = membershipId => {
    console.log('hhi');
    let planDetails = [];
    this.props.membershipDetails &&
      this.props.membershipDetails.forEach(_plan => {
        if (Array.isArray(_plan[0])) {
          _plan.forEach(_p => planDetails.push(_p));
        } else {
          planDetails.push(_plan);
        }
      });
    planDetails =
      planDetails &&
      planDetails.find(_plan => {
        if (Array.isArray(_plan)) {
          return !!_plan.find(
            _planProp => _planProp.membershipId === membershipId
          );
        } else {
          return _plan.membershipId === membershipId;
        }
      });

    const costProp =
      planDetails && planDetails.find(_planProp => !!_planProp.planCost);
    const planCost =
      costProp && costProp.launchPrice
        ? costProp && costProp.launchPrice
        : costProp && costProp.planCost;

    this.setState({
      membershipType: membershipId,
      planCost: planCost,
      RealCost: costProp && costProp.planCost
    });
  };

  handlePromoCode = event => {
    this.setState({ promoCode: event.target.value });
  };

  onCheck = () => {
    this.setState({ ischeck: !this.state.ischeck });
    if (this.state.ischeck) {
      this.openModal();
    }
  };

  openModal = () => {
    this.setState({ showTermModal: true });
  };

  hideModal = () => {
    this.setState({ showTermModal: false });
  };

  onPrivacyCheck = () => {
    this.setState({ ispcheck: !this.state.ispcheck });
    if (this.state.ispcheck) {
      this.openPrivacyModal();
    }
  };

  openPrivacyModal = () => {
    this.setState({ showPrivacyModal: true });
  };

  hidePrivacyModal = () => {
    this.setState({ showPrivacyModal: false });
  };

  onCookieCheck = () => {
    this.setState({ isccheck: !this.state.isccheck });
    if (this.state.isccheck) {
      this.openCookieModal();
    }
  };

  openCookieModal = () => {
    this.setState({ showCookieModal: true });
  };

  hideCookieModal = () => {
    this.setState({ showCookieModal: false });
  };
  confirmPayment(e) {
    e.preventDefault();
    // if (this.state.planCost === 0) {
    //   this.planCostHandller('1');
    // }
    this.togglePaymentModal();

    // this.complete_signup(this.state.membershipType);
  }

  togglePaymentModal() {
    if (this.state.planCost === 0) {
      const local = localStorage.getItem('userType');

      if (local === '1') {
        this.planCostHandller('1');
      }
      if (local === '3') {
        this.planCostHandller('6');
      }
      if (local === '5') {
        this.planCostHandller('5');
      }
    }
    this.setState({
      showPaymentModal: !this.state.showPaymentModal
      // profileData: {}
    });
  }

  addClientToken(nonce) {
    this.togglePaymentModal();

    this.props.completeSignup('/api/v1/pay/membership/', {
      membershipId: this.state.membershipType,
      type: 'MemberShip',
      prCode: localStorage.getItem('prCode')
        ? localStorage.getItem('prCode')
        : null,
      clientToken: nonce
    });
  }

  validatePRCode() {
    if (document.getElementById('code').value !== '')
      this.props.validatePR(
        '/api/v1/follow/check/validity/' +
          document.getElementById('code').value,
        document.getElementById('code').value,
        false
      );
    else
      notify.show('Please enter a valid PR code.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
  }
  handlePromoApply = async () => {
    const { promoCode } = this.state;
    console.log('promoCode', promoCode);
    this.setState({ dataset: promoCode });

    const payload = {
      promoCode
    };
    try {
      const Promodata = await axios.post(
        BASEURL + '/api/v1/PromoCodeValidate',
        payload
      );

      const { code, message, checkValidPromo } = Promodata.data;
      if (code === 200) {
        this.setState({ discountAmount: checkValidPromo[0].amount });
        this.setState({ allDiscountData: checkValidPromo[0] });
        notify.show(message, 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
      } else {
        this.setState({ discountAmount: '' });
        this.setState({ allDiscountData: '' });

        notify.show(message, 'custom', 2000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // componentDidMount() {
  //   console.log('hiiiiiisssssssssssssssssssss');
  //   // this.props.handleGetAllDiscounts('/api/v1/Get/discounts');
  //   axios
  //     .get(BASEURL + '/api/v1/discounts')
  //     .then(response => {
  //       console.log('response', response);
  //       //this.setState({ AuthorTypes: response.data.result.userTypes });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  render() {
    const membershipDetails = this.props.membershipDetails.length
      ? this.props.membershipDetails
      : [];

    const { discountAmount } = this.state;
    // let isTypeExists = membershipDetails.length > 1 ? true : false;
    // let membershipId = null;
    // let plan = null;
    // let data = [];
    // let datacost = this.state.RealCost - this.state.planCost;
    let promoCode = this.state.RealCost - this.state.planCost;
    let promoCode1 = promoCode.toFixed(2);

    let addDiscount = this.state.planCost - discountAmount;
    let addDiscount1 = addDiscount.toFixed(2);
    let addPlanCost1 = this.state.planCost;

    console.log('addPlanCost1', addPlanCost1);
    let addPlanCost = addPlanCost1 ? (addPlanCost1 * 1).toFixed(2) : '';
    // let addPlanCost = parseFloat(
    //   Math.round(this.state.planCost * 100) / 100
    // ).toFixed(2);
    console.log('hiii', promoCode1, addDiscount1, addPlanCost);

    if (membershipDetails.length && !this.props.success) {
      return (
        <Layout>
          <PaymentModal
            isOpen={this.state.showPaymentModal}
            addClientToken={this.addClientToken}
            planCost={
              this.state.discountAmount
                ? this.state.planCost - this.state.discountAmount
                : this.state.planCost
            }
            toggle={this.togglePaymentModal.bind(this)}
            promoCode={this.state.promoCode}
            allDiscountData={this.state.allDiscountData}
          />
          <div className="container">
            <div className="row signupmain payment-page membership-page member-payment-page">
              <div className="main_right">
                <form onSubmit={e => this.confirmPayment(e)}>
                  <div className="author_main_s">
                    <div className="col-md-12 mt-2">
                      <fieldset
                        className={
                          this.props.displayMode
                            ? 'scheduler-border border-light'
                            : 'scheduler-border border-dark'
                        }
                      >
                        <legend className="scheduler-border">
                          <b>Membership Options</b>
                        </legend>
                        <span className="select-one">Select one</span>
                        {membershipDetails &&
                          membershipDetails.map(result => {
                            if (result.length) {
                              return result.map(membershipDetails => {
                                let key = Object.keys(membershipDetails)[0];
                                let value = Object.values(membershipDetails)[0];
                                // console.log('value=====', value);
                                // console.log(
                                //   ' this.state.radioData',
                                //   this.state.radioData
                                // );
                                if (key == 'membershipId') {
                                  this.state.radioData = value;
                                }
                              });
                            }
                          })}
                        {localStorage.getItem('userType') == 5 && (
                          <div className="row row-m-10">
                            <Col
                              md="12"
                              sm="12"
                              xs="12"
                              id="scroll author-scroll"
                            >
                              <Table
                                bordered
                                className="mt-3 equal-width"
                                id="table1"
                              >
                                <tr className="text-center">
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        <span className="pink">
                                          <b>PR FIRM</b>
                                        </span>
                                      </span>
                                    </div>

                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="5"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          checked={this.state.selected === '5'}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$540</span>{' '}
                                        USD for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <br />
                                        <span className="usd">
                                          Regular: $720 USD/year
                                        </span>{' '}
                                        <i>
                                          <span
                                            className="usd"
                                            style={{ color: '#FF0000' }}
                                          >
                                            Save $180 USD first year!
                                          </span>
                                        </i>
                                      </p>
                                      {/* <p className="font-weight-bold txt">
                                      <strike className="pink">
                                        <span className="black price_1">
                                          $720{' '}
                                        </span>
                                      </strike>
                                      <span className="launch">LAUNCH: </span>
                                      <span className="price_1">$540</span>
                                      <br />
                                      <span className="usd">USD/year</span>
                                    </p> */}
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <td>
                                    <ul className="text_data">
                                      <li>
                                        Add events to the OTR calendar, such as
                                        PR tours, giveaways or release parties.
                                      </li>
                                      <li>
                                        Provide bloggers easy access to sign-up
                                        forms for cover reveals, ARCs, release
                                        promo & blog tours.
                                      </li>
                                      <li>
                                        Organize your postings in Daily Tasks.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle2}
                                        ></i>
                                      </li>
                                      <li>
                                        Customizable PR profile to highlight PR
                                        services, hosted events, contact &
                                        social media links.
                                      </li>
                                      <li>
                                        Network with authors & vendors
                                        (designers, social media consultants
                                        etc.){' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle4}
                                        ></i>
                                      </li>
                                      <li>
                                        Gain followers.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle7}
                                        ></i>
                                      </li>
                                      <li>
                                        Earn or purchase a Blast to followers.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle8}
                                        ></i>
                                      </li>
                                      <li>
                                        Be featured on the vendor list viewable
                                        by all authors.
                                      </li>
                                      <li>
                                        Indicate on profile/vendor list if new
                                        clients are being accepted.
                                      </li>
                                      <li>
                                        To secure cancelled bookings with new
                                        clients, register Vendor Cancellation
                                        Openings (VCO), to be featured on Vendor
                                        tab.
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle6}
                                        ></i>
                                      </li>
                                      <li>
                                        Option to register as a beta reader on a
                                        list open to authors.
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle6}
                                        ></i>
                                      </li>

                                      <li>
                                        Full access to all reader calendar
                                        events with easy filter/search
                                        functions.
                                      </li>
                                      <li>
                                        Organize your own reading calendar with
                                        options to set daily/weekly reminders.
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </Table>
                            </Col>
                          </div>
                        )}
                        {localStorage.getItem('userType') == 4 && (
                          <div className="row row-m-10">
                            <Col
                              md="12"
                              sm="12"
                              xs="12"
                              id="scroll author-scroll"
                            >
                              <Table
                                bordered
                                className="mt-3 equal-width"
                                id="table1"
                              >
                                <tr className="text-center">
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        <span className="green">
                                          <b>VENDOR</b>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="4"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          checked={this.state.selected === '4'}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$90</span> USD
                                        for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <br />
                                        <span className="usd">
                                          Regular: $120 USD/year
                                        </span>{' '}
                                        <i>
                                          <span
                                            className="usd"
                                            style={{ color: '#FF0000' }}
                                          >
                                            Save $30 USD first year!
                                          </span>
                                        </i>
                                      </p>
                                      {/* <p className="font-weight-bold mt-2">
                                      <strike className="green">
                                        <span className="black price_1">
                                          $120{' '}
                                        </span>
                                      </strike>
                                      <span className="launch">LAUNCH: </span>
                                      <span className="price_1">$90</span>
                                      <br />
                                      <span className="usd">USD/year</span>
                                    </p> */}
                                    </div>
                                  </th>
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        <span className="pink">
                                          <b>PR FIRM</b>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="5"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          checked={this.state.selected === '5'}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$540</span>{' '}
                                        USD for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <br />
                                        <span className="usd">
                                          Regular: $720 USD/year
                                        </span>{' '}
                                        <i>
                                          <span
                                            className="usd"
                                            style={{ color: '#FF0000' }}
                                          >
                                            Save $180 USD first year!
                                          </span>
                                        </i>
                                      </p>
                                      {/* <p className="font-weight-bold txt">
                                      <strike className="pink">
                                        <span className="black price_1">
                                          $720{' '}
                                        </span>
                                      </strike>
                                      <span className="launch">LAUNCH: </span>
                                      <span className="price_1">$540</span>
                                      <br />
                                      <span className="usd">USD/year</span>
                                    </p> */}
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <td>
                                    <ul className="text_data">
                                      <li>
                                        Highlight your services, contact &
                                        social media links on a customized
                                        Vendor page.
                                      </li>
                                      <li>
                                        Offer any of the following services:
                                        Agent, Blurb Writer, Book Cover
                                        Designer, Book Formatter, Book Signing
                                        Organizer, Cover Model, Editor, Graphic
                                        Designer, Personal Assistant (PA),
                                        Photographer, Proofreader, Publisher,
                                        Social Media Consultant, Social Media
                                        Manager, Web Design, Workshops.
                                      </li>
                                      <li>
                                        Network with authors & other vendors.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle4}
                                        ></i>
                                      </li>
                                      <li>
                                        Be featured on our vendor list viewable
                                        by all authors.
                                      </li>
                                      <li>
                                        Indicate on profile/vendor list if new
                                        clients are being accepted.
                                      </li>
                                      <li>
                                        To secure cancelled bookings with new
                                        clients, register Vendor Cancellation
                                        Openings (VCO), to be featured on Vendor
                                        tab.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle5}
                                        ></i>
                                      </li>
                                      <li>
                                        Organize your Daily Tasks with daily or
                                        weekly reminder options.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle2}
                                        ></i>
                                      </li>
                                      <li>
                                        Add book signings to the calendar.
                                      </li>
                                      <li>
                                        Option to register as a beta reader on a
                                        list open to authors.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle6}
                                        ></i>
                                      </li>
                                      <li>
                                        Full access to all reader calendar
                                        events with easy filter/search
                                        functions.
                                      </li>
                                      <li>
                                        Organize your own reading calendar with
                                        options to set daily/weekly reminders.
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <ul className="text_data">
                                      <li>
                                        Add events to the OTR calendar, such as
                                        PR tours, giveaways or release parties.
                                      </li>
                                      <li>
                                        Provide bloggers easy access to sign-up
                                        forms for cover reveals, ARCs, release
                                        promo & blog tours.
                                      </li>
                                      <li>
                                        Organize your postings in Daily Tasks.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle2}
                                        ></i>
                                      </li>
                                      <li>
                                        Customizable PR profile to highlight PR
                                        services, hosted events, contact &
                                        social media links.
                                      </li>
                                      <li>
                                        Network with authors & vendors
                                        (designers, social media consultants
                                        etc.){' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle4}
                                        ></i>
                                      </li>
                                      <li>
                                        Gain followers.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle7}
                                        ></i>
                                      </li>
                                      <li>
                                        Earn or purchase a Blast to followers.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle8}
                                        ></i>
                                      </li>
                                      <li>
                                        Be featured on the vendor list viewable
                                        by all authors.
                                      </li>
                                      <li>
                                        Indicate on profile/vendor list if new
                                        clients are being accepted.
                                      </li>
                                      <li>
                                        To secure cancelled bookings with new
                                        clients, register Vendor Cancellation
                                        Openings (VCO), to be featured on Vendor
                                        tab.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle6}
                                        ></i>
                                      </li>
                                      <li>
                                        Option to register as a beta reader on a
                                        list open to authors.
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle6}
                                        ></i>
                                      </li>
                                      <li>
                                        Full access to all reader calendar
                                        events with easy filter/search
                                        functions.
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle5}
                                        ></i>
                                      </li>
                                      <li>
                                        Organize your own reading calendar with
                                        options to set daily/weekly reminders.
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </Table>
                            </Col>
                          </div>
                        )}
                        {localStorage.getItem('userType') == 3 && (
                          <div className="row row-m-10">
                            <Col
                              md="12"
                              sm="12"
                              xs="12"
                              id="scroll author-scroll"
                            >
                              <Table
                                bordered
                                className="mt-3 equal-width"
                                id="table1"
                              >
                                <tr className="text-center">
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        {/* <input
                                        type="radio"
                                        value="3"
                                        name={'plan'}
                                        onChange={this.handleRadioChange.bind(
                                          this
                                        )}
                                        required
                                      />{' '} */}
                                        <span className="orange">
                                          <b>BLOGGER</b>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="3"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$29</span> USD
                                        for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <br />
                                        <span className="usd">
                                          Regular: $39 USD/year
                                        </span>{' '}
                                        <i>
                                          <span
                                            className="usd"
                                            style={{ color: '#FF0000' }}
                                          >
                                            Limited time offer!
                                          </span>
                                        </i>
                                      </p>
                                      {/* <p className="font-weight-bold">
                                      <strike className="orange">
                                        <span className="black price_1">
                                          $39{' '}
                                        </span>
                                      </strike>
                                      <span className="launch">LAUNCH: </span>
                                      <span className="price_1">$29</span>
                                      <br />
                                      <span className="usd">USD/year</span>
                                    </p> */}
                                    </div>
                                  </th>
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        {/* <input
                                        type="radio"
                                        value="6"
                                        name={'plan'}
                                        onChange={this.handleRadioChange.bind(
                                          this
                                        )}
                                        checked={this.state.selected === '6'}
                                        required
                                      />{' '} */}
                                        <span className="yellow">
                                          <b>BLOGGER PREMIUM</b>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="6"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          checked={this.state.selected === '6'}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$49</span> USD
                                        for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <br />
                                        <span className="usd">
                                          Regular: $59 USD/year
                                        </span>{' '}
                                        <i>
                                          <span
                                            className="usd"
                                            style={{ color: '#FF0000' }}
                                          >
                                            Limited time offer!
                                          </span>
                                        </i>
                                      </p>
                                      {/* <p className="font-weight-bold txt">
                                      <strike className="yellow">
                                        <span className="black price_1">
                                          $59{' '}
                                        </span>
                                      </strike>
                                      <span className="launch">LAUNCH: </span>
                                      <span className="price_1">$49</span>
                                      <br />
                                      <span className="usd">USD/year</span>
                                    </p> */}
                                    </div>
                                  </th>
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        {/* <input
                                        type="radio"
                                        value="5"
                                        name={'plan'}
                                        onChange={this.handleRadioChange.bind(
                                          this
                                        )}
                                        required
                                      />{' '} */}
                                        <span className="pink">
                                          <b>PR FIRM</b>
                                        </span>
                                      </span>
                                    </div>

                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="5"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$540</span>{' '}
                                        USD for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <br />
                                        <span className="usd">
                                          Regular: $720 USD/year
                                        </span>{' '}
                                        <i>
                                          <span
                                            className="usd"
                                            style={{ color: '#FF0000' }}
                                          >
                                            Save 180 USD first year!
                                          </span>
                                        </i>
                                      </p>
                                      {/* <p className="font-weight-bold txt">
                                      <strike className="pink">
                                        <span className="black price_1">
                                          $720{' '}
                                        </span>
                                      </strike>
                                      <span className="launch">LAUNCH: </span>
                                      <span className="price_1">$540</span>
                                      <br />
                                      <span className="usd">USD/year</span>
                                    </p> */}
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <td>
                                    <ul className="text_data">
                                      <li>
                                        Exclusive blogger access to all Promo &
                                        ARC sign ups.
                                      </li>
                                      <li>
                                        Featured on the blogger list viewable by
                                        all authors.
                                      </li>
                                      <li>
                                        Quickly filter events specific to your
                                        blog.
                                      </li>
                                      <li>
                                        Easy access to links for pre-orders,
                                        book trailers, teasers, playlists.
                                      </li>
                                      <li>
                                        Bookmark links to release parties &
                                        giveaways.
                                      </li>
                                      <li>
                                        Option to register as a beta reader on a
                                        list open to authors.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle6}
                                        ></i>
                                      </li>
                                      <li>
                                        Full access to all calendar events with
                                        easy filter/search functions.
                                      </li>
                                      <li>
                                        Organize your reading calendar with
                                        options to set daily/weekly reminders.
                                      </li>
                                      <li>
                                        Receive notifications when favorite
                                        authors share news & releases.
                                      </li>
                                      <li>
                                        Stay current by quickly viewing new,
                                        popular or featured events.
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <p className="text_data">
                                      <i>
                                        <b>Everything in Blogger plus:</b>
                                      </i>
                                    </p>
                                    <ul className="ul text_data">
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Customize Blogger profile page with
                                          links to social media &amp; blog
                                          events.
                                        </div>
                                      </li>
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Organize blog postings in Daily Tasks
                                          with the option of reminders.{' '}
                                          <i
                                            class="fa fa-info-circle"
                                            aria-hidden="true"
                                            onClick={this.toggle2}
                                          ></i>
                                        </div>
                                      </li>
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Add giveaways &amp; release parties to
                                          the OTR calendar.
                                        </div>
                                      </li>
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Gain followers.{' '}
                                          <i
                                            class="fa fa-info-circle"
                                            aria-hidden="true"
                                            onClick={this.toggle7}
                                          ></i>
                                        </div>
                                      </li>
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Earn or purchase Blasts to followers.{' '}
                                          <i
                                            class="fa fa-info-circle"
                                            aria-hidden="true"
                                            onClick={this.toggle8}
                                          ></i>
                                        </div>
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <ul className="text_data">
                                      <li>
                                        Add events to the OTR calendar, such as
                                        PR tours, giveaways or release parties.
                                      </li>
                                      <li>
                                        Provide bloggers easy access to sign-up
                                        forms for cover reveals, ARCs, release
                                        promo & blog tours.
                                      </li>
                                      <li>
                                        Organize your postings in Daily Tasks.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle2}
                                        ></i>
                                      </li>
                                      <li>
                                        Customizable PR profile to highlight PR
                                        services, hosted events, contact &
                                        social media links.
                                      </li>
                                      <li>
                                        Network with authors & vendors
                                        (designers, social media consultants
                                        etc.){' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle4}
                                        ></i>
                                      </li>
                                      <li>
                                        Gain followers.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle7}
                                        ></i>
                                      </li>
                                      <li>
                                        Earn or purchase a Blast to followers.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle8}
                                        ></i>
                                      </li>
                                      <li>
                                        Be featured on the vendor list viewable
                                        by all authors.
                                      </li>
                                      <li>
                                        Indicate on profile/vendor list if new
                                        clients are being accepted.
                                      </li>

                                      <li>
                                        To secure cancelled bookings with new
                                        clients, register Vendor Cancellation
                                        Openings (VCO), to be featured on Vendor
                                        tab.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle5}
                                        ></i>
                                      </li>

                                      <li>
                                        Option to register as a beta reader on a
                                        list open to authors.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle6}
                                        ></i>
                                      </li>
                                      <li>
                                        Full access to all reader calendar
                                        events with easy filter/search
                                        functions.
                                      </li>
                                      <li>
                                        Organize your own reading calendar with
                                        options to set daily/weekly reminders.
                                      </li>
                                    </ul>
                                    {/* <p className="text_data">
                                    *With our launch we offer 3-month barter
                                    membership with PR firms, please contact
                                    SueBee: xxx
                                  </p> */}
                                  </td>
                                </tr>
                              </Table>
                            </Col>
                          </div>
                        )}
                        {localStorage.getItem('userType') == 2 && (
                          <div className="row row-m-10">
                            <Col
                              md="12"
                              sm="12"
                              xs="12"
                              id="scroll author-scroll"
                            >
                              <Table
                                bordered
                                className="mt-3 equal-width"
                                id="table1"
                              >
                                <tr className="text-center">
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        <span className="red">
                                          <b>READER</b>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="2"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">FREE</span>{' '}
                                        USD for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <br />
                                        <span className="usd">
                                          Regular: $29 USD/year
                                        </span>{' '}
                                        <i>
                                          <span
                                            className="usd"
                                            style={{ color: '#FF0000' }}
                                          >
                                            Limited time offer!
                                          </span>
                                        </i>
                                      </p>
                                    </div>
                                  </th>
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        <span className="orange">
                                          <b>BLOGGER</b>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="3"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$29</span> USD
                                        for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <br />
                                        <span className="usd">
                                          Regular: $39 USD/year
                                        </span>{' '}
                                        <i>
                                          <span
                                            className="usd"
                                            style={{ color: '#FF0000' }}
                                          >
                                            limited time offer!
                                          </span>
                                        </i>
                                      </p>
                                    </div>
                                  </th>
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        <span className="yellow">
                                          <b>BLOGGER PREMIUM</b>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="6"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          checked={this.state.selected === '6'}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$49</span> USD
                                        for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <br />
                                        <span className="usd">
                                          Regular: $59 USD/year
                                        </span>{' '}
                                        <i>
                                          <span
                                            className="usd"
                                            style={{ color: '#FF0000' }}
                                          >
                                            Limited time offer!
                                          </span>
                                        </i>
                                      </p>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <td>
                                    <ul className="text_data">
                                      <li>
                                        Full access to all reader calendar
                                        events with easy filter/search
                                        functions.
                                      </li>
                                      <li>
                                        Organize your reading calendar with
                                        options to set daily/weekly reminders.
                                      </li>
                                      <li>
                                        Customize your OTR reader calendar by
                                        adding & saving events you wish to
                                        follow.
                                      </li>
                                      <li>
                                        Quickly find links to pre-orders, book
                                        trailers, teasers, playlists.
                                      </li>
                                      <li>
                                        Bookmark links to release parties &
                                        giveaways.
                                      </li>
                                      <li>
                                        Option to register as a beta reader on a
                                        list open to authors.
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle6}
                                        ></i>
                                      </li>
                                      <li>
                                        Access ARC sign-ups available to all
                                        readers.
                                      </li>
                                      <li>
                                        Receive notifications when favorite
                                        authors share releases & news.
                                      </li>
                                      <li>
                                        Stay current by quickly viewing new,
                                        popular or featured events.
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <ul className="text_data">
                                      <li>
                                        Exclusive blogger access to all Promo &
                                        ARC sign ups.
                                      </li>
                                      <li>
                                        Featured on the blogger list viewable by
                                        all authors.
                                      </li>
                                      <li>
                                        Quickly filter events specific to your
                                        blog.
                                      </li>
                                      <li>
                                        Easy access to links for pre-orders,
                                        book trailers, teasers, playlists.
                                      </li>
                                      <li>
                                        Bookmark links to release parties &
                                        giveaways.
                                      </li>
                                      <li>
                                        Option to register as a beta reader on a
                                        list open to authors.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle6}
                                        ></i>
                                      </li>
                                      <li>
                                        Full access to all calendar events with
                                        easy filter/search functions.
                                      </li>
                                      <li>
                                        Organize your reading calendar with
                                        options to set daily/weekly reminders.
                                      </li>
                                      <li>
                                        Receive notifications when favorite
                                        authors share news &amp; releases.
                                      </li>
                                      <li>
                                        Stay current by quickly viewing new,
                                        popular or featured events.
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <p className="text_data">
                                      <i>
                                        <b>Everything in Blogger plus:</b>
                                      </i>
                                    </p>
                                    <ul className="ul text_data">
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Customize Blogger profile page with
                                          links to social media &amp; blog
                                          events.
                                        </div>
                                      </li>
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Organize blog postings in Daily Tasks
                                          with the option of reminders.{' '}
                                          <i
                                            class="fa fa-info-circle"
                                            aria-hidden="true"
                                            onClick={this.toggle2}
                                          ></i>
                                        </div>
                                      </li>
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Add giveaways &amp; release parties to
                                          the OTR calendar.
                                        </div>
                                      </li>
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Gain followers.{' '}
                                          <i
                                            class="fa fa-info-circle"
                                            aria-hidden="true"
                                            onClick={this.toggle7}
                                          ></i>
                                        </div>
                                      </li>
                                      <li className="mt-2 mb-3">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Earn or purchase Blasts to followers.{' '}
                                          <i
                                            class="fa fa-info-circle"
                                            aria-hidden="true"
                                            onClick={this.toggle8}
                                          ></i>
                                        </div>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </Table>
                            </Col>
                          </div>
                        )}

                        {localStorage.getItem('userType') == 1 && (
                          <div className="row row-m-10">
                            <Col
                              md="6"
                              sm="12"
                              xs="12"
                              id="scroll author-scroll"
                            >
                              <Table
                                bordered
                                className="mt-3 equal-width"
                                id="table1"
                              >
                                <tr className="text-center">
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        <span className="blue">
                                          <b>AUTHOR</b>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="btn1 member-page">
                                      <p className="font-weight-bold txt">
                                        {/* <strike className="blue"> */}
                                        <input
                                          type="radio"
                                          value="8"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$260</span>{' '}
                                        USD for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */}
                                        <span className="launch"> </span>
                                        <span className="price_1"></span>
                                        <br />
                                        <span className="usd">
                                          Regular:  $390 USD/year
                                        </span>{' '}
                                        <i>
                                          <span className="usd">
                                          Save $130 USD first year!
                                          </span>
                                        </i>
                                      </p>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <td>
                                    <ul className="text_data">
                                      <li>
                                        Add three book releases per membership
                                        year with add-on option at $160 USD per
                                        additional release.
                                      </li>
                                      <li>
                                        Customize Author profile page with links
                                        to all your calendar events, social
                                        media &amp; contact details.
                                      </li>
                                      <li>
                                        3-in-1: Promote using Author/PR
                                        functions, network &amp; organize your
                                        own reading calendar with daily or
                                        weekly reminder options.
                                      </li>
                                      <li>
                                        Add &amp; manage book releases, release
                                        parties &amp; giveaways.
                                      </li>
                                      <li>
                                        Bookmark links for pre-orders, book
                                        trailers, playlists &amp; teasers.
                                      </li>
                                      <li>
                                        Free release day Blast to followers with
                                        option to earn or purchase additional
                                        Blasts.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle}
                                        ></i>
                                      </li>
                                      <li>
                                        Provide bloggers easy access to sign-up
                                        forms for PR opportunities such as cover
                                        reveals, ARCs, release promo &amp; blog
                                        tours.
                                      </li>
                                      <li>
                                        Connect with beta readers &amp; extend
                                        ARC signups to all readers.
                                      </li>
                                      <li>
                                        Access to list of all Blogger and
                                        Blogger Premium members.
                                      </li>
                                      <li>
                                        Increase exposure by gaining followers.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle1}
                                        ></i>
                                      </li>
                                      <li>
                                        Organize Daily Tasks with reminder
                                        options.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle2}
                                        ></i>
                                      </li>
                                      <li>
                                        Track your writing goals.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle3}
                                        ></i>
                                      </li>
                                      <li>
                                        {/* Network with vendors (cover designers,
                                        editors etc.), PR firms and other
                                        authors.{' '} */}
                                        Network with vendors (cover designers, editors etc.), PR firms, bookstores & other authors.
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle4}
                                        ></i>
                                      </li>
                                      <li>
                                        Easily check for last-minute Vendor
                                        Cancellation Openings to reserve
                                        services with previously fully-booked
                                        vendors.{' '}
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                          onClick={this.toggle5}
                                        ></i>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </Table>
                            </Col>
                            <Col
                              md="6"
                              sm="12"
                              xs="12"
                              id="scroll author-scroll"
                            >
                              <Table
                                bordered
                                className="mt-3 equal-width"
                                id="table1"
                              >
                                <tr className="text-center">
                                  <th id="heading" className="head">
                                    <div className="margin">
                                      <span className="main_head">
                                        <span className="purple">
                                          <b>AUTHOR PREMIUM</b>
                                        </span>
                                      </span>
                                    </div>

                                    <div className="btn1">
                                      <p className="font-weight-bold txt">
                                        <input
                                          type="radio"
                                          value="1"
                                          name={'plan'}
                                          onChange={this.handleRadioChange.bind(
                                            this
                                          )}
                                          checked={this.state.selected === '1'}
                                          required
                                        />{' '}
                                        Launch Special:{' '}
                                        <span className="price_1">$390</span>{' '}
                                        USD for 1<sup>st</sup>&nbsp;year
                                        {/* </strike> */} 

                                        <span className="launch"> </span>
                                        <span className="price_1"></span>
                                        <br />
                                        <span className="usd">
                                          {/* Regular: $540 USD/year */}
                                          Regular: $580 USD/year
                                        </span>{' '}
                                        <i>
                                          <span className="usd">
                                            {/* Save $180 USD first year! */}
                                            Save $190 USD first year!
                                          </span>
                                        </i>
                                      </p>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="text_data">
                                      <i>
                                        <b>Everything in Author plus:</b>
                                      </i>
                                    </p>
                                    <ul className="ul text_data">
                                      <li className="mt-2">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>{' '}
                                        <div>
                                          Add&nbsp;<i>unlimited</i>&nbsp;book
                                          releases to the OTR calendar.
                                        </div>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </Table>
                            </Col>
                          </div>
                        )}
                      </fieldset>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 mt-2 member-consent-section promo-code-section">
                          <fieldset
                            className={
                              this.props.displayMode
                                ? 'scheduler-border border-light'
                                : 'scheduler-border border-dark'
                            }
                          >
                            <legend className="scheduler-border">
                              <b>Member Consent</b>
                            </legend>
                            {membershipDetails &&
                              membershipDetails.map(result => {
                                if (result.length) {
                                  return result.map(membershipDetails => {
                                    let key = Object.keys(membershipDetails)[0];
                                    let value = Object.values(
                                      membershipDetails
                                    )[0];
                                    // console.log('value=====', value);
                                    // console.log(
                                    //   ' this.state.radioData',
                                    //   this.state.radioData
                                    // );
                                    if (key == 'membershipId') {
                                      this.state.radioData = value;
                                    }
                                  });
                                }
                              })}
                            {this.state.radioData == 8 && (
                              <div className="row">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  <div class="form-check mb-2 mr-sm-2">
                                    <div class="mb-2 check-box">
                                      <input
                                        type="checkbox"
                                        name="acknowledge"
                                        className="agreeTerms"
                                        id="old"
                                        required
                                      />
                                      <label
                                        for="old"
                                        style={{ display: 'inline' }}
                                        class="form-check-label ml-2"
                                      >
                                        {' '}
                                        I am 18 years of age or older. I
                                        acknowledge OTR may have adult themes
                                        and adult content including, but not
                                        limited to, sexual situations, violence,
                                        dubious or non-consent and/or
                                        drug/alcohol use.{' '}
                                      </label>
                                    </div>

                                    <div class="mb-2 check-box">
                                      <input
                                        type="checkbox"
                                        name="acknowledge"
                                        className="agreeTerms"
                                        id="acknowledge"
                                        required
                                      />
                                      <label
                                        for="acknowledge"
                                        style={{ display: 'inline' }}
                                        class="form-check-label ml-2"
                                      >
                                        {' '}
                                        I understand that all money paid to OTR
                                        is non-refundable.
                                      </label>
                                    </div>

                                    <div class="mb-2 check-box">
                                      <input
                                        type="checkbox"
                                        name="acknowledge"
                                        className="agreeTerms"
                                        id="terms"
                                        checked={this.state.ischeck}
                                        onClick={this.openModal}
                                        required
                                      />
                                      <label
                                        for="terms"
                                        style={{ display: 'inline' }}
                                        class="form-check-label ml-2"
                                      >
                                        I agree{' '}
                                        <strong
                                          // onClick={this.openModal}
                                          className="mousechange"
                                        >
                                          {' '}
                                          <Link
                                            to={`/condition`}
                                            target="_blank"
                                          >
                                            <u> {'OTR Terms & Conditions'}</u>
                                          </Link>
                                        </strong>
                                      </label>
                                    </div>

                                    <div class="mb-2 check-box agree-checkbox">
                                      <input
                                        type="checkbox"
                                        name="privacy"
                                        className="agreePrivacy"
                                        id="privacy"
                                        checked={this.state.ispcheck}
                                        onClick={this.openPrivacyModal}
                                        required
                                      />
                                      <label
                                        for="terms"
                                        style={{ display: 'inline' }}
                                        class="form-check-label ml-2"
                                      >
                                        I agree{' '}
                                        <strong
                                          //  onClick={this.openPrivacyModal}
                                          className="mousechange"
                                        >
                                          {' '}
                                          <Link to={`/privacy`} target="_blank">
                                            <u> {'OTR Privacy Policy'}</u>{' '}
                                          </Link>
                                        </strong>
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            )}
                          </fieldset>
                          <fieldset
                            className={
                              this.props.displayMode
                                ? 'scheduler-border border-light'
                                : 'scheduler-border border-dark'
                            }
                          >
                            <legend className="scheduler-border">
                              <b>Promo Code</b>
                            </legend>
                            {membershipDetails &&
                              membershipDetails.map(result => {
                                if (result.length) {
                                  return result.map(membershipDetails => {
                                    let key = Object.keys(membershipDetails)[0];
                                    let value = Object.values(
                                      membershipDetails
                                    )[0];
                                    // console.log('value=====', value);
                                    // console.log(
                                    //   ' this.state.radioData',
                                    //   this.state.radioData
                                    // );
                                    if (key == 'membershipId') {
                                      this.state.radioData = value;
                                    }
                                  });
                                }
                              })}
                            {this.state.radioData == 8 && (
                              <div className="row">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  <div class="form-check pl-0">
                                    <div className="promo-code">
                                      <button
                                        type="button"
                                        className="btn bg-btn"
                                        onClick={this.handlePromoApply.bind(
                                          this
                                        )}
                                      >
                                        Apply
                                      </button>
                                      <input
                                        type="text"
                                        value={this.state.promoCode}
                                        name="promoCode"
                                        onChange={this.handlePromoCode.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <p>
                                    <i>
                                      Promo codes are valid for one time use
                                      only. Renewal will be at the regular
                                      membership rate at the time of renewal.
                                      Offer may not be combined with any other
                                      promo codes, coupons, discounts, offers,
                                      or promotions.
                                    </i>
                                  </p>
                                </Col>
                              </div>
                            )}
                          </fieldset>
                        </div>
                        <div className="col-md-6 mt-2 member-purchase-section">
                          <fieldset
                            className={
                              this.props.displayMode
                                ? 'scheduler-border border-light'
                                : 'scheduler-border border-dark'
                            }
                          >
                            <legend className="scheduler-border">
                              <b>Auto Renewal</b>
                            </legend>
                            {membershipDetails &&
                              membershipDetails.map(result => {
                                if (result.length) {
                                  return result.map(membershipDetails => {
                                    let key = Object.keys(membershipDetails)[0];
                                    let value = Object.values(
                                      membershipDetails
                                    )[0];
                                    // console.log('value=====', value);
                                    // console.log(
                                    //   ' this.state.radioData',
                                    //   this.state.radioData
                                    // );
                                    if (key == 'membershipId') {
                                      this.state.radioData = value;
                                    }
                                  });
                                }
                              })}
                            {this.state.radioData == 8 && (
                              <div className="row  auto-renewal">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  <div className="auto-renewal-input">
                                    <span>Auto renewal</span>
                                    <span>Yes</span> {''}
                                    <input
                                      type="radio"
                                      value="1"
                                      name={'authorenewal'}
                                      onClick={this.handleChangeRadio}
                                      checked={true}
                                      required
                                    />{' '}
                                    <span>No</span> {''}
                                    <input
                                      type="radio"
                                      value="0"
                                      name={'authorenewal'}
                                      onClick={this.handleChangeRadio}
                                      required
                                    />{' '}
                                  </div>
                                  <p>
                                    You can update auto-renewal and/or upgrade
                                    membership on Membership Info page in
                                    Settings.
                                  </p>
                                  <p>
                                    Disclaimer:
                                    <i>
                                      On the day of one-year membership
                                      expiration you will auto-renew at the
                                      regular membership rate at the time of
                                      renewal
                                    </i>
                                  </p>
                                </Col>
                              </div>
                            )}
                          </fieldset>
                          {this.state.dataset ? (
                            <fieldset
                              className={
                                this.props.displayMode
                                  ? 'scheduler-border border-light'
                                  : 'scheduler-border border-dark'
                              }
                            >
                              <legend className="scheduler-border">
                                <b>Promo Code</b>
                              </legend>
                              {membershipDetails &&
                                membershipDetails.map(result => {
                                  if (result.length) {
                                    return result.map(membershipDetails => {
                                      let key = Object.keys(
                                        membershipDetails
                                      )[0];
                                      let value = Object.values(
                                        membershipDetails
                                      )[0];
                                      // console.log('value=====', value);
                                      // console.log(
                                      //   ' this.state.radioData',
                                      //   this.state.radioData
                                      // );
                                      if (key == 'membershipId') {
                                        this.state.radioData = value;
                                      }
                                    });
                                  }
                                })}
                              {this.state.radioData == 8 && (
                                <div className="row">
                                  <Col md="12" sm="12" xs="12" id="scroll">
                                    <div class="form-check pl-0">
                                      <div className="promo-code">
                                        <button
                                          type="button"
                                          className="btn bg-btn"
                                          onClick={this.handlePromoApply.bind(
                                            this
                                          )}
                                        >
                                          Apply
                                        </button>
                                        <input
                                          type="text"
                                          value={this.state.promoCode}
                                          name="promoCode"
                                          onChange={this.handlePromoCode.bind(
                                            this
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <p>
                                      <i>
                                        Promo codes are valid for one time use
                                        only. Renewal will be at the regular
                                        membership rate at the time of renewal.
                                        Offer may not be combined with any other
                                        promo codes, coupons, discounts, offers,
                                        or promotions.
                                      </i>
                                    </p>
                                  </Col>
                                </div>
                              )}
                            </fieldset>
                          ) : (
                            <fieldset
                              className={
                                this.props.displayMode
                                  ? 'scheduler-border border-light'
                                  : 'scheduler-border border-dark'
                              }
                            >
                              <legend className="scheduler-border">
                                <b>Purchase</b>
                              </legend>
                              {membershipDetails &&
                                membershipDetails.map(result => {
                                  if (result.length) {
                                    return result.map(membershipDetails => {
                                      let key = Object.keys(
                                        membershipDetails
                                      )[0];
                                      let value = Object.values(
                                        membershipDetails
                                      )[0];

                                      // console.log(
                                      //   ' this.state.radioData',
                                      //   this.state.radioData
                                      // );
                                      if (key == 'membershipId') {
                                        this.state.radioData = value;
                                      }
                                    });
                                  }
                                })}
                              {/* {this.state.radioData == 8 && (
                              <div className="row">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  <div className="purchase-section">
                                    <p>
                                      Author Premium Membership 1 year{' '}
                                      <span>${this.state.RealCost}</span>
                                    </p>
                                    <h5>
                                      <i>
                                        Launch Promo Discount: Author Premium $
                                        {this.state.RealCost -
                                          this.state.planCost}{' '}
                                        OFF 1st year
                                      </i>
                                      <span>
                                        $
                                        {this.state.RealCost -
                                          this.state.planCost}
                                      </span>
                                    </h5>
                                    {discountAmount && (
                                      <h5>
                                        <i> Promo Code discount </i>
                                        <span>${discountAmount}</span>
                                      </h5>
                                    )}
                                    <h6>
                                      Membership Total, 1st year{' '}
                                      <span>
                                        $
                                        {discountAmount
                                          ? this.state.planCost - discountAmount
                                          : this.state.planCost}
                                      </span>
                                    </h6>
                                  </div>
                                </Col>
                              </div>
                            )} */}

                              <div className="row">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  {this.state.selected === '8' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Author Standard Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Author $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1<sup>st</sup>&nbsp;year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        <p>
                                          Membership Total, 1<sup>st</sup>
                                          &nbsp;year{' '}
                                        </p>
                                        <p>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </p>
                                      </h6>
                                    </div>
                                  )}

                                  {this.state.selected === '1' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Author Premium Membership, 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Author Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1<sup>st</sup>&nbsp;year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        <p>
                                          Membership Total, 1<sup>st</sup>
                                          &nbsp;year{' '}
                                        </p>
                                        <p>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </p>
                                      </h6>
                                    </div>
                                  )}

                                  {this.state.selected === '2' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Reader Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Reader
                                          Membership $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1<sup>st</sup>&nbsp;year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        <p>
                                          Membership Total, 1<sup>st</sup>
                                          &nbsp;year{' '}
                                        </p>
                                        <p>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </p>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '3' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Blogger Premium Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Blogger Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1<sup>st</sup>&nbsp;year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        <p>
                                          Membership Total, 1<sup>st</sup>
                                          &nbsp;year{' '}
                                        </p>
                                        <p>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </p>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '4' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Vendor Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Vendor
                                          Membership $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1<sup>st</sup>&nbsp;year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        <p>
                                          Membership Total, 1<sup>st</sup>
                                          &nbsp;year{' '}
                                        </p>
                                        <p>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </p>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '5' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Author Premium Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Author Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1<sup>st</sup>&nbsp;year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        <p>
                                          Membership Total, 1<sup>st</sup>
                                          &nbsp;year{' '}
                                        </p>
                                        <p>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </p>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '6' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Blogger Premium Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Blogger Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1<sup>st</sup>&nbsp;year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        <p>
                                          Membership Total, 1<sup>st</sup>
                                          &nbsp;year{' '}
                                        </p>
                                        <p>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </p>
                                      </h6>
                                    </div>
                                  )}
                                </Col>
                              </div>
                            </fieldset>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 mt-2 promo-code-section">
                          <fieldset
                            className={
                              this.props.displayMode
                                ? 'scheduler-border border-light'
                                : 'scheduler-border border-dark'
                            }
                          >
                            <legend className="scheduler-border">
                              <b>Promo Code</b>
                            </legend>
                            {membershipDetails &&
                              membershipDetails.map(result => {
                                if (result.length) {
                                  return result.map(membershipDetails => {
                                    let key = Object.keys(membershipDetails)[0];
                                    let value = Object.values(
                                      membershipDetails
                                    )[0];
                                    // console.log('value=====', value);
                                    // console.log(
                                    //   ' this.state.radioData',
                                    //   this.state.radioData
                                    // );
                                    if (key == 'membershipId') {
                                      this.state.radioData = value;
                                    }
                                  });
                                }
                              })}
                            {this.state.radioData == 8 && (
                              <div className="row">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  <div class="form-check pl-0">
                                    <div className="promo-code">
                                      <button
                                        type="button"
                                        className="btn bg-btn"
                                        onClick={this.handlePromoApply.bind(
                                          this
                                        )}
                                      >
                                        Apply
                                      </button>
                                      <input
                                        type="text"
                                        value={this.state.promoCode}
                                        name="promoCode"
                                        onChange={this.handlePromoCode.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <p>
                                    <i>
                                      Promo codes are valid for one time use
                                      only. Renewal will be at the regular
                                      membership rate at the time of renewal.
                                      Offer may not be combined with any other
                                      promo codes, coupons, discounts, offers,
                                      or promotions.
                                    </i>
                                  </p>
                                </Col>
                              </div>
                            )}
                          </fieldset>
                        </div>

                        {this.state.dataset ? (
                          <div className="col-md-6 mt-2 member-purchase-section">
                            <fieldset
                              className={
                                this.props.displayMode
                                  ? 'scheduler-border border-light'
                                  : 'scheduler-border border-dark'
                              }
                            >
                              <legend className="scheduler-border">
                                <b>Purchase</b>
                              </legend>
                              {membershipDetails &&
                                membershipDetails.map(result => {
                                  if (result.length) {
                                    return result.map(membershipDetails => {
                                      let key = Object.keys(
                                        membershipDetails
                                      )[0];
                                      let value = Object.values(
                                        membershipDetails
                                      )[0];

                                      // console.log(
                                      //   ' this.state.radioData',
                                      //   this.state.radioData
                                      // );
                                      if (key == 'membershipId') {
                                        this.state.radioData = value;
                                      }
                                    });
                                  }
                                })}
                              {/* {this.state.radioData == 8 && (
                              <div className="row">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  <div className="purchase-section">
                                    <p>
                                      Author Premium Membership 1 year{' '}
                                      <span>${this.state.RealCost}</span>
                                    </p>
                                    <h5>
                                      <i>
                                        Launch Promo Discount: Author Premium $
                                        {this.state.RealCost -
                                          this.state.planCost}{' '}
                                        OFF 1st year
                                      </i>
                                      <span>
                                        $
                                        {this.state.RealCost -
                                          this.state.planCost}
                                      </span>
                                    </h5>
                                    {discountAmount && (
                                      <h5>
                                        <i> Promo Code discount </i>
                                        <span>${discountAmount}</span>
                                      </h5>
                                    )}
                                    <h6>
                                      Membership Total, 1st year{' '}
                                      <span>
                                        $
                                        {discountAmount
                                          ? this.state.planCost - discountAmount
                                          : this.state.planCost}
                                      </span>
                                    </h6>
                                  </div>
                                </Col>
                              </div>
                            )} *f/}

                              <div className="row">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  {this.state.selected === '8' && (
                                    <div className="purchase-section">
                                      <p>
                                        Author Standard Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Author $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}

                                  {this.state.selected === '1' && (
                                    <div className="purchase-section">
                                      <p>
                                        Author Premium Membership, 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Author Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}

                                  {this.state.selected === '2' && (
                                    <div className="purchase-section">
                                      <p>
                                        Reader Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Reader
                                          Membership $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '3' && (
                                    <div className="purchase-section">
                                      <p>
                                        Blogger Premium Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Blogger Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '4' && (
                                    <div className="purchase-section">
                                      <p>
                                        Vendor Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Vendor
                                          Membership $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '5' && (
                                    <div className="purchase-section">
                                      <p>
                                        Author Premium Membership, 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Author Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '6' && (
                                    <div className="purchase-section">
                                      <p>
                                        Blogger Premium Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Blogger Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                </Col>
                              </div>
                            </fieldset>
                          </div>
                        ) : (
                          <div className="col-md-6 mt-2 member-purchase-section">
                            <fieldset
                              className={
                                this.props.displayMode
                                  ? 'scheduler-border border-light'
                                  : 'scheduler-border border-dark'
                              }
                            >
                              <legend className="scheduler-border">
                                <b>Purchase</b>
                              </legend>
                              {membershipDetails &&
                                membershipDetails.map(result => {
                                  if (result.length) {
                                    return result.map(membershipDetails => {
                                      let key = Object.keys(
                                        membershipDetails
                                      )[0];
                                      let value = Object.values(
                                        membershipDetails
                                      )[0];

                                      // console.log(
                                      //   ' this.state.radioData',
                                      //   this.state.radioData
                                      // );
                                      if (key == 'membershipId') {
                                        this.state.radioData = value;
                                      }
                                    });
                                  }
                                })}
                              {/* {this.state.radioData == 8 && (
                              <div className="row">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  <div className="purchase-section">
                                    <p>
                                      Author Premium Membership 1 year{' '}
                                      <span>${this.state.RealCost}</span>
                                    </p>
                                    <h5>
                                      <i>
                                        Launch Promo Discount: Author Premium $
                                        {this.state.RealCost -
                                          this.state.planCost}{' '}
                                        OFF 1st year
                                      </i>
                                      <span>
                                        $
                                        {this.state.RealCost -
                                          this.state.planCost}
                                      </span>
                                    </h5>
                                    {discountAmount && (
                                      <h5>
                                        <i> Promo Code discount </i>
                                        <span>${discountAmount}</span>
                                      </h5>
                                    )}
                                    <h6>
                                      Membership Total, 1st year{' '}
                                      <span>
                                        $
                                        {discountAmount
                                          ? this.state.planCost - discountAmount
                                          : this.state.planCost}
                                      </span>
                                    </h6>
                                  </div>
                                </Col>
                              </div>
                            )} *-/}

                              <div className="row">
                                <Col md="12" sm="12" xs="12" id="scroll">
                                  {this.state.selected === '8' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Author Standard Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Author $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}

                                  {this.state.selected === '1' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Author Premium Membership, 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Author Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}

                                  {this.state.selected === '2' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Reader Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Reader
                                          Membership $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '3' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Blogger Premium Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Blogger Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '4' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Vendor Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Vendor
                                          Membership $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '5' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Author Premium Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Author Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                  {this.state.selected === '6' && (
                                    <div className="purchase-sectionnnnn">
                                      <p>
                                        Blogger Premium Membership 1 year{' '}
                                        <span>${this.state.RealCost}</span>
                                      </p>
                                      <h5>
                                        <i>
                                          Launch Promo Discount: Blogger Premium
                                          $
                                          {this.state.RealCost -
                                            this.state.planCost}{' '}
                                          OFF 1st year
                                        </i>
                                        <span>${promoCode1}</span>
                                      </h5>
                                      {discountAmount && (
                                        <h5>
                                          <i> Promo Code discount </i>
                                          <span>${discountAmount}</span>
                                        </h5>
                                      )}
                                      <h6>
                                        Membership Total, 1st year{' '}
                                        <span>
                                          <span>
                                            $
                                            {discountAmount
                                              ? addDiscount1
                                              : addPlanCost}
                                          </span>
                                        </span>
                                      </h6>
                                    </div>
                                  )}
                                </Col>
                              </div>
                            </fieldset>
                          </div>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <ConditionsModel
                    isOpen={this.state.showTermModal}
                    check={this.onCheck}
                    toggle={this.hideModal.bind(this)}
                  />

                  <PrivacyModal
                    isOpen={this.state.showPrivacyModal}
                    cross={this.onPrivacyCheck}
                    toggle={this.hidePrivacyModal.bind(this)}
                  />
                  <CookieModal
                    isOpen={this.state.showCookieModal}
                    toggle={this.hideCookieModal.bind(this)}
                  />
                  <div className="col-md-12">
                    <div className="thanktwo_main">
                      <Button className=" outer-btn">GO Back</Button>
                      <div className="membership-btn">
                        <div className="otr-text">
                          OTR uses third-party sites for payment. OTR does not
                          collect nor store member payment information.
                        </div>
                        <img src={braintree} alt="payment" />
                        <img src={payment} alt="payment" />
                        <Button className="bg-btn">PAY now</Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="modal1"
          >
            <ModalHeader toggle={this.toggle} className="eventModal">
              <h3 className="text-center p-0">BLASTS (AUTHORS)</h3>
            </ModalHeader>
            <ModalBody>
              <ol>
                <li start="1">
                  Send a FREE release day Blast to all followers and members who
                  added the book release to their calendar.
                </li>
                <li className="mt-2">
                  Earn a free Blast upon earning your first 500 followers & one
                  free Blast for every additional 500 followers.
                </li>
                <li className="mt-2">
                  Additional Blasts to followers can be purchased, but limited
                  to a maximum of one per month.
                </li>
              </ol>
              <i>
                <p>*** Subject to change.</p>
              </i>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modal1}
            toggle={this.toggle1}
            className="modal1"
          >
            <ModalHeader toggle={this.toggle1} className="eventModal">
              <h3 className="text-center p-0">FOLLOWERS (AUTHORS)</h3>
            </ModalHeader>
            <ModalBody>
              <p>
                An exclusive “invite code” will be generated after sign-up to
                share with your fan base. When an invite code is redeemed upon
                member sign up, you will automatically earn that follower.{' '}
              </p>
              <ol>
                <li start="1">
                  Increase visibility on the calendar by gaining followers.
                </li>
                <li className="mt-2">
                  Send FREE release day Blast to all followers.
                </li>
                <li className="mt-2">
                  Reach followers with earned and purchased Blasts.
                </li>
              </ol>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modal2}
            toggle={this.toggle2}
            className="modal1"
          >
            <ModalHeader toggle={this.toggle2} className="eventModal">
              <h3 className="text-center p-0">DAILY TASKS</h3>
            </ModalHeader>
            <ModalBody>
              <p>
                Daily Tasks (with reminders) is a private calendar for
                organizing daily tasks with notes, links and reminders. Create a
                to-do-list, save posting data or track writing goals.
              </p>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modal3}
            toggle={this.toggle3}
            className="modal1"
          >
            <ModalHeader toggle={this.toggle3} className="eventModal">
              <h3 className="text-center p-0">WRITING GOALS</h3>
            </ModalHeader>
            <ModalBody>
              <p>
                Use the Daily Tasks notes feature with reminders to keep track
                of writing goals and to help stay organized.
              </p>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modal4}
            toggle={this.toggle4}
            className="modal1"
          >
            <ModalHeader toggle={this.toggle4} className="eventModal">
              <h3 className="text-center p-0">NETWORK WITH VENDORS</h3>
            </ModalHeader>
            <ModalBody>
              <p>
                In addition to the reader calendar accessible to all members,
                OTR enables authors to network with vendors, PR firms & other
                authors with messaging & access to exclusive OTR lists for
                bloggers, beta readers & Vendor Cancellation Openings.
              </p>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modal5}
            toggle={this.toggle5}
            className="modal1"
          >
            <ModalHeader toggle={this.toggle5} className="eventModal">
              <h3 className="text-center p-0">
                VENDOR CANCELLATION OPENINGS LIST
              </h3>
            </ModalHeader>
            <ModalBody>
              <p>
                Even if a vendor is not accepting new clients, their
                cancellations can be added as Vendor Cancellation Openings (VCO)
                marked on Vendor list. This enables authors to maximize an
                opportunity to reserve services with previously fully booked
                vendors.
              </p>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modal6}
            toggle={this.toggle6}
            className="modal1"
          >
            <ModalHeader toggle={this.toggle6} className="eventModal">
              <h3 className="text-center p-0">BETA READER LIST</h3>
            </ModalHeader>
            <ModalBody>
              <p>
                All members are able to add themselves to the beta reader list
                accessible by all authors.
              </p>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modal7}
            toggle={this.toggle7}
            className="modal1"
          >
            <ModalHeader toggle={this.toggle7} className="eventModal">
              FOLLOWERS
            </ModalHeader>
            <ModalBody>
              <p>
                An exclusive “invite code” will be generated after sign-up to
                share with your fan base. When an invite code is redeemed upon
                member sign up, you will automatically earn that follower.
              </p>
              <ol>
                <li start="1">
                  Increase visibility on the calendar by gaining followers.
                </li>
                <li className="mb-2">
                  Reach followers with earned and purchased Blasts.
                </li>
              </ol>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modal8}
            toggle={this.toggle8}
            className="modal1"
          >
            <ModalHeader toggle={this.toggle8} className="eventModal">
              {' '}
              <h3 className="text-center p-0">BLASTS</h3>{' '}
            </ModalHeader>
            <ModalBody>
              <ol>
                <li start="1">
                  Earn a free Blast upon earning your first 500 followers & one
                  free Blast for every additional 500 followers.
                </li>
                <li className="mb-2">
                  Additional Blasts to followers can be purchased, but limited
                  to a maximum of one per month.
                </li>
              </ol>
              <i>
                <p>*** Subject to change.</p>
              </i>
            </ModalBody>
          </Modal>
        </Layout>
      );
    } else if (this.props.success) {
      return (
        <Layout>
          <div className="container">
            <div className="">
              <div className="thankyou_cover">
                <div className="inner_thankyou">
                  <h1>Thank You</h1>
                  <p>For Becoming An </p>
                  <p>ON target romance Member.</p>
                  <div className="button_btft" style={{ marginTop: '10%' }}>
                    <div className="col-md-12 text-center">
                      <Link
                        to="/log-in"
                        onClick={() => localStorage.clear()}
                        className="btn new_btn_save_bt float-md-right"
                      >
                        Log In
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      );
    } else {
      return <div></div>;
    }
  }

  componentDidMount() {
    const local = localStorage.getItem('userType');
    console.log('this.props1222', this.props, this.props.success);

    if (local === '1') {
      this.setState({ selected: '1' });
      this.planCostHandller('1');
    } else if (local === '3' || local === '2') {
      this.setState({ selected: '6' });
      this.planCostHandller('6');
    } else if (local === '4') {
      this.setState({ selected: '4' });
      this.planCostHandller('4');
    } else if (local === '5') {
      this.setState({ selected: '5' });
      this.planCostHandller('5');
    }
    const a = document.querySelectorAll('input[name=plan]');

    this.props.setPaymentSucess(false);
    if (
      localStorage.getItem('userType') &&
      (localStorage.getItem('membershipId') == 'null' ||
        !localStorage.getItem('membershipId') == 'null')
    ) {
      this.props.getMembershipDetails(
        // `/api/v1/user-type/${localStorage.getItem("userType")}`
        `/api/v1/membershipPlans`
      );
      // this.props.getPrCode(`/api/v1/users/getPRCode/${localStorage.getItem('userId')}`)
    } else if (localStorage.getItem('membershipId')) {
      this.props.history.push('/dashboard');
    }
    // this.props.handleGetAllDiscounts('/api/v1/Get/discounts');
    // axios
    //   .get(BASEURL + '/api/v1/discounts')
    //   .then(response => {
    //     console.log('response', response);
    //     //this.setState({ AuthorTypes: response.data.result.userTypes });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }
}

const mapStateToProps = state => {
  return {
    loading: state.signuploading,
    membershipDetails: state.getMembershipDetails,
    success: state.success,
    getFollowPrCode: state.getFollowPrCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMembershipDetails: url => dispatch(getUserMembershipDetails(url)),
    validatePR: (url, value, status) =>
      dispatch(verifyPRCode(url, value, status)),
    handleGetAllDiscounts: url => dispatch(GetAllDiscounts(url)),
    completeSignup: (url, body) =>
      dispatch(paymentConfirmation(url, body, false)),
    getPrCode: url => dispatch(getFollowedUserPrCode(url)),
    setPaymentSucess: status => dispatch(paymentSucess(status))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
