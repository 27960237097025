import React, { Component } from 'react';
import { notify } from 'react-notify-toast';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  setupEvent,
  getAllEventsSummary,
  Tab,
  filterevent,
  getAllEvents,
  changeEventMode
} from '../../Actions/event-setup';
import { getAllTasks } from '../../Actions/dailytask';
import { getProfileDetails } from '../../Actions/profile-setup';
import { changeDisplayMode, popularEvents } from '../../Actions/profile-setup';
import { Search } from '../../Actions/filters';

import Popover, { ArrowContainer } from 'react-tiny-popover';
import './../usersList/usersList.css';
import {
  getNotificationlist,
  countUnreadMessages
} from '../../Actions/messages';

import createHistory from 'history/createHashHistory';
import moment from 'moment';
import axios from 'axios';
import { BASEURL } from '../../constant';
const globalHistory = new createHistory();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formJSON: [],
      event: null,
      showModal: false,
      showFilterModal: false,
      showSortModal: false,
      myevents: true,
      isCalenderView: true,
      showMenu: false,
      showNotification: false,
      activeTab: this.props.activeTab,
      search: '',
      showNotificaiton: false,
      popularEvents: false,
      notificationData: {},
      count: ''
    };
    this.props.setupEvent();
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onCheckboxChangeHandler = this.onCheckboxChangeHandler.bind(this);
    this.onCustomChangeHandler = this.onCustomChangeHandler.bind(this);
    this.onChangeFileHandler = this.onChangeFileHandler.bind(this);
    this.onSubmitFormHandler = this.onSubmitFormHandler.bind(this);
    this.getCustomElementObject = this.getCustomElementObject.bind(this);
    this.addMoreLinkHandler = this.addMoreLinkHandler.bind(this);
    this.addMoreServiceHandler = this.addMoreServiceHandler.bind(this);
    this.changeEventsView = this.changeEventsView.bind(this);
    this.hideDropDown = this.hideDropDown.bind(this);
    this.showDropDown = this.showDropDown.bind(this);
  }

  //======================= On Change Handlers ================
  showDropDown() {
    this.setState({
      showMenu: !this.state.showMenu
    });
    this.props.changeTab('');
  }

  showNotification() {
    this.setState({
      showNotification: !this.state.showNotification
    });
  }

  hideCreateModal = () => {
    this.setState({
      showModal: false
      // profileData: {}
    });
  };

  hideFilterModal() {
    this.setState({
      showFilterModal: false
      // profileData: {}
    });
  }

  hideSortModal() {
    this.setState({
      showSortModal: false
      // profileData: {}
    });
  }

  hideDropDown() {
    this.setState({
      showMenu: false
    });
  }

  handleMyEvents(event) {
    this.setState({
      myevents: event.target.checked
    });
    this.props.eventDisplayMode(event.target.checked);
    // this.props.getAllEvents(event.target.checked);
    this.props.getEvents(event.target.checked);
  }
  onChangeHandler(value, index) {
    let data = this.state.formJSON;
    data[index].value = value;
    this.setState({ formJSON: data });
  }
  onCheckboxChangeHandler(value, parentIndex) {
    let data = this.state.formJSON;
    if (data[parentIndex].value.includes(value)) {
      data[parentIndex].value = data[parentIndex].value.replace(value, '');
    } else {
      data[parentIndex].value += data[parentIndex].value.concat(value);
    }

    // if(data[parentIndex].value.contains(value))
    // {
    //   data[parentIndex].value = data[parentIndex].value.replace(data[parentIndex].value).trim(",");
    // }
    // else{
    //   data[parentIndex].value += "," + value;
    // }
    this.setState({ formJSON: data });
  }

  onCustomChangeHandler(value, index, parentIndex) {
    let data = this.state.formJSON;
    data[parentIndex].children[index].value = value;
    this.setState({ formJSON: data });
  }

  onChangeFileHandler(file, acceptedSize) {
    if (parseInt(acceptedSize) * 1000 <= parseInt(file.size)) {
      notify.show(
        'File size is bigger than max size. Profile will be saved without Image.',
        'custom',
        5000,
        { background: '#f8d7da', text: '#721c24' }
      );
      return;
    }
    this.setState({ profile: file });
  }

  onSubmitFormHandler(e, history) {
    e.preventDefault();
    this.setState({
      showModal: true
    });
    // history.push("/setup-event");
  }

  addMoreLinkHandler(parentIndex, index) {
    let data = this.state.formJSON;
    data[parentIndex].children.push(data[parentIndex].children[index]);
    let el = {
      tag: 'input',
      type: 'text',
      className: 'form-control in_form_control',
      placeholder: 'link',
      value: '',
      validation: false,
      name: ''
    };
    data[parentIndex].children[index] = el;
    data[parentIndex].children[index].name =
      data[parentIndex].linkname + (parseInt(data[parentIndex].links) + 1);
    data[parentIndex].links = parseInt(data[parentIndex].links) + 1;

    this.setState({ formJSON: data });
  }

  addMoreServiceHandler(parentIndex, index) {
    let data = this.state.formJSON;
    //data[parentIndex].children.push(data[parentIndex].children[index]);
    let el = data[parentIndex].children[index];
    let el1 = { tag: 'date', value: '', name: '' };
    let el2 = {
      tag: 'service',
      name: '',
      type: 'text',
      value: '',
      className: 'form-control',
      validation: 'false',
      placeholder: 'Service'
    };

    data[parentIndex].children[index] = el1;
    data[parentIndex].children[index].name =
      'date_' + (parseInt(data[parentIndex].service) + 1);
    data[parentIndex].children.push(el2);
    data[parentIndex].children[index + 1].name =
      'service_' + (parseInt(data[parentIndex].service) + 1);
    data[parentIndex].service = parseInt(data[parentIndex].service) + 1;
    data[parentIndex].children.push(el);
    this.setState({ formJSON: data });
  }

  changeEventsView(value) {
    this.setState({
      isCalenderView: value
    });
  }

  //======================= On Change Handlers ENDS ================

  //======================= Common functions ====================

  getJSONFormData() {
    const formData = new FormData();
    this.state.formJSON.map(el => {
      if (el.name) {
        if (el.tag === 'file') formData.append(el.name, this.state.profile);
        else if (el.tag === 'custom') {
          let data = this.getCustomElementObject(el.children);

          formData.append(el.name, data);
        } else formData.append(el.name, el.value);
      }
    });
    return formData;
  }

  getCustomElementObject(children) {
    let data = {};
    children.map(el => {
      if (el.name) {
        data[el.name] = el.value;
      }
    });
    return JSON.stringify(data);
  }

  // onChangeTab(tab) {
  //   this.props.changeTab(tab);
  // }
  onChangeTab(tab) {
    if (
      tab === 'EVENT FILTER' &&
      localStorage.getItem('membershipId') !== '2'
    ) {
      window.location.reload();
    }

    this.props.changeTab(tab);
  }
  setSearchFilter(e) {
    if (this.props.search.trim() == '') {
      this.props.changeTab('Event filter');
      this.props.navData.history.push(this.props.match.url + '/event-filter');
    }
    this.setState({ search: e.target.value });
    //this.props.setSearchFilter(e.target.value)
  }

  isAuthorized(route) {
    let isAuthorized = false;
    JSON.parse(localStorage.getItem('menuList')).forEach(element => {
      if (route === '/users/blogger') {
        isAuthorized = true;
      }
      if (+localStorage.getItem('membershipId') == 6) {
        isAuthorized = true;
      }
      if (element.weblink === route) {
        isAuthorized = true;
      } else if (element.subMenu) {
        element.subMenu.map(subMenu => {
          if (subMenu.weblink == route) {
            return (isAuthorized = true);
          }
        });
      }
    });
    return isAuthorized;
  }

  getNoficationList = async data => {
    console.log('data=======>>>>>>>317', data);
    let my = data.forEach(async el => {
      const exist = await axios.get(
        BASEURL + `/api/v1/events/getNotification`,
        {
          params: {
            user_id: localStorage.getItem('userId'),
            type: 'events',
            event_id: el.id
          }
        }
      );
      const addtoCalendar = await axios.get(
        BASEURL + `/api/v1/events/getNotificationAddtoCalendar`,
        {
          params: {
            user_id: localStorage.getItem('userId'),
            type: 'events',
            event_id: el.id
          }
        }
      );

      if (addtoCalendar.data.result.length) {
        await axios.post(
          BASEURL + `/api/v1/events/notificationUpdateAddtoCalendar`,
          { type: 'events', event_id: el.id },
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          }
        );
      }

      if (exist.data.result[0].count == 0) {
        await axios.post(
          BASEURL + `/api/v1/events/notificationUpdate`,
          { type: 'events', event_id: el.id },
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          }
        );
        await this.props.getAllTask(`/api/v1/daily_task/getTask`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        });
        this.props.getNotificationList(`/api/v1/notification`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        });
      }

      await this.props.getAllTask(`/api/v1/daily_task/getTask`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });

      this.props.getNotificationList(`/api/v1/notification`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });
    });
    //window.location.reload();

    this.setState(
      {
        notificationData: my,
        showNotification: false
      },
      // this.props.navData.history.push('/dashboard/notification')
      globalHistory.push('/dashboard/notification')
    );
  };

  getDailyTask() {
    this.setState({
      showNotification: false
    });
    // this.setState({ count: 1 });
    //window.location.reload();
    // this.props.navData.history.push(
    //   `/dashboard/dailyTaskDetails?startDate=${moment().format('YYYY-MM-DD')}`
    // );

    globalHistory.push(
      `/dashboard/dailyTaskDetails?startDate=${moment().format('YYYY-MM-DD')}`
    );
  }

  getdailyTaskRedirect() {
    this.setState({ count: 1 });
    //window.location.reload();
    // this.props.navData.history.push(
    //   `/dashboard/dailyTaskDetails?startDate=${moment().format('YYYY-MM-DD')}`
    // );

    globalHistory.push(
      `/dashboard/dailyTaskDetails?startDate=${moment().format('YYYY-MM-DD')}`
    );
  }

  handlePopularEvents = event => {
    this.props.changePopularEvents(event.target.checked);
    this.setState({
      popularEvents: event.target.checked
    });
  };

  getMostPopularevents = () => {
    this.props.changePopularEvents(true);
    this.setState({
      popularEvents: true,
      showNotification: false
    });

    // this.props.navData.history.push(`/dashboard/tranding-events`);

    globalHistory.push(`/dashboard/tranding-events`);
  };

  getNewletter = () => {
    // this.props.navData.history.push(`/dashboard/newsletter`);
    this.setState({ showNotification: false });
    globalHistory.push(`/dashboard/newsletter`);
  };

  getCurrentNotification = () => {
    this.setState({
      showNotification: false
    });
    globalHistory.push(`/dashboard/notification`);
  };

  followingEventRedirect = () => {
    this.setState({
      showNotification: false
    });
    globalHistory.push(`/dashboard/followingevent`);
  };
  redirectBlastNotification = () => {
    this.setState({
      showNotification: false
    });

    globalHistory.push(`/dashboard/blast-notification`);
  };
  followingEvent = async data => {
    console.log('ddddddddddddd459', data);
    let my = data.forEach(async el => {
      const exist = await axios.get(
        BASEURL + `/api/v1/events/getNotification`,
        {
          params: {
            user_id: localStorage.getItem('userId'),
            type: 'events',
            event_id: el.id
          }
        }
      );

      if (exist.data.result[0].count == 0) {
        await axios.post(
          BASEURL + `/api/v1/events/notificationUpdate`,
          { type: 'events', event_id: el.id },
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          }
        );

        this.props.getNotificationList(`/api/v1/notification`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        });
      }

      // this.props.getNotificationList(`/api/v1/notification`, {
      //   headers: { 'x-authorization': localStorage.getItem('token') }
      // });
    });
    //window.location.reload();

    this.setState(
      {
        showNotification: false
      },
      // this.props.navData.history.push(`/dashboard/followingevent`)
      globalHistory.push(`/dashboard/followingevent`)
    );
  };

  getBlastNotification = async data => {
    let ddd = data.forEach(async el => {
      const exist = await axios.get(BASEURL + `/api/v1/users/getblast`, {
        params: {
          user_id: localStorage.getItem('userId'),
          type: 'blast',
          blast_id: el.id
        }
      });

      if (exist.data.result[0].count == 0) {
        await axios.post(
          BASEURL + `/api/v1/users/blastUpdate`,
          { type: 'blast', blast_id: el.id },
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          }
        );
      }
      this.props.getNotificationList(
        `/api/v1/notification`,

        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        }
      );
    });
    //window.location.reload();
    this.setState(
      {
        showNotification: false
      },
      // this.props.navData.history.push('/dashboard/blast-notification')
      globalHistory.push(`/dashboard/blast-notification`)
    );
  };

  //======================= Common functions ENDS ====================

  //===================== RENDER ==========================
  render() {
    // check for todays task and then show daily notification
    let dailyTask = this.props.taskData;
    const fiTask = dailyTask.filter(el => el.is_seen == 0);
    // console.log('fiTask', fiTask);
    let dailyDate = fiTask.map(d => moment(d.added_on).format('DD/MM/YYYY'));

    const today = moment().format('DD/MM/YYYY');

    let today_daily_task = dailyDate
      .filter(data => data.includes(today))
      .map(d => d);

    const data = JSON.parse(localStorage.getItem('menuList'));
    const pathName = '/dashboard';
    const pathIsDashboard = pathName == '/dashboard' ? true : false;
    const isEventFilter = pathName == '/dashboard/event-filter' ? true : false;
    const isReader = +localStorage.getItem('membershipId') == 2 ? true : false;
    const isBlogger = +localStorage.getItem('membershipId') == 3 ? true : false;
    const isBloggerPrem =
      +localStorage.getItem('membershipId') == 6 ? true : false;
    const isPRFirm =
      +localStorage.getItem('membershipId') == 5 ||
      +localStorage.getItem('membershipId') == 6
        ? true
        : false;
    const isAuthor =
      +localStorage.getItem('membershipId') == 1 ||
      +localStorage.getItem('membershipId') == 8
        ? true
        : false;
    const isVendor = +localStorage.getItem('membershipId') === 4 ? true : false;

    let subMenuArray = [];
    if (data && data.length > 0) {
      let actualSubmenu = data[3].subMenu;

      if (isReader || isBlogger || isVendor) {
        actualSubmenu.forEach(el => {
          if (el.name === 'BETA READERS' || el.name === 'BETA READER LIST') {
            subMenuArray[0] = { ...el };
          } else if (el.name === 'FOLLOWING') {
            subMenuArray[1] = { ...el };
          } else if (el.name === 'ALL PROFILES') {
            subMenuArray[2] = { ...el };
          } else if (el.name === 'MOST FOLLOWED') {
            subMenuArray[3] = { ...el };
          }
        });
        if (isAuthor) {
          subMenuArray[0] = {
            applink: null,
            id: 9,
            is_submenu: 1,
            membership_id: 8,
            menu_id: 4,
            name: 'BETA READERS',
            title: 'BETA READERS',
            weblink: '/users/beta_readers'
          };
        }
      } else if (isBloggerPrem || isPRFirm) {
        actualSubmenu.forEach(el => {
          if (el.name === 'BETA READER LIST' || el.name === 'BETA READERS') {
            subMenuArray[0] = { ...el };
          } else if (el.name === 'FOLLOWING') {
            subMenuArray[2] = { ...el };
          } else if (el.name === 'ALL PROFILES') {
            subMenuArray[3] = { ...el };
          } else if (el.name === 'MOST FOLLOWED') {
            subMenuArray[4] = { ...el };
          }
        });
        if (localStorage.getItem('membershipId') === 4) {
          subMenuArray[0] = {
            applink: null,
            id: 9,
            is_submenu: 1,
            membership_id: 8,
            menu_id: 4,
            name: 'BETA READERS',
            title: 'BETA READERS',
            weblink: '/users/beta_readers'
          };
        }
        subMenuArray[1] = {
          name: 'FOLLOWERS',
          title: 'FOLLOWERS',
          weblink: '/users/followers'
        };
      } else if (isAuthor) {
        actualSubmenu.forEach(el => {
          if (el.name === 'BETA READERS') {
            subMenuArray[0] = { ...el };
          } else if (el.name === 'FOLLOWING') {
            subMenuArray[3] = { ...el };
          } else if (el.name === 'ALL PROFILES') {
            subMenuArray[4] = { ...el };
          } else if (el.name === 'MOST FOLLOWED') {
            subMenuArray[5] = { ...el };
          }
        });
        subMenuArray[1] = {
          name: 'BLOGGERS',
          title: 'BLOGGERS',
          weblink: '/users/blogger'
        };
        subMenuArray[2] = {
          name: 'FOLLOWERS',
          title: 'FOLLOWERS',
          weblink: '/users/followers'
        };
      }
    }

    let notifylink =
      this.props.notificationList.currentUserData &&
      this.props.notificationList.currentUserData.filter(
        el =>
          (el.event && el.event.is_seen == null) ||
          (el.event && el.event.is_seen == 0)
      );
    let note = notifylink && notifylink.length > 0 ? notifylink.length : 0;
    let notifylinkfollowing =
      this.props.notificationList.sevenDaysEvent &&
      this.props.notificationList.sevenDaysEvent.filter(
        el => el.event && el.event.is_seen == null
      );
    let notefollowingdata =
      notifylinkfollowing && notifylinkfollowing.length > 0
        ? notifylinkfollowing.length
        : 0;
    let notifyBlast =
      this.props.notificationList.blastData &&
      this.props.notificationList.blastData.filter(el => el.is_seen == null);

    let noteBlast =
      notifyBlast && notifyBlast.length > 0 ? notifyBlast.length : 0;

    let notificationTotal =
      this.props.notificationList &&
      Object.keys(this.props.notificationList).length !== 0
        ? note +
          notefollowingdata +
          this.props.notificationList.suggestedEvents.length +
          this.props.notificationList.favouriteEvents.length +
          noteBlast
        : 0;
    // console.log('notificationTotal', this.props.notificationList);

    let notif;
    if (notifylink && notifylink.length > 0) {
      notif = notifylink.length;
    }

    let totalNotification = notificationTotal + today_daily_task.length;

    let activeRoute = window.location.href;
    const splitData = activeRoute.split('/');
    const activePage = splitData[splitData.length - 1];
    // console.log('this is current page ', this.props.activeTab);

    return (
      <>
        {/* <Logo history={this.props.history} /> */}
        {/* <div
                className={
                  this.props.displayMode
                    ? 'main_right author_profile'
                    : 'main_right author_profile calendar-dark'
                }
                // style={{ minHeight: 1000 + "px" }}
                style={{ flex: '1' }}
              > */}
        <div
          className={
            this.props.displayMode
              ? 'navigation bg-light'
              : 'navigation bg-dark'
          }
        >
          <nav
            className={
              this.props.displayMode
                ? 'navbar navbar-expand-lg navbar-light bg-light'
                : 'navbar navbar-expand-lg navbar-light bg-dark'
            }
          >
            <button
              className={
                this.props.displayMode
                  ? 'navbar-toggler'
                  : 'navbar-toggler bg-white'
              }
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                {data && data.length
                  ? data.map((tab, index) => {
                      if (!tab.subMenu) {
                        if (tab.name === 'MESSAGES') {
                          return (
                            <>
                              <li
                                onClick={() => {
                                  this.onChangeTab(tab.name);
                                }}
                                className={
                                  this.props.activeTab.toUpperCase() == tab.name
                                    ? 'nav-item active'
                                    : 'nav-item'
                                }
                              >
                                {/* {console.log('tab.name========>', tab.name)} */}
                                <Link
                                  className="nav-link"
                                  to={'/dashboard' + tab.weblink}
                                >
                                  {tab.name}
                                </Link>
                                {this.props.messageCount > 0 && (
                                  <span class="badge badge-pill badge-danger messageBadge">
                                    {this.props.messageCount}
                                  </span>
                                )}
                              </li>
                            </>
                          );
                        } else {
                          return (
                            <li
                              onClick={() => {
                                this.onChangeTab(tab.name);
                              }}
                              className={
                                this.props.activeTab.toUpperCase() == tab.name
                                  ? 'nav-item active'
                                  : tab.name === 'EVENT FILTER' &&
                                    (activePage === 'today-event-list' ||
                                      activePage === 'tranding-events')
                                  ? 'nav-item active'
                                  : tab.name === 'DAILY TASKS' &&
                                    (activePage === 'daily_task_list' ||
                                      activePage === 'daily-task')
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <Link
                                className="nav-link"
                                to={'/dashboard' + tab.weblink}
                              >
                                {tab.name}
                              </Link>
                            </li>
                          );
                        }
                      } else {
                        if (tab.name == 'SETTINGS') {
                          return;
                        }
                        return (
                          <li
                            class="nav-item dropdown"
                            onClick={() => {
                              this.onChangeTab(tab.name);
                            }}
                            className={
                              this.props.activeTab.toUpperCase() == tab.name
                                ? 'nav-item active'
                                : activePage === 'beta_readers' ||
                                  activePage === 'blogger' ||
                                  activePage === 'followers' ||
                                  activePage === 'following' ||
                                  activePage === 'all' ||
                                  activePage === 'most_followed'
                                ? 'nav-item active'
                                : 'nav-item'
                            }
                          >
                            <Link
                              class="nav-link dropdown"
                              id="navbarDropdownMenuLink"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className="nav-link"
                              to=""
                            >
                              {tab.name}
                            </Link>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="navbarDropdownMenuLink"
                            >
                              {subMenuArray.length > 0 &&
                                subMenuArray.map((subMenu, index) => {
                                  return (
                                    <>
                                      <li style={{ fontSize: '14px' }}>
                                        {/* <a class="dropdown-item" href="#">{subMenu.name}</a> */}
                                        <Link
                                          class="dropdown-item"
                                          to={'/dashboard' + subMenu.weblink}
                                        >
                                          {subMenu.name}
                                        </Link>
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                          </li>
                        );
                      }
                    })
                  : ''}
              </ul>
            </div>
          </nav>

          <div className="noti_setting">
            {/* <a className="" href="#"> */}
            <Popover
              isOpen={this.state.showNotification}
              position={'bottom'}
              // disableReposition
              onClickOutside={() => this.setState({ showNotification: false })}
              content={({ position, targetRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  targetRect={targetRect}
                  popoverRect={popoverRect}
                  arrowColor={'blue'}
                  arrowSize={5}
                  arrowStyle={{ opacity: 0.7 }}
                >
                  <div
                    class="alert alert-dark notif_wrap"
                    role="alert"
                    style={{ zIndex: 20 }}
                  >
                    <h4 class="alert-heading">Notifications</h4>

                    {!isBlogger &&
                    !isReader &&
                    this.props.notificationList.dailyTaskCount > 0 ? (
                      <div
                        class="sub_notification mousechange"
                        onClick={() => this.getDailyTask()}
                        role="alert"
                      >
                        <span
                          ref="currentData"
                          className={`${
                            today_daily_task.length > 0
                              ? 'notification-span'
                              : ''
                          }`}
                        >
                          {today_daily_task.length}
                        </span>{' '}
                        <p>Daily Tasks</p>
                      </div>
                    ) : (
                      <>
                        {!(
                          localStorage.getItem('membershipId') === '2' ||
                          localStorage.getItem('membershipId') === '3'
                        ) ? (
                          <div
                            class="sub_notification mousechange"
                            onClick={() => this.getdailyTaskRedirect()}
                            role="alert"
                          >
                            <span
                              ref="currentData"
                              className={`${
                                today_daily_task.length > 0
                                  ? 'notification-span'
                                  : ''
                              }`}
                            >
                              {today_daily_task.length}
                            </span>{' '}
                            <p>Daily Tasks</p>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                    {this.props.notificationList.currentEventCount > 0 ? (
                      <div
                        class="sub_notification mousechange"
                        onClick={() =>
                          this.getNoficationList(
                            this.props.notificationList.currentUserData
                          )
                        }
                        role="alert"
                      >
                        <span
                          ref="currentData"
                          className={`${
                            notifylink && notifylink.length > 0
                              ? 'notification-span'
                              : ''
                          }`}
                        >
                          {notifylink && notifylink.length > 0
                            ? notifylink.length
                            : 0}
                        </span>{' '}
                        <p>
                          {' '}
                          Events{' '}
                          {this.props.notificationList.weekly
                            ? 'this week'
                            : 'today'}
                        </p>
                      </div>
                    ) : (
                      <>
                        <div
                          class="sub_notification mousechange"
                          onClick={() => this.getCurrentNotification()}
                          role="alert"
                        >
                          <span
                            ref="currentData"
                            className={`${
                              notifylink && notifylink.length > 0
                                ? 'notification-span'
                                : ''
                            }`}
                          >
                            {notifylink && notifylink.length > 0
                              ? notifylink.length
                              : 0}
                          </span>{' '}
                          <p>
                            {' '}
                            Events{' '}
                            {this.props.notificationList.weekly
                              ? 'this week'
                              : 'today'}
                          </p>
                        </div>
                      </>
                    )}

                    {this.props.notificationList.followingCount > 0 ? (
                      <div
                        class="sub_notification mousechange"
                        onClick={() =>
                          this.followingEvent(
                            this.props.notificationList.sevenDaysEvent
                          )
                        }
                        role="alert"
                      >
                        {/* <span
                            ref="followingData"
                            className={`${
                              this.props.notificationList.followingData.length >
                              0
                                ? 'notification-span'
                                : ''
                            }`}
                          >
                            {this.props.notificationList.followingData.length}


                            
                          </span>{' '} */}
                        <span
                          ref="followingData"
                          className={`${
                            notifylinkfollowing &&
                            notifylinkfollowing.length > 0
                              ? 'notification-span'
                              : ''
                          }`}
                        >
                          {notifylinkfollowing && notifylinkfollowing.length > 0
                            ? notifylinkfollowing.length
                            : 0}
                        </span>{' '}
                        <p> Events by members I follow</p>
                      </div>
                    ) : (
                      <>
                        <div
                          class="sub_notification mousechange"
                          onClick={() => this.followingEventRedirect()}
                          role="alert"
                        >
                          <span
                            ref="followingData"
                            className={`${
                              notifylinkfollowing &&
                              notifylinkfollowing.length > 0
                                ? 'notification-span'
                                : ''
                            }`}
                          >
                            {notifylinkfollowing &&
                            notifylinkfollowing.length > 0
                              ? notifylinkfollowing.length
                              : 0}
                          </span>{' '}
                          <p> Events by members I follow</p>
                        </div>
                      </>
                    )}

                    {this.props.notificationList.blastNotifications > 0 ? (
                      <div
                        className="sub_notification mousechange"
                        onClick={() =>
                          this.getBlastNotification(
                            this.props.notificationList.blastData
                          )
                        }
                        role="alert"
                      >
                        <span
                          ref="currentData"
                          className={`${
                            notifyBlast && notifyBlast.length > 0
                              ? 'notification-span'
                              : ''
                          }`}
                        >
                          {notifyBlast && notifyBlast.length > 0
                            ? notifyBlast.length
                            : 0}
                        </span>{' '}
                        <p>Blasts</p>
                      </div>
                    ) : (
                      <div
                        className="sub_notification mousechange"
                        onClick={() => this.redirectBlastNotification()}
                        role="alert"
                      >
                        <span
                          ref="currentData"
                          className={`${
                            notifyBlast && notifyBlast.length > 0
                              ? 'notification-span'
                              : ''
                          }`}
                        >
                          {0}
                        </span>{' '}
                        <p>Blasts</p>
                      </div>
                    )}

                    {this.state.showNotification && (
                      <>
                        <div
                          class="sub_notification mousechange"
                          onClick={() => this.getMostPopularevents()}
                          role="alert"
                        >
                          Trending Events
                        </div>
                        <div
                          className="sub_notification mousechange"
                          onClick={() => this.getNewletter()}
                          role="alert"
                        >
                          OTR Newsletter
                        </div>
                      </>
                    )}
                  </div>
                </ArrowContainer>
              )}
            >
              <em
                className="fa fa-2x fa-bell mousechange"
                style={
                  this.props.displayMode
                    ? {
                        color: '#202020',
                        fontSize: '1.7em',
                        marginTop: 3,
                        marginRight: 10
                      }
                    : {
                        color: 'white',
                        fontSize: '1.7em',
                        marginTop: 3,
                        marginRight: 10
                      }
                }
                onClick={() => this.showNotification()}
              >
                {totalNotification !== 0 && (
                  <div>
                    <span className="badge custom-badge mousechange">
                      {totalNotification}
                    </span>
                  </div>
                )}
              </em>
            </Popover>

            {/* <div className={this.state.showNotification ? "dropdown-content show" : "dropdown-content"}>
                       
                        <Link to={'/dashboard/membership'}><button className="btn btn-link" style={{ color: "black" }} onClick={() => this.showDropDown()}>Membership info</button></Link>
                        </div> */}

            <Popover
              isOpen={this.state.showMenu}
              position={['right', 'bottom']}
              // padding={10}
              // disableReposition
              // disableReposition
              onClickOutside={() => this.setState({ showMenu: false })}
              content={({ position, targetRect, popoverRect }) => (
                <ul className="showPopover">
                  {isBlogger && (
                    <li>
                      <Link to="/dashboard/my-profile">
                        <button
                          className="btn btn-link"
                          style={{ color: 'black' }}
                          onClick={() => this.showDropDown()}
                        >
                          My profile
                        </button>
                      </Link>
                    </li>
                  )}

                  {data.map((tab, index) => {
                    if (tab.name == 'SETTINGS') {
                      return tab.subMenu.map(sub => {
                        if (sub.name !== 'Logout') {
                          return (
                            <li>
                              <Link to={sub.weblink}>
                                <button
                                  className="btn btn-link"
                                  style={{ color: 'black' }}
                                  onClick={() => this.showDropDown()}
                                >
                                  {sub.name}
                                </button>
                              </Link>
                            </li>
                          );
                        }
                        // else {
                        //   return (
                        //     <li>
                        //       <a href="/">
                        //         <button
                        //           className="btn btn-link"
                        //           style={{ color: "black" }}
                        //           onClick={() => {
                        //             localStorage.clear();
                        //           }}
                        //         >
                        //           Log out
                        //         </button>
                        //       </a>
                        //     </li>
                        //   );
                        // }
                      });
                    }
                  })}
                  {isPRFirm && (
                    <li>
                      <Link to="/dashboard/blasts">
                        <button
                          className="btn btn-link"
                          style={{ color: 'black' }}
                          onClick={() => this.showDropDown()}
                        >
                          Blasts
                        </button>
                      </Link>
                    </li>
                  )}
                  {data.map((tab, index) => {
                    if (tab.name == 'SETTINGS') {
                      return tab.subMenu.map(sub => {
                        if (sub.name == 'Logout') {
                          return (
                            <li>
                              <a href="/">
                                <button
                                  className="btn btn-link"
                                  style={{ color: 'black' }}
                                  onClick={() => {
                                    localStorage.clear();
                                  }}
                                >
                                  Log out
                                </button>
                              </a>
                            </li>
                          );
                        }
                      });
                    }
                  })}
                </ul>
              )}
            >
              <em
                className="fa fa-2x fa-cog mousechange"
                style={
                  this.props.displayMode
                    ? { color: '#202020' }
                    : { color: 'white' }
                }
                onClick={() => this.showDropDown()}
              ></em>
            </Popover>

            {/* </a> */}
          </div>
          {/* </div> */}

          <div></div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.props.getAllTask(`/api/v1/daily_task/getTask`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    this.props.getNotificationList(`/api/v1/notification`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }

  // componentWillReceiveProps() {
  //   // if (!this.props.eventsData || this.props.eventsData === "") {
  //   //   this.props.getAllEvents(this.state.myevents);
  //   // }
  //   // if (!this.props.events || this.props.events === "") {
  //   //   this.props.getEvents(this.state.myevents);
  //   // }
  //   //this.props.getAllEvents();
  //   //this.setState({ formJSON: this.props.formJSON });
  // }
  // componentDidUpdate(prevProps, prevState) {
  //   console.log('prevProps', prevProps.notificationList, prevState,this.state.notificationData);
  //   if (prevState.data !== this.state.notificationData) {
  //     // Now fetch the new data here.
  //     this.setState({notificationData:})
  //   }
  // }

  // this.props.getNotificationList(`/api/v1/notification`, {
  //   headers: { 'x-authorization': localStorage.getItem('token') }
  // });
}

const mapStateToProps = state => {
  return {
    loading: state.eventLoading,
    eventsData: state.eventsummary,
    events: state.events,
    activeTab: state.Tab,
    search: state.Search,
    menuList: state.dashboardTabs.menuList,
    displayMode: state.changeMode,
    displayEventMode: state.getEventMode,
    notificationList: state.getNotification,
    messageCount: state.countUnreadedMessages,
    profileData: state.myProfileData,
    taskData: state.filterTask
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setupEvent: () => dispatch(setupEvent()),
    getAllEvents: eventMode =>
      dispatch(
        getAllEventsSummary(`/api/v1/events/day/count?isGeneral=${eventMode}`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    getEvents: eventMode =>
      dispatch(
        getAllEvents(`/api/v1/events?isGeneral=${eventMode}`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    changeTab: tab => dispatch(Tab(tab)),
    setSearchFilter: search => dispatch(Search(search)),
    clearFilterEvents: () => dispatch(filterevent([])),
    clearSearch: () => dispatch(Search('')),
    getDisplayMode: () => dispatch(changeDisplayMode()),
    eventDisplayMode: mode => dispatch(changeEventMode(mode)),
    getUserProfile: (url, header, edit) =>
      dispatch(getProfileDetails(url, header, edit)),
    getNotificationList: (url, header) =>
      dispatch(getNotificationlist(url, header)),
    getMessageCount: (url, header) =>
      dispatch(countUnreadMessages(url, header)),
    getAllTask: (url, headers) => dispatch(getAllTasks(url, headers)),
    changePopularEvents: status => dispatch(popularEvents(status))
    // getMenuList:(url,headers)=>dispatch(getDashboardTabs(url,headers))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
