import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { GridLoader, BounceLoader } from 'react-spinners';
import { authenticate, signuppage } from '../../Actions/index';
import '../Landing/landing.css';
import Axios from 'axios';

const override = css`
  position: relative;
`;

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Helmet>
          <body className="home-main-page" />
        </Helmet>
        <div className="mid-section landing-page home-page">
          <div className="main-wrapper">
            <div className="container">
              <section className="optimizeBox">
                <div className="row">
                  <div className="col-12 text-center main-title">
                    <h1>Your Romance book life, optimized</h1>
                  </div>
                  <div className="col-12 col-md-3 text-md-left text-center">
                    <img
                      className="brand-logo black-logo"
                      src={require('../../Utils/Assets/Black_Background.png')}
                      alt="Logo"
                    />
                    <img
                      className="brand-logo white-logo"
                      src={require('../../Utils/Assets/logo_3.png')}
                      alt="Logo"
                    />
                  </div>
                  <div className="col-12 col-md-9 text-md-left text-center pl-5">
                    <p className="welcome-text">
                      Welcome to <b>On Target Romance</b>, a cutting-edge
                      organizational calendar platform for romance readers,
                      authors and industry professionals to optimize their
                      reading and stay up-to-date with book related events and
                      exclusives.
                    </p>
                    <div className="row align-items-end">
                      <div className="col-12 col-md-4">
                        <div className="signup-btn">
                          {localStorage.getItem('token') ? (
                            ''
                          ) : (
                            <Link
                              to="/join"
                              class="btn btn_save_bt radius8 text-white bg-dark mt-0"
                            >
                              Sign Up
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-8">
                        <ul className="download-links list-unstyled d-flex align-items-center justify-content-center justify-content-md-end">
                          <li>
                            <a href="/#">
                              {' '}
                              <img
                                src={require('../../Utils/Assets/google-play-store-icon.png')}
                                alt="Google Play"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              {' '}
                              <img
                                src={require('../../Utils/Assets/apple-app-store-icon.png')}
                                alt="App Store"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="writing-promoting-section">
                <div className="row">
                  <div className="col-12 text-center main-title">
                    <h1>
                      Stay on Target in your reading, writing &amp; promoting
                    </h1>
                  </div>
                  <div className="col-12">
                    <ul className="ul_plans list-unstyled">
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Books_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon01.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>OTR Hub</h5>
                        <p>
                          Stay on target in your Romance reading & book-related
                          events with the OTR app & website.
                        </p>
                      </li>
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Calendar_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon02.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>Features</h5>
                        <p>
                          Calendar consists of:
                          <br />
                          - Book Release events
                          <br />
                          - Promo Tours, Cover Reveals & ARC Sign-ups
                          <br />
                          - Release Parties & Giveaways
                          <br />- Book Signing events
                        </p>
                      </li>
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Dartboard_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon03.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>Choose</h5>
                        <p>
                          Target the right plan for your professional or reading
                          needs as an Author, Reader, Blogger, PR Firm or Vendor
                          (editor, agent, designer, PA, consultant etc.). <br />
                          <i> *Premium plans available.</i>
                        </p>
                      </li>
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Phone_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon04.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>Stay Current</h5>
                        <p>
                          Keep apprised of Romance book events by Authors, PR
                          Firms, Vendors & Premium Bloggers.
                          {/* Member generated
                        content includes both public & exclusive to OTR events. */}
                        </p>
                      </li>
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Folder_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon05.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>Get Organized</h5>
                        <p>
                          Quickly browse & sort OTR calendar, save events & set
                          reminders. Plan your writing & reading.
                        </p>
                      </li>
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Magnifying glass_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon06.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>Easy Search</h5>
                        <p>
                          - Customize searches by genres, tropes, authors &
                          more.
                          <br />
                          - Book release page links to all release events.
                          <br />- All events listed by an author are linked in
                          their profile.
                        </p>
                      </li>
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Paperholder_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon07.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>Daily Tasks</h5>
                        <p>
                          Use the private notepad* with a reminder function to
                          help you stay organized. <br />
                          <i>*Applicable to certain plans.</i>
                        </p>
                      </li>
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Network_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon08.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>Network</h5>
                        <p>
                          Authors have access to exclusive resources to connect
                          with vendors, search for bloggers & find beta readers.
                        </p>
                      </li>
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Globe_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon09.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>One-stop Marketing</h5>
                        <p>
                          - Quickly organize PR launch of book releases.
                          <br />- All book release events are linked via
                          author's profile & book release page.
                        </p>
                      </li>
                      <li>
                        <div className="divImg">
                          {!this.props.displayMode ? (
                            <img
                              src={require('../../Utils/Assets/white/Puzzle (Not Blurred)_White_Png.png')}
                              alt="Plans Icon"
                            />
                          ) : (
                            <img
                              src={require('../../Utils/Assets/plans-icon10.png')}
                              alt="Plans Icon"
                            />
                          )}
                        </div>
                        <h5>Stay on Target</h5>
                        <p>
                          - Never miss another cover reveal, book release,
                          release party or giveaway.
                          <br />- Free more time to read, write or simply relax!
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 text-right">
                    <div className="see-plans-btn">
                      <Link
                        to="/plans"
                        class="btn btn_save_bt radius8 text-white bg-dark"
                      >
                        See Plans
                      </Link>
                    </div>
                  </div>
                </div>
              </section>

              <section className="features-plans-section">
                <div className="row">
                  <div className="col-12 main-title text-center">
                    <h1>
                      Learn more about our membership plans &amp; features
                    </h1>
                  </div>
                  <div className="col-md-12 m-auto">
                    <div className="row" />
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <VideoFrames />
                      </div>
                      <div className="col-12 col-sm-6">
                        <VideoFrames />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <VideoFrames />
                      </div>
                      <div className="col-12 col-sm-6">
                        <VideoFrames />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="our-members-section">
                <div className="row">
                  <div className="col-12 main-title text-center">
                    <h1>What our members say</h1>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="members-list">
                      <em>
                        "Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Vero quo earum vitae dolorem perspiciatis amet
                        dolores quod quos dignissimos totam! Quidem incidunt
                        adipisci porro rerum. Eveniet nesciunt debitis magni
                        illum."
                      </em>
                      <h5>Lorem ipsum dolor</h5>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="members-list">
                      <em>
                        "Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Vero quo earum vitae dolorem perspiciatis amet
                        dolores quod quos dignissimos totam! Quidem incidunt
                        adipisci porro rerum. Eveniet nesciunt debitis magni
                        illum."
                      </em>
                      <h5>Lorem ipsum dolor</h5>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="members-list">
                      <em>
                        "Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Vero quo earum vitae dolorem perspiciatis amet
                        dolores quod quos dignissimos totam! Quidem incidunt
                        adipisci porro rerum. Eveniet nesciunt debitis magni
                        illum."
                      </em>
                      <h5>Lorem ipsum dolor</h5>
                    </div>
                  </div>
                  {/* {( !localStorage.getItem('cookiepolicy') && localStorage.getItem('userId')) ? (
                    <div class="cookip-policy">
                      <div class="cookip-inner">
                        <div class="container">
                          <div class="row">
                            <div class="col-md-9">
                              <b>
                                {" "}
                                We use cookies to ensure the functionality Of our
                                platform, to personalize content, to provide media
                                features and ads, and to analyze our traffic. For more
                                information, please see our{" "}
                                <Link to={"/privacy"} className="red">
                                  <u>privacy policy.</u>
                                </Link>
                              </b>
                            </div>
                            <div class="col-md-3 d-flex align-items-center mt-3 mt-mb-0">
                              <button
                                class="btn btn-lg cookip-btn"
                                onClick={(e) => this.handleSaveCookies()}
                              >
                                Accept
                              </button>
                              <button
                                class="close ml-auto"
                                onClick={(e) => this.handleSaveCookies}
                              >
                                <i class="fa fa-times-circle"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    // this.props.resetSignupPage();
    // console.log('componentDidMount');
  }
}

// {======= VideoFrames Component ========}
const VideoFrames = () => (
  <div className="video">
    <iframe
      width="100%"
      src="https://www.youtube.com/embed/yodw5jIOBt4"
      frameBorder="0"
      title="video 1"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

const mapStateToProps = state => {
  return {
    displayMode: state.changeMode
  };
};

const mapDispatchToProps = dispatch => {};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
